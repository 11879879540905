import React, { useEffect, useState, useContext } from 'react';
import "../../../assets/PCP_HDSC_V3/css/idupload.scss"
import uploaddemo from "../../../assets/PCP_HDSC_V3/img/upload.gif"
import Header_PCP_HDSC_V3 from "../../Includes/Layouts/PCP_HDSC_V3/Header";
import Button from '../../UI/Button';
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { queryString } from "../../../Utility/QueryString";
import { useIdUpload } from "../../../Hooks/useIdUpload";
import Footer from "../../Includes/Layouts/PCP_HDSC_V3/Footer";
import 'bootstrap-icons/font/bootstrap-icons.css';
import GetVisitorsParams from '../../../Utility/GetVisitorsParams';
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";

const PcpIdUploadV3 = () => {
  const [showSlide2, setShowSlide2] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [idType, setIdType] = useState('');
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  const [isInValidfFile, setIsInValidfFile] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState(false);
  const [userfile, setUserfile] = useState({ preview: '', title: '' })
  const [btnLoader, setBtnLoader] = useState(false);
  const [progressbar ,setProgressBar] = useState(false);
  const handleNextUploadClick = (e) => {
    setIdType(e.target.id)
    setShowSlide2(true);
  };
  const [base64Image, setBase64Image] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const { saveIdUpload, isLoading } = useIdUpload();
  const getUuid = () => {
    const visitorData = visitorParameters.visitor_parameters.uuid;
    if (visitorData) {
      var uuid = visitorData;
    } else {
      const query = new URLSearchParams(window.location.search);
      const query_uuid = query.get("uuid");
      const local_storage_uuid = localStorage.getItem("uuid");
      var uuid = query_uuid ? query_uuid : local_storage_uuid;
    }
    return uuid;
  };
  const uuid = getUuid();
  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit(
    "PCP_HDSC_V3",
    "id-upload-v3"
);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const timeout = setTimeout(() => {
      setShowLoader(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);




  const handleFileChange = (event) => {
    setProgressBar(true);
    const HandleChangeTimeout = setTimeout(() => {
      setSelectedFile(event.target.files[0]);
      let imageFile = event.target.files[0];
      if (imageFile) {
        let extension = event.target.files[0].type;
        let imgsize = event.target.files[0].size;
        let allowedExtensions = ["image/jpeg", "image/png"];
        if (allowedExtensions.indexOf(extension) === -1) {
          setIsInValidfFile(true);
          setErrorMessage({
            txt: "The uploaded ID type should be an image in jpeg,png or jpg",
          });
          return false;
        } else if (imgsize > 1024 * 1024 * 20) {
          setIsInValidfFile(true);
          setErrorMessage({ txt: "Please choose a file less than 20mb" });
          return false;
        } else {
          setSelectedFileType(event.target.name);
          setIsInValidfFile(false);
          setIsFileSelected(true);
        }

        setUserfile({ preview: URL.createObjectURL(imageFile), title: imageFile.name })
        if (imageFile) {
          let reader = new FileReader();
          reader.onload = _handleReaderLoaded.bind(this);
          reader.readAsDataURL(imageFile);
        }
      } else {
        setIsInValidfFile(true);
        setUserfile({ preview: '', title: '' })
      }
      setProgressBar(false);
    }, 2000);
  };

  const handleButtonClick = (e) => {
    if (e.target.name == 'photo') {
       document.getElementById(`${idType}_cam`).click();
    } 
     if (e.target.name == 'image') {
      document.getElementById(`${idType}_doc`).click();
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setBase64Image({
      base64TextString: binaryString,
    });
  };
  const retakeClick = (e) => {
    setUserfile(({ preview: '', title: '' }));
    setIsFileSelected(false);
    setSelectedFile('');
  };
  const handleSubmit = async (event) => {
    setBtnLoader(true);
    event.preventDefault();
    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");

    let uploadData = "";
    if (isFileSelected) {
      uploadData = {
        imageFile: base64Image.base64TextString,
        captureType: selectedFileType.split('_')[0],
        documentType: selectedFileType.split('_')[0],
      };
      let providers = '';
      const response = await saveIdUpload(
        uploadData,
        visitorParameters.visitor_parameters,
        formParameters,
        visitorParameters.data,
        providers,
        queryString,
        "user_docs_store"
      );
      setSelectedFile('');
      setBase64Image('');
      setSelectedFileType('');
      history.push("/" + DynamicRouteNextPage + "?uuid=" + uuid + "&split_name=" + DynamicRouteSplitName);
    } else {
      setBtnLoader(false)
      setIsInValidfFile(true);
      setErrorMessage({ txt: "Please upload a file" });
    }

  };

  const handleBackbutton = () =>{
    setShowSlide2(false);
    setIsFileSelected(false);
    setSelectedFileType('');
    setUserfile({ preview: '', title: '' });
    setBase64Image('');
    setErrorMessage('');
  }
  return (
    <>
    <GetVisitorsParams/>
      <div className='id-upload-V3'>
        {showLoader && (
          <div id="preloader">
            <div id="loader" />
          </div>
        )}
        <Header_PCP_HDSC_V3 />
        <section className="bg-gray   p-2  uploads-section   ">
          <div className="bg-inner bg-white rounded-2 p-lg-5 p-md-5 p-sm-4 p-3 pt-lg-0 pt-md-0 pt-sm-0 pt-3 ">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 offset-lg-3">
                  <h1 className="fw-bold my-4  animate__animated animate__fadeInUp  mt-lg-5 mt-md-5 mt-sm-4 mt-4 ">
                    {" "}
                    Now, Let's verify your identity
                  </h1>
                  <h5 className=" animate__animated animate__fadeInUp">
                    Your ID will be stored securely and used solely for the purpose of
                    identity verification.
                  </h5>
                  <div className="mt-5 mb-5 animate__animated animate__fadeInUp">
                    {!showSlide2 ? (<div className="uploadbtns ">
                      {/* <Button
                        className="uploads id_upLoad w-100 fw-bold mb-3"
                        id="nationalId"
                        name="idType"
                        onClick={handleNextUploadClick}
                        buttonText={
                          <>
                            <span style={{ pointerEvents: 'none' }}>
                              <i className="bi bi-person-vcard mx-2" />
                              National ID Card{" "}
                            </span>
                            <span className="float-end" style={{ pointerEvents: 'none' }}>
                              <img height="35px" src={uploaddemo} alt="" />
                            </span>{" "}
                          </>
                        }
                      /> */}
                      <Button
                        className="uploads w-100 id_upLoad fw-bold mb-3"
                        id="Drriving"
                        name="idType"
                        onClick={handleNextUploadClick}
                        buttonText={
                          <>
                            <span style={{ pointerEvents: 'none' }}>
                              <i className="bi bi-car-front-fill mx-2" />
                              Driver's License{" "}
                            </span>
                            <span className="float-end" style={{ pointerEvents: 'none' }}>
                              <img height="35px" src={uploaddemo} alt="" />
                            </span>
                          </>
                        }
                      />
                      <Button
                        className="uploads w-100 id_upLoad fw-bold mb-3"
                        id="passport"
                        name="idType"
                        onClick={handleNextUploadClick}
                        buttonText={
                          <>
                            <span style={{ pointerEvents: 'none' }}>
                              <i className="bi bi-airplane mx-2" />
                              Passport{" "}
                            </span>
                            <span className="float-end" style={{ pointerEvents: 'none' }}>
                              <img height="35px" src={uploaddemo} alt="" />
                            </span>
                          </>
                        }
                      />
                    </div>) : (
                      <div>
                        <div id="uploarea" >
                          <div className="fw-bold mb-2 fs-5 backupload" onClick={()=>{handleBackbutton()}}>
                            {" "}
                            <i className="bi bi-arrow-left" /> Back
                          </div>
                          <div className="up-wrap p-1">
                            {isFileSelected ? (
                              <div className="uploaded" >
                                <img
                                  className="img-fluid"
                                  style={{maxWidth : '100%', height : '190px'}}
                                  src={userfile.preview}
                                  alt={userfile.title}
                                />
                              </div>) : (
                              <div className="uploadbox">
                                <div>
                                  <div>
                                    <i className="bi bi-cloud-upload" />
                                  </div>
                                  <div className="upload-text"> Upload image </div>
                                </div>
                               {progressbar && <div
                                  className="text-center imgloader "
                                >
                                  <div
                                    className="progress"
                                    role="progressbar"
                                    aria-label="Animated striped example"
                                    aria-valuenow={75}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  >
                                    <div
                                      className="progress-bar progress-bar-striped progress-bar-animated bg-warning"
                                      style={{ width: "100%" }}
                                    />
                                  </div>
                                </div>}
                              </div>)}
                          </div>
                          {!isFileSelected && <div className="row mt-3 upactionbtn">
                            <div className="col-6">
                              <Button
                                className="upl-btn w-100 fw-bold"
                                onClick={(e)=>handleButtonClick(e)}
                                name = "photo"
                                buttonText={
                                  <>
                                    <span style={{ pointerEvents: 'none' }}>
                                      <i className="bi bi-camera" /> Take Photo{" "}
                                      <input
                                        type="file"
                                        accept="image/*"
                                        capture="camera"
                                        id={`${idType}_cam`}
                                        name={`${idType}_cam`}
                                        onChange={handleFileChange}
                                        
                                      />
                                    </span>
                                  </>
                                }
                              />
                            </div>
                            <div className="col-6 ps-0">
                              <Button
                                className="upl-btn  img-uploadbtn w-100 fw-bold"
                                onClick={(e)=>handleButtonClick(e)}
                                name = "image"
                                buttonText={
                                  <>
                                    <span style={{ pointerEvents: 'none' }}>
                                      <i className="bi bi-image" />  Upload image{" "}
                                      <input
                                        type="file"
                                        accept="image/*"
                                        id={`${idType}_doc`}
                                        name={`${idType}_doc`}
                                        onChange={handleFileChange}
                                      />
                                    </span>
                                  </>
                                }
                              />
                            </div>
                          </div>}
                          {isFileSelected && <div className="row retake mt-3">
                            <div className="col-12  ">
                              <Button
                                className="upl-btn w-100 fw-bold "
                                onClick={(e) => retakeClick(e)}
                                buttonText={
                                  <>
                                    <i className="bi bi-arrow-repeat" />
                                    &nbsp;&nbsp; Retake
                                  </>
                                }
                              />
                            </div>
                          </div>}
                          {isFileSelected && (<div
                            className="mt-lg-5  mt-md-5 mt-sm-3 mt-3  gonext"
                          >
                            <Button
                              id="next2"
                              className=" btn-comm2 p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-3"
                              onClick={(e) => (!btnLoader ? handleSubmit(e) : null)}
                              buttonText={
                                <>
                                  <span className="fw-bold">Submit
                                    {btnLoader &&
                                      <svg className="ms-2" width={30} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><linearGradient id="a10"><stop offset="0" stopColor="#FFFFFF" stopOpacity="0"></stop><stop offset="1" stopColor="#FFFFFF"></stop></linearGradient><circle fill="none" stroke="url(#a10)" strokeWidth="25" strokeLinecap="round" strokeDasharray="0 44 0 44 0 44 0 44 0 360" cx="100" cy="100" r="70" transform-origin="center"><animateTransform type="rotate" attributeName="transform" calcMode="discrete" dur="2" values="360;324;288;252;216;180;144;108;72;36" repeatCount="indefinite"></animateTransform></circle></svg>
                                    }
                                  </span>
                                </>
                              }
                            />
                          </div>)}
                        </div>
                      </div>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default PcpIdUploadV3;
