import React, { useState } from 'react';

const PrivacyModal = ({ privacyModal, setPrivacyModal }) => {
    return (
        <>
            {/* Modal  privacy*/}
            <div
                // className="modal fade"
                className={`modal fade show ${privacyModal ? "d-block" : "d-none"}`}
                id="privacymodal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl ">
                    <div className="modal-content footermodal">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Privacy Policy
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setPrivacyModal(false)}
                            />
                        </div>
                        <div className="modal-body d-block">
                            <p>
                                This Privacy Policy sets out how Henderson Claims Ltd t/a
                                ‘Hendersons’, collects, uses and protects your information when you
                                use this website or our services, in accordance with the Data
                                Protection Act 2018 and the General Data Protection Regulation 2016
                                (GDPR).
                            </p>
                            <p>
                                Hendersons is the controller and is responsible for your personal
                                data. We are committed to ensuring that your privacy is protected.
                                By using our website, you may be providing us with some of your
                                personal information and we want to make sure that we do not use
                                your data in a way that you would not expect. Hendersons assures you
                                that your personal information will only be used in accordance with
                                this privacy statement and in compliance with the latest privacy
                                regulations.
                            </p>
                            <p>
                                Hendersons may change this policy in future by updating this page.
                                You should check this page from time to time to ensure that you are
                                happy with any changes. This policy is effective from January 2021.
                            </p>
                            <h4>Personal Information We Collect</h4>
                            <p>
                                Personal information is any information about an individual from
                                which that person can be identified. It does not include data which
                                has been anonymised.
                            </p>
                            <p>
                                We may collect, use, store and transfer different kinds of personal
                                data about you which we have grouped together as follows:We may
                                collect, use, store and transfer different kinds of personal data
                                about you which we have grouped together as follows:
                            </p>
                            <ul>
                                <li>
                                    <strong>Contact information:</strong> Name, current and previous
                                    addresses (including postal, email and IP addresses) and telephone
                                    numbers.{" "}
                                </li>
                                <li>
                                    <strong>Identity Data</strong> includes first name, maiden name,
                                    last name, username or similar identifier, marital status, title,
                                    date of birth and gender.
                                </li>
                                <li>
                                    <strong>Financial Data:</strong> includes (but not limited to)
                                    previous lenders, financial institutions and financial advisors
                                    used by you and the financial products, services and/or advice
                                    taken.{" "}
                                </li>
                                <li>
                                    <strong>Usage Data</strong> includes information about how you use
                                    our website, products and services.{" "}
                                </li>
                                <li>
                                    <strong>Marketing and Communications Data</strong> includes your
                                    preferences in receiving marketing from us and our third parties
                                    and your communication preferences.
                                </li>
                                <li>
                                    <strong>Special Category Data:</strong> We will only process
                                    special category data where you have provided this to us and given
                                    your explicit consent to store it or we have a legal/regulatory
                                    obligation to. This includes race or ethnicity, religious or
                                    philosophical beliefs, sex life, sexual orientation, political
                                    opinions, trade union membership, information about your health
                                    and genetic and biometric data.{" "}
                                </li>
                            </ul>
                            <p>
                                This list may not include all the information we require when
                                providing our service/s. The information we ask you for will relate
                                to your specific enquiry and will only consist of the information we
                                need to carry out our service. If you contact us other than via our
                                website, we may keep a record of that correspondence and a copy of
                                call recordings.
                            </p>
                            <p>
                                We also collect, use and share Aggregated Data such as statistical
                                or demographic data for any purpose. Aggregated Data may be derived
                                from your personal data but is not considered personal data in law
                                as this data does not directly or indirectly reveal your identity.
                                For example, we may aggregate your Usage Data to calculate the
                                percentage of users accessing a specific website feature. However,
                                if we combine or connect Aggregated Data with your personal data so
                                that it can directly or indirectly identify you, we treat the
                                combined data as personal data which will be used in accordance with
                                this privacy policy.
                            </p>
                            <h4>If you fail to provide personal data</h4>
                            <p>
                                Where we need to collect personal data by law, or under the terms of
                                a contract we have with you and you fail to provide that data when
                                requested, we may not be able to perform the contract we have or are
                                trying to enter into with you (for example, to provide you with
                                goods or services). In this case, we may have to cancel a product or
                                service you have with us, but we will notify you if this is the case
                                at the time.
                            </p>
                            <h4>Method of Data Collection</h4>
                            <ul>
                                <li>
                                    <strong>Direct interactions:</strong> By you filling in forms on
                                    our website or social media or by corresponding with us by post,
                                    phone, email or otherwise.
                                </li>
                                <li>
                                    <strong>Third parties or public sources:</strong> We may receive
                                    information about you from various reputable third-party lead
                                    providers, from regulators or from publicly available data. We
                                    will always inform you where we have obtained your information
                                    from if requested by you.{" "}
                                </li>
                            </ul>
                            <h4>Cookies</h4>
                            <p>
                                A cookie is a small file of letters and numbers that we store on
                                your browser or the hard drive of your computer if you agree. As you
                                interact with our website, we may automatically collect Technical
                                Data and Usage Data about your equipment, browsing actions and
                                patterns. We collect this personal data by using Third-Party
                                cookies, specifically Google Analytics. You can view Google’s
                                privacy policy here:&nbsp;
                                <a href="http://www.google.com/policies/privacy">
                                    www.google.com/policies/privacy.
                                </a>
                            </p>
                            <p>
                                Our website uses cookies to distinguish you from other users of our
                                website. This helps us to provide you with a good experience when
                                you browse our website and also allows us to improve our site. You
                                will be asked to provide your consent for us to use cookies upon
                                every visit to our website.
                            </p>
                            <p>
                                You can set your browser to refuse all or some browser cookies, or
                                to alert you when websites set or access cookies. Also, to opt out
                                of being tracked via Google Analytics, you can use Google’s opt-out
                                browser add-on:&nbsp;
                                <a href="https://tools.google.com/dlpage/gaoptout">
                                    https://tools.google.com/dlpage/gaoptout
                                </a>
                            </p>
                            <p>
                                If you disable or refuse cookies, please note that some parts of
                                this website may become inaccessible or not function properly.
                            </p>
                            <h4>The basis on which we process your information</h4>
                            <p>
                                The legal grounds for processing your personal information depend
                                upon the nature of our relationship with you and the context of
                                processing and are as follows:{" "}
                            </p>
                            <ul>
                                <li>
                                    Processing is necessary for the performance of a contract with
                                    you, or to take steps prior to entering into a contract with you.
                                </li>
                                <li>
                                    Processing is necessary for the purposes of your legitimate
                                    interests or our legitimate interests, where your fundamental
                                    rights and interests do not override those interests. In order to
                                    determine this we shall undertake a Legitimate Interests
                                    Assessment, which we keep a record of and review on an annual
                                    basis or, at any point where it is deemed necessary to review
                                    and/or update it.{" "}
                                </li>
                                <li>
                                    Processing is necessary for compliance with mandatory legal or
                                    regulatory obligations to which we are subject to.
                                </li>
                                <li>
                                    Processing is undertaken after you have given us your express
                                    consent.
                                </li>
                            </ul>
                            <h4>What we use your personal information for</h4>
                            <div className="col-sm-12">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                We collect/store or use your information to…
                                            </th>
                                            <th scope="col">Legal basis</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>provide the service you have requested</td>
                                            <td>Contract</td>
                                        </tr>
                                        <tr>
                                            <td>send to our third-party service provider partners</td>
                                            <td>Consent, Legitimate Interest, Contract</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                send you information regarding our products and services
                                            </td>
                                            <td>Legitimate Interests &amp; Consent</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                provide annual statistics and information to our regulator
                                            </td>
                                            <td>Legal Obligation</td>
                                        </tr>
                                        <tr>
                                            <td>resolve complaints against us</td>
                                            <td>Legitimate Interest &amp; Legal Obligation</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                gather feedback to enable us to improve our products and
                                                services
                                            </td>
                                            <td>Legitimate Interest &amp; Consent</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                verify your identity where we receive requests to access or
                                                change the information we hold about you
                                            </td>
                                            <td>Legal Obligation</td>
                                        </tr>
                                        <tr>
                                            <td>maintain our accounts and records</td>
                                            <td>Legal Obligation</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                research and analyse trends to better understand how users
                                                are using the services in order to improve them
                                            </td>
                                            <td>Legitimate Interests</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                inform you about changes in our services, Privacy Policy and
                                                important service related notices, such as security and
                                                fraud issues
                                            </td>
                                            <td>Legal Obligation</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h4>Marketing</h4>
                            <p>
                                You are provided with choices regarding marketing and we record your
                                preferences in relation to this and how we communicate with you.{" "}
                            </p>
                            <p>
                                We may rely on consent when we use your personal information for
                                direct marketing. This will be where you have specifically consented
                                to us or a third party that you are happy to receive marketing
                                contact from us.
                            </p>
                            <p>
                                We may also rely on legitimate interests for our direct marketing.
                                Our legitimate interests are to inform individuals about products or
                                services which may be of interest to them and our commercial
                                interests in operating our business, which includes acquiring new
                                customers, providing additional services to existing or previous
                                customers and, expanding our operations.
                            </p>
                            <p>
                                You may also receive marketing communications from us if you have
                                previously purchased similar goods or services from us and, in each
                                case, you have not opted-out of receiving that marketing. This is
                                known as a ‘soft opt-in’.
                            </p>
                            <p>
                                Such marketing communications may be in relation to claims
                                management, financial advice/services and legal services which could
                                be done by post, email, SMS or telephone.
                            </p>
                            <p>
                                To opt out of receiving future marketing from us under a soft opt-in
                                or under legitimate interest, please opt-out using the details in
                                the ‘General’ section of this Privacy Policy.
                            </p>
                            <p>
                                If you require any further information about the lawful basis we
                                have relied on to send direct marketing to you, please also contact
                                us using the details in the ‘General’ section.
                            </p>
                            <h4>How long do we keep your personal information</h4>
                            <p>
                                We retain your information for as long as is necessary for the
                                purpose for which it was originally obtained. We also have some
                                legal and regulatory obligations to hold certain pieces of
                                information for specific timeframes for example, we are required to
                                keep a copy of telephone call recordings for a period of 12 months
                                after our last contact with you.{" "}
                            </p>
                            <p>
                                Where we have provided a service to you, we will hold all
                                information relevant to this for 6 years to enable us to address any
                                claims/complaints made about our service. Information relating to
                                any complaints will be held for a further 3 years.
                            </p>
                            <p>
                                We also need to keep some of your information for our accounting and
                                reporting requirements.
                            </p>
                            <p>
                                Your contact details will be held for the purposes of direct
                                marketing for 2 years, this is to enable us to let you know about
                                new products or services that may be of interest to you as detailed
                                above.{" "}
                            </p>
                            <h4>Your Rights </h4>
                            <p>
                                You provide us with details of what we ‘can do’ with your data and
                                you have the right to opt-in and importantly to opt-out, which you
                                can do by using the contact details below. You have the following
                                rights:
                            </p>
                            <ul>
                                <li>To know that your data is being processed</li>
                                <li>To access your personal data free of charge</li>
                                <li>
                                    To have your information corrected if inaccurate (Including
                                    ensuring any third party puts right any inaccuracy)
                                </li>
                                <li>To request that your data be erased</li>
                                <li>To restrict processing</li>
                                <li>To request transfer of your data</li>
                                <li>To object to processing; and </li>
                                <li>To rights relating to automated decision making</li>
                            </ul>
                            <p>
                                Where you send us a request to access your data, this will usually
                                be free of change and sent to you within one month (unless your
                                request is complex). However, we may charge a reasonable fee if your
                                request is clearly unfounded, repetitive or excessive.
                                Alternatively, we may refuse to comply with your request in these
                                circumstances.
                            </p>
                            <p>
                                We may need to request specific information from you to help us
                                confirm your identity before we are able to process your request.
                            </p>
                            <h4>Security</h4>
                            <p>
                                Hendersons recognise the personal nature of the information we
                                collect, process and store. As we are committed to ensuring that
                                your information is secure we have put in place suitable physical,
                                electronic and managerial procedures to prevent loss, unauthorised
                                access, misuse or disclosure and to make sure that your information
                                is safe and secure. If password access is required for certain parts
                                of our website/application, you are responsible for keeping this
                                password confidential.
                            </p>
                            <h4>Links to other websites</h4>
                            <p>
                                Our website may contain links to other websites of interest.
                                However, once you have used these links to leave our site, you
                                should note that we do not have any control over that other website.
                                Therefore, we cannot be responsible for the protection and privacy
                                of any information which may be collected or you provide whilst
                                visiting such sites and such sites are not governed by this privacy
                                statement. You should exercise caution and look at the privacy
                                statement applicable to the website in question.
                            </p>
                            <h4>Disclosure / transfer of data</h4>
                            <p>
                                Hendersons may share or disclose personal information in the
                                following situations:
                            </p>
                            <ul>
                                <li>
                                    External third party service providers: We may use external
                                    printers, accountants, admin companies, call centres, compliance
                                    consultants and legal service providers (such as solicitors firms
                                    and barrister chambers) who may process your data on our behalf.
                                    Any external processors are subject to a data processing agreement
                                    to ensure the safety and protection of your data. None of our
                                    processors are allowed to use your data for any other purposes
                                    than instructed by us.
                                </li>
                                <li>
                                    If we merge with or acquire a business, the business is
                                    restructured, or if our assets are acquired by a third party, any
                                    personal data held by Hendersons may be transferred, in which case
                                    you will be contacted by us to notify you of the transfer.{" "}
                                </li>
                                <li>
                                    If we are under a duty to disclose or share your personal data to
                                    comply with any legal or regulatory obligation.
                                </li>
                                <li>
                                    To enforce or apply our terms of business and other agreements.
                                </li>
                                <li>
                                    To protect the rights, property, or safety of our company, other
                                    customers and others. This includes exchanging information with
                                    organisations for the purposes of fraud protection and credit risk
                                    reduction.{" "}
                                </li>
                            </ul>
                            <p>
                                Where possible in some circumstances as described above, we may be
                                able to minimise your personal data so that you are not identifiable
                                as the data subject.{" "}
                            </p>
                            <h4>General and ‘opt out’ details</h4>
                            <p>
                                You may not transfer any of your rights under this Privacy Policy to
                                any other person. We may transfer our rights under this Privacy
                                Policy where we reasonably believe your rights will not be affected.{" "}
                            </p>
                            <p>
                                If any court or competent authority finds that any provision of this
                                Privacy Policy (or part of any provision) is invalid, illegal or
                                unenforceable, that provision or part-provision will, to the extent
                                required, be deemed to be deleted, and the validity and
                                enforceability of the other provisions of this Privacy Policy will
                                not be affected. Unless otherwise agreed, no delay, act or omission
                                by a party in exercising any right or remedy will be deemed a waiver
                                of that, or any other, right or remedy.{" "}
                            </p>
                            <p>
                                If you have any queries relating to our Privacy Policy or the
                                personal data we hold about you, or you wish to invoke any of your
                                legal rights, including opting out of receiving our marketing, you
                                can contact us by:
                            </p>
                            <p>
                                <strong>Email:</strong>{" "}
                                <a href="mailto:contact@trusthendersons.co.uk">
                                    contact@trusthendersons.co.uk
                                </a>
                            </p>
                            <p>
                                <strong>Telephone:</strong> 0161 359 7140
                            </p>
                            <p>
                                <strong>Write to us at:</strong> Grosvenor House Part Ground Floor (Rear Wing), St Thomas Place, Stockport, United Kingdom, SK1 3TZ.
                            </p>
                            <h4>Complaints</h4>
                            <p>
                                You have the right to make a complaint at any time to the
                                Information Commissioner’s Office (ICO), the UK supervisory
                                authority for data protection issues at&nbsp;
                                <a href="http://www.ico.org.uk" target="_blank">
                                    www.ico.org.uk.
                                </a>
                            </p>
                            <p>
                                We would, however, appreciate the chance to deal with your concerns
                                before you approach the ICO so please contact us in the first
                                instance using the contact details set out above.
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                onClick={() => setPrivacyModal(false)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal  privacy end*/}
        </>
    );
};

export default PrivacyModal;
