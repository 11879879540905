import React, { useEffect, useState, useContext } from "react";
import car_img from "../../../assets/PCP_HDSC_V3/img/car.png";
import safty_img from "../../../assets/PCP_HDSC_V3/img/safe and secure.png";
import loader from "../../../assets/PCP_HDSC_V3/img/loader03.svg"
import ZCggE3Tml from "../../../assets/PCP_HDSC_V3/img/5ZCggE3Tml.gif"
import { useVehicleValidation } from "../../../Hooks/useVehicleValidation";
import VisitorParamsContext from "../../../../src/Contexts/VisitorParams";
import Footer from "../../Includes/Layouts/PCP_HDSC_V3/Footer";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import { CheckUUID } from "../../../Utility/CheckUUID";
import QueryStringContext from "../../../Contexts/QueryString";
import { useQuestionnairev1 } from "../../../Hooks/useQuestionnairev1";
import Header_PCP_HDSC_V3 from "../../Includes/Layouts/PCP_HDSC_V3/Header";
import "../../../assets/PCP_HDSC_V3/css/carrrgistrationV3.scss";
import { Api } from "../../../api/Api";
import { Finance ,Finance_Prominent_List_Reworded } from "../../../Constants/ConstantsPCP";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";
import Button from "../../UI/Button";
import { useSkipCarRegisterValidation } from "../../../Hooks/useSkipCarRegisterValidation";
import TextFieldV3 from "../../UI/TextFieldV3";
import { useCarRegistrationSkipAll } from "../../../Hooks/useCarRegistrationSkipAll";

const CarRegistrationV3 = () => {
  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit(
    "PCP_HDSC_V3",
    "car-registration-v3"
  );
  const { isCheck } = CheckUUID();
  isCheck();
  const { skipRegistrationValidation } = useSkipCarRegisterValidation();
  const [vehicleNumberInput, setVehicleNumberInput] = useState("");
  const { isLoading, validateRegNum } = useVehicleValidation();
  const { register, errors, trigger, setError, clearErrors, getValues } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const [isLoading1, setIsLoading] = useState(true);
  const [loadingStage, setLoadingStage] = useState(0);
  const [currentCount, setCurrentCount] = useState(0);
  const [vehicleDateModal, setVehicleDateModal] = useState(false);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [vehicleNumbers, setVehicleNumbers] = useState([]);
  const { queryString } = useContext(QueryStringContext);
  const { storeVehicleDetails } = useQuestionnairev1();
  const [formattedDates, setFormattedDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [responseslug, setResponseSlug] = useState([]);
  const [modalCount, setModalCount] = useState(0);
  const [keeperLatest, setKeeperLatest] = useState(null);
  const { storeSkipallValues } = useCarRegistrationSkipAll();
  const query = new URLSearchParams(window.location.search);
  const query_uuid = query.get("uuid");

  const uuid = visitorParameters.visitor_parameters.uuid ?? localStorage.getItem("uuid");
  const history = useHistory();
  const page_name = "PCP_HDSC_V3";

  useEffect(() => {
    const fetchData = async () => {
      let localStorageValue = JSON.parse(localStorage.getItem("finaceProviders"));
      if (localStorageValue && localStorageValue.length > 0) {
        const transformedArray = localStorageValue.map((slug, index) => ({
          id: index + 1,
          slug: slug,
          provider_name: slug
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
        }));
        const filteredArray = transformedArray.filter(item =>
          Finance.some(financeItem => financeItem.slug === item.slug && financeItem.car_reg)
        );
        setResponseSlug(filteredArray);
        setModalCount(filteredArray.length);
      } else {
        const responseslug = await Api.get(
          "v1/get_finanace_slug_details?uuid=" + query_uuid,
          {}
        );
        const filteredArray = responseslug.data.filter(item =>
          Finance.some(financeItem => financeItem.slug === item.slug && financeItem.car_reg)
        );
        setResponseSlug(filteredArray);
        setModalCount(filteredArray.length);
        localStorageValue = responseslug.data.map(item => item.slug);
        localStorage.setItem("finaceProviders", JSON.stringify(localStorageValue));
      }
        // const Required_id = localStorageValue.find((slug) =>
        //   Finance.some(
        //     (financeItem) =>
        //       financeItem.slug === slug && financeItem.id_required === true
        //   )
        // );
    };

    setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    fetchData();

  }, [query_uuid]);

  const handleAddCHange = async () => {

    await trigger("vehicle_number");
    if (errors.vehicle_number) return;
    try {
      const validationResponse = await validateRegNum(
        getValues("vehicle_number"),
        uuid,
        page_name
      );

      if (validationResponse && validationResponse.data.keeper_date) {
        const keeperDatesObj = JSON.parse(validationResponse.data.keeper_date);

        if (keeperDatesObj && keeperDatesObj.keeper_dates) {
          const keeperDates = keeperDatesObj.keeper_dates;

          const latestDate = new Date(
            Math.max(...keeperDates.map((date) => new Date(date)))
          );

          const nextDayDate = new Date(latestDate);
          nextDayDate.setDate(latestDate.getDate() + 1);

          const nextDayDateString = nextDayDate.toISOString().split("T")[0];

          setKeeperLatest(nextDayDateString);
        }
      }

      if (validationResponse.status === 200 && validationResponse.data.is_valid === 1) {
        const keeperDateStr = validationResponse.data.keeper_date;
        const keeperDateObj = JSON.parse(keeperDateStr);
        // Extract the array of keeper dates
        const keeperDates = keeperDateObj.keeper_dates;
        // Convert each keeper date to the desired format
        const formattedDatesArray = keeperDates.map((dateStr) => {
          const parts = dateStr.split(" "); // Split the date string by space
          const month = parts[1];
          const year = parts[2];
          const formattedDate = `${month} ${year}`;
          return { formattedDate, originalDate: dateStr }; // Store both formatted and unformatted dates
        });

        formattedDatesArray.push({
          formattedDate: "Other",
          originalDate: "other",
        });

        // Set the formatted dates in the state variable
        setFormattedDates(formattedDatesArray);

        setVehicleDateModal(true);
      } else {
        setError("vehicle_number", { message: "Enter valid vehicle number" });
      }
    } catch (error) {
      console.error("Error validating car registration: ", error);
      setError("vehicle_number", { message: "Network error" });
    } finally {
      // setShowSpinner(false);
    }
  };
  const vehicleModalClick = async (value) => {
    try {
      setTimeout(async () => {
        setVehicleDateModal(false);

        setLoadingStage(1);

        const vehicleNumber = getValues("vehicle_number");
        let flag = responseslug.length;
        for (let i = 0; i < responseslug.length; i++) {
          const vehicle_data = {
            keeper_date: value,
            current_keeper_date: keeperLatest,
            yourVehicle: "",
            carRegNo: vehicleNumber,
            pageName: "PCP_HDSC_V3",
          };
          const formdata = {
            page_name: "PCP_HDSC_V3",
          };

          let providers = {
            slug: responseslug[i].slug,
          };

          if (i == currentCount) {
            const response = await storeVehicleDetails(
              visitorParameters.visitor_parameters,
              visitorParameters.data,
              formdata,
              queryString,
              vehicle_data,
              providers,
              "store-vehicle-details"
            );
          }
        }
        setCurrentCount(currentCount + 1);
        setLoadingStage(2);
        setTimeout(() => {
          setVehicleNumbers((prevNumbers) => [
            ...prevNumbers,
            {
              provider_name: responseslug[currentCount].provider_name,
              number: vehicleNumber,
            },
          ]);

          setLoadingStage(0);
          setSelectedDate(null);
          setVehicleNumberInput('');
          const storedDataa = localStorage.getItem("finaceProviders");
          if (storedDataa) {
            const slugval = JSON.parse(storedDataa);
            const Required_id = slugval.find((slug) =>
              Finance.some(
                (financeItem) =>
                  financeItem.slug === slug && financeItem.id_required === true
              )
            );
          }
          if (currentCount + 1 == modalCount) {
            const storedData = localStorage.getItem("finaceProviders");
            if (storedData) {
              const slugs = JSON.parse(storedData);
              // let newSlugArray = slugs.filter(slug => !skipArray.includes(slug));
              const matchedSlug = slugs.find((slug) =>
                Finance.some(
                  (financeItem) =>
                    financeItem.slug === slug && financeItem.id_required === true
                )
              );

              carRegNotRequiredFunction();

              if (matchedSlug && currentCount + 1 == modalCount) {
                history.push(`/${DynamicRouteNextPage}?uuid=${visitorParameters.visitor_parameters.uuid}&split_name=${DynamicRouteSplitName}`);
              } else {
                history.push(`/thankyou?uuid=${visitorParameters.visitor_parameters.uuid}&split_name=${DynamicRouteSplitName}`);
              }
            }
          }
        }, 3000);
      }, 500);
    } catch (error) {
      console.error("Error storing vehicle details: ", error);
    }
  };



  // const handleNextChange =  () => {
  //   if (currentCount > 0) {
  //     const storedData = localStorage.getItem("finaceProviders");
  //     if (storedData) {
  //       const requiredSlugs = JSON.parse(storedData);
  //       const slugs =(currentval  == requiredSlugs.length ) ? requiredSlugs : requiredSlugs.slice(0, currentCount );
  //       const matchedSlug = slugs.find(slug =>
  //         Finance.some(financeItem =>
  //           financeItem.slug === slug && financeItem.id_required === true
  //         )
  //       );
  //       if (matchedSlug) {
  //         history.push(`/${DynamicRouteNextPage}?uuid=${visitorParameters.visitor_parameters.uuid}&split_name=${DynamicRouteSplitName}`);
  //       }else{
  //         // history.push(`/thankyou?uuid=${encodeURIComponent(query_uuid)}`);
  //         history.push(`/thankyou?uuid=${visitorParameters.visitor_parameters.uuid}&split_name=${DynamicRouteSplitName}`);
  //       }
  //     }
  //   }
  // };
  const carRegNotRequiredFunction = async () => {
    const slugs = JSON.parse(localStorage.getItem("finaceProviders"));
    const carRegNotRequiredValues = slugs.flatMap((slug) =>
      Finance
        .filter(
          (financeItem) =>
            financeItem.slug === slug && (financeItem.car_reg === false || !('car_reg' in financeItem))
        )
        .map((financeItem) => financeItem.value)
    );

    if (carRegNotRequiredValues.length !== 0) {
      storeSkipallValues(
        visitorParameters.visitor_parameters,
        "car_registration",
        "store-skip-all-vehicle-details",
        carRegNotRequiredValues
      );
    }
  }

  const skipThisPage = () => {
    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");
    const storedData = localStorage.getItem("finaceProviders");
    if (storedData) {
      const slugg = responseslug[currentCount] ? responseslug[currentCount].slug : null;
      // If we have a valid slug, use it in the API call
      if (slugg) {
        skipCarRegisterValidationApi(query_uuid, page_name, slugg);
        // setSkipArray(prevSkipArray => [...prevSkipArray, slugg]);
      }
      if (currentCount + 1 == modalCount) {
        const slugs = JSON.parse(storedData);
        const matchedSlug = slugs.find((slug) =>
          Finance.some(
            (financeItem) =>
              financeItem.slug === slug && financeItem.id_required === true
          )
        );

        carRegNotRequiredFunction();
        if (matchedSlug) {
          history.push(`/${DynamicRouteNextPage}?uuid=${visitorParameters.visitor_parameters.uuid}&split_name=${DynamicRouteSplitName}`);
        } else {
          history.push(`/thankyou?uuid=${visitorParameters.visitor_parameters.uuid}&split_name=${DynamicRouteSplitName}`);
        }
      } else {
        setCurrentCount(currentCount + 1);
        setVehicleNumberInput("");
        setVehicleDateModal(false);
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    }
  };

  const skipCarRegisterValidationApi = async (uuid, pageName, slug) => {
    const item = Finance.find(financeItem => financeItem.slug === slug);
    try {
      await skipRegistrationValidation(uuid, page_name, slug, item.value, "car_registration");
    } catch (error) {
      console.log(error);
    }
  };


  const findCategoryAndLabel = (slug) => {
    for (const category of Finance_Prominent_List_Reworded) {
      for (const sub_category of category.sub_categories) {
        if (sub_category.slug === slug) {
          return { category: category.category, label: sub_category.label };
        }
      }
    }
    return { category: "", label: "" };
  };
  const handleCarReg = (e) => {
    var carRegValue = e.target.value.replace(/[^a-zA-Z0-9]/g, '').replace(/\s/g, '');
    setVehicleNumberInput(carRegValue);
    clearErrors("vehicle_number");
  };


  return (
    <>
      <GetVisitorsParams />
      <div className="carregistrationV3">
        {/* Loader */}
        {isLoading1 && <div className=" text-center loader loader1">
          <div className="inner py-5">
            <div className="my-5">
              <img height={100} src={loader} alt="" />
            </div>
            <h2 className="px-4"> Analysing Your Result...</h2>
          </div>
        </div>}
        {/* Loader */}
        {!isLoading1 && <div>
          <Header_PCP_HDSC_V3 />
          <section className="bg-gray  p-2   vehiclenumber  ">
            <div className="bg-inner vehicle-srtop  rounded-2 p-lg-5 p-md-5 p-sm-4 p-4 ">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 offset-lg-3">
                    <form
                      className="form_action"
                      action=""
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <h4 className="mt-5  animate__animated animate__fadeInUp  ">
                        To help us expedite your&nbsp;claim,&nbsp;we need a few more
                        information from you
                      </h4>
                      {modalCount >= 0 && (<h1 className="fw-bold my-4  animate__animated animate__fadeInUp  ">
                        Enter the vehicle registration number associated with your loan
                        from {""}
                        {/* {responseslug[currentCount]?.provider_name ? (
                          <span>{responseslug[currentCount].provider_name} </span>
                        ) : (
                          <span>{responseslug.map(slug => slug.provider_name).join(", ")}</span>
                        )} */}
                        {responseslug.map((response, index) => {
                          if (currentCount === index) {
                            const { category, label } = findCategoryAndLabel(response.slug);
                            return (
                              <>
                                <span key={index} style={{ lineHeight: "24px",color:"black",textDecoration:'underline' }}>
                                  {category} / {label}
                                </span>
                              </>
                            );
                          }
                          return null;
                        })}
                      </h1>)}
                      <div className="wrap-number  ">
                        <div className="car-img text-end  animate__animated animate__fadeInUp  ">
                          <img src={car_img} alt="" />
                        </div>
                        <div className="mb-3  animate__animated animate__fadeInUp  ">
                          <div className="d-flex  number-plate">
                            <div className="flag"></div>
                            <div className="input-wrap">
                              <TextFieldV3
                                type="text"
                                name="vehicle_number"
                                id="vehicle_number"
                                className="form-control w-100"
                                placeholder="Enter Vehicle Number "
                                value={vehicleNumberInput}
                                onKeyUp={handleCarReg}
                                onChange={handleCarReg}
                                maxLength="9"
                                validation={register({
                                  required:
                                    "Please Enter Vehicle Number",
                                  maxLength: {
                                    value: 9,
                                    message:
                                      "Please Enter Valid Vehicle Number",
                                  },
                                })}
                              />
                            </div>
                            <span
                              className="add-more fw-bold"
                              id="addNUmber"
                              onClick={handleAddCHange}>
                              Add
                            </span>
                          </div>
                          {errors.vehicle_number && (
                            <p
                              className=""
                              style={{
                                background: "red",
                                borderRadius: "4px",
                                width: "100%",
                                marginLeft: "0px",
                                marginTop: "5px",
                                padding: "5px",
                                color: "white",
                              }}
                            >
                              &nbsp;{errors.vehicle_number.message}
                            </p>
                          )}
                        </div>
                        <ul
                          className="num-plate-list p-0 m-0 numberList mb-2"
                        >
                            {vehicleNumbers.map((vehicle, index) => (
                            <li key={index}>
                              <div className="uk-numberplate">
                                 {vehicle.number}
                              </div>
                            </li>
                          ))}
                        </ul>
                        <div className="mb-5 animate__animated animate__fadeInUp animate__delay-0.5s">

                          {" "}
                          <div className="text-center mt-3">
                            <Button
                              onClick={() => skipThisPage()}
                              type="button"
                              data-type="back"
                              id="backStep-1"
                              className="msf-nav-button backbutton"
                              buttonText = "Skip,if not handy"
                            />
                          </div>
                          {/* <button
                            className="mt-2 btn-comm2 p-2 w-100  btn-carnumber"
                            onClick={handleNextChange}
                            fdprocessedid="1e5gr"
                            type="button"
                          >
                            <span>Next</span>{" "}
                          </button> */}
                          {/* </a> */}
                        </div>
                        <div className="text-center ">
                          <img
                            height={40}
                            className=""
                            src={safty_img}
                            alt=""
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>}
        {/* Loader */}
        {loadingStage > 0 && <div className="text-center loader loader2">
          <div className="inner py-5">
            <div className="my-5">
              {loadingStage === 1 && <div className="lt-loader">
                <img height={100} src={loader} alt="" />
                <h2 className="px-4"> Please Wait...</h2>
              </div>}
              {loadingStage === 2 && <div className="successmsg" >
                <div>
                  <img height={130} src={ZCggE3Tml} alt="" />
                </div>
                <div className="fw-bold text-white">Number Succefully Added</div>
              </div>}
            </div>
          </div>
        </div>}
        {/* Loader */}
        {/* vehicle keeper date popup section below */}
        <div
          className={`modal fade ${vehicleDateModal && "show d-block"}`}
          id="CarDate"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="pre_addLabel"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s ">
              <div className="modal-body">
                <div className="form_drop yes_no_sec col-lg-12 col-12 p-3">
                  <div
                    className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s"
                    style={{ zIndex: 2, position: "relative" }}
                  >
                    <p className="png_t">
                      Please select the date you acquired your vehicle?
                    </p>
                    <div className="m-0 choose_Y_N row animate__animated animate__fadeInUp animate__delay-.5s">
                      {formattedDates.map((dateObj, index) => (
                        <div className="form-check px-0" key={index}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="Radioshere2"
                            id={`radio${index}`}
                            checked={selectedDate === dateObj.originalDate}
                            value={dateObj.originalDate} // Set the unformatted value here
                            onClick={() =>
                              vehicleModalClick(dateObj.originalDate)
                            }
                            onChange={() => setSelectedDate(dateObj.originalDate)}
                          />
                          <label
                            className="form-check-label selecteddate"
                            htmlFor={`radio${index}`}
                          >
                            {dateObj.formattedDate}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------------------- */}
      </div>
    </>
  );
};

export default CarRegistrationV3;
