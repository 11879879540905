import React from 'react';
import PreviousPostcode_PCP_HDSC_V1 from "../../../Includes/Layouts/PCP_HDSC_V1/PreviousPostcode_PCP_HDSC_V1";

const CustomModal = ({
  showModal,
  showSecondSection,
  handleYesClick,
  handleNoClick,
  handleSubmit,
  register,
  errors,
  trigger,
  clearErrors,
  setError,
  cancelpopup,
  cancelpopupTwo,
}) => {
  return (
    showModal && (
      <div
        className="modal fade show"
        id="pre_add"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="pre_addLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s ">
            <div className="modal-body d-block">
              {!showSecondSection ? (
                <div className="form_drop yes_no_sec col-lg-12 col-12 p-3">
                  <div
                    className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s"
                    style={{ zIndex: 2, position: "relative" }}
                  >
                    <p className="png_t">
                      Did you register with your lender at a different/ previous address than your current address?
                    </p>
                    <div className="choose_Y_N col-lg-12 col-12 animate__animated animate__fadeInUp animate__delay-.5s">
                      <div className="form-check px-0">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Radioshere2"
                          id="Radios2"
                          defaultValue="optionclick1"
                          onClick={handleNoClick}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="Radios2"
                        >
                          No
                        </label>
                      </div>
                      <div className="form-check px-0">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Radioshere2"
                          id="Radios1"
                          defaultValue="optionclick2"
                          onClick={handleYesClick}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="Radios1"
                        >
                          Yes
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="p-lg-4 p-md-3 p-sm-2 p-2 enter_postcode_mod">
                  <div className="pt-0 animate__animated animate__fadeInUp animate__delay-.5s">
                    <p className="png_t">
                      Enter your previous postcode
                    </p>
                    <label>Postcode</label>
                    <div className="postcode_wrap d-block">
                      <PreviousPostcode_PCP_HDSC_V1
                        validation={register}
                        validationMsg={errors}
                        trigger={trigger}
                        clearErrors={clearErrors}
                        setError={setError}
                      />
                    </div>
                    <p className="tiny_note mt-2">
                      Your address is required for a smooth claim process
                    </p>
                    <div className="col-lg-5 col-12"></div>
                  </div>
                  <div className="modal-footer">
                    <div className="box_button pt-3 final_sub pt-3 animate__animated animate__fadeInUp animate__delay-.5s">
                      <div className="bg-1">
                        <button
                          type="button"
                          className="button w-100 button--moema button--text-thick button--text-upper button--size-s h60_click blick_notice"
                          id="nextPlate_007"
                          onClick={handleSubmit}
                        >
                          Submit
                          <svg
                            width={27}
                            height={28}
                            viewBox="0 0 27 28"
                            fill="none"
                          >
                            <mask
                              id="mask0_695_171"
                              style={{ maskType: "alpha" }}
                              maskUnits="userSpaceOnUse"
                              x={0}
                              y={0}
                              width={27}
                              height={28}
                            >
                              <rect
                                x="0.708008"
                                y="0.813721"
                                width="26.29"
                                height="26.29"
                                fill="#D9D9D9"
                              />
                            </mask>
                            <g mask="url(#mask0_695_171)">
                              <path
                                d="M17.5751 15.4101H4.56348V12.5072H17.5751L11.7815 6.71327L13.8532 4.66895L23.1429 13.9586L13.8532 23.2483L11.7815 21.204L17.5751 15.4101Z"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default CustomModal;
