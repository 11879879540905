import React, { useContext, useEffect, useState } from 'react'
import PageLoader from '../../Includes/Layouts/PCP_HDSC_V5/PageLoader'
import Lender from '../../Includes/Layouts/PCP_HDSC_V5/Lender'
import PersonalDetails from '../../Includes/Layouts/PCP_HDSC_V5/PersonalDetails'
import { useForm } from "react-hook-form";
import SlideThree from '../../Includes/Layouts/PCP_HDSC_V5/SlideThree'
import ContactDetails from '../../Includes/Layouts/PCP_HDSC_V5/ContactDetails'
import DOB from '../../Includes/Layouts/PCP_HDSC_V5/DOB'
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import QueryStringContext from "../../../Contexts/QueryString";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import PostCodeDetails from '../../Includes/Layouts/PCP_HDSC_V5/PostCodeDetails'
import UnqualifiedModal from '../../Includes/Layouts/PCP_HDSC_V5/UnqualifiedModal';
import { PreviousPostcodeModal } from '../../Includes/Layouts/PCP_HDSC_V5/PreviousPostcodeModal';
import * as EnvConstants from "../../../Constants/EnvConstants";
import { useHistory } from "react-router-dom";
import GTMDataLayer from "../../../Utility/GTMDataLayer";

const PCP_HDSC_V5Form = () => {
  const { register, errors, trigger, setError, clearErrors, getValues, setValue } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  // pages
  // Lender
  // QuestionnaireSlide
  // Postcode
  const [currentPage, setCurrentPage] = useState("Lender");

  // modals
  const [personalDetailsModal, setPersonalDetailsModal] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [dobModal, setDobModal] = useState(false);
  const [prePostcodeModal, setPrePostcodeModal] = useState(false);
  const [loader, setLoader] = useState(false);
  // modals end
  const [plateOne, setPlateOne] = useState(false);
  const [plateFour, setPlateFour] = useState(false);
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const { queryString } = useContext(QueryStringContext);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const field = "pid";
  const history = useHistory();

  const finalSubmit = async () => {
    GTMDataLayer({
      question: 'previous address',
      answer: null,
    });
    const split_name = "PCP_HDSC_V5";
    const formData = getValues();
    const selectedBanks = JSON.parse(localStorage.getItem('selectedBanks'));
    if (selectedBanks) {
      formData.financeProvider = selectedBanks.map(bank => bank.value);
    }
    formData.carfinance = "13";
    formData.bankruptcy = "3";
    formData.commission = "1";
    localStorage.setItem('formData', JSON.stringify(formData));

    const formSubmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      formData,
      'split_form_submit',
      split_name,
      queryString,
      visitorParameters.data
    );

    if (formSubmitResult.data.status === "Success") {
      if (queryString != null) {
        if (queryString.indexOf("&" + field + "=") !== -1) {
          if (EnvConstants.AppConversionPixel === "true") {
            console.log("if fb");
            history.push(
              "/fbpixel?split_name=" + split_name + "&next_page=signature-v5"
            );
          } else {
            console.log("else fb");
            window.scrollTo(0, 0);
            history.push(
              "/signature-v5?uuid=" +
              visitorParameters.visitor_parameters.uuid +
              "&split_name=" +
              split_name
            );
          }
        } else {
          console.log("another else fb");
          window.scrollTo(0, 0);
          history.push(
            "/signature-v5?uuid=" +
            visitorParameters.visitor_parameters.uuid +
            "&split_name=" +
            split_name
          );
        }
      } else {
        console.log("laset else  fb");
        window.scrollTo(0, 0);
        history.push(
          "/signature-v5?uuid=" +
          visitorParameters.visitor_parameters.uuid +
          "&split_name=" +
          split_name
        );
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage])

  const postCodeNextClick = async () => {
    let txtPostCode = await trigger('txtPostCode');
    let address1;
    let txtCountry = await trigger('txtCountry');
    let txtTown = await trigger('txtTown');
    let txtHouseNumber = await trigger('txtHouseNumber');

    if (!txtPostCode) {
      setError("txtPostCode", {
        type: "manual",
        message: "Please Enter Postcode",
      });
      return false;
    } else {
      address1 = await trigger('address1');
    }
    if (!address1) {
      setError("address1", {
        type: "manual",
        message: "Please select address",
      });
      return false;
    } else {
      if (txtPostCode && address1 && txtCountry && txtTown && txtHouseNumber) {
        GTMDataLayer({
          question: 'postcode',
          answer: null,
        });
        setPrePostcodeModal(true);
      }
    }
  }

  return (
    <>
      <form id="form" onSubmit={(e) => e.preventDefault()}>
        <Lender
          setPersonalDetailsModal={setPersonalDetailsModal}
          personalDetailsModal={personalDetailsModal}
          currentPage={currentPage}
          validation={register}
          validationMsg={errors}
          getValues={getValues}
          trigger={trigger}
          setValue={setValue}

        />
        {/* this is a modal */}
        <PersonalDetails
          personalDetailsModal={personalDetailsModal}
          validation={register}
          validationMsg={errors}
          trigger={trigger}
          clearErrors={clearErrors}
          setError={setError}
          getValues={getValues}
          setPersonalDetailsModal={setPersonalDetailsModal}
          setDobModal={setDobModal}
          setCurrentPage={setCurrentPage}
        />
        {/* this is a modal */}
        <ContactDetails
          contactModal={contactModal}
          validation={register}
          validationMsg={errors}
          trigger={trigger}
          clearErrors={clearErrors}
          setError={setError}
          getValues={getValues}
          setLoader={setLoader}
          setContactModal={setContactModal}
          setPlateFour={setPlateFour}
          loader={loader}
          setCurrentPage={setCurrentPage}
        />
        <SlideThree
          plateOne={plateOne}
          validation={register}
          setPlateOne={setPlateOne}
          setDobModal={setDobModal}
          plateFour={plateFour}
          setContactModal={setContactModal}
          currentPage={currentPage}

        />
        {/* this is a modal */}
        <DOB
          dobModal={dobModal}
          validation={register}
          validationMsg={errors}
          trigger={trigger}
          clearErrors={clearErrors}
          setError={setError}
          getValues={getValues}
          setPlateFour={setPlateFour}
          setDobModal={setDobModal}
          setContactModal={setContactModal}
        />
        <PageLoader
          style={{
            background: "#ffffff",
            paddingRight: "15px",
            paddingLeft: "15px"
          }}
          pageLoader={loader}
          analysingText="Please wait while we review the information you have provided to determine your eligibility for a claim."
        />
        {/* postcode page */}
        <PostCodeDetails
          currentPage={currentPage}
          validation={register}
          validationMsg={errors}
          trigger={trigger}
          clearErrors={clearErrors}
          postCodeNextClick={postCodeNextClick}
          setValue={setValue}
        />
        <PreviousPostcodeModal
          prePostcodeModal={prePostcodeModal}
          setPrePostcodeModal={setPrePostcodeModal}
          validation={register}
          validationMsg={errors}
          trigger={trigger}
          clearErrors={clearErrors}
          postCodeNextClick={postCodeNextClick}
          setValue={setValue}
          finalSubmit={finalSubmit}

        />
      </form>
      <UnqualifiedModal
        unqualifiedModalBody=""
      />
      <PageLoader
        pageLoader={false}
        analysingText=""
      />
    </>
  )
}

export default PCP_HDSC_V5Form