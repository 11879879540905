import React, { useEffect, useState, useContext } from "react";
import ssl from "../../../assets/PCP_HDSC_V1/img/ssl.png";
import info from "../../../assets/PCP_HDSC_V1/img/info.png";
import logo_blue from "../../../assets/PCP_HDSC_V1/img/logo.png";
import hendersons_logo from "../../../assets/PCP_HDSC_V1/img/hendersons_logo.svg";
import hendersons_logo_gold from "../../../assets/PCP_HDSC_V1/img/hendersons_logo.png";
import Spinner from "../../../assets/PCP_HDSC_V1/img/Spinner.gif";
import { useVehicleValidation } from "../../../Hooks/useVehicleValidation";
import VisitorParamsContext from "../../../../src/Contexts/VisitorParams";

import "../../../assets/PCP_HDSC_V1/css/custom.scss";
import "../../../assets/PCP_HDSC_V1/css/carregistration.scss";
import Footer from "../../Includes/Layouts/PCP_HDSC_V1/Footer";
import FeaturedinAndAccordion from "../../Includes/Layouts/PCP_HDSC_V1/FeaturedinAndAccordion";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import { CheckUUID } from "../../../Utility/CheckUUID";
import QueryStringContext from "../../../Contexts/QueryString";
import { useQuestionnairev1 } from "../../../Hooks/useQuestionnairev1";
import Header_PCP_HDSC_V1 from "../../Forms/PCP_HDSC_V1/Header_PCP_HDSC_V1";
import { Api } from "../../../api/Api";
import { Finance, Finance_Prominent_List_Reworded } from "../../../Constants/ConstantsPCP";
import { useSkipCarRegisterValidation } from "../../../Hooks/useSkipCarRegisterValidation";
import GTMDataLayer from "../../../Utility/GTMDataLayer";
import { useCarRegistrationSkipAll } from "../../../Hooks/useCarRegistrationSkipAll";

const CarRegistrationV2 = () => {
  const { isCheck } = CheckUUID();
  isCheck();
  const { skipRegistrationValidation } = useSkipCarRegisterValidation();

  const [formattedDates, setFormattedDates] = useState([]);
  const [showSpinner, setShowSpinner] = useState(true);
  const [vehicleDateModal, setVehicleDateModal] = useState(false);
  const [afterRegModal, setAfterRegModal] = useState(true);
  const [modalCount, setModalCount] = useState(0);
  const { isLoading, validateRegNum } = useVehicleValidation();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [currentModalCount, setCurrentModalCount] = useState(0);
  const [responseslug, setResponseSlug] = useState([]);
  const { queryString } = useContext(QueryStringContext);
  const { storeVehicleDetails } = useQuestionnairev1();
  const uuid =
    visitorParameters.visitor_parameters.uuid ?? localStorage.getItem("uuid");
  const history = useHistory();
  const page_name = "PCP_HDSC_V2";

  const query = new URLSearchParams(window.location.search);
  const query_uuid = query.get("uuid");
  const [vehicleNumberInput, setVehicleNumberInput] = useState("");
  const [keeperLatest, setKeeperLatest] = useState(null);
  const [docSkip, setDocSkip] = useState(false);
  const [skipArray, setSkipArray] = useState([]);
  const { storeSkipallValues } = useCarRegistrationSkipAll();

  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  const loaderTrigger = () => {
    const timer = setTimeout(() => {
      setShowSpinner(false);
    }, 3000);
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    loaderTrigger();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const skipCarRegisterValidationApi = async (uuid, pageName, slug) => {
    const item = Finance.find(financeItem => financeItem.slug === slug);

    try {
      await skipRegistrationValidation(uuid, page_name, slug, item.value, "car_registration");
    } catch (error) {
      console.log(error);
    }
  };

  const handleNextButtonClick = async () => {
    await trigger("vehicle_number");
    if (errors.vehicle_number) return;

    try {
      // setShowSpinner(true);
      const validationResponse = await validateRegNum(
        getValues("vehicle_number"),
        uuid,
        page_name
      );
      if (validationResponse && validationResponse.data.keeper_date) {
        const keeperDatesObj = JSON.parse(validationResponse.data.keeper_date);

        if (keeperDatesObj && keeperDatesObj.keeper_dates) {
          const keeperDates = keeperDatesObj.keeper_dates;

          const latestDate = new Date(
            Math.max(...keeperDates.map((date) => new Date(date)))
          );

          const nextDayDate = new Date(latestDate);
          nextDayDate.setDate(latestDate.getDate() + 1);

          const nextDayDateString = nextDayDate.toISOString().split("T")[0];

          setKeeperLatest(nextDayDateString);
        }
      }

      if (
        validationResponse.status === 200 &&
        validationResponse.data.is_valid === 1
      ) {
        const keeperDateStr = validationResponse.data.keeper_date;
        const keeperDateObj = JSON.parse(keeperDateStr);

        // Extract the array of keeper dates
        const keeperDates = keeperDateObj.keeper_dates;

        // Convert each keeper date to the desired format
        const formattedDatesArray = keeperDates.map((dateStr) => {
          const parts = dateStr.split(" "); // Split the date string by space
          const month = parts[1];
          const year = parts[2];
          const formattedDate = `${month} ${year}`;
          return { formattedDate, originalDate: dateStr }; // Store both formatted and unformatted dates
        });

        formattedDatesArray.push({
          formattedDate: "Other",
          originalDate: "other",
        });

        // Set the formatted dates in the state variable
        setFormattedDates(formattedDatesArray);

        setVehicleDateModal(true);
      } else {
        setError("vehicle_number", { message: "Enter valid vehicle number" });
      }
    } catch (error) {
      console.error("Error validating car registration: ", error);
      setError("vehicle_number", { message: "Network error" });
    } finally {
      // setShowSpinner(false);
    }
  };

  const carRegNotRequiredFunction = async () => {
    const slugs = JSON.parse(localStorage.getItem("slugArray"));
    const carRegNotRequiredValues = slugs.flatMap((slug) =>
      Finance
        .filter(
          (financeItem) =>
            financeItem.slug === slug && (financeItem.car_reg === false || !('car_reg' in financeItem))
        )
        .map((financeItem) => financeItem.value)
    );

    if (carRegNotRequiredValues.length !== 0) {
      storeSkipallValues(
        visitorParameters.visitor_parameters,
        "car_registration",
        "store-skip-all-vehicle-details",
        carRegNotRequiredValues
      );
    }
  }

  const skipThisPage = () => {
    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");
    const storedData = localStorage.getItem("slugArray");
    if (storedData) {
      const slugg = responseslug[currentModalCount] ? responseslug[currentModalCount].slug : null;

      // If we have a valid slug, use it in the API call
      if (slugg) {
        skipCarRegisterValidationApi(query_uuid, page_name, slugg);
        setSkipArray(prevSkipArray => [...prevSkipArray, slugg]);
      }

      if (currentModalCount + 1 == modalCount) {
        if (storedData) {
          const slugs = JSON.parse(storedData);

          // const matchedSlug = slugs.find((slug) =>
          //   Finance.some(
          //     (financeItem) =>
          //       financeItem.slug === slug && financeItem.id_required === true
          //   )
          // );

          if (currentModalCount + 1 == modalCount) {
          //   history.push(`/pcp-id-uploadv2?uuid=${encodeURIComponent(query_uuid)}`);
          // } else {
            carRegNotRequiredFunction();
            history.push(`/thankyou-v2?uuid=${encodeURIComponent(query_uuid)}`);
          }
        }
      } else {
        setCurrentModalCount(currentModalCount + 1);
        setVehicleNumberInput("");
        setVehicleDateModal(false);
        AfterRegModal();
      }
    }
  };

  const AfterRegModal = () => {
    setAfterRegModal(false);
    const timer = setTimeout(() => {
      setAfterRegModal(true);
    }, 2000);

    // Clear the timeout to avoid memory leaks
    return () => clearTimeout(timer);
  };

  const vehicleModalClick = async (value) => {
    const vehicleNumber = getValues("vehicle_number");
    let flag = responseslug.length;
    for (let i = 0; i < responseslug.length; i++) {
      let vehicle_data = {
        keeper_date: value,
        current_keeper_date: keeperLatest,
        yourVehicle: "",
        carRegNo: vehicleNumber,
        pageName: "PCP_HDSC_V2",
      };
      const formdata = {
        page_name: "PCP_HDSC_V2",
        // ...formdata
      };
      let providers = {
        slug: responseslug[i].slug,
      };
      if (i == currentModalCount) {
        const response = await storeVehicleDetails(
          visitorParameters.visitor_parameters,
          visitorParameters.data,
          formdata,
          queryString,
          vehicle_data,
          providers,
          "store-vehicle-details"
        );
        GTMDataLayer({
          question: 'car reg.number',
          answer: null,
        })
        GTMDataLayer({
          question: 'vehicle acquired date',
          answer: null,
        });
      }
    }

    setCurrentModalCount(currentModalCount + 1);

    setVehicleNumberInput("");
    setVehicleDateModal(false);
    AfterRegModal();

    const radios = document.getElementsByName("Radioshere2");
    radios.forEach((radio) => {
      radio.checked = false;
    });
    // console.log(
    //   "currentModalCount",
    //   currentModalCount,
    //   "modalCount",
    //   modalCount
    // );
    const storedDataa = localStorage.getItem("slugArray");
    if (storedDataa) {
      const slugval = JSON.parse(storedDataa);

      const Required_id = slugval.find((slug) =>
        Finance.some(
          (financeItem) =>
            financeItem.slug === slug && financeItem.id_required === true
        )
      );

      if (Required_id) {
        setDocSkip(true);

      }
    }

    if (currentModalCount + 1 == modalCount) {
      const storedData = localStorage.getItem("slugArray");
      if (storedData) {
        const slugs = JSON.parse(storedData);
        let newSlugArray = slugs.filter(slug => !skipArray.includes(slug));
        const matchedSlug = slugs.find((slug) =>
          Finance.some(
            (financeItem) =>
              financeItem.slug === slug && financeItem.id_required === true
          )
        );

        if (matchedSlug) {

          setDocSkip(true);
        }

        if (currentModalCount + 1 == modalCount) {
        //   history.push(`/pcp-id-uploadv2?uuid=${encodeURIComponent(query_uuid)}`);
        // } else {
          carRegNotRequiredFunction();
          history.push(`/thankyou-v2?uuid=${encodeURIComponent(query_uuid)}`);
        }
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let localStorageValue = JSON.parse(localStorage.getItem("slugArray"));
      if (localStorageValue) {
        const transformedArray = localStorageValue.map((slug, index) => ({
          id: index + 1,
          slug: slug,
          provider_name: slug
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
        }));
        const filteredArray = transformedArray.filter(item =>
          Finance.some(financeItem => financeItem.slug === item.slug && financeItem.car_reg)
        );
        setResponseSlug(filteredArray);
        setModalCount(filteredArray.length);
      } else {
        const responseslug = await Api.get(
          "v1/get_finanace_slug_details?uuid=" + query_uuid,
          {}
        );
        const filteredArray = responseslug.data.filter(item =>
          Finance.some(financeItem => financeItem.slug === item.slug && financeItem.car_reg)
        );
        setResponseSlug(filteredArray);
        setModalCount(filteredArray.length);
        localStorageValue = responseslug.data.map(item => item.slug);
        localStorage.setItem("slugArray", JSON.stringify(localStorageValue));
      }
      const Required_id = localStorageValue.find((slug) =>
        Finance.some(
          (financeItem) =>
            financeItem.slug === slug && financeItem.id_required === true
        )
      );

      if (Required_id) {
        setDocSkip(true);
      }
    };

    fetchData();
  }, [query_uuid]);

  const findCategoryAndLabel = (slug) => {
    for (const category of Finance_Prominent_List_Reworded) {
      for (const sub_category of category.sub_categories) {
        if (sub_category.slug === slug) {
          return { category: category.category, label: sub_category.label };
        }
      }
    }
    return { category: "", label: "" };
  };
  const handleCarReg = (e) => {
    var carRegValue = e.target.value.replace(/[^a-zA-Z0-9]/g, '').replace(/\s/g, '');
    setVehicleNumberInput(carRegValue);
    clearErrors("vehicle_number");
  };

  return (
    <>
      <GetVisitorsParams />
      <div className="carregistration">
        <div className="pcp_siteHome">
          {showSpinner ? (
            <div className="loader-wrap succLoader text-center text-light ">
              <nav className="navbar navbar-expand-lg">
                <a className="navbar-brand text-center col-12 mt-5 pt-5">
                  <img src={hendersons_logo_gold} alt="Logo" height="45px" />
                </a>
              </nav>
              <div className="mt-5 pt-5">
                <img height="150" src={Spinner} alt="" />
                <h2 className="mt-3 succtext px-5 ">
                  Analysing the information you have provided <br />{" "}
                  <br />
                  <small> Please wait...</small>
                </h2>
              </div>
            </div>
          ) : afterRegModal ? (
            <div className="">
              <Header_PCP_HDSC_V1 />
              <section className="main_content sign_content custom_height">
                <div className="container">
                  {/* <!-- row start --> */}
                  <div className="row">
                    {/* <!-- form section --> */}
                    <div className="col-lg-12 col-md-12">
                      <form
                        className="form_action"
                        action="finance_agreement.html"
                        onSubmit={(e) => e.preventDefault()}
                      >
                        <div className="widget_mobi">
                          <div className="sign_dis_paGe">
                            <div className="slide_7Dec">
                              <div className="row justify-content-center">
                                <div className="cs_hero_text col-lg-8 col-12">
                                  <div className="" id="">
                                    <h3 className="carreg animate__animated animate__fadeInUp animate__delay-.1s">
                                      To help us expedite your{" "}
                                      <span className="clr-dif">claim,</span> we
                                      need a few more information from you
                                    </h3>

                                    <div className="col-lg-12 col-md-12 form_dec animate__animated animate__fadeInUp animate__delay-.1s animated-element">
                                      <div
                                        className="widget_mobi animate__animated animate__fadeInUp animate__delay-.1s animated-element br-30"
                                        id=""
                                      >
                                        {/* <!-- // slide_1dec --> */}
                                        <div className="slide_1Dec">
                                        <div className="text-center first_fnt first_fnt2">
                                        Please provide the car registration number that you registered with &nbsp;
                                        </div>
                                        </div>
                                        <div className="slide_1Dec">
                                          <div className="display-gold-box">
                                            {currentModalCount >= 0 && (
                                              <h2 className="text-center first_fnt animate__animated animate__fadeInUp animate__delay-.2s animated-element">
                                                {/* <br /> */}
                                                <span className="lender-text">
                                                  <p className="my-2">
                                                    {responseslug.map((response, index) => {
                                                      if (currentModalCount === index) {
                                                        const { category, label } = findCategoryAndLabel(response.slug);
                                                        return (
                                                          <>
                                                            <p key={index} style={{lineHeight:"24px"}}>
                                                              {label}
                                                            </p>
                                                          </>
                                                        );
                                                      }
                                                      return null;
                                                    })}
                                                  </p>
                                                </span>
                                              </h2>
                                            )}
                                            <div className="form_drop col-lg-12 col-12 mb-3">
                                              <div className="form_complete_reg animated-element animate__animated animate__fadeInLeft animate__delay-.5s">
                                                <div className="material-textfield mb-0">
                                                <input
                                                  type="text"
                                                  name="vehicle_number"
                                                  id="vehicle_number"
                                                  placeholder=" "
                                                  value={vehicleNumberInput}
                                                  onKeyUp={handleCarReg}
                                                  onChange={handleCarReg}
                                                  maxLength="9"
                                                  ref={register({
                                                    required: "Please Enter Vehicle Number",
                                                    maxLength: {
                                                      value: 9,
                                                      message: "Please Enter Valid Vehicle Number",
                                                    },
                                                  })}
                                                />

                                                  <label>
                                                    Enter Vehicle Number
                                                  </label>
                                                </div>
                                                {errors.vehicle_number && (
                                                  <p
                                                    className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s"
                                                    style={{
                                                      background: "red",
                                                      borderRadius: "4px",
                                                      width: "100%",
                                                      marginLeft: "0px",
                                                      marginTop: "0px",
                                                    }}
                                                  >
                                                    {/* <img src={info} className="pe-1" alt="" /> */}
                                                    &nbsp;
                                                    {
                                                      errors.vehicle_number
                                                        .message
                                                    }
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          <p
                                            className="text-center"
                                            style={{
                                              color: "#fff",
                                              fontSize: "12px",
                                            }}
                                          >
                                            You are eligible for a claim even if
                                            you don't currently have the car or
                                            an active finance agreement.
                                          </p>
                                          <div className="box_button animate__animated animate__fadeInUp animate__delay-1s animated-element">
                                            <div className="bg-1 hme_btn pt-3 text-center">
                                              <button
                                                className="button"
                                                onClick={handleNextButtonClick}
                                                aria-label=""
                                              >
                                                Next
                                              </button>
                                            </div>
                                          </div>
                                          <div className="text-center mt-3">
                                            <button
                                              onClick={() => skipThisPage()}
                                              type="button"
                                              data-type="back"
                                              id="backStep-1"
                                              className="msf-nav-button backbutton"
                                            >
                                              Skip, if not handy
                                            </button>
                                          </div>
                                          <div className="img_secure text-center pt-3">
                                            <img src={ssl} alt="" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="modal fade"
                              id="Add_preName"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              tabIndex="-1"
                              aria-labelledby="Add_preNameLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s ">
                                  <div className="modal-header p-0">
                                    <h1
                                      className="modal-title fs-5"
                                      id="add_dbLabel"
                                    >
                                      <img src={logo_blue} alt="" />
                                    </h1>
                                  </div>
                                  <div className="modal-body">
                                    <div className="form_drop pt-2 drop_details col-lg-12 col-12">
                                      <p className="sty_pop">
                                        Please update if your full name differs
                                        from the one displayed
                                      </p>
                                      <p className="sty_pop">Mr.XYZ XYZ XYZ</p>
                                      <div className="row name_detail pt-0 animate__animated animate__fadeInUp animate__delay-.5s">
                                        <div className="col-lg-4 col-md-4 col-12 f_name">
                                          <label htmlFor="topmention1">
                                            First Name
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="topmention1"
                                            placeholder="First name"
                                            aria-label="First name"
                                          />
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-12 m_name">
                                          <label htmlFor="topmention3">
                                            Middle Name <span>(optional)</span>
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="topmention3"
                                            placeholder="Middle name"
                                            aria-label="Middle name"
                                          />
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-12 l_name">
                                          <label htmlFor="topmention2">
                                            Last Name
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="topmention2"
                                            placeholder="Last name"
                                            aria-label="Last name"
                                          />
                                        </div>
                                        <p className="tiny_note">
                                          <img
                                            src={info}
                                            className="pe-1"
                                            alt=""
                                          />
                                          What you are legally known as
                                        </p>
                                      </div>
                                    </div>
                                    <div className="form_drop drop_details col-lg-12 col-12">
                                      <p className="sty_pop">
                                        You have any Previous Name?(Leave blank
                                        if not applicable)
                                      </p>
                                      <div className="row name_detail pt-0 animate__animated animate__fadeInUp animate__delay-.5s">
                                        <div className="col-lg-12 col-md-12 col-12 f_name">
                                          <label htmlFor="topmention1">
                                            Previous Name
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="topmention1"
                                            placeholder="First name"
                                            aria-label="First name"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <div className="box_button final_ent pt-3 animate__animated animate__fadeInUp animate__delay-.5s">
                                      <div className="bg-1">
                                        <input
                                          type="button"
                                          className="button wb_5 button--moema button--text-thick button--text-upper button--size-s h60_click blick_notice"
                                          id="nextUpdate_001"
                                          value="Update"
                                          data-bs-dismiss="modal"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  {/* <!-- row end --> */}
                </div>
              </section>
              <FeaturedinAndAccordion />
              <Footer />
            </div>
          ) : (
            <div className="loader-wrap succLoader text-center text-light ">
              <nav className="navbar navbar-expand-lg">
                <a className="navbar-brand text-center col-12 mt-5 pt-5"></a>
              </nav>
              <div className="mt-5 pt-5">
                <img height="150" src={Spinner} alt="" />
                <h2 className="mt-3 succtext px-5 ">
                  Analysing the information you have provided <br />{" "}
                  <br />
                  <small> Please wait...</small>
                </h2>
              </div>
            </div>
          )}
          <div
            className={`modal fade ${vehicleDateModal && "show d-block"}`}
            id="CarDate"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="pre_addLabel"
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s ">
                <div className="modal-body">
                  <div className="form_drop yes_no_sec col-lg-12 col-12 p-3">
                    <div
                      className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s"
                      style={{ zIndex: 2, position: "relative" }}
                    >
                      <p className="png_t">
                        Please select the date you acquired your vehicle?
                      </p>
                      <div className="m-0 choose_Y_N row animate__animated animate__fadeInUp animate__delay-.5s">
                        {formattedDates.map((dateObj, index) => (
                          <div className="form-check px-0" key={index}>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="Radioshere2"
                              id={`radio${index}`}
                              value={dateObj.originalDate} // Set the unformatted value here
                              onClick={() =>
                                vehicleModalClick(dateObj.originalDate)
                              }
                            />
                            <label
                              className="form-check-label selecteddate"
                              htmlFor={`radio${index}`}
                            >
                              {dateObj.formattedDate}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CarRegistrationV2;
