import React, { useState } from "react";

const PreviousNameModal = ({
  showPreviousNameModal,
  setShowPreviousNameModal,
  setFormData,
  nextStep
}) => {
  // const [preNameError, setPreNameError] = useState("");
  const [preName, setPreName] = useState("");
  const [step, setStep] = useState(0);

  const updateUservalue = async (e) => {
    if(e.target.id == 'Radios2'){
      setFormData((prevFormData) => ({
        ...prevFormData,
        previousName: ''
      }));
    }else{
      setFormData((prevFormData) => ({
        ...prevFormData,
        previousName: preName === " " ? "" : preName.trim()
      }));
    }
    

    setShowPreviousNameModal(false);
    nextStep();
    setStep(0);
  };

  const handletitleChange = (e) => {
    const value = e.target.value;

    // Regex to allow only alphabets and single spaces
    if (/[^a-zA-Z\s]|[\s]{2,}/.test(value)) {
      return;
    }

    setPreName(value.trimStart());
    // if (!value.trim()) {
    //   setPreNameError("Please enter the Previous Surname.");
    //   return;
    // } else {
    //   setPreNameError('');
    // }
  }

  return (
    <>
      <div className={`modal preNameModal fade ${showPreviousNameModal && "show d-block"}`} id="Add_preName" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1}
        aria-labelledby="Add_preNameLabel" aria-modal="true" role="dialog">
        <div className="modal-dialog modal-dialog-centered px-0">
          <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s px-0">
            <div className="d-block modal-body px-0">
              <div className={`${step !== 0 && "d-none"} form_drop yes_no_sec col-lg-12 col-12 px-0`}>
                <div
                  className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s"
                  style={{ zIndex: 2, position: "relative" }}
                >
                  <p className="png_t">
                  Did you have any previous name?
                  </p>
                  <div className="choose_Y_N col-12 animate__animated animate__fadeInUp animate__delay-.5s">
                    <div className="form-check px-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="Radioshere2"
                        id="Radios2"
                        defaultValue="optionclick1"
                        onClick={updateUservalue}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Radios2"
                      >
                        No
                      </label>
                    </div>
                    <div className="form-check px-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="Radioshere2"
                        id="Radios1"
                        defaultValue="optionclick2"
                        onClick={() => setStep(step + 1)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="Radios1"
                      >
                        Yes
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`${step !== 1 && "d-none"} form_drop drop_details col-lg-12 col-12 animate__animated animate__fadeInUp animate__delay-.5s`}>
                <p className="sty_pop">
                  You have any Previous Name? (Leave blank if not applicable)
                </p>
                <div className="row name_detail pt-0">
                  <div className="col-lg-12 col-md-12 col-12 f_name">
                    <label htmlFor="topmention1">Previous Surname</label>
                    <input
                      type="text"
                      className="form-control"
                      id="topmention3"
                      placeholder="Previous Surname"
                      value={preName}
                      onChange={handletitleChange}
                    />
                    {/* {preNameError && (
                        <p className="animate__animated animate__fadeInUp animate__delay-.5s validate">
                          {preNameError}
                        </p>
                      )} */}
                  </div>
                </div>
              </div>
            </div>
            {step === 1 &&
              <div className="modal-footer">
                <div className="box_button final_ent pt-3 animate__animated animate__fadeInUp animate__delay-.5s">
                  <div className="bg-1 text-center">
                    <input
                      type="button"
                      className="button wb_5 button--moema button--text-thick button--text-upper button--size-s h60_click blick_notice"
                      id="nextUpdate_001"
                      value="Update"
                      onClick={updateUservalue} // Close modal on button click
                    />
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviousNameModal;
