import React, {
  useContext,
  useReducer,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import secure from "../../../assets/PCP_HDSC_V1/img/secure.svg";
import { useEmailValidation } from "../../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../../Hooks/usePhoneValidation";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import QueryStringContext from "../../../Contexts/QueryString";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import review_star from "../../../assets/PCP_HDSC_V1/img/review-star.png";
import bg_float from "../../../assets/PCP_HDSC_V1/img/bg-float3.png";
import * as EnvConstants from "../../../Constants/EnvConstants";
import PreviousNameModal from "../../Includes/Layouts/PCP_HDSC_V1/PreviousNameModal";
import BasicOne from "../../Includes/Layouts/PCP_HDSC_V1/BasicOne";
import FinanceProviderForm from "../../Includes/Layouts/PCP_HDSC_V1/FinanceProviderForm";
import CommissionDisclosureSection from "../../Includes/Layouts/PCP_HDSC_V1/CommissionDisclosureSection";
import BankruptcyQuestion from "../../Includes/Layouts/PCP_HDSC_V1/BankruptcyQuestion";
import ClaimedQuestion from "../../Includes/Layouts/PCP_HDSC_V1/ClaimedQuestion";
import WhyNotClaimed from "../../Includes/Layouts/PCP_HDSC_V1/WhyNotClaimed";
import PersonalizeExperience from "../../Includes/Layouts/PCP_HDSC_V1/PersonalizeExperience";
import { Finance as UnsortedFinance } from "../../../Constants/ConstantsPCP";
import EmailPhoneSection from "../../Includes/Layouts/PCP_HDSC_V1/EmailPhoneSection";
import DateOfBirthSection from "../../Includes/Layouts/PCP_HDSC_V1/DateOfBirthSection";
import PostcodeMainSection from "../../Includes/Layouts/PCP_HDSC_V1/PostcodeMainSection";
import CustomModal from "../../Includes/Layouts/PCP_HDSC_V1/CustomModal";
import LenderModal from "../../Includes/Layouts/PCP_HDSC_V1/LenderModal";
import UnQualifiedModal from "../../Includes/Layouts/PCP_HDSC_V1/UnQualifiedModal";
import { useForm } from "react-hook-form";
import GTMDataLayer from "../../../Utility/GTMDataLayer";

const highlightedBankValues = [6, 39, 61, 68];
// Filter the banks that match the highlighted values
const highlightedBank = UnsortedFinance.filter((bank) =>
  highlightedBankValues.includes(parseInt(bank.value))
);
// Order the highlighted banks according to highlightedBankValues
const highlightedBanksOrdered = highlightedBankValues.map((value) =>
  highlightedBank.find((bank) => parseInt(bank.value) === value)
);
// Get the remaining banks that are not highlighted and sort them alphabetically
const remainingBanks = UnsortedFinance.filter(
  (bank) => !highlightedBankValues.includes(parseInt(bank.value))
).sort((a, b) => a.label.localeCompare(b.label));
// Merge highlightedBanksOrdered and remainingBanks
const Finance = [...highlightedBanksOrdered, ...remainingBanks];

const initialState = {
  showSlide1: "show",
  showSlide2: "hide",
  showSlide3: "hide",
  showSlide4: "hide",
  showSlide5: "hide",
  showSlide6: "hide",
  showSlide7: "hide",
  showSlide8: "hide",
  showSlide9: "hide",
  showSlide10: "hide",
};

const FormReducer = (state, action) => {
  switch (action.type) {
    case "showSlide": {
      if (action.payload.clickedSlide.slide === "nextPlate_001") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "show",
        };
      } else if (
        action.payload.clickedSlide.slide === "nextslideCl_002" ||
        action.payload.clickedSlide.slide === "LenderClick"
      ) {
        return {
          ...state,
          showSlide2: "hide",
          showSlide3: "show",
        };
      } else if (action.payload.clickedSlide.slide === "commission") {
        return {
          ...state,
          showSlide3: "hide",
          showSlide4: "show",
        };
      } else if (action.payload.clickedSlide.slide === "bankruptcy") {
        return {
          ...state,
          showSlide4: "hide",
          showSlide5: "show",
        };
      } else if (action.payload.clickedSlide.slide === "claimed") {
        return {
          ...state,
          showSlide5: "hide",
          showSlide6: "show",
        };
      } else if (action.payload.clickedSlide.slide === "whyNotClaimed") {
        return {
          ...state,
          showSlide6: "hide",
          showSlide7: "show",
        };
      } else if (
        action.payload.clickedSlide.slide === "previousNameRadio" ||
        action.payload.clickedSlide.slide === "PreviousButton"
      ) {
        return {
          ...state,
          showSlide7: "hide",
          showSlide8: "show",
        };
      } else if (action.payload.clickedSlide.slide === "nextslideCl_004") {
        return {
          ...state,
          showSlide8: "hide",
          showSlide9: "show",
        };
      } else if (action.payload.clickedSlide.slide === "nextslideCl_005") {
        return {
          ...state,
          showSlide9: "hide",
          showSlide10: "show",
        };
      } else if (action.payload.clickedSlide.slide === "nextslideCl_006") {
        // setShowLoader(true);
        // setTimeout(() => {
        //   setShowLoader(false);
        // }, 4000);
      } else {
        return {
          ...state,
        };
      }
    }
    case "previousSlides": {
      if (action.payload.previousSlide.slide === "back01") {
        return {
          ...state,
          showSlide1: "show",
          showSlide2: "hide",
        };
      } else if (action.payload.previousSlide.slide === "back02") {
        return {
          ...state,
          showSlide2: "show",
          showSlide3: "hide",
        };
      } else if (action.payload.previousSlide.slide == "back03") {
        return {
          ...state,
          showSlide3: "show",
          showSlide4: "hide",
        };
      } else if (action.payload.previousSlide.slide == "back04") {
        return {
          ...state,
          showSlide4: "show",
          showSlide5: "hide",
        };
      } else if (action.payload.previousSlide.slide == "back05") {
        return {
          ...state,
          showSlide5: "show",
          showSlide6: "hide",
        };
      } else if (action.payload.previousSlide.slide == "back06") {
        return {
          ...state,
          showSlide6: "show",
          showSlide7: "hide",
        };
      } else if (
        action.payload.previousSlide.slide == "back07" ||
        action.payload.previousSlide.slide === "previousNameRadio" ||
        action.payload.previousSlide.slide === "PreviousButton"
      ) {
        return {
          ...state,
          showSlide7: "show",
          showSlide8: "hide",
        };
      } else if (action.payload.previousSlide.slide == "back08") {
        return {
          ...state,
          showSlide8: "show",
          showSlide9: "hide",
        };
      } else if (action.payload.previousSlide.slide == "back09") {
        return {
          ...state,
          showSlide9: "show",
          showSlide10: "hide",
        };
      } else {
        return {
          ...state,
        };
      }
    }
    default: {
    }
  }
};

const PCP_HDSC_V1Form = ({
  step,
  showModal,
  setShowModal,
  handleYesClick,
  showSecondSection,
  formData,
  handleChange,
  phoneError,
  emailError,
  setEmailError,
  setPhoneError,
  postCodeError,
  setFormData,
  setCurrentModalCount,
  currentModalCount,
  setPostCodeError,
  showPreviousNameModal,
  setShowPreviousNameModal,
  consentError,
  setConsentError,
  setShowLoader,
}) => {
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const [clickedSlide, setClickedSlide] = useState([]);
  const [previousSlide, setPreviousSlide] = useState([]);
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const history = useHistory();
  const { queryString } = useContext(QueryStringContext);
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const [formModalTwo, setFormModalTwo] = useState(false);
  const field = "pid";
  const [formModal, setFormModal] = useState(false);

  const {
    register,
    errors,
    trigger,
    clearErrors,
    getValues,
    setError,
    setValue,
    control,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  useEffect(() => {
    dispatch({ type: "showSlide", payload: { clickedSlide } });
  }, [clickedSlide]);

  useEffect(() => {
    if (previousSlide) {
      dispatch({ type: "previousSlides", payload: { previousSlide } });
    }
  }, [previousSlide]);

  const slideChange = (e) => {
    if (e.target.name == "nextPlate_001") {
      GTMDataLayer({
        question: "express registration",
        answer: null,
      });
    } else if (e.target.name == "nextslideCl_002") {
      GTMDataLayer({
        question: "lender",
        answer: null,
      });
    } else if (e.target.name == "commission") {
      GTMDataLayer({
        question: "commission",
        answer: null,
      });
    } else if (e.target.name == "bankruptcy") {
      GTMDataLayer({
        question: "bankruptcy",
        answer: null,
      });
    } else if (e.target.name == "claimed") {
      GTMDataLayer({
        question: "already claimed",
        answer: null,
      });
    } else if (e.target.name == "whyNotClaimed") {
      GTMDataLayer({
        question: "reason for claim",
        answer: null,
      });
    } else if (e.target.name == "previousNameRadio" || e.target.name == "PreviousButton") {
      GTMDataLayer({
        question: "name",
        answer: null,
      });
    } else if (e.target.name == "nextslideCl_004") {
      GTMDataLayer({
        question: "email,phone",
        answer: null,
      });
    } else if (e.target.name == "nextslideCl_005") {
      GTMDataLayer({
        question: "dob",
        answer: null,
      });
    }
    setClickedSlide({ slide: e.target.name });
  };
  const handleChangeButton = (e) => {
    const name = e.target.name;
    const value = e.target.getAttribute("id");
    setFormData({ ...formData, [name]: value });
    formData.commission = e.target.value;
    formData.bankruptcy = e.target.value;
    formData.claimed = e.target.value;
    slideChange(e);
  };

  const previousSlideChange = (slideName) => {
    dispatch({
      type: "previousSlides",
      payload: {
        previousSlide: {
          slide: slideName,
        },
      },
    });
  };
  const handleYesModal = (e) => {
    setFormModalTwo(true);
    const name = e.target.name;
    const value = "";
    setFormData({ ...formData, [name]: value });
  };
  const validateEmail = async (e) => {
    const email = formData.txtEmail;
    const phone = formData.txtPhone;
    const unsubscribeStatus = formData.unsubscribeStatus;
    if (email == "") {
      setEmailError("Please enter email.");
    }
    if (phone == "") {
      setPhoneError("Please enter phone number.");
    }
    if (unsubscribeStatus === null) {
      setConsentError("Please select any one of the above.");
    } else {
      setConsentError("");
    }
    if (email != "" && phone != "") {
      const getPhoneValidation = await phoneValidation(phone);
      const getEmailValidation = await emailValidation(
        email,
        visitorParameters.visitor_parameters.uuid
      );
      if (getEmailValidation.data.status_code === 1) {
        setEmailError("");

        if (getPhoneValidation.data.status_code === 1) {
          if (unsubscribeStatus != null) {
            slideChange(e);
          }
        }
        if (getPhoneValidation.data.status_code != 1) {
          setPhoneError("Invalid phone number.");
        }
      } else {
        setEmailError("Invalid email.");
      }
    }
  };
  // Handle form submission
  const handleSubmit = async () => {
    const split_name = "PCP_HDSC_V1";
    const values = getValues();
    const storedFormData = localStorage.setItem(
      "formData",
      JSON.stringify(formData)
    );
    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");
    const formdatavalues = values;
    formdatavalues.txtFName = formData.txtFName.trim();
    formdatavalues.txtLName = formData.txtLName.trim();
    formdatavalues.lstSalutation = formData.lstSalutation;
    formdatavalues.txtEmail = formData.txtEmail;
    formdatavalues.txtPhone = formData.txtPhone;
    formdatavalues.dobDay = formData.dobDay;
    formdatavalues.dobMonth = formData.dobMonth;
    formdatavalues.dobYear = formData.dobYear;
    formdatavalues.financeProvider = formData.financeProvider;
    formdatavalues.commission = formData.commission;
    formdatavalues.bankruptcy = formData.bankruptcy;
    formdatavalues.claimed = formData.claimed;
    formdatavalues.whyNotClaimed = formData.whyNotClaimed;
    formdatavalues.previousName = formData.previousName;
    formdatavalues.unsubscribeStatus = formData.unsubscribeStatus;

    const formSUbmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      formdatavalues,
      "split_form_submit",
      "PCP_HDSC_V1",
      queryString,
      visitorParameters.data
    );

    GTMDataLayer({
      question: "address",
      answer: null,
    });

    if (formSUbmitResult.data.status === "Success") {
      if (queryString != null) {
        if (queryString.indexOf("&" + field + "=") !== -1) {
          if (EnvConstants.AppConversionPixel === "true") {
            console.log("if fb");
            history.push(
              "/fbpixel?split_name=" + split_name + "&next_page=signature"
            ); // fb fixel firing
          } else {
            console.log("else fb");
            window.scrollTo(0, 0);
            history.push(
              "/signature?uuid=" +
                visitorParameters.visitor_parameters.uuid +
                "&split_name=" +
                split_name
            );
          }
        } else {
          console.log("another else fb");
          window.scrollTo(0, 0);
          history.push(
            "/signature?uuid=" +
              visitorParameters.visitor_parameters.uuid +
              "&split_name=" +
              split_name
          );
        }
      } else {
        console.log("laset else  fb");
        window.scrollTo(0, 0);
        history.push(
          "/signature?uuid=" +
            visitorParameters.visitor_parameters.uuid +
            "&split_name=" +
            split_name
        );
      }
    }
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: "56px",
    }),
  };
  const handleNoClick = async () => {
    const split_name = "PCP_HDSC_V1";
    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");
    const values = getValues();
    const storedFormData = localStorage.setItem(
      "formData",
      JSON.stringify(formData)
    );
    const formdatavalues = values;
    formdatavalues.txtFName = formData.txtFName.trim();
    formdatavalues.txtLName = formData.txtLName.trim();
    formdatavalues.lstSalutation = formData.lstSalutation;
    formdatavalues.txtEmail = formData.txtEmail;
    formdatavalues.txtPhone = formData.txtPhone;
    formdatavalues.dobDay = formData.dobDay;
    formdatavalues.dobMonth = formData.dobMonth;
    formdatavalues.dobYear = formData.dobYear;
    formdatavalues.financeProvider = formData.financeProvider;
    formdatavalues.commission = formData.commission;
    formdatavalues.bankruptcy = formData.bankruptcy;
    formdatavalues.claimed = formData.claimed;
    formdatavalues.whyNotClaimed = formData.whyNotClaimed;
    formdatavalues.previousName = formData.previousName;
    formdatavalues.unsubscribeStatus = formData.unsubscribeStatus;

    const formSUbmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      formdatavalues,
      "split_form_submit",
      "PCP_HDSC_V1",
      queryString,
      visitorParameters.data
    );

    GTMDataLayer({
      question: "address",
      answer: null,
    });

    if (formSUbmitResult.data.status === "Success") {
      // Get the slugs corresponding to the selected values
      const selectedSlugs = formData.financeProvider.map((value) => {
        const selectedOption = Finance.find((option) => option.value === value);
        return selectedOption ? selectedOption.slug : "";
      });

      // Join the slugs into a single string
      const lendersQueryParam = selectedSlugs.join(",");

      if (queryString != null) {
        if (queryString.indexOf("&" + field + "=") !== -1) {
          if (EnvConstants.AppConversionPixel === "true") {
            console.log("if fb");
            history.push(
              "/fbpixel?split_name=" + split_name + "&next_page=signature"
            ); // fb fixel firing
          } else {
            console.log("else fb");
            window.scrollTo(0, 0);
            history.push(
              "/signature?uuid=" +
                visitorParameters.visitor_parameters.uuid +
                "&split_name=" +
                split_name
            );
          }
        } else {
          console.log("another else fb");
          window.scrollTo(0, 0);
          history.push(
            "/signature?uuid=" +
              visitorParameters.visitor_parameters.uuid +
              "&split_name=" +
              split_name
          );
        }
      } else {
        console.log("laset else  fb");
        window.scrollTo(0, 0);
        history.push(
          "/signature?uuid=" +
            visitorParameters.visitor_parameters.uuid +
            "&split_name=" +
            split_name
        );
      }
    }
  };
  const handleKeyPress = (e) => {
    const charCode = e.charCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };
  const emailValidCheck = async (event) => {
    const email = event.target.value;
    if (email !== "") {
      const getEmailValidation = await emailValidation(
        email,
        visitorParameters.visitor_parameters.uuid
      );
      if (getEmailValidation.data.status_code === 1) {
        setEmailError("");
        return 1;
      } else {
        setEmailError("Invalid email.");
      }
    }
  };
  const cancelpopup = () => {
    setFormModal(false);
  };
  const cancelpopupTwo = () => {
    setFormModalTwo(false);
  };

  const phoneValidCheck = useCallback(async (event) => {
    const phone = event.target.value;

    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone);
      if (getPhoneValidation.data.status_code === 1) {
        clearErrors("txtPhone");
        return 1;
      } else if (getPhoneValidation.data.status_code === 0) {
        setPhoneError("Please Enter Valid Working Phone Number");
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        setPhoneError("Phone Number Already Exist");
        return 0;
      } else {
        setPhoneError("Please Enter Valid Phone Number");
        return 0;
      }
    } else {
      setPhoneError("Please Enter Phone Number");
      return 0;
    }
  });

  const handleFinanceProviderChange = (e) => {
    if (currentModalCount < 4) {
      setCurrentModalCount(currentModalCount + 1);
    }
    localStorage.setItem("currentModalCount", currentModalCount);
    if (currentModalCount > 3) {
      setFormModal(true);
    }
    const selectedValue = e.value;
    // Check if selectedValue exists in the Finance constant

    if (!formData.financeProvider.includes(e.value)) {
      // Add selectedValue to formData.financeProvider array
      if (formData.financeProvider.length < 4) {
        setFormData({
          ...formData,
          financeProvider: [...formData.financeProvider, e.value],
        });
      }
    }
    const financeItem = Finance.find((item) => item.value === selectedValue);
    if (financeItem) {
      // Retrieve slug name from the finance item
      const slug = financeItem.slug;

      // Get existing data from local storage or initialize an empty array
      const localStorageData =
        JSON.parse(localStorage.getItem("slugArray")) || [];

      // Check if the slug is already stored in local storage
      if (!localStorageData.includes(slug)) {
        // Add the slug to the array
        const updatedData = [...localStorageData, slug];
        // Update local storage
        if (updatedData.length <= 4) {
          localStorage.setItem("slugArray", JSON.stringify(updatedData));
        }
      }
    }
  };

  useEffect(() => {
    const localStorageData =
      JSON.parse(localStorage.getItem("slugArray")) || [];
    if (localStorageData) {
      localStorage.removeItem("slugArray");
    }
  }, []);

  const handleRemoveFinanceProvider = (index) => {
    setCurrentModalCount(currentModalCount - 1);

    // Remove the value from the formData.financeProvider array
    const updatedFinanceProvider = [...formData.financeProvider];
    const removedValue = updatedFinanceProvider.splice(index, 1)[0];
    setFormData({ ...formData, financeProvider: updatedFinanceProvider });

    // Remove associated slug from local storage
    const financeItem = Finance.find((item) => item.value === removedValue);
    if (financeItem) {
      const slug = financeItem.slug;
      const localStorageData =
        JSON.parse(localStorage.getItem("slugArray")) || [];
      const updatedData = localStorageData.filter((item) => item !== slug);
      if (updatedData.length <= 4) {
        localStorage.setItem("slugArray", JSON.stringify(updatedData));
      }
    }
  };

  const autofocus = useRef();
  const toggleFocus = (event) => {
    if (autofocus.current) {
      const refElement = autofocus.current;
      const offset = -135;

      const scrollPosition =
        refElement.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    } else {
      window.scroll(0, 0);
    }
  };

  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()}>
        <section className="main_content">
          <div className="container">
            {/* row start */}
            <div className="row align-items-center">
              <div className="cs_hero_wrap cs_bg_filed">
                {/*Gradient*/}
                <div className="container-md">
                  <div className="home_plate_001">
                    <div className="row pt-5 pt-md-3">
                      <div className="cs_hero_text col-lg-6 col-12 d-block order-lg-1 order-md-1 order-2 pt-0 pt-md-3">
                        <BasicOne
                          className={`${state.showSlide1}`}
                          slideChange={slideChange}
                        />
                        <div
                          className={`mt-0
                            form_dec col-12 col-md-11
                            animate__animated animate__fadeInUp
                            animate__delay-.5s animated-element
                            `}
                        >
                          <div
                            className=" animate__animated animate__fadeInUp animate__delay-.5s animated-element borderx"
                            id="dynamic"
                          >
                            {step === 1 && <div className="no_border"></div>}
                            <FinanceProviderForm
                              className={`${state.showSlide2}`}
                              formData={formData}
                              validation={register}
                              validationMsg={errors}
                              trigger={trigger}
                              clearErrors={clearErrors}
                              getValues={getValues}
                              handleFinanceProviderChange={
                                handleFinanceProviderChange
                              }
                              handleRemoveFinanceProvider={
                                handleRemoveFinanceProvider
                              }
                              customStyles={customStyles}
                              register={register}
                              toggleFocus={toggleFocus}
                              slideChange={slideChange}
                              previousSlideChange={previousSlideChange}
                              setFormData={setFormData}
                              errors={errors}
                              setError={setError}
                            />

                            <CommissionDisclosureSection
                              className={`${state.showSlide3}`}
                              formData={formData}
                              handleYesModal={handleYesModal}
                              slideChange={slideChange}
                              previousSlideChange={previousSlideChange}
                              register={register}
                              handleChangeButton={handleChangeButton}
                            />

                            <BankruptcyQuestion
                              className={`${state.showSlide4}`}
                              formData={formData}
                              handleYesModal={handleYesModal}
                              slideChange={slideChange}
                              handleChangeButton={handleChangeButton}
                              previousSlideChange={previousSlideChange}
                              register={register}
                            />
                            <ClaimedQuestion
                              className={`${state.showSlide5}`}
                              formData={formData}
                              handleYesModal={handleYesModal}
                              slideChange={slideChange}
                              handleChangeButton={handleChangeButton}
                              previousSlideChange={previousSlideChange}
                            />

                            <WhyNotClaimed
                              formData={formData}
                              slideChange={slideChange}
                              className={`${state.showSlide6}`}
                              previousSlideChange={previousSlideChange}
                              handleChangeButton={handleChangeButton}
                            />

                            <PersonalizeExperience
                              validation={register}
                              validationMsg={errors}
                              trigger={trigger}
                              clearErrors={clearErrors}
                              getValues={getValues}
                              formData={formData}
                              handleChange={handleChange}
                              setError={setError}
                              slideChange={slideChange}
                              className={`${state.showSlide7}`}
                              previousSlideChange={previousSlideChange}
                              setFormData={setFormData}
                              setShowPreviousNameModal={
                                setShowPreviousNameModal
                              }
                            />

                            <EmailPhoneSection
                              formData={formData}
                              handleChange={handleChange}
                              emailValidCheck={emailValidCheck}
                              phoneValidCheck={phoneValidCheck}
                              handleKeyPress={handleKeyPress}
                              validateEmail={validateEmail}
                              validation={register}
                              validationMsg={errors}
                              trigger={trigger}
                              clearErrors={clearErrors}
                              setError={setError}
                              getValues={getValues}
                              emailError={emailError}
                              phoneError={phoneError}
                              consentError={consentError}
                              setFormData={setFormData}
                              slideChange={slideChange}
                              className={`${state.showSlide8}`}
                              previousSlideChange={previousSlideChange}
                            />

                            <DateOfBirthSection
                              formData={formData}
                              validation={register}
                              slideChange={slideChange}
                              className={`${state.showSlide9}`}
                              previousSlideChange={previousSlideChange}
                              trigger={trigger}
                              errors={errors}
                              clearErrors={clearErrors}
                              setError={setError}
                              getValues={getValues}
                              setShowLoader={setShowLoader}
                            />

                            <PostcodeMainSection
                              register={register}
                              errors={errors}
                              trigger={trigger}
                              clearErrors={clearErrors}
                              handleChange={handleChange}
                              setError={setError}
                              setValue={setValue}
                              setPostCodeError={setPostCodeError}
                              postCodeError={postCodeError}
                              slideChange={slideChange}
                              className={`${state.showSlide10}`}
                              previousSlideChange={previousSlideChange}
                              setShowModal={setShowModal}
                              formData={formData}
                              getValues={getValues}

                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className={`img_float animate__animated animate__fadeInLeft animate__delay-1s order-lg-2 order-md-2 order-1 col-lg-6 col-12 text-center
                          ${
                            state.showSlide1 == "show"
                              ? ""
                              : "must_hide_at_mobile"
                          }`}
                      >
                        <img
                          src={bg_float}
                          alt="Hero"
                          className="cs_hero_img mt-0"
                        />
                      </div>
                      <div className="col-12 order-3">
                        {step === 1 && (
                          <div
                            className={`${
                              state.showSlide1 == "show" ? "d-block" : "d-none"
                            } `}
                          >
                            <div
                              className={`box_button animate__animated animate__fadeInLeft animate__delay-1s animated-element wht_iFNot no_border`}
                              id="subRegNo1"
                            >
                              <div className={` bg-1 hme_btn `}>
                                <input
                                  type="button"
                                  className="button button--moma button--text-thick button--text-upper button--size-s blick_notice"
                                  id="nextPlate_001"
                                  defaultValue="EXPRESS REGISTRATION"
                                  aria-label="submitid"
                                  name="nextPlate_001"
                                  onClick={slideChange}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <p
                          className={`mt-2 fw-semibold text-white text-center nimate__animated animate__fadeInLeft animate__delay-1s animated-element
                            mb-2 ${
                              state.showSlide1 === "show"
                                ? "show-on-mobile hide-on-desktop"
                                : "must_hide_at_mobile hide-on-desktop"
                            } `}
                        >
                          Quick, easy, fully online registration.
                        </p>
                        <div className="mob_view animate__animated animate__fadeInLeft animate__delay-1s">
                          <img src={review_star} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* form section */}
            </div>
            <div className="img_secure text-center py-4 d-none">
              <img src={secure} alt="" />
            </div>
            {/* row end */}
          </div>
        </section>

        {showModal && (
          <CustomModal
            showModal={showModal}
            showSecondSection={showSecondSection}
            handleYesClick={handleYesClick}
            handleNoClick={handleNoClick}
            handleSubmit={handleSubmit}
            register={register}
            errors={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            setError={setError}
            cancelpopup={() => setFormModal(false)}
            cancelpopupTwo={() => setFormModalTwo(false)}
          />
        )}
      </form>
      <UnQualifiedModal
        cancelpopupTwo={cancelpopupTwo}
        formModalTwo={formModalTwo}
      />
      <LenderModal
        formModal={formModal}
        cancelpopup={cancelpopup}
        slideChange={slideChange}
        setFormModal={setFormModal}
      />

      <PreviousNameModal
        showPreviousNameModal={showPreviousNameModal}
        setShowPreviousNameModal={setShowPreviousNameModal}
        slideChange={slideChange}
        setFormData={setFormData}
      />
    </div>
  );
};

export default PCP_HDSC_V1Form;
