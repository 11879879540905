import React from "react";
import Button from "../../../UI/Button";

const UnQualifiedModal = (props) => {
  return (
    <div>
      <div
        className={`modal fade ${props.formModalTwo && "show d-block"}`}
        id="CarDate"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="pre_addLabel"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s ">
            <div className="modal-header p-1">
              <Button
                type="button"
                className="btn-close"
                onClick={props.cancelpopupTwo}
              />
            </div>

            <div className="modal-body text-center">
              <div className="form_drop yes_no_sec col-lg-12 col-12 p-1">
                <div
                  className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s"
                  style={{ zIndex: 2, position: "relative" }}
                >
                  <p className="png_t">
                    Sorry, we will not be able to help with this claim.
                  </p>
                </div>
                <div className="button-group">
                  <Button
                    type="button"
                    className="bt_cancel"
                    buttonText="OK"
                    onClick={props.cancelpopupTwo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnQualifiedModal;
