export const Finance = [
  {
    label: "ALD Automotive Limited",
    value: "1",
    slug: 'ald_automotive_limited',
    id_required: false
  },
  {
    label: "Lombard Vehicle Solutions",
    value: "2",
    slug: 'ald_automotive_limited_lombard_vehicle_solutions',
    id_required: false
  },
  {
    label: "NatWest Car Finance",
    value: "3",
    slug: 'ald_automotive_limited_natwest_car_finance',
    id_required: false
  },
  {
    label: "Aldemore Bank PLC",
    value: "4",
    slug: 'aldemore_bank_plc_aldemore_bank_plc',
    id_required: false
  },
  {
    label: "Black Horse Limited",
    value: "5",
    slug: "black_horse_limited_black_horse_limited",
    id_required: false
  },
  {
    label: "Black Horse Motor Finance",
    value: "6",
    slug: "black_horse_limited_black_horse_motor_finance",
    id_required: false
  },
  {
    label: "Black Horse Caravan and Motorhome Finance",
    value: "7",
    slug: "black_horse_limited_black_horse_caravan_and_motorhome_finance",
    id_required: false
  },
  {
    label: "Land Rover Financial Services 2016-Present",
    value: "8",
    slug: "black_horse_limited_land_rover_financial_services_2016_present",
    id_required: false
  },
  {
    label: "Jaguar Financial Services 2016-Present",
    value: "9",
    slug: "black_horse_limited_jaguar_financial_services_2016_present",
    id_required: false
  },
  {
    label: "Triumph Motorcycle Finance 2016-Present",
    value: "10",
    slug: "black_horse_limited_triumph_motorcycle_finance_2016_present",
    id_required: false
  },
  {
    label: "Subaru Finance 2016-Present",
    value: "11",
    slug: "black_horse_limited_subaru_finance_2016_present",
    id_required: false
  },
  {
    label: "Harley Davidson Finance 2016-Present",
    value: "12",
    slug: "black_horse_limited_harley_davidson_finance_2016_present",
    id_required: false
  },
  {
    label: "Yamaha Finance 2016-2019",
    value: "13",
    slug: "black_horse_limited_yamaha_finance_2016_2019",
    id_required: false
  },
  {
    label: "Ducati Finance 2016-Present",
    value: "14",
    slug: "black_horse_limited_ducati_finance_2016_present",
    id_required: false
  },
  {
    label: "Kawasaki Finance 2016-Present",
    value: "15",
    slug: "black_horse_limited_kawasaki_finance_2016_present",
    id_required: false
  },
  {
    label: "Lotus Finance 2016-Present",
    value: "16",
    slug: "black_horse_limited_lotus_finance_2016_present",
    id_required: false
  },
  {
    label: "Suzuki Finance 2016-Present",
    value: "17",
    slug: "black_horse_limited_suzuki_finance_2016_present",
    id_required: false
  },
  {
    label: "BMW Financial Services (GB) Limited",
    value: "18",
    slug: "bmw_financial_services_gb_limited_bmw_financial_services_gb_limited",
    id_required: true
  },
  // {
  //   label: "BMW Financial Services",
  //   value: "19",
  //   slug: "bmw_financial_services_gb_limited_bmw_financial_services",
  //   id_required: true
  // },
  {
    label: "MINI Finance 2005-Present",
    value: "20",
    slug: "bmw_financial_services_gb_limited_mini_finance_2005_present",
    id_required: true
  },
  {
    label: "Alphera Financial Services",
    value: "21",
    slug: "bmw_financial_services_gb_Limited_alphera_financial_services",
    id_required: true
  },
  {
    label: "Aston Martin Financial Services 2016-Present",
    value: "22",
    slug: "bmw_financial_services_gb_limited_aston_martin_financial_services_2016_present",
    id_required: true
  },
  {
    label: "Rolls Royce Financial Services",
    value: "23",
    slug: "bmw_financial_services_gb_limited_rolls_royce_financial_services",
    id_required: true
  },
  {
    label: "Borderway Finance Limited",
    value: "24",
    slug: "borderway_finance_limited_borderway_finance_limited",
    id_required: null
  },
  {
    label: "C.E.M. Day Ltd",
    value: "25",
    slug: "cem_day_ltd_cem_day_ltd",
    id_required: true
  },
  {
    label: "Days Motor Group",
    value: "26",
    slug: "cem_day_ltd_days_motor_group",
    id_required: true
  },
  {
    label: "CA Auto Finance UK Ltd",
    value: "27",
    slug: "ca_auto_finance_uk_ltd_ca_auto_finance_uk_ltd",
    id_required: false
  },
  {
    label: "FCA Automotive Services UK Ltd",
    value: "28",
    slug: "ca_auto_finance_uk_ltd_fca_automotive_services_uk_ltd",
    id_required: true
  },
  {
    label: "Alfa Romeo Financial Services",
    value: "29",
    slug: "ca_auto_finance_uk_ltd_alfa_romeo_financial_services",
    id_required: false
  },
  {
    label: "Chrysler Financial Services",
    value: "30",
    slug: "ca_auto_finance_uk_ltd_chrysler_financial_services",
    id_required: false
  },
  {
    label: "FGA Capital UK Limited",
    value: "31",
    slug: "ca_auto_finance_uk_ltd_fga_capital_uk_limited",
    id_required: false
  },
  {
    label: "Fiat Financial Services",
    value: "32",
    slug: "ca_auto_finance_uk_ltd_fiat_financial_services",
    id_required: false
  },
  {
    label: "Jaguar Cars Finance",
    value: "33",
    slug: "ca_auto_finance_uk_ltd_jaguar_cars_finance",
    id_required: false
  },
  {
    label: "Jeep Financial Services 2016-Present",
    value: "34",
    slug: "ca_auto_finance_uk_ltd_jeep_financial_services_2016_resent",
    id_required: false
  },
  {
    label: "Landrover Finance",
    value: "35",
    slug: "ca_auto_finance_uk_ltd_landrover_finance",
    id_required: false
  },
  {
    label: "Maserati Financial Services 2016-Present",
    value: "36",
    slug: "ca_auto_finance_uk_ltd_maserati_financial_services_2016_present",
    id_required: false
  },
  {
    label: "Erwin Hymer Group Finance 2016-Present",
    value: "37",
    slug: "ca_auto_finance_uk_ltd_erwin_hymer_group_finance_2016_present",
    id_required: false
  },
  {
    label: "Close Motor Finance Limited",
    value: "38",
    slug: "close_motor_finance_limited_close_motor_finance_limited",
    id_required: true
  },
  {
    label: "Close Brothers Motor Finance",
    value: "39",
    slug: "close_motor_finance_limited_close_brothers_motor_finance",
    id_required: true
  },
  {
    label: "Clydesdale Financial Services Limited",
    value: "40",
    slug: "barclays_partner_finance_limited_clydesdale_financial_services_limited",
    id_required: true
  },
  {
    label: "Barclays Partner Finance",
    value: "41",
    slug: "barclays_partner_finance_limited_barclays_partner_finance",
    id_required: true
  },
  {
    label: "Barclaycard Motor loans",
    value: "42",
    slug: "barclays_partner_finance_limited_barclaycard_motor_loans",
    id_required: true
  },
  {
    label: "FCE Bank Plc",
    value: "43",
    slug: "fce_bank_plc_fce_bank_plc",
    id_required: null
  },
  {
    label: "Ford Credit",
    value: "44",
    slug: "fce_bank_plc_ford_credit",
    id_required: null
  },
  {
    label: "Ford Financial",
    value: "45",
    slug: "fce_bank_plc_ford_financial",
    id_required: null
  },
  {
    label: "Jaguar Financial Services 2005-2015",
    value: "46",
    slug: "fce_bank_plc_jaguar_financial_services_2005_2015",
    id_required: null
  },
  {
    label: "Land Rover Financial Services 2005-2015",
    value: "47",
    slug: "fce_bank_plc_land_rover_financial_services_2005_2015",
    id_required: null
  },
  {
    label: "Mazda Credit 2006-2018",
    value: "48",
    slug: "fce_bank_plc_mazda_credit_2006_2018",
    id_required: null
  },
  {
    label: "Volvo Car Finance 2005-2015",
    value: "49",
    slug: "fce_bank_plc_volvo_car_finance_2005_2015",
    id_required: null
  },
  {
    label: "Ferrari Financial Services GmbH",
    value: "50",
    slug: "ferrari_financial_services_gmbh_ferrari_financial_services_gmbh",
    id_required: true
  },
  {
    label: "Honda Finance Europe Plc",
    value: "51",
    slug: "honda_finance_europe_plc_honda_finance_europe_plc",
    id_required: true
  },
  {
    label: "Honda Financial Services",
    value: "52",
    slug: "honda_finance_europe_plc_honda_financial_services",
    id_required: true
  },
  {
    label: "Hyundai Capital (UK) Limited",
    value: "53",
    slug: "hyundai_capital_uk_limited_hyundai_capital_uk_limited",
    id_required: false
  },
  // {
  //   label: "Hyundai Capital UK Limited",
  //   value: "54",
  //   slug: "hyundai_capital_uk_limited__hyundai_capital_uk_limited",
  //   id_required: false
  // },
  {
    label: "Hyundai Finance",
    value: "55",
    slug: "hyundai_capital_uk_limited_hyundai_finance",
    id_required: false
  },
  {
    label: "Kia Finance 2015-Present",
    value: "56",
    slug: "hyundai_capital_uk_limited_kia_finance_2015_present",
    id_required: false
  },
  {
    label: "JBR Capital Limited",
    value: "57",
    slug: "jbr_capital_limited_jbr_capital_limited",
    id_required: true
  },
  {
    label: "Mercedes-Benz Financial Services UK Limited",
    value: "58",
    slug: "mercedes_benz_financial_services_uk_limited_mercedes_benz_financial_services_uk_limited",
    id_required: true
  },
  {
    label: "MI Vehicle Finance Limited",
    value: "59",
    slug: "mi_vehicle_finance_limited_mi_vehicle_finance_limited",
    id_required: true
  },
  {
    label: "Mann Island",
    value: "60",
    slug: "mi_vehicle_finance_limited_mann_island",
    id_required: true
  },
  {
    label: "MotoNovo Finance",
    value: "61",
    slug: "motonovo_finance_motonovo_finance",
    id_required: false
  },
  {
    label: "FirstRand Bank",
    value: "62",
    slug: "motonovo_finance_firstrand_bank",
    id_required: false
  },
  {
    label: "RCI Financial Service Limited",
    value: "63",
    slug: "rci_financial_service_limited__rci_financial_service_limited",
    id_required: true
  },
  {
    label: "Nissan Finance / Financial Services",
    value: "64",
    slug: "rci_financial_service_limited_nissan_finance_financial_services",
    id_required: true
  },
  {
    label: "Renault Finance / Financial Services / RFS",
    value: "65",
    slug: "rci_financial_service_limited_renault_finance_financial_services_rfs",
    id_required: true
  },
  {
    label: "Alpine Finance",
    value: "66",
    slug: "rci_financial_service_limited_alpine_finance",
    id_required: true
  },
  {
    label: "Dacia Finance",
    value: "67",
    slug: "rci_financial_service_limited_dacia_finance",
    id_required: true
  },
  {
    label: "Santander Consumer (UK) Plc",
    value: "68",
    slug: "santander_consumer_uk_plc_santander_consumer_uk_plc",
    id_required: false
  },
  {
    label: "GE Capital Motor Finance 2015-2019",
    value: "69",
    slug: "santander_consumer_uk_plc_ge_capital_motor_finance_2015_2019",
    id_required: false
  },
  {
    label: "GE Money Car Loans 2015-2019",
    value: "70",
    slug: "santander_consumer_uk_plc_ge_money_car_loans_2015_20199",
    id_required: false
  },
  {
    label: "Kia Financial Services 2015-Present",
    value: "71",
    slug: "santander_consumer_uk_plc_kia_financial_services_2015_present",
    id_required: false
  },
  {
    label: "Lookers Finance 2015-2019",
    value: "72",
    slug: "santander_consumer_uk_plc_lookers_finance_2015_2019",
    id_required: false
  },
  {
    label: "Mazda Financial Services 2018-Present",
    value: "73",
    slug: "santander_consumer_uk_plc_mazda_financial_services_2018_present",
    id_required: false
  },
  {
    label: "Volvo Car Financial Services 2015-Present",
    value: "74",
    slug: "santander_consumer_uk_plc_volvo_car_financial_services_2015_present",
    id_required: false
  },
  {
    label: "Woodchester Finance 2015-2019",
    value: "75",
    slug: "santander_consumer_uk_plc_woodchester_finance_2015_2019",
    id_required: false
  },
  {
    label: "Secure Trust Bank PLC",
    value: "76",
    slug: "secure_trust_bank_plc_secure_trust_bank_plc",
    id_required: null
  },
  {
    label: "Moneyway",
    value: "77",
    slug: "secure_trust_bank_plc_moneyway",
    id_required: false
  },
  {
    label: "V12 Vehicle Finance",
    value: "78",
    slug: "secure_trust_bank_plc_v12_vehicle_finance",
    id_required: null
  },
  {
    label: "Shogun Finance Limited",
    value: "79",
    slug: "shogun_finance_limited_shogun_finance_limited",
    id_required: false
  },
  {
    label: "Finance Mitsubishi",
    value: "80",
    slug: "shogun_finance_limited_finance_mitsubishi",
    id_required: false
  },
  {
    label: "Shogun Finance / Financial Services",
    value: "81",
    slug: "shogun_finance_limited_shogun_finance_financial_services",
    id_required: false
  },
  {
    label: "Stellantis Financial Services UK Limited",
    value: "82",
    slug: "stellantis_financial_services_uk_limited_stellantis_financial_services_uk_limited",
    id_required: null
  },
  {
    label: "Citroen Finance",
    value: "83",
    slug: "stellantis_financial_services_uk_limited_citroen_finance",
    id_required: null
  },
  {
    label: "Peugeot Finance",
    value: "84",
    slug: "stellantis_financial_services_uk_limited_peugeot_finance",
    id_required: null
  },
  {
    label: "PSA Finance",
    value: "85",
    slug: "stellantis_financial_services_uk_limited_psa_finance",
    id_required: false
  },
  {
    label: "PSA Financial Services Limited",
    value: "86",
    slug: "stellantis_financial_services_uk_limited_psa_financial_services_limited",
    id_required: null
  },
  {
    label: "Vauxhall Finance Plc",
    value: "87",
    slug: "vauxhall_finance_plc_vauxhall_finance_plc",
    id_required: false,
    car_reg: true
  },
  {
    label: "Ally Finance / Financial Services 2010-2016",
    value: "88",
    slug: "vauxhall_finance_plc_ally_finance_financial_services_2010_2016",
    id_required: false,
    car_reg: true
  },
  {
    label: "Chevrolet Finance",
    value: "89",
    slug: "vauxhall_finance_plc_chevrolet_finance",
    id_required: false,
    car_reg: true
  },
  {
    label: "General Motors Financial Services 2016-2019",
    value: "90",
    slug: "vauxhall_finance_plc_general_motors_financial_services_2016_2019",
    id_required: false,
    car_reg: true
  },
  {
    label: "GMAC 2005-2019",
    value: "91",
    slug: "vauxhall_finance_plc_gmac_2005_2019",
    id_required: false,
    car_reg: true
  },
  {
    label: "MG Financial Services 2016-2020",
    value: "92",
    slug: "vauxhall_finance_plc_mg_financial_services_2016_2020",
    id_required: false,
    car_reg: true
  },
  {
    label: "Opel Vauxhall Finance 2018-Present",
    value: "93",
    slug: "vauxhall_finance_plc_opel_vauxhall_finance_2018_present",
    id_required: false,
    car_reg: true
  },
  {
    label: "Saab Finance 2010-2017",
    value: "94",
    slug: "vauxhall_finance_plc_saab_finance_2010_2017",
    id_required: false,
    car_reg: true
  },
  {
    label: "Ssangyong Finance 2016-2020",
    value: "95",
    slug: "vauxhall_finance_plc_ssangyong_finance_2016_2020",
    id_required: false,
    car_reg: true
  },
  {
    label: "Toyota Financial Services (UK) Plc",
    value: "96",
    slug: "toyota_financial_services_uk_plc_toyota_financial_services_uk_plc",
    id_required: true,
    car_reg: true
  },
  {
    label: "Lexus Finance / Financial Services",
    value: "97",
    slug: "toyota_financial_services_uk_plc_lexus_finance_financial_services",
    id_required: true,
    car_reg: true
  },
  {
    label: "Redline Finance / Financial Services",
    value: "98",
    slug: "toyota_financial_services_uk_plc_redline_finance_financial_services",
    id_required: true,
    car_reg: true
  },
  {
    label: "Toyota Finance / Financial Services",
    value: "99",
    slug: "toyota_financial_services_uk_plc_toyota_finance_financial_services",
    id_required: true,
    car_reg: true
  },
  {
    label: "Volkswagen Financial Services (UK) Limited",
    value: "100",
    slug: "volkswagen_financial_services_uk_limited_volkswagen_financial_services_uk_limited",
    id_required: true
  },
  {
    label: "Audi Finance / Financial Services",
    value: "101",
    slug: "volkswagen_financial_services_uk_limited_audi_finance_financial_services",
    id_required: true
  },
  {
    label: "Bentley Finance / Financial Services",
    value: "102",
    slug: "volkswagen_financial_services_uk_limited_bentley_finance_financial_services",
    id_required: true
  },
  {
    label: "Porsche Finance / Financial Services",
    value: "103",
    slug: "volkswagen_financial_services_uk_limited_porsche_finance_financial_services",
    id_required: true
  },
  {
    label: "Seat Finance / Financial Services",
    value: "104",
    slug: "volkswagen_financial_services_uk_limited_seat_finance_financial_services",
    id_required: true
  },
  {
    label: "Skoda Finance / Financial Services",
    value: "105",
    slug: "volkswagen_financial_services_uk_limited_skoda_finance_financial_services",
    id_required: true
  },
  {
    label: "Volkswagen Finance / Financial Services",
    value: "106",
    slug: "volkswagen_financial_services_uk_limited_volkswagen_finance_financial_services",
    id_required: true
  },
  {
    label: "Blue Motor Finance Ltd",
    value: "107",
    slug: "blue-motor-finance-ltd",
    id_required: true
  },
];

export const Finance_Prominent_List_Reworded = [
  {
    category: "ALD Automotive",
    sub_categories: [
      {
        label: "ALD Automotive Limited",
        value: "1",
        slug: 'ald_automotive_limited',
        id_required: false
      },
      {
        label: "Lombard Vehicle Solutions",
        value: "2",
        slug: 'ald_automotive_limited_lombard_vehicle_solutions',
        id_required: false
      },
      {
        label: "NatWest Car Finance",
        value: "3",
        slug: 'ald_automotive_limited_natwest_car_finance',
        id_required: false
      },
    ]
  },
  {
    category: "Aldemore Bank PLC",
    sub_categories: [
      {
        label: "Aldemore Bank PLC",
        value: "4",
        slug: 'aldemore_bank_plc_aldemore_bank_plc',
        id_required: false
      },
    ]
  },
  {
    category: "Black Horse Limited",
    sub_categories: [
      {
        label: "Black Horse Limited",
        value: "5",
        slug: "black_horse_limited_black_horse_limited",
        id_required: false
      },
      {
        label: "Black Horse Motor Finance",
        value: "6",
        slug: "black_horse_limited_black_horse_motor_finance",
        id_required: false
      },
      {
        label: "Black Horse Caravan and Motorhome Finance",
        value: "7",
        slug: "black_horse_limited_black_horse_caravan_and_motorhome_finance",
        id_required: false
      },
      {
        label: "Land Rover Financial Services 2016-Present",
        value: "8",
        slug: "black_horse_limited_land_rover_financial_services_2016_present",
        id_required: false
      },
      {
        label: "Jaguar Financial Services 2016-Present",
        value: "9",
        slug: "black_horse_limited_jaguar_financial_services_2016_present",
        id_required: false
      },
      {
        label: "Triumph Motorcycle Finance 2016-Present",
        value: "10",
        slug: "black_horse_limited_triumph_motorcycle_finance_2016_present",
        id_required: false
      },
      {
        label: "Subaru Finance 2016-Present",
        value: "11",
        slug: "black_horse_limited_subaru_finance_2016_present",
        id_required: false
      },
      {
        label: "Harley Davidson Finance 2016-Present",
        value: "12",
        slug: "black_horse_limited_harley_davidson_finance_2016_present",
        id_required: false
      },
      {
        label: "Yamaha Finance 2016-2019",
        value: "13",
        slug: "black_horse_limited_yamaha_finance_2016_2019",
        id_required: false
      },
      {
        label: "Ducati Finance 2016-Present",
        value: "14",
        slug: "black_horse_limited_ducati_finance_2016_present",
        id_required: false
      },
      {
        label: "Kawasaki Finance 2016-Present",
        value: "15",
        slug: "black_horse_limited_kawasaki_finance_2016_present",
        id_required: false
      },
      {
        label: "Lotus Finance 2016-Present",
        value: "16",
        slug: "black_horse_limited_lotus_finance_2016_present",
        id_required: false
      },
      {
        label: "Suzuki Finance 2016-Present",
        value: "17",
        slug: "black_horse_limited_suzuki_finance_2016_present",
        id_required: false
      },
    ]
  },
  {
    category: "BMW Financial Services",
    sub_categories: [
      {
        label: "BMW Financial Services (GB) Limited",
        value: "18",
        slug: "bmw_financial_services_gb_limited_bmw_financial_services_gb_limited",
        id_required: true
      },
      // {
      //   label: "BMW Financial Services",
      //   value: "19",
      //   slug: "bmw_financial_services_gb_limited_bmw_financial_services",
      //   id_required: true
      // },
      {
        label: "MINI Finance 2005-Present",
        value: "20",
        slug: "bmw_financial_services_gb_limited_mini_finance_2005_present",
        id_required: true
      },
      {
        label: "Alphera Financial Services",
        value: "21",
        slug: "bmw_financial_services_gb_Limited_alphera_financial_services",
        id_required: true
      },
      {
        label: "Aston Martin Financial Services 2016-Present",
        value: "22",
        slug: "bmw_financial_services_gb_limited_aston_martin_financial_services_2016_present",
        id_required: true
      },
      {
        label: "Rolls Royce Financial Services",
        value: "23",
        slug: "bmw_financial_services_gb_limited_rolls_royce_financial_services",
        id_required: true
      },
    ]
  },
  {
    category: "Borderway Finance",
    sub_categories: [
      {
        label: "Borderway Finance Limited",
        value: "24",
        slug: "borderway_finance_limited_borderway_finance_limited",
        id_required: null
      },
    ]
  },
  {
    category: "C.E.M. Day",
    sub_categories: [
      {
        label: "C.E.M. Day Ltd",
        value: "25",
        slug: "cem_day_ltd_cem_day_ltd",
        id_required: true
      },
      {
        label: "Days Motor Group",
        value: "26",
        slug: "cem_day_ltd_days_motor_group",
        id_required: true
      },
    ]
  },
  {
    category: "CA Auto Finance",
    sub_categories: [
      {
        label: "CA Auto Finance UK Ltd",
        value: "27",
        slug: "ca_auto_finance_uk_ltd_ca_auto_finance_uk_ltd",
        id_required: false
      },
      {
        label: "FCA Automotive Services UK Ltd",
        value: "28",
        slug: "ca_auto_finance_uk_ltd_fca_automotive_services_uk_ltd",
        id_required: true
      },
      {
        label: "Alfa Romeo Financial Services",
        value: "29",
        slug: "ca_auto_finance_uk_ltd_alfa_romeo_financial_services",
        id_required: false
      },
      {
        label: "Chrysler Financial Services",
        value: "30",
        slug: "ca_auto_finance_uk_ltd_chrysler_financial_services",
        id_required: false
      },
      {
        label: "FGA Capital UK Limited",
        value: "31",
        slug: "ca_auto_finance_uk_ltd_fga_capital_uk_limited",
        id_required: false
      },
      {
        label: "Fiat Financial Services",
        value: "32",
        slug: "ca_auto_finance_uk_ltd_fiat_financial_services",
        id_required: false
      },
      {
        label: "Jaguar Cars Finance",
        value: "33",
        slug: "ca_auto_finance_uk_ltd_jaguar_cars_finance",
        id_required: false
      },
      {
        label: "Jeep Financial Services 2016-Present",
        value: "34",
        slug: "ca_auto_finance_uk_ltd_jeep_financial_services_2016_resent",
        id_required: false
      },
      {
        label: "Landrover Finance",
        value: "35",
        slug: "ca_auto_finance_uk_ltd_landrover_finance",
        id_required: false
      },
      {
        label: "Maserati Financial Services 2016-Present",
        value: "36",
        slug: "ca_auto_finance_uk_ltd_maserati_financial_services_2016_present",
        id_required: false
      },
      {
        label: "Erwin Hymer Group Finance 2016-Present",
        value: "37",
        slug: "ca_auto_finance_uk_ltd_erwin_hymer_group_finance_2016_present",
        id_required: false
      },
    ]
  },
  {
    category: "Close Motor Finance",
    sub_categories: [
      {
        label: "Close Motor Finance Limited",
        value: "38",
        slug: "close_motor_finance_limited_close_motor_finance_limited",
        id_required: true
      },
      {
        label: "Close Brothers Motor Finance",
        value: "39",
        slug: "close_motor_finance_limited_close_brothers_motor_finance",
        id_required: true
      },
    ]
  },
  {
    category: "Barclays Partner Finance",
    sub_categories: [
      {
        label: "Clydesdale Financial Services Limited",
        value: "40",
        slug: "barclays_partner_finance_limited_clydesdale_financial_services_limited",
        id_required: true
      },
      {
        label: "Barclays Partner Finance",
        value: "41",
        slug: "barclays_partner_finance_limited_barclays_partner_finance",
        id_required: true
      },
      {
        label: "Barclaycard Motor loans",
        value: "42",
        slug: "barclays_partner_finance_limited_barclaycard_motor_loans",
        id_required: true
      },
    ]
  },
  {
    category: "FCE Bank",
    sub_categories: [
      {
        label: "FCE Bank Plc",
        value: "43",
        slug: "fce_bank_plc_fce_bank_plc",
        id_required: null
      },
      {
        label: "Ford Credit",
        value: "44",
        slug: "fce_bank_plc_ford_credit",
        id_required: null
      },
      {
        label: "Ford Financial",
        value: "45",
        slug: "fce_bank_plc_ford_financial",
        id_required: null
      },
      {
        label: "Jaguar Financial Services 2005-2015",
        value: "46",
        slug: "fce_bank_plc_jaguar_financial_services_2005_2015",
        id_required: null
      },
      {
        label: "Land Rover Financial Services 2005-2015",
        value: "47",
        slug: "fce_bank_plc_land_rover_financial_services_2005_2015",
        id_required: null
      },
      {
        label: "Mazda Credit 2006-2018",
        value: "48",
        slug: "fce_bank_plc_mazda_credit_2006_2018",
        id_required: null
      },
      {
        label: "Volvo Car Finance 2005-2015",
        value: "49",
        slug: "fce_bank_plc_volvo_car_finance_2005_2015",
        id_required: null
      },
    ]
  },
  {
    category: "Ferrari Financial Services",
    sub_categories: [
      {
        label: "Ferrari Financial Services GmbH",
        value: "50",
        slug: "ferrari_financial_services_gmbh_ferrari_financial_services_gmbh",
        id_required: true
      },
    ]
  },
  {
    category: "Honda Finance",
    sub_categories: [
      {
        label: "Honda Finance Europe Plc",
        value: "51",
        slug: "honda_finance_europe_plc_honda_finance_europe_plc",
        id_required: true
      },
      {
        label: "Honda Financial Services",
        value: "52",
        slug: "honda_finance_europe_plc_honda_financial_services",
        id_required: true
      },
    ]
  },
  {
    category: "Hyundai Capital",
    sub_categories: [
      {
        label: "Hyundai Capital (UK) Limited",
        value: "53",
        slug: "hyundai_capital_uk_limited_hyundai_capital_uk_limited",
        id_required: false
      },
      // {
      //   label: "Hyundai Capital UK Limited",
      //   value: "54",
      //   slug: "hyundai_capital_uk_limited__hyundai_capital_uk_limited",
      //   id_required: false
      // },
      {
        label: "Hyundai Finance",
        value: "55",
        slug: "hyundai_capital_uk_limited_hyundai_finance",
        id_required: false
      },
      {
        label: "Kia Finance 2015-Present",
        value: "56",
        slug: "hyundai_capital_uk_limited_kia_finance_2015_present",
        id_required: false
      },
    ]
  },
  {
    category: "JBR Capital",
    sub_categories: [
      {
        label: "JBR Capital Limited",
        value: "57",
        slug: "jbr_capital_limited_jbr_capital_limited",
        id_required: true
      },
    ]
  },
  {
    category: "Mercedes-Benz Financial Services",
    sub_categories: [
      {
        label: "Mercedes-Benz Financial Services UK Limited",
        value: "58",
        slug: "mercedes_benz_financial_services_uk_limited_mercedes_benz_financial_services_uk_limited",
        id_required: true
      },
    ]
  },
  {
    category: "MI Vehicle Finance",
    sub_categories: [
      {
        label: "MI Vehicle Finance Limited",
        value: "59",
        slug: "mi_vehicle_finance_limited_mi_vehicle_finance_limited",
        id_required: true
      },
      {
        label: "Mann Island",
        value: "60",
        slug: "mi_vehicle_finance_limited_mann_island",
        id_required: true
      },
    ]
  },
  {
    category: "MotoNovo",
    sub_categories: [
      {
        label: "MotoNovo Finance",
        value: "61",
        slug: "motonovo_finance_motonovo_finance",
        id_required: false
      },
      {
        label: "FirstRand Bank",
        value: "62",
        slug: "motonovo_finance_firstrand_bank",
        id_required: false
      },
    ]
  },
  {
    category: "RCI Financial Service",
    sub_categories: [
      {
        label: "RCI Financial Service Limited",
        value: "63",
        slug: "rci_financial_service_limited__rci_financial_service_limited",
        id_required: true
      },
      {
        label: "Nissan Finance / Financial Services",
        value: "64",
        slug: "rci_financial_service_limited_nissan_finance_financial_services",
        id_required: true
      },
      {
        label: "Renault Finance / Financial Services / RFS",
        value: "65",
        slug: "rci_financial_service_limited_renault_finance_financial_services_rfs",
        id_required: true
      },
      {
        label: "Alpine Finance",
        value: "66",
        slug: "rci_financial_service_limited_alpine_finance",
        id_required: true
      },
      {
        label: "Dacia Finance",
        value: "67",
        slug: "rci_financial_service_limited_dacia_finance",
        id_required: true
      },
    ]
  },
  {
    category: "Santander",
    sub_categories: [
      {
        label: "Santander Consumer (UK) Plc",
        value: "68",
        slug: "santander_consumer_uk_plc_santander_consumer_uk_plc",
        id_required: false
      },
      {
        label: "GE Capital Motor Finance 2015-2019",
        value: "69",
        slug: "santander_consumer_uk_plc_ge_capital_motor_finance_2015_2019",
        id_required: false
      },
      {
        label: "GE Money Car Loans 2015-2019",
        value: "70",
        slug: "santander_consumer_uk_plc_ge_money_car_loans_2015_20199",
        id_required: false
      },
      {
        label: "Kia Financial Services 2015-Present",
        value: "71",
        slug: "santander_consumer_uk_plc_kia_financial_services_2015_present",
        id_required: false
      },
      {
        label: "Lookers Finance 2015-2019",
        value: "72",
        slug: "santander_consumer_uk_plc_lookers_finance_2015_2019",
        id_required: false
      },
      {
        label: "Mazda Financial Services 2018-Present",
        value: "73",
        slug: "santander_consumer_uk_plc_mazda_financial_services_2018_present",
        id_required: false
      },
      {
        label: "Volvo Car Financial Services 2015-Present",
        value: "74",
        slug: "santander_consumer_uk_plc_volvo_car_financial_services_2015_present",
        id_required: false
      },
      {
        label: "Woodchester Finance 2015-2019",
        value: "75",
        slug: "santander_consumer_uk_plc_woodchester_finance_2015_2019",
        id_required: false
      },
    ]
  },
  {
    category: "Secure Trust Bank",
    sub_categories: [
      {
        label: "Secure Trust Bank PLC",
        value: "76",
        slug: "secure_trust_bank_plc_secure_trust_bank_plc",
        id_required: null
      },
      {
        label: "Moneyway",
        value: "77",
        slug: "secure_trust_bank_plc_moneyway",
        id_required: false
      },
      {
        label: "V12 Vehicle Finance",
        value: "78",
        slug: "secure_trust_bank_plc_v12_vehicle_finance",
        id_required: null
      },
    ]
  },
  {
    category: "Shogun Finance",
    sub_categories: [
      {
        label: "Shogun Finance Limited",
        value: "79",
        slug: "shogun_finance_limited_shogun_finance_limited",
        id_required: false
      },
      {
        label: "Finance Mitsubishi",
        value: "80",
        slug: "shogun_finance_limited_finance_mitsubishi",
        id_required: false
      },
      {
        label: "Shogun Finance / Financial Services",
        value: "81",
        slug: "shogun_finance_limited_shogun_finance_financial_services",
        id_required: false
      },
    ]
  },
  {
    category: "Stellantis Financial Services",
    sub_categories: [
      {
        label: "Stellantis Financial Services UK Limited",
        value: "82",
        slug: "stellantis_financial_services_uk_limited_stellantis_financial_services_uk_limited",
        id_required: null
      },
      {
        label: "Citroen Finance",
        value: "83",
        slug: "stellantis_financial_services_uk_limited_citroen_finance",
        id_required: null
      },
      {
        label: "Peugeot Finance",
        value: "84",
        slug: "stellantis_financial_services_uk_limited_peugeot_finance",
        id_required: null
      },
      {
        label: "PSA Finance",
        value: "85",
        slug: "stellantis_financial_services_uk_limited_psa_finance",
        id_required: false
      },
      {
        label: "PSA Financial Services Limited",
        value: "86",
        slug: "stellantis_financial_services_uk_limited_psa_financial_services_limited",
        id_required: null
      },
    ]
  },
  {
    category: "Vauxhall",
    sub_categories: [
      {
        label: "Vauxhall Finance Plc",
        value: "87",
        slug: "vauxhall_finance_plc_vauxhall_finance_plc",
        id_required: false,
        car_reg: true
      },
      {
        label: "Ally Finance / Financial Services 2010-2016",
        value: "88",
        slug: "vauxhall_finance_plc_ally_finance_financial_services_2010_2016",
        id_required: false,
        car_reg: true
      },
      {
        label: "Chevrolet Finance",
        value: "89",
        slug: "vauxhall_finance_plc_chevrolet_finance",
        id_required: false,
        car_reg: true
      },
      {
        label: "General Motors Financial Services 2016-2019",
        value: "90",
        slug: "vauxhall_finance_plc_general_motors_financial_services_2016_2019",
        id_required: false,
        car_reg: true
      },
      {
        label: "GMAC 2005-2019",
        value: "91",
        slug: "vauxhall_finance_plc_gmac_2005_2019",
        id_required: false,
        car_reg: true
      },
      {
        label: "MG Financial Services 2016-2020",
        value: "92",
        slug: "vauxhall_finance_plc_mg_financial_services_2016_2020",
        id_required: false,
        car_reg: true
      },
      {
        label: "Opel Vauxhall Finance 2018-Present",
        value: "93",
        slug: "vauxhall_finance_plc_opel_vauxhall_finance_2018_present",
        id_required: false,
        car_reg: true
      },
      {
        label: "Saab Finance 2010-2017",
        value: "94",
        slug: "vauxhall_finance_plc_saab_finance_2010_2017",
        id_required: false,
        car_reg: true
      },
      {
        label: "Ssangyong Finance 2016-2020",
        value: "95",
        slug: "vauxhall_finance_plc_ssangyong_finance_2016_2020",
        id_required: false,
        car_reg: true
      },
    ]
  },
  {
    category: "Toyota Financial Services",
    sub_categories: [
      {
        label: "Toyota Financial Services (UK) Plc",
        value: "96",
        slug: "toyota_financial_services_uk_plc_toyota_financial_services_uk_plc",
        id_required: true,
        car_reg: true
      },
      {
        label: "Lexus Finance / Financial Services",
        value: "97",
        slug: "toyota_financial_services_uk_plc_lexus_finance_financial_services",
        id_required: true,
        car_reg: true
      },
      {
        label: "Redline Finance / Financial Services",
        value: "98",
        slug: "toyota_financial_services_uk_plc_redline_finance_financial_services",
        id_required: true,
        car_reg: true
      },
      {
        label: "Toyota Finance / Financial Services",
        value: "99",
        slug: "toyota_financial_services_uk_plc_toyota_finance_financial_services",
        id_required: true,
        car_reg: true
      },
    ]
  },
  {
    category: "Volkswagen Financial Services",
    sub_categories: [
      {
        label: "Volkswagen Financial Services (UK) Limited",
        value: "100",
        slug: "volkswagen_financial_services_uk_limited_volkswagen_financial_services_uk_limited",
        id_required: true
      },
      {
        label: "Audi Finance / Financial Services",
        value: "101",
        slug: "volkswagen_financial_services_uk_limited_audi_finance_financial_services",
        id_required: true
      },
      {
        label: "Bentley Finance / Financial Services",
        value: "102",
        slug: "volkswagen_financial_services_uk_limited_bentley_finance_financial_services",
        id_required: true
      },
      {
        label: "Porsche Finance / Financial Services",
        value: "103",
        slug: "volkswagen_financial_services_uk_limited_porsche_finance_financial_services",
        id_required: true
      },
      {
        label: "Seat Finance / Financial Services",
        value: "104",
        slug: "volkswagen_financial_services_uk_limited_seat_finance_financial_services",
        id_required: true
      },
      {
        label: "Skoda Finance / Financial Services",
        value: "105",
        slug: "volkswagen_financial_services_uk_limited_skoda_finance_financial_services",
        id_required: true
      },
      {
        label: "Volkswagen Finance / Financial Services",
        value: "106",
        slug: "volkswagen_financial_services_uk_limited_volkswagen_finance_financial_services",
        id_required: true
      },
    ]
  },
  {
    category: "Blue Motor Finance Ltd",
    sub_categories: [
      {
        label: "Blue Motor Finance Ltd",
        value: "107",
        slug: "blue-motor-finance-ltd",
        id_required: true
      },
    ]
  },
];