import React from 'react';
import nextgreen from "../../../../assets/PCP_HDSC_V1/img/next-green.png";
import RadioButtonV1 from "../../../UI/RadioButtonV1";
import Button from '../../../UI/Button';

const WhyNotClaimed = (props) => {
  return (
    <div className={`slide_2_3Dec ${props.className}`}>
      <h2 className="animate__animated animate__fadeInUp animate__delay-.5s animated-element pt-5 pt-md-3">
        Why do you wish to seek profession help from us to make this claim?
      </h2>
      <div className="px-3 py-3 widget_mobi borderx">
        <div className="mb-2 row no100 choose_Y_N low_line_height animate__animated animate__fadeInUp animate__delay-.5s">
          <div className="form-check px-0 col-lg-12 col-12 w_full">
            <RadioButtonV1
              className="form-check-input"
              name="whyNotClaimed"
              id="8"
              value="8"
              defaultChecked={props.formData.whyNotClaimed == "8"}
              onClick={props.handleChangeButton}
              labelClassName="text-start"
              labelName="I do not have the time"
            />
          </div>
          <div className="form-check px-0 col-lg-12 col-12 w_full">
            <RadioButtonV1
              className="form-check-input"
              name="whyNotClaimed"
              id="10"
              value="10"
              defaultChecked={props.formData.whyNotClaimed == "10"}
              onClick={props.handleChangeButton}
              labelClassName="text-start"
              labelName="I would prefer professional help"
            />
          </div>
          <div className="form-check px-0 col-lg-12 col-12 w_full">
            <RadioButtonV1
              className="form-check-input"
              name="whyNotClaimed"
              id="9"
              value="9"
              defaultChecked={props.formData.whyNotClaimed == "9"}
              onClick={props.handleChangeButton}
              // labelName="I do not know how to make a claim"
              labelClassName="text-start"
              labelName="I do not want to deal with the lender directly"
            />
          </div>
          {/* <div className="form-check px-0 col-lg-12 col-12 w_full">
            <RadioButtonV1
              className="form-check-input"
              name="whyNotClaimed"
              id="11"
              value="11"
              defaultChecked={props.formData.whyNotClaimed == "11"}
              onClick={props.handleChangeButton}
              labelName="Other"
            />
          </div> */}
        </div>
        <div className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s animated-element" id="subRegNo4">
          <div className="row align-items-center">
            <div className="col-12 text-center ">
            <Button
              type="button"
              data-type="back"
              id="backStep-3"
              name="back05"
              className="msf-nav-button backbutton"
              onClick={() =>props.previousSlideChange('back05')}
              buttonText={
                <span className="btntext"><img src={nextgreen} alt="" />
                  Back <i className="arrowicon"></i>
                </span>
              }

            />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyNotClaimed;
