import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButton";
import Button from "../../../UI/Button";
const BasicQuestionnaireOne = (props) => {
  const question_id = props.gtm_question.questionId;
  const [checkedQuestionOne, setCheckedQuestionOne] = useState([]);
  const [eventVal,setEventVal] = useState('')
  const nextSlide = async (e,index) => {
    setCheckedQuestionOne({[index]:true})
    setEventVal(e.target.value);
    if(e.target.value){
      props.setErrorMessage("")
    }
    if(e.target.value == 2 || e.target.value == 4 || e.target.value == 6 ){
      props.setModalContent(true);
      return;
    }
  };

  const Radio = props.gtm_question.answers.map((value, index) => {
    return (
      <div className={`option-btn mb-3 optionBtn ${(checkedQuestionOne[index] == true) ? 'current' : ''}`}>
        <span class="check"></span>
        <RadioButton
            id={value.id}
            key={index}
            value={value.value}
            name={props.buttonName}
            className="btn-opacity"
            labelName={value.label}
            labelClassName={`radio label-class`}
            onClick={(e) => { nextSlide(e, index) }}
            validation={props.validation()}
            gtm_question={props.gtm_question.gtmQuestion}
            gtm_trigger={props.gtm_question.gtm_trigger}
            gtm_answer={value.gtmAnswer}
            clickevent={props.gtm_question.clickevent}
          />

      </div>
    )
  });

  return (
    <>
      
      <div className={`${props.className}`}>
        <h4 className="mt-5 mb-4 animate__animated animate__fadeInUp title ">
          {" "}
          {props.gtm_question.question}
        </h4>
        <div className="wraper-option mb-4 mt-4 animate__animated animate__fadeInUp">
        {Radio}
        </div>
        {props.errorMessage && (
          <div
            className="validation-error mt-3 text-center"
            style={{
              background: "red",
              borderRadius: "4px",
              width: "100%",
              marginLeft: "0px",
              marginTop: "5px",
              padding: "5px",
              color: "white",
            }}
          >{props.errorMessage}</div>
        )}
        <div className="mt-lg-2  mt-md-5 mt-sm-3 mt-4 pt-2 animate__animated animate__fadeInUp">
          <div className="row">
            <div className="col-4 pe-0">
              {props.backBtn && <Button
                id="back01"
                className=" btn-comm2 backBtn1  p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-3"
                name={`back${question_id}`}
                type ="button"
                buttonText={
                  <>
                    <span className=" " style={{ pointerEvents: 'none' }}>
                      <i className="bi bi-chevron-left" /> Back
                    </span>
                  </>
                }
                onClick={(e)=>{props.previousSlide(e)}}
             />}
            </div>
            <div className="col-8">
              <Button
                id="qsNext1"
                className=" btn-comm2 p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-3"
                type ="button"
                name ={props.buttonName}
                value ={eventVal}
                question_id={question_id}
                buttonText={
                  <span className="fw-bold" style={{ pointerEvents: 'none' }}>
                  Next{" "}
                  <i className="arrowicon">
                    <i className="bi bi-chevron-right" />
                  </i>
                </span>
                }
                onClick ={(e)=>{props.handleClick(e)}}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BasicQuestionnaireOne;
