import React, {useState, useEffect} from "react";
import { Salutation } from "../../../../Constants/Constants";
import TextField from "../../../UI/TextField";
import SelectBox from '../../../UI/SelectBox';

const PersonalDetails = (props) => {
  const [firstName, setFirstName] = useState();

  useEffect(()=>{
    setFirstName(props.formData.txtFName);
  },[props.formData])
  return (
    <>
      <div>
        <h2 className="mb-4 title title2 fw-bold mt-4 animate__animated animate__fadeInUp animate__delay-1s">
          Enter Personal Details
        </h2>
        <div className="row">
          <div className="col-lg-2 mb-3 animate__animated animate__fadeInUp animate__delay-1s">
            <SelectBox
              className="form-select select1"
              OptionValue={Salutation}
              name="lstSalutation"
              id="lstSalutation"
              onChange={props.handleChange}
              myRef={props.validation({
                required: "Select Title",
                message: "Please Select Title",
              })}
              validationMsg={props.validationMsg.lstSalutation?.message}
             />
          </div>
          <div className="col-lg-5 ps-lg-0 animate__animated animate__fadeInUp animate__delay-1s">
            <div className="mb-3">
              <TextField
                type="text"
                className="form-control"
                placeholder="First Name"
                name="txtFName"
                id="txtFName"
                // defaultValue={props.formData.txtFName}
                // value={props.formData.txtFName}
                autocomplete="off"
                onChange={props.handleChange}
                validation={props.validation({
                  required: "Please Enter First Name",
                  minLength: {
                    value: 3,
                    message: "Please Enter Valid First Name",
                  },
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: "Your Name is Invalid. Please Recheck",
                  },
                })}
                validationMsg={props.validationMsg.txtFName?.message}
              />
            </div>
          </div>
          <div className="col-lg-5 ps-lg-0 animate__animated animate__fadeInUp animate__delay-1s">
            <div className="mb-3">
              <TextField
                type="text"
                className="form-control"
                placeholder="Last Name"
                name="txtLName"
                id="txtLName"
                // value={props.formData.txtLName}
                // defaultValue={props.formData.txtLName}
                autocomplete="off"
                onChange={props.handleChange}
                validation={props.validation({
                  required: "Please Enter Last Name",
                  minLength: {
                    value: 3,
                    message: "Please Enter Valid Last Name",
                  },
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: "Your Name is Invalid. Please Recheck",
                  },
                })}
                validationMsg={props.validationMsg.txtLName?.message}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalDetails;
