import React, { useReducer, useState, useContext } from "react";
import AddressTextField from "../../../UI/AddressTextField";
import AddressSelectBox from "../../../UI/AddressSelectBox";
import HiddenField from "../../../UI/HiddenField";
import { usePostCodeAddress } from "../../../../Hooks/usePostCodeAddress";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import edit from "../../../../assets/PCP_HDSC_V5/img/edit.png"
import return_png from "../../../../assets/PCP_HDSC_V5/img/return.png"
import Button from "../../../UI/Button";

const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "hide",
  getPostcode: "#",
  clickManualLink: 0,
};
const postcodeReducer = (state, action) => {
  switch (action.type) {
    case "validationSucess": {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "getExtraAddressDetails": {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "showManualDiv": {
      return {
        ...state,
        showManualAddress: "show",
        lookUp: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click,
      };
    }
    case "resetaddress": {
      return {
        ...state,
        getDetails: [],
        getAddress: [],
        showManualAddress: "hide",
        lookUp: true,
        nextClick: false,
        checkSelect: false,
      };
    }
    case "updateField": {
      return {
        ...state,
        getDetails: {
          ...state.getDetails,
          [action.payload.field]: action.payload.value
        }
      };
    }
    default:
      return state;
  }
};
const PostCode_PCP_HDSC_V5 = ({
  validation,
  validationMsg,
  clearErrors,
  setError,
  slideChange,
  trigger,
  postCodeError,
  setPostCodeError,
  className,
  backClick,
  splitForm,
  getValues,
  handleChange,
  setValue
}) => {
  const { getPostcodeValidation, getPostcodeAddress, getSplitPostcodeAddress } =
    usePostCodeAddress();
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const [postCodeAddress, setPostCodeAddress] = useState([]);
  const [postCodeEntry, setPostCodeEntry] = useState("0");
  const [manualEdit, setManualEdit] = useState(false);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const checkValidation = async (e) => {
    e.preventDefault();
    dispatch({
      type: "resetaddress",
      get_address: [],
    });
    const txtPostCode = await trigger("txtPostCode");
    if (txtPostCode) {
      await getPostcodeAddress(
        e.target.value,
        visitorParameters.visitor_parameters.uuid
      )
        .then((res) => {
          if (res.data.status === "0") {
            setError("txtPostCode", {
              type: "manual",
              message: "Please Enter Valid Postcode",
            });

            //setPostCodeError( "Please Enter Valid Postcode");
          }
          if (res.data.length > 0) {
            dispatch({
              type: "validationSucess",
              payload: {
                lookup: false,
                next_click: true,
                postcode: e.target.value,
                get_address: res.data,
                check_select: true,
                manual_address: "hide",
              },
            });
          }
        })
        .catch(function () {
          setError("txtPostCode", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
          // setPostCodeError("Please Enter Valid Postcode");
        });
    }
  };
  const getValue = async (e) => {
    //handleChange(e);
    setManualEdit(false)
    e.preventDefault();
    // document.getElementById("address1").classList.remove("anim_ylw");
    // document.getElementById("NextButton").classList.add("anim_ylw");
    clearErrors([
      "txtHouseNumber",
      "address1",
      "txtAddress2",
      "txtAddress3",
      "txtCounty",
      "txtTown",
      "txtCountry"
    ]);
    //setPostCodeError("")
    if (e.target.value != "") {
      const getData = await getSplitPostcodeAddress(
        e.target.value,
        state.getPostcode,
        visitorParameters.visitor_parameters.uuid
      );
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: getData.data, manual_address: "show" },
      });
      setPostCodeAddress(getData.data);
      clearErrors([
        "txtHouseNumber",
        "txtAddress2",
        "txtCounty",
        "txtTown",
        "address1",
        "txtCountry",
      ]);
      setTimeout(() => {
        document.getElementById("nextPostCode").focus();
      }, 1000);
    } else {
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: [], manual_address: "hide" },
      });
      setPostCodeAddress([]);
    }
  };

  const lookupValidation = async (e) => {
    await trigger("txtPostCode");
    // handleChange(e);
  };
  const handleChangeInsideOnChange = (e) => {
    clearErrors([
      e.target.name
    ]);
  };

  const checkPostCodeEntry = () => {
    const addressLine1 = document.getElementById("txtHouseNumber").value;
    const addressLine2 = document.getElementById("txtAddress2").value;
    // const addressLine3_1 = document.getElementById("txtAddress3").value;
    const town = document.getElementById("txtTown").value;
    const county = document.getElementById("txtCounty").value;
    const country = document.getElementById("txtCountry").value;

    if (
      postCodeAddress.length === 0 ||
      postCodeAddress.Country !== country ||
      postCodeAddress.County !== county ||
      postCodeAddress.Line1 !== addressLine1 ||
      postCodeAddress.Line2 !== addressLine2 ||
      postCodeAddress.Town !== town
    ) {
      setPostCodeEntry("1");
    }
  };

  const addressValidation = async (e) => {
    let errorFlag = 0;
    const txtPostCode = await trigger("txtPostCode");
    var manual_txtHouseNumber;
    var manual_txtTown;
    var manual_txtCountry;
    var txtAddress;
    if (txtPostCode) {
      manual_txtHouseNumber = await trigger("txtHouseNumber");
      txtAddress = await trigger("address1");
    } else {
      document.getElementById("txtPostCode").focus();
      return false;
    }
    if (manual_txtHouseNumber) {
      manual_txtTown = await trigger("txtTown");
    } else {
      return false;
    }
    if (manual_txtTown) {
      manual_txtCountry = await trigger("txtCountry");
    } else {
      return false;
    }
    if (
      !txtPostCode ||
      !manual_txtHouseNumber ||
      !manual_txtTown ||
      !manual_txtCountry
    ) {
      errorFlag = 1;
    }

    if (state.clickManualLink === 0) {
      const address1 = await trigger("address1");
      if (!address1) {
        errorFlag = 1;
      }
    }
    if (errorFlag === 0) {
      checkPostCodeEntry();
      slideChange(e);
    } else {
      return false;
    }
  };
  const HiddenFields = () => (
    <>
      <HiddenField
        name="txtUdprn"
        inputId="txtUdprn"
        inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
      />
      <HiddenField
        name="txtDeliveryPointSuffix"
        inputId="txtDeliveryPointSuffix"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""
        }
      />
      <HiddenField
        name="txtPz_mailsort"
        inputId="txtPz_mailsort"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""
        }
      />
      <HiddenField
        name="txtStreet"
        inputId="txtStreet"
        inputValue={
          state.getDetails.line_3 !== "#" ? state.getDetails.line_3 : ""
        }
      />
    </>
  );

  const handlePostcodeChange = (e) => {
    const { name, value } = e.target;

    let fieldname = ""
    switch (name) {
      case "txtHouseNumber":
        fieldname = "line_1"
        break;
      case "txtAddress2":
        fieldname = "line_2"
        break;
      case "txtAddress3":
        fieldname = "line_3"
        break;
      case "txtCounty":
        fieldname = "county"
        break;
      case "txtTown":
        fieldname = "town"
        break;
      case "txtCountry":
        fieldname = "country"
        break;
      default:
        break;
    }
    dispatch({ type: "updateField", payload: { field: fieldname, value: value } });
  };

  return (
    <div className="d-grid gap-1">
      <HiddenField
        name="pCode_EntryType"
        inputId="pCode_EntryType"
        inputValue={postCodeEntry}
      />
      <div className="postcode_wrap">
        <AddressTextField
          type="text"
          placeholder="Your Postcode"
          name="txtPostCode"
          id="txtPostCode"
          className="form-control inputtttt"
          dataId="txtPostCode"
          onChange={handleChangeInsideOnChange}
          onBlur={checkValidation}
          autoComplete="txtPostCode"
          validation={validation({
            required: "Please Enter Postcode",
            pattern: {
              value:
                /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
              message: "Please Enter a Valid Postcode",
            },
            minLength: {
              value: 5,
              message: "Please Enter a Valid Postcode",
            },
          })}
        />
        {/* <button
          // style={{ float: "none" }}
          name="next"
          className="bg_add_click button"
          onClick={lookupValidation}
        >
          Find My Address
        </button> */}
        <Button
          type="button"
          className="bg_add_click button"
          buttonText={
            <span>Find My Address</span>
          }
          id="lookUp"
          onClick={lookupValidation}
        />
      </div>
      {validationMsg.txtPostCode && (
        <p className="error_msg animate__animated animate__fadeInUp animate__delay-.5s validate">
          {validationMsg.txtPostCode.message}
        </p>
      )}
      {/* <div
        className={`form-group col-lg-12 col-12 p-0 adj_width animate__animated animate__fadeInUp animate__delay-.5s animated-element`}
        id="currentAddressCollapse"

      > */}
      <div
        className="col-12 pt-3 animate__animated animate__fadeInLeft animate__delay-.5s"
        id="selcetAdd"
        style={state.checkSelect ? { display: "block" } : { display: "none" }}
      >
        <AddressSelectBox
          className="form-select pe-5"
          OptionValue={state.getAddress}
          name="address1"
          id="address1"
          onChange={getValue}
          myRef={validation({ required: "Please select address" })}
        validationMsg={
          validationMsg.address1 && validationMsg.address1.message
        }
        />
        {!manualEdit &&
          <div
            className={`ADD_detail py-3 px-3 animate__animated animate__fadeInLeft animate__delay-.5s ${state.getDetails && Object.keys(state.getDetails).length > 0 ? "d-block" : "d-none"}`}
          >
            <p className="ADD_clr_typ">
              {state.getDetails.line_1 ? (<>{state.getDetails.line_1}<br /> </>) : null}
              {state.getDetails.line_2 ? (<>{state.getDetails.line_2}<br /> </>) : null}
              {state.getDetails.line_3 ? (<>{state.getDetails.line_3}<br /> </>) : null}
              {state.getDetails.county ? (<>{state.getDetails.county}<br /></>) : null}
              {state.getDetails.town ? (<>{state.getDetails.town}<br /></>) : null}
              {state.getDetails.country ? (<>{state.getDetails.country}<br /></>) : null}
            </p>
          </div>
        }
      </div>
      <div
        className="col-12 text-center"
        style={
          state.getDetails.length === 0
            ? { display: "block" }
            : { display: "none" }
        }
      >
      </div>
      {/* <div id="selcetAdd" className={`slide8_add row mt-2 gy-2 ${state.showManualAddress}`}> */}
      <div className={`px-0 ${!manualEdit && "d-none"} col-lg-12 form-group required-field adj_width`}>
        <AddressTextField
          type="text"
          placeholder="Address Line 1 *"
          name="txtHouseNumber"
          id="txtHouseNumber"
          className="form-control address hero-input"
          dataId="txtHouseNumber"
          autoComplete="off"
          readonly={manualEdit ? false : true}
          validation={validation({
            required: "Please Enter Address Line 1",
          })}
          validationMsg={
            validationMsg.txtHouseNumber &&
            validationMsg.txtHouseNumber.message
          }
          value={state.getDetails.line_1 || ""}
          onChange={handlePostcodeChange}
        />
        <i
          className="validate validate_success"
          aria-hidden="true"
          style={{ display: "none" }}
        ></i>
      </div>

      <div className={`px-0 ${!manualEdit && "d-none"} col-lg-12 form-group adj_width`}>
        <AddressTextField
          type="text"
          placeholder="Address Line 2"
          name="txtAddress2"
          id="txtAddress2"
          className="form-control  address hero-input"
          dataId="txtAddress2"
          autoComplete="off"
          readonly={manualEdit ? false : true}
          validation={validation()}
          validationMsg=""
          value={state.getDetails.line_2 || ""}
          onChange={handlePostcodeChange}
        />
        <i
          className="validate validate_success"
          aria-hidden="true"
          style={{ display: "none" }}
        ></i>
      </div>
      <div className={`px-0 ${!manualEdit && "d-none"} col-lg-12 form-group adj_width`}>
        <AddressTextField
          type="hidden"
          placeholder="Address Line 3"
          name="txtAddress3"
          id="txtAddress3"
          className="form-control  address hero-input"
          dataId="txtAddress3"
          autoComplete="off"
          readonly={manualEdit ? false : true}
          validation={validation()}
          validationMsg=""
          value={state.getDetails.line_3 || ""}
          onChange={handlePostcodeChange}
        />
        <i
          className="validate validate_success"
          aria-hidden="true"
          style={{ display: "none" }}
        ></i>
      </div>

      <div className={`px-0 ${!manualEdit && "d-none"} col-lg-12 form-group adj_width`}>
        <AddressTextField
          type="text"
          placeholder="County"
          name="txtCounty"
          id="txtCounty"
          className="form-control  address hero-input"
          dataId="txtCounty"
          autoComplete="off"
          readonly={manualEdit ? false : true}
          validation={validation()}
          validationMsg=""
          value={state.getDetails.county || ""}
          onChange={handlePostcodeChange}
        />
        <i
          className="validate validate_success"
          aria-hidden="true"
          style={{ display: "none" }}
        ></i>
      </div>

      <div className={`px-0 ${!manualEdit && "d-none"} col-lg-12 form-group adj_width`}>
        <AddressTextField
          type="text"
          placeholder="Town *"
          name="txtTown"
          id="txtTown"
          className="form-control  address hero-input"
          dataId="txtTown"
          autoComplete="off"
          readonly={manualEdit ? false : true}
          validation={validation({ required: "Please Enter Town" })}
          validationMsg={
            validationMsg.txtTown && validationMsg.txtTown.message
          }
          value={state.getDetails.town || ""}
          onChange={handlePostcodeChange}
        />
        <i
          className="validate validate_success"
          aria-hidden="true"
          style={{ display: "none" }}
        ></i>
      </div>
      <div className={`px-0 ${!manualEdit && "d-none"} col-lg-12 form-group adj_width}`}>
        <AddressTextField
          type="text"
          placeholder="Country *"
          name="txtCountry"
          id="txtCountry"
          className="form-control address hero-input"
          dataId="txtCountry"
          autoComplete="off"
          readonly={manualEdit ? false : true}
          validation={validation({ required: "Please Enter Country" })}
          validationMsg={
            validationMsg.txtCountry && validationMsg.txtCountry.message
          }
          value={state.getDetails.country || ""}
          onChange={handlePostcodeChange}
        />
        <i
          className="validate validate_success"
          aria-hidden="true"
          style={{ display: "none" }}
        ></i>
      </div>
      {Object.keys(state.getDetails).length > 0 ? <HiddenFields /> : null}
      <div id="selcetAdd" className={`animate__animated animate__fadeInUp animate__delay-.5s animated-element ${state.checkSelect ? "d-block" : "d-none"}`}>
        <div className="return_back ">
          <p className="mb-0 mt-3">
            <span className="cursor_pointer" onClick={() => (
              dispatch({
                type: "resetaddress",
                get_address: [],
              }),
              dispatch({
                type: "getExtraAddressDetails",
                payload: { get_details: [], manual_address: "hide" },
              }),
              setPostCodeAddress([]),
              setValue("txtPostCode", ""),
              document.getElementById("txtPostCode").focus(),
              setManualEdit(false)
            )}>
              <img src={return_png} alt="" />
              <span>Search Again</span>
            </span>{" "}
            <span className={`cursor_pointer ${manualEdit && "d-none"} ${state.showManualAddress}`} onClick={() => setManualEdit(true)}>
              <span>Edit</span>
              <img src={edit} alt="" />
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
export default PostCode_PCP_HDSC_V5;
