import React, { useState } from 'react'
import RadioButtonV2 from '../../../UI/RadioButtonV2'
import banner_img2 from "../../../../assets/PCP_HDSC_V5/img/banner-img2.png"
import RadioButton_span_inside_label_V5 from '../PCP_HDSC_V3/RadioButton_span_inside_label_V5';
import Button from '../../../UI/Button';
import GTMDataLayer from "../../../../Utility/GTMDataLayer";

const SlideThree = (props) => {
    const [selectedOption, setSelectedOption] = useState('');
    const [platTwo, setPlateTwo] = useState(false);
    const [platThree, setPlateThree] = useState(false);
    const [validationError, setValidationError] = useState('');

    const handleNoClick = (e) => {
        // setSelectedOption(e.target.value);
        // props.setPlateOne(false);
        // setPlateTwo(true);
        // if(e.target.name == "yesno"){
        //     setPlateThree(true);
        //     setPlateTwo(false);

        // }
    };

    const handleYesClick = (e) => {
        if (e.target.name == "yesno") {
            setPlateThree(true);
            setPlateTwo(false);
        }
        if (e.target.name == "yesno2") {
            setPlateThree(false);
            setPlateTwo(false);
            props.setDobModal(true);
        }
        if (e.target.name == "seekhelp") {
            setValidationError('');
        }
    };

    const submitClick = () => {
        const selectedValue = document.querySelector('input[name="seekhelp"]:checked');
        if (!selectedValue) {
            setValidationError('Please select an option.');
            return;
        } else {
            setValidationError('');
            GTMDataLayer({
                question: 'reason for claim',
                answer: null,
              });
            props.setContactModal(true);
        }
    }
    return (
        <div>
            <div className={`home_plate_004 ${props.currentPage === "QuestionnaireSlide" ? 'show' : 'd-none'}`}>
                <div className="row justify-content-center ">
                    <div className="py-lg-0 py-md-0 py-sm-4 py-5 cs_hero_text col-lg-6 col-md-6  col-12 order-lg-1 order-md-1 order-2">
                        <div className={`plateTwo_anim slidex1 ${props.plateOne ? 'show' : 'd-none'} `}>
                            <p className="png_t animate__animated animate__fadeInUp  cs_heading_color text-lg-start text-md-start text-sm-center text-center">
                                Did you take out car finance between 2007 &amp; 2020?
                            </p>
                            <div className="choose_Y_N col-lg-12 col-12 animate__animated animate__fadeInUp ">
                                <div className="form-check px-0">
                                    <RadioButtonV2
                                        className="form-check-input"
                                        name="slide4Yes"
                                        id="preno1"
                                        value="Yes"
                                        labelName="Yes"
                                        //checked={selectedOption === 'Yes'}
                                        onClick={handleYesClick}
                                    />
                                </div>
                                <div className="form-check px-0 ">
                                    <RadioButtonV2
                                        className="form-check-input"
                                        name="slide4Yes"
                                        id="preno1"
                                        value="No"
                                        labelName="No"
                                        //checked={selectedOption === 'No'}
                                        onClick={handleNoClick}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className={`plateTwo_anim slidex2 ${platTwo ? 'show' : 'hide'}`}

                        >
                            <p className="png_t animate__animated animate__fadeInUp  cs_heading_color text-lg-start text-md-start text-sm-center text-center">
                                Are you currently in an IVA, bankruptcy, or other debt
                                management arrangement?
                            </p>
                            <div className="choose_Y_N col-lg-12 col-12 animate__animated animate__fadeInUp ">
                                <div className="form-check px-0">

                                    <RadioButtonV2
                                        className="form-check-input button--moma qs2"
                                        name="yesno"
                                        id="ys2"
                                        value="Yes"
                                        labelName="Yes"
                                        //checked={selectedOption === 'Yes'}
                                        onClick={handleYesClick}
                                    />
                                </div>
                                <div className="form-check px-0  ">

                                    <RadioButtonV2
                                        className="form-check-input"
                                        name="yesno"
                                        id="no22"
                                        value="No"
                                        labelName="No"
                                        //checked={selectedOption === 'No'}
                                        onClick={handleYesClick}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*  //////////////////////// */}
                        <div
                            className=
                            {`plateTwo_anim plateTwo_anim ${platThree ? 'show' : 'hide'}`}
                        >
                            <p className="png_t animate__animated animate__fadeInUp cs_heading_color text-lg-start text-md-start text-sm-center text-center">
                                Did the dealer disclose their commission and how it may
                                affect the interest rate on your loan?
                            </p>
                            <div className="choose_Y_N col-lg-12 col-12 animate__animated animate__fadeInUp ">
                                <div className="form-check px-0">
                                    <RadioButtonV2
                                        className="form-check-input button--moma"
                                        name="yesno2"
                                        id="yes02"
                                        value="Yes"
                                        labelName="Yes"
                                        //checked={selectedOption === 'Yes'}
                                        onClick={handleYesClick}
                                    />
                                </div>
                                <div className="form-check px-0  ">

                                    <RadioButtonV2
                                        className="form-check-input"
                                        name="yesno2"
                                        id="yes02"
                                        value="No"
                                        labelName="No"
                                        //checked={selectedOption === 'No'}
                                        onClick={handleYesClick}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*  //////////////////////// */}
                        <div
                            className={`plateTwo_anim slidex4 ${props.plateFour ? 'show' : 'hide'}`}
                        >
                            <p className="png_t animate__animated animate__fadeInUp cs_heading_color text-lg-start text-md-start text-sm-center text-center">
                                Why do you wish to seek profession help from us to make
                                this claim?
                            </p>
                            <div className="pe-lg-5">
                                <div className="lenderpopslide2 mt-4 float-start w-100">
                                    <div className="selectoptions optionfull   animate__animated animate__fadeInUp   ">
                                        <div>
                                            <RadioButton_span_inside_label_V5
                                                name="seekhelp"
                                                id="donttime"
                                                value="14"
                                                labelText="I do not have the time"
                                                onClick={handleYesClick}
                                                spanClassName="option_title title_1"
                                                validation={props.validation()}
                                            />
                                        </div>
                                        <div>

                                            <RadioButton_span_inside_label_V5
                                                name="seekhelp"
                                                id="professional"
                                                value="15"
                                                labelText="I would prefer professional help"
                                                onClick={handleYesClick}
                                                spanClassName="option_title title_1"
                                                validation={props.validation()}
                                            />
                                        </div>
                                        <div>

                                            <RadioButton_span_inside_label_V5
                                                name="seekhelp"
                                                id="lenderdirectly"
                                                value="16"
                                                labelText="I do not want to deal with the lender directly"
                                                onClick={handleYesClick}
                                                spanClassName="option_title title_1"
                                                validation={props.validation()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="box_button  w-100 float-start mb-4 sign_Btn_paGe animate__animated animate__fadeInUp animate__delay-1s animated-element wht_iFNot">
                                    {validationError && (
                                        <div className="error_msg">{validationError}</div>
                                    )}
                                    <div className="mt-4 bg-1 hme_btn text-lg-start text-md-start text-sm-center text-sm-center">

                                        <Button
                                            type="button"
                                            className=" w-100 button button--moema button--text-thick button--text-upper button--size-s blick_notice"
                                            buttonText={
                                                <span>Submit To Claim</span>
                                            }
                                            id="submitClaim"
                                            name="subRegNo1"

                                            onClick={submitClick}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="img_float animate__animated animate__fadeInUp animate__delay-.5s order-lg-2 order-md-2 order-1 col-lg-6 col-md-6 col-12 text-center  ">
                        <img
                            src={banner_img2}
                            alt="Hero"
                            className="  banner-img img-fluid px-lg-4"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SlideThree