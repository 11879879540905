import React, { useEffect } from "react";
import "../../../assets/PCP_HDSC_V1/css/custom.scss";
import hendersons_logo from "../../../assets/PCP_HDSC_V1/img/hendersons_logo.png";
import thankyou from "../../../assets/PCP_HDSC_V1/img/thankyou.gif";
import Footer from "../../Includes/Layouts/PCP_HDSC_V1/Footer";

const Thankyou = () => {
  useEffect(() => {
    localStorage.clear();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      <div className="carregistration">
        <div className="loader-wrap tnk_br text-center text-light autoh">
          <nav className="navbar navbar-expand-lg">
            <a className="navbar-brand text-center col-12">
              <img src={hendersons_logo} alt="Logo" height="35px" />
            </a>
          </nav>

          <div className="container">
            <div className="col-lg-10 offset-lg-1 col-12">
              <div className="row  whathapns mb-3">
                <div className="col-lg-12 col-12 text-center">
                  <img height={150} src={thankyou} alt="" />
                  <h2 className=" mt-3 succtext px-2 ">Thank You for Your Submission!</h2>
                  <p>
                    We appreciate you taking the time to complete your claim application.
                    Your details have been successfully submitted, and we are now
                    processing your request.
                  </p>
                </div>
                <h4 className="text-lg-start text-center">What Happens Next?</h4>
                <div className="col-lg-4 col-md-4 col-12 text-center">
                  <div className="steps_col align-middle">
                    <h5>
                      <span className="steps">1</span>
                      <strong>Verification</strong>
                    </h5>
                    <p>
                      Our team will verify the information you provided, including your ID
                      proof(if provided).
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12 text-center">
                  <div className="steps_col">
                    <h5>
                      <span className="steps">2</span>
                      <strong>Processing</strong>
                    </h5>
                    <p>
                      We will process your claim application and contact you if any
                      additional information is needed.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12 text-center ">
                  <div className="steps_col mb-0">
                    <h5>
                      <span className="steps">3</span>
                      <strong>Updates</strong>
                    </h5>
                    <p>
                      You will receive updates on the status of your claim via email and
                      text messages.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-12 pt-3 text-lg-start text-center mb-3">
                  <h4>Need Assistance?</h4>
                  <p>
                    If you have any questions or need further assistance, please do not
                    hesitate to contact our support team. We are here to help you every
                    step of the way.
                  </p>
                  <p>
                    <strong>Contact Us:</strong>
                    <br />
                    Email:
                    <a href="mailto:contact@trusthendersons.co.uk" />
                    contact@hendersonclaims.com
                  </p>
                  <p>
                   
                  Telephone number :
                  
                  0161 359 7140
                  </p>
                  <p>
                    Thank you for choosing Hendersons. We look forward to serving you!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pcp_siteHome">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Thankyou;
