import React, { useState } from "react";
import nextgreen from "../../../../assets/PCP_HDSC_V1/img/next-green.png";
import { DobDay, DobMonth, DobYear } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
import Button from "../../../UI/Button";

const DateOfBirthSection = (props) => {
  const [showError, setShowError] = useState({
    dobDay: false,
    dobMonth: false,
    dobYear: false,
  });

  const validateFields = async () => {
    const errors = {};
    const fields = ["dobDay", "dobMonth", "dobYear"];
    let firstErrorField = null;

    for (const field of fields) {
      const isValid = await props.trigger(field);
      if (!isValid) {
        errors[field] = {
          type: "manual",
          message: props.errors[field]?.message || "",
        };
        if (!firstErrorField) firstErrorField = field;
      }
    }

    const currentValues = props.getValues();
    const selectedYear = parseInt(currentValues.dobYear, 10);

    if (
      currentValues.dobMonth === "February" &&
      parseInt(currentValues.dobDay) === 29 &&
      !isLeapYear(selectedYear)
    ) {
      errors.dobYear = {
        type: "manual",
        message: "This is not a valid leap year.",
      };
      if (!firstErrorField) firstErrorField = "dobYear";
    }

    const daysInMonth = {
      January: 31,
      February: isLeapYear(selectedYear) ? 29 : 28,
      March: 31,
      April: 30,
      May: 31,
      June: 30,
      July: 31,
      August: 31,
      September: 30,
      October: 31,
      November: 30,
      December: 31,
    };

    if (parseInt(currentValues.dobDay) > daysInMonth[currentValues.dobMonth]) {
      errors.dobDay = { type: "manual", message: "Please enter a valid day." };
      if (!firstErrorField) firstErrorField = "dobDay";
    }

    return { errors, firstErrorField };
  };

  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  const handleInputChange = async (e) => {
    const { name } = e.target;
    const { errors, firstErrorField } = await validateFields();
    if (errors[name]) {
      props.setError(name, errors[name]);
    } else {
      props.clearErrors(name);
    }

    setShowError((prev) => ({
      ...prev,
      [name]: true,
    }));

    if (firstErrorField && name === firstErrorField) {
      setShowError((prev) => ({
        ...prev,
        [firstErrorField]: true,
      }));
    }
  };

  const handleNextClick = async (e) => {
    const { errors, firstErrorField } = await validateFields();
    if (Object.keys(errors).length > 0) {
      Object.entries(errors).forEach(([field, error]) => {
        props.setError(field, error);
      });

      if (firstErrorField) {
        setShowError((prev) => ({
          ...prev,
          [firstErrorField]: true,
        }));
      }

      return;
    }

    const currentValues = props.getValues();
    props.formData.dobDay = currentValues.dobDay;
    props.formData.dobMonth = currentValues.dobMonth;
    props.formData.dobYear = currentValues.dobYear;
    props.setShowLoader(true);

    setTimeout(() => {
      props.setShowLoader(false);
      props.slideChange(e);
    }, 2000);
  };

  return (
    <div className={`slide_5Dec ${props.className}`}>
      <h2 className="animate__animated animate__fadeInUp animate__delay-.5s animated-element pt-5 pt-md-3">
        Please provide your Date of Birth to ensure accuracy in our records.
      </h2>
      <div className="px-3 py-3 widget_mobi borderx">
        <div className="form_drop col-lg-12 col-12 text-start">
          <label htmlFor="">Date of Birth</label>
          <div className="row pt-0 Birth_detail animate__animated animate__fadeInUp animate__delay-.5s animated-element">
            <div className="col-lg-4 col-md-4 col-12 date_here">
              <label htmlFor="DobDay">Day</label>
              <SelectBox
                placeholderOption="Day"
                className={`form-select`}
                OptionValue={DobDay}
                name="dobDay"
                id="dobDay"
                onChange={handleInputChange}
                myRef={props.validation({
                  required: "Please select a day.",
                })}
              />
              {props.errors.dobDay && showError.dobDay && (
                <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                  {props.errors.dobDay.message}
                </p>
              )}
            </div>

            <div className="col-lg-4 col-md-4 col-12 month_here">
              <label htmlFor="DobMonth">Month</label>
              <SelectBox
                className={`form-select`}
                OptionValue={DobMonth}
                name="dobMonth"
                id="dobMonth"
                myRef={props.validation({
                  required: "Please Select Month",
                })}
                onChange={handleInputChange}
              />
              {props.errors.dobMonth && showError.dobMonth && (
                <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate1">
                  {props.errors.dobMonth.message}
                </p>
              )}
            </div>

            <div className="col-lg-4 col-md-4 col-12 year_here">
              <label htmlFor="DobYear">Year</label>
              <SelectBox
                className={`form-select`}
                OptionValue={DobYear}
                name="dobYear"
                id="dobYear"
                myRef={props.validation({
                  required: "Please Select Year",
                })}
                onChange={handleInputChange}
              />
              {props.errors.dobYear && showError.dobYear && (
                <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate1">
                  {props.errors.dobYear.message}
                </p>
              )}
            </div>
          </div>
        </div>

        <div
          className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s animated-element"
          id="subRegNo5"
        >
          <div className="row align-items-center">
            <div className="col-4 text-center">
              <Button
                type="button"
                data-type="back"
                id="backStep-4"
                name="back08"
                className="msf-nav-button backbutton"
                onClick={() => props.previousSlideChange("back08")}
                buttonText={
                  <span className="btntext">
                    <img src={nextgreen} alt="" />
                    Back <i className="arrowicon"></i>
                  </span>
                }
              />
            </div>
            <div className="col-8">
              <div className="bg-1 hme_btn">
                <Button
                  type="button"
                  id="nextslideCl_005"
                  aria-label="submitid"
                  name="nextslideCl_005"
                  className="button w-100 next-btn"
                  buttonText={
                    <span className="fw-bold" style={{ pointerEvents: "none" }}>
                      Next
                    </span>
                  }
                  onClick={handleNextClick}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateOfBirthSection;
