import React, { useContext, useState, useRef, useEffect } from "react";
import "../../../assets/FinanceAgreement/css/custom.scss";
import ssl from "../../../assets/FinanceAgreement/img/ssl.png";
import info from "../../../assets/FinanceAgreement/img/info.png";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import { useIdUpload } from "../../../Hooks/useIdUpload";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { queryString } from "../../../Utility/QueryString";
import Footer from "../../Pages/FinanceAgreement/Footer";
import { CheckUUID } from "../../../Utility/CheckUUID";
// import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";
import { useHistory } from "react-router-dom";
import Header_PCP_HDSC_V1 from "../../Forms/PCP_HDSC_V1/Header_PCP_HDSC_V1";
import FeaturedinAndAccordion from "../../Includes/Layouts/PCP_HDSC_V1/FeaturedinAndAccordion";
import { Api } from "../../../api/Api";

const FinanceAgreement = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isInValidfFile, setIsInValidfFile] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState();
  const [base64Image, setBase64Image] = useState();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { saveIdUpload, isLoading } = useIdUpload();
  const [currentModalCount, setCurrentModalCount] = useState(0);
  const [responseslug, setResponseSlug] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [fileUploadInput, setfileUploadInput] = useState("");
  // const {DynamicRouteSplitName,DynamicRouteNextPage}  = DynamicRoutingSplit('','id-upload');
  const { isCheck } = CheckUUID();
  const formParameters = JSON.parse(localStorage.getItem("formData"));

  const getUuid = () => {
    const visitorData = visitorParameters.visitor_parameters.uuid;
    if (visitorData) {
      var uuid = visitorData;
    } else {
      const query = new URLSearchParams(window.location.search);
      const query_uuid = query.get("uuid");
      const local_storage_uuid = localStorage.getItem("uuid");
      var uuid = query_uuid ? query_uuid : local_storage_uuid;
    }
    return uuid;
  };
  const uuid = getUuid();
  useEffect(() => {
    const fetchData = async () => {
      const responseslug = await Api.get(
        "v1/get_finanace_slug_details?uuid=" + uuid,
        {}
      );
      setResponseSlug(responseslug.data);
      setDataCount(responseslug.data.length);
    };

    fetchData();
  }, [uuid]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoader(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);
  const userfile = {
    preview: undefined,
    title: undefined,
  };
  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setBase64Image({
      base64TextString: binaryString,
    });
  };
  const changeFilesHandler = (event) => {
    setfileUploadInput(event.target.value);
    setSelectedFile(event.target.files[0]);
    let imageFile = event.target.files[0];
    if (imageFile) {
      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;

      let allowedExtensions = ["image/jpeg", "image/png"];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage({
          txt: "The uploaded ID type should be an image in jpeg,png or jpg",
        });
      } else if (imgsize > 1024 * 1024 * 20) {
        setIsInValidfFile(true);
        setErrorMessage({ txt: "Please choose a file less than 20mb" });
      } else {
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
      }
      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if (imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsInValidfFile(true);
      setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");

    let uploadData = "";
    if (isFilePicked) {
      for (let i = 0; i < responseslug.length; i++) {
        let providers = {
          slug: responseslug[i].slug,
        };
        uploadData = {
          imageFile: base64Image.base64TextString,
          captureType: selectedFileType.substring(
            selectedFileType.indexOf("doc")
          ),
          documentType: selectedFileType.substr(
            0,
            selectedFileType.indexOf("_doc")
          ),
        };
        setBtnLoader(true);
        if (i == currentModalCount) {
          const response = await saveIdUpload(
            uploadData,
            visitorParameters.visitor_parameters,
            formParameters,
            visitorParameters.data,
            providers,
            queryString,
            "user_docs_store"
          );
        }
      }
      setSelectedFile(null);
      setIsFilePicked(false);
      setBase64Image(null);
      setSelectedFileType(null);
      setBtnLoader(false);
      setCurrentModalCount(currentModalCount + 1);
      setfileUploadInput("");
      if (currentModalCount + 1 == dataCount) {
        history.push(`/thankyou?uuid=${encodeURIComponent(query_uuid)}`);
      }
      // }
    } else {
      setBtnLoader(false);
      setIsInValidfFile(true);
      setErrorMessage({ txt: "Please upload a file" });
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <GetVisitorsParams />
      <div className="finance_agreement">
        {/* preloader */}
        {showLoader && (
          <div id="preloader">
            <div id="loader" />
          </div>
        )}
        {/* <Header/> */}
        {/* <header>
                        <div class="container">
                            <nav class="navbar navbar-expand-lg text-center justify-content-lg-start  justify-content-center">
                                <a class="navbar-brand animate__animated animate__fadeInUp animate__delay-1s animated-element" href="">
                                    <img src={hendersons_logo} alt="Logo" />
                                </a>

                            </nav>
                        </div>
                    </header> */}
        <Header_PCP_HDSC_V1 />
        <form>
          <section className="main_content sign_content custom_height">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <form
                    className="form_action"
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <div className="widget_mobi">
                      <div className="sign_dis_paGe">
                        <div className="slide_7Dec">
                          <div className="row justify-content-center">
                            <div className="cs_hero_text col-lg-8 col-12">
                              <div className="" id="">
                                <h3 className="f18 carreg animate__animated animate__fadeInUp animate__delay-.1s head-text">
                                  To facilitate the evaluation of your claim
                                  related to your vehicle, we kindly ask you to
                                  upload a copy of your vehicle finance
                                  agreement
                                </h3>

                                <div className="col-lg-12 col-md-12 form_dec animate__animated animate__fadeInUp animate__delay-.1s animated-element">
                                  <div
                                    className="widget_mobi animate__animated animate__fadeInUp animate__delay-.1s animated-element br-30"
                                    id=""
                                  >
                                    <div className="slide_1Dec">
                                      <h2 className="first_fnt animate__animated animate__fadeInUp animate__delay-.2s animated-element">
                                        Upload Document
                                      </h2>
                                      <div
                                        className={`mb-3 financeagree ${
                                          isFilePicked &&
                                          selectedFile &&
                                          base64Image
                                            ? "financeagree-logo"
                                            : ""
                                        }`}
                                      >
                                        <input
                                          type="file"
                                          className="fileinput"
                                          // className="form-control"
                                          id="idTypeFile"
                                          name="FINANCE_AGREEMENT_doc"
                                          placeholder="Upload picture of Your Id"
                                          accept="image/*"
                                          value={fileUploadInput}
                                          onChange={changeFilesHandler}
                                          onClick={(e) =>
                                            (e.target.value = null)
                                          }
                                        />
                                        {isInValidfFile && (
                                          <span
                                            className="error_msg"
                                            id="email_err"
                                          >
                                            <p className="animate__animated animate__fadeInUp animate__delay-.5s validate">
                                              {/* <img
                                                  src={info}
                                                  className="pe-1"
                                                  alt=""
                                                /> */}
                                              {errorMessage.txt}
                                            </p>
                                          </span>
                                        )}
                                      </div>

                                      <div className="box_button animate__animated animate__fadeInUp animate__delay-1s animated-element">
                                        <div className="bg-1 hme_btn pt-3 align-items-center text-center">
                                          {/* <input type="submit" className="button" id="" value="SUBMIT"  onClick={handleSubmit} aria-label="" name=" " /> */}
                                          {/* <InputButton
                                              name=""
                                              className="button"
                                              id=""
                                              value="SUBMIT"
                                              btnType="submit"
                                              onClick={handleSubmit}

                                            /> */}
                                          <span
                                            name=""
                                            className={`${
                                              isFilePicked &&
                                              selectedFile &&
                                              base64Image
                                                ? "button-blink-animation"
                                                : ""
                                            } button text-center`}
                                            id="save"
                                            onClick={
                                              !btnLoader ? handleSubmit : null
                                            }
                                          >
                                            SUBMIT
                                            {btnLoader && (
                                              <svg
                                                className="ms-2"
                                                width={30}
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 200 200"
                                              >
                                                <linearGradient id="a10">
                                                  <stop
                                                    offset="0"
                                                    stopColor="#FFFFFF"
                                                    stopOpacity="0"
                                                  ></stop>
                                                  <stop
                                                    offset="1"
                                                    stopColor="#FFFFFF"
                                                  ></stop>
                                                </linearGradient>
                                                <circle
                                                  fill="none"
                                                  stroke="url(#a10)"
                                                  strokeWidth="25"
                                                  strokeLinecap="round"
                                                  strokeDasharray="0 44 0 44 0 44 0 44 0 360"
                                                  cx="100"
                                                  cy="100"
                                                  r="70"
                                                  transform-origin="center"
                                                >
                                                  <animateTransform
                                                    type="rotate"
                                                    attributeName="transform"
                                                    calcMode="discrete"
                                                    dur="2"
                                                    values="360;324;288;252;216;180;144;108;72;36"
                                                    repeatCount="indefinite"
                                                  ></animateTransform>
                                                </circle>
                                              </svg>
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="img_secure text-center pt-3">
                                        <img src={ssl} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="modal fade"
                          id="Add_preName"
                          data-bs-backdrop="static"
                          data-bs-keyboard="false"
                          tabIndex="-1"
                          aria-labelledby="Add_preNameLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s ">
                              <div className="modal-header p-0">
                                <h1
                                  className="modal-title fs-5"
                                  id="add_dbLabel"
                                >
                                  <img src="" alt="" />
                                </h1>
                              </div>
                              <div className="modal-body">
                                <div className="form_drop pt-2 drop_details col-lg-12 col-12">
                                  <p className="sty_pop">
                                    Please update if your full name differs from
                                    the one displayed
                                  </p>
                                  <p className="sty_pop">Mr.XYZ XYZ XYZ</p>
                                  <div className="row name_detail pt-0 animate__animated animate__fadeInUp animate__delay-.5s">
                                    <div className="col-lg-4 col-md-4 col-12 f_name">
                                      <label htmlFor="topmention1">
                                        First Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="topmention1"
                                        placeholder="First name"
                                        aria-label="First name"
                                      />
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12 m_name">
                                      <label htmlFor="topmention3">
                                        Middle Name <span>(optional)</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="topmention3"
                                        placeholder="Middle name"
                                        aria-label="Middle name"
                                      />
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12 l_name">
                                      <label htmlFor="topmention2">
                                        Last Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="topmention2"
                                        placeholder="Last name"
                                        aria-label="Last name"
                                      />
                                    </div>
                                    <p className="tiny_note">
                                      <img src={info} className="pe-1" alt="" />
                                      What you are legally known as
                                    </p>
                                  </div>
                                </div>
                                <div className="form_drop drop_details col-lg-12 col-12">
                                  <p className="sty_pop">
                                    You have any Previous Name?(Leave blank if
                                    not applicable)
                                  </p>
                                  <div className="row name_detail pt-0 animate__animated animate__fadeInUp animate__delay-.5s">
                                    <div className="col-lg-12 col-md-12 col-12 f_name">
                                      <label htmlFor="topmention1">
                                        Previous Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="topmention1"
                                        placeholder="First name"
                                        aria-label="First name"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <div className="box_button final_ent pt-3 animate__animated animate__fadeInUp animate__delay-.5s">
                                  <div className="bg-1">
                                    <input
                                      type="button"
                                      className="button wb_5 button--moema button--text-thick button--text-upper button--size-s h60_click blick_notice"
                                      id="nextUpdate_001"
                                      value="Update"
                                      data-bs-dismiss="modal"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </form>
        <FeaturedinAndAccordion />
        <Footer />
      </div>
    </>
  );
};

export default FinanceAgreement;
