import { Api } from '../api/Api';


export const useCarRegistrationSkipAll = () => {
    const storeSkipallValues = async (visitor_parameters, vehicle_data, message_type, FinanaceProviderIdArray) => {
        const response = await Api.post(
            "v1/data-ingestion-pipeline",
            {
                visitor_parameters,
                vehicle_data,
                message_type,
                FinanaceProviderIdArray
            });
        return response;
    };
    return {
        storeSkipallValues
    }
}