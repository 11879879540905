import React from 'react'
import loader from "../../../../assets/img/loader03.svg";

const Loader = (props) => {
  return (
    <>
      <div className={`text-center loader loader1 ${props.loader}`}>
        <div className="inner py-5 mt-5">
          <div className="my-5">
            <img height="100" src={loader} alt="" />
          </div>
          <h2 className="px-4"> Analysing Your Result...
          </h2>
        </div>
      </div>
    </>
  )
}

export default Loader