import React, { useReducer, useState, useContext } from "react";
import AddressTextField from "../../../UI/AddressTextField";
import AddressSelectBox from "../../../UI/AddressSelectBox";
import { usePostCodeAddress } from "../../../../Hooks/usePostCodeAddress";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import HiddenField from "../../../UI/HiddenField";
import Button from "../../../UI/Button";

const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "d-none",
  getPostcode: "#",
  clickManualLink: 0,
};
const postcodeReducer = (state, action) => {
  switch (action.type) {
    case "validationSucess": {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "getExtraAddressDetails": {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "showManualDiv": {
      return {
        ...state,
        showManualAddress: "d-block",
        lookUp: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click,
      };
    }
    case "resetaddress": {
      return {
        ...state,
        getDetails: [],
        getAddress: [],
        showManualAddress: "d-none",
        lookUp: true,
        nextClick: false,
        checkSelect: false,
      };
    }
    default:
      return state;
  }
};
const PostCode = (props) => {
  const { getPostcodeAddress, getSplitPostcodeAddress } = usePostCodeAddress();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const [postCodeAddress, setPostCodeAddress] = useState([]);

  const checkValidation = async (e) => {
    e.preventDefault();
    dispatch({
      type: "resetaddress",
      get_address: [],
    });
    const txtPostCode = await props.trigger("txtPostCode");
    if (txtPostCode) {
      await getPostcodeAddress(
        e.target.value,
        visitorParameters.visitor_parameters.uuid
      )
        .then((res) => {
          if (res.data.status === "0") {
            props.setError("txtPostCode", {
              type: "manual",
              message: "Please Enter Valid Postcode",
            });
          }
          if (res.data.length > 0) {
            dispatch({
              type: "validationSucess",
              payload: {
                lookup: false,
                next_click: true,
                postcode: e.target.value,
                get_address: res.data,
                check_select: true,
                manual_address: "d-none",
              },
            });
          }
        })
        .catch(function () {
          props.setError("txtPostCode", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
        });
    }
  };
  const handleChangeInsideOnChange = (e) => {
    props.handleChange(e);
  };
  const getValue = async (e) => {
    props.handleChange(e);
    e.preventDefault();
    props.clearErrors([
      "txtHouseNumber",
      "txtAddress3",
      "txtCounty",
      "txtTown",
      "txtCountry",
    ]);
    if (e.target.value != "") {
      const getData = await getSplitPostcodeAddress(
        e.target.value,
        state.getPostcode,
        visitorParameters.visitor_parameters.uuid
      );
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: getData.data, manual_address: "show" },
      });
      setPostCodeAddress(getData.data);
      props.clearErrors([
        "txtHouseNumber",
        "txtAddress3",
        "txtCounty",
        "txtTown",
        "txtCountry",
      ]);
    } else {
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: [], manual_address: "d-none" },
      });
      setPostCodeAddress([]);
    }
  };
  const lookupValidation = async (e) => {
    await props.trigger("txtPostCode");
    props.handleChange(e);
  };
  const HiddenFields = () => (
    <>
      <HiddenField
        name="txtUdprn"
        inputId="txtUdprn"
        inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
      />
      <HiddenField
        name="txtDeliveryPointSuffix"
        inputId="txtDeliveryPointSuffix"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""
        }
      />
      <HiddenField
        name="txtPz_mailsort"
        inputId="txtPz_mailsort"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""
        }
      />
      <HiddenField
        name="txtStreet"
        inputId="txtStreet"
        inputValue={
          state.getDetails.line_3 !== "#" ? state.getDetails.line_3 : ""
        }
      />
    </>
  );
  return (
    <>
      <h2 className="mb-3  title mt-4  animate__animated animate__fadeInUp animate__delay-1s">Registered Address
      </h2>
      <div className="col-lg-8  mb-3 animate__animated animate__fadeInUp animate__delay-1s">
        <div className="">
          <AddressTextField
            type="text"
            placeholder="Post Code"
            name="txtPostCode"
            id="txtPostCode"
            className="form-control"
            dataId="txtPostCode"
            onChange={handleChangeInsideOnChange}
            onBlur={checkValidation}
            autoComplete="off"
            validation={props.validation({
              required: "Please Enter Postcode",
              pattern: {
                value:
                  /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                message: "Please Enter a Valid Postcode",
              },
              minLength: {
                value: 5,
                message: "Please Enter a Valid Postcode",
              },
            })}
            validationMsg={
              props.validationMsg.txtPostCode && props.validationMsg.txtPostCode.message
            }
          />
        </div>
      </div>
      <div className="col-lg-4 ps-lg-0 mb-2 animate__animated animate__fadeInUp animate__delay-1s">
        <Button
          type="button"
          className="btn-comm2 p-2 w-100  lookup-btn"
          buttonText={
            <span>Look Up Address</span>
          }
          id="lookUp"
          onClick={lookupValidation}
        />
      </div>
      <div className="col-lg-12 mb-2" id="LookupAddress" style={state.checkSelect ? { display: "block" } : { display: "none" }}>
        <AddressSelectBox
          className="orm-select watermark anim_ylw animated-effect"
          OptionValue={state.getAddress}
          name="address1"
          id="address1"
          onChange={getValue}
          myRef={props.validation({ required: "Please Select Address",message:"Please Select Address" })}
          validationMsg={
            props.validationMsg.address1 && props.validationMsg.address1.message
          }
        />
      </div>
      <div id="fullAddress" className={`col-lg-12 ${state.showManualAddress}`}>

        <div className="mb-2">
          <AddressTextField
            type="text"
            placeholder="Address Line 1 *"
            name="txtHouseNumber"
            id="txtHouseNumber"
            className="form-control address hero-input"
            dataId="txtHouseNumber"
            autoComplete="off"
            validation={props.validation({
              required: "Please Enter Address Line 1",
            })}
            validationMsg={
              props.validationMsg.txtHouseNumber &&
              props.validationMsg.txtHouseNumber.message
            }
            value={state.getDetails.line_1}
          />
          <i
            className="validate validate_success"
            aria-hidden="true"
            style={{ display: "none" }}
          ></i>
        </div>
        <div className="mb-2">
          <AddressTextField
            type="text"
            placeholder="Address Line 2"
            name="txtAddress2"
            id="txtAddress2"
            className="form-control  address hero-input"
            dataId="txtAddress2"
            autoComplete="off"
            validation={props.validation({
              required: "Select Address",
              message: "Please Select Address",
            })}
            value={state.getDetails.line_2}
          />
          <i
            className="validate validate_success"
            aria-hidden="true"
            style={{ display: "none" }}
          ></i>
        </div>
        <div className="mb-2">
          <AddressTextField
            type="text"
            placeholder="Address Line 3"
            name="txtAddress3"
            id="txtAddress3"
            className="form-control  address hero-input"
            dataId="txtAddress3"
            autoComplete="off"
            validation={props.validation({
              required: "Select Address",
              message: "Please Select Address",
            })}
            value={state.getDetails.line_3}
          />
          <i
            className="validate validate_success"
            aria-hidden="true"
            style={{ display: "none" }}
          ></i>
        </div>

        <div className="mb-2">
          <AddressTextField
            type="text"
            placeholder="County"
            name="txtCounty"
            id="txtCounty"
            className="form-control  address hero-input"
            dataId="txtCounty"
            autoComplete="off"
            validation={props.validation()}
            validationMsg=""
            value={state.getDetails.county}
          />
          <i
            className="validate validate_success"
            aria-hidden="true"
            style={{ display: "none" }}
          ></i>
        </div>

        <div className="mb-2">
          <AddressTextField
            type="text"
            placeholder="Town *"
            name="txtTown"
            id="txtTown"
            className="form-control  address hero-input"
            dataId="txtTown"
            autoComplete="off"
            validation={props.validation({ required: "Please Enter Town" })}
            validationMsg={
              props.validationMsg.txtTown && props.validationMsg.txtTown.message
            }
            value={state.getDetails.town}
          />
          <i
            className="validate validate_success"
            aria-hidden="true"
            style={{ display: "none" }}
          ></i>
        </div>
        <div className="mb-2">
          <AddressTextField
            type="text"
            placeholder="Country *"
            name="txtCountry"
            id="txtCountry"
            className="form-control address hero-input"
            dataId="txtCountry"
            autoComplete="off"
            validation={props.validation({ required: "Please Enter Country" })}
            validationMsg={
              props.validationMsg.txtCountry && props.validationMsg.txtCountry.message
            }
            value={state.getDetails.country}
          />
          <i
            className="validate validate_success"
            aria-hidden="true"
            style={{ display: "none" }}
          ></i>
        </div>
        {Object.keys(state.getDetails).length > 0 ? <HiddenFields /> : null}
      </div>


    </>
  )
}

export default PostCode