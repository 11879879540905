import React, { useState } from "react";
const CarRegistrationModal = ({
    vehicleDateModal,
    setVehicleDateModal,
    formattedDates,
    setFormattedDates,
    vehicleModalClick
}) => {
  return (
    <>
   <div
            className={`modal fade ${vehicleDateModal && "show d-block"}`}
            id="CarDate"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="pre_addLabel"
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s ">
                <div className="modal-body">
                  <div className="form_drop yes_no_sec col-lg-12 col-12 p-3">
                    <div
                      className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s"
                      style={{ zIndex: 2, position: "relative" }}
                    >
                      <p className="png_t">
                        Please select the date you acquired your vehicle?
                      </p>
                      <div className="m-0 choose_Y_N row animate__animated animate__fadeInUp animate__delay-.5s">
                        {formattedDates.map((dateObj, index) => (
                          <div className="form-check px-0" key={index}>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="Radioshere2"
                              id={`radio${index}`}
                              value={dateObj.originalDate} // Set the unformatted value here
                              onClick={() =>
                                vehicleModalClick(dateObj.originalDate)
                              }
                            />
                            <label
                              className="form-check-label selecteddate"
                              htmlFor={`radio${index}`}
                            >
                              {dateObj.formattedDate}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
    </>
  );
};

export default CarRegistrationModal;
