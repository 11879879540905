import React, { useState, useEffect } from "react";
import RadioButtonV1 from "../../../UI/RadioButtonV1";
import TextField from "../../../UI/TextField";
import InputButton from "../../../UI/InputButton";

const PreviousNameModal = ({
  showPreviousNameModal,
  setShowPreviousNameModal,
  setFormData,
  slideChange,
}) => {
  const [preName, setPreName] = useState("");
  const [showInput, setShowInput] = useState(false);

  const updateUservalue = async (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      previousName: preName.trim(),
    }));

    setShowPreviousNameModal(false);
    slideChange(e);
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    if (/[^a-zA-Z\s]|[\s]{2,}/.test(value)) {
      return;
    }

    setPreName(value.trimStart());
  };

  const handleRadioChange = (e) => {
    const value = e.target.value;
    if (value === "No") {
      const inputField = document.getElementById('previousName');
      if (inputField) {
        inputField.value = '';
      }

      setShowPreviousNameModal(false);
      slideChange(e);
    }
    setShowInput(value === "Yes");
  };

  useEffect(() => {
    if (!showPreviousNameModal) {
      setShowInput(false);
    }
  }, [showPreviousNameModal]);

  return (
    <>
      <div
        className={`modal preNameModal fade ${showPreviousNameModal && "show d-block"
          }`}
        id="Add_preName"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="Add_preNameLabel"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered px-0">
          <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s px-0">
            <div className="d-block modal-body px-0">
              <div className={`${!showInput ? "" : "d-none"} form_drop yes_no_sec col-lg-12 col-12 px-0`}>
                <div
                  className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s"
                  style={{ zIndex: 2, position: "relative" }}
                >
                  <p className="png_t">Did you have any previous name?</p>
                  <div className="choose_Y_N col-12 animate__animated animate__fadeInUp animate__delay-.5s">
                    <div className="form-check px-0">
                      <RadioButtonV1
                        className="form-check-input"
                        type="radio"
                        name="previousNameRadio"
                        id="noRadio"
                        value="No"
                        onClick={handleRadioChange}
                        labelName="No"
                      />
                    </div>
                    <div className="form-check px-0">
                      <RadioButtonV1
                        className="form-check-input"
                        type="radio"
                        name="previousNameRadio"
                        id="yesRadio"
                        value="Yes"
                        onClick={handleRadioChange}
                        labelName="Yes"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${showInput ? "" : "d-none"} form_drop drop_details col-lg-12 col-12 animate__animated animate__fadeInUp animate__delay-.5s`}>
                <p className="sty_pop">
                  You have any Previous Name? (Leave blank if not applicable)
                </p>
                <div className="row name_detail pt-0">
                  <div className="col-lg-12 col-md-12 col-12 f_name">
                    <label htmlFor="previousName">Previous Surname</label>
                    <TextField
                      type="text"
                      className="form-control"
                      id="previousName"
                      placeholder="Previous Surname"
                      value={preName}
                      onChange={handleNameChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={`${showInput ? "" : "d-none"} modal-footer`}>
              <div className="box_button final_ent pt-3 animate__animated animate__fadeInUp animate__delay-.5s">
                <div className="bg-1 text-center">
                  <InputButton
                    type="button"
                    className="button text-center wb_5 button--moema button--text-thick button--text-upper button--size-s h60_click blick_notice"
                    id="PreviousButton"
                    value="Update"
                    name="PreviousButton"
                    onClick={updateUservalue}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviousNameModal;