import React, { useState } from 'react';
import Button from '../../../UI/Button';
import CheckBoxV3 from "../../../UI/CheckBoxV3";
import AngleArrow from "../../../../assets/img/angle-arrow.svg";
import { useHistory } from "react-router-dom";
import { Finance_Prominent_List_Reworded } from "../../../../Constants/ConstantsPCP";

const MultipleLenders = (props) => {
  const history = useHistory();
  const [selectedLenders, setSelectedLenders] = useState([]);
  const [selectedLendersSlug, setSelectedLendersSlug] = useState([]);
  const [validationMessage, setValidationMessage] = useState('');
  const [finalValidationMessage, setFinalValidationMessage] = useState('');
  const [validationMessagePopUp, setValidationMessagePopUp] = useState(false);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [currentCategory, setCurrentCategory] = useState('');

  const handleLenderSelection = (value) => {
    setFinalValidationMessage('');
    const selectedLender = Finance_Prominent_List_Reworded.find(lender => lender.category === value);
    if (selectedLender) {
      setCurrentCategory(value);
      if (selectedLender.sub_categories.length === 1) {
        let newSelectedLenders = [...selectedLenders];
        if (newSelectedLenders.includes(value)) {
          newSelectedLenders = newSelectedLenders.filter(lender => lender !== value);
        } else {
          if (selectedSubCategories.length < 3) {
            newSelectedLenders.push(value);
          } else {
            setIsPopupVisible(true);
            setValidationMessagePopUp(true);
          }
        }
        setSelectedLenders(newSelectedLenders);
        handleSubCategorySelection(selectedLender.sub_categories[0].value, selectedLender.sub_categories[0].slug, "category");
      } else {
        const exists = selectedLender.sub_categories.some(subcategory => selectedSubCategories.includes(subcategory.value));
        if (selectedSubCategories.length < 3) {
          setIsPopupVisible(true);
          setValidationMessagePopUp(false);
          setValidationMessage('');
        } else {
          if (exists == true) {
            setIsPopupVisible(true);
            setValidationMessagePopUp(false);
            setValidationMessage('');
          } else {
            setIsPopupVisible(true);
            setValidationMessagePopUp(true);
          }
        }
      }
    }
  };

  const handleSubCategorySelection = (value, slug, type) => {
    setValidationMessage('');
    let newSelectedSubCategories = [...selectedSubCategories];
    let newSelectedLendersSlug = [...selectedLendersSlug];
    if (newSelectedSubCategories.includes(value)) {
      newSelectedSubCategories = newSelectedSubCategories.filter(subCategory => subCategory !== value);
      newSelectedLendersSlug = newSelectedLendersSlug.filter(lenderSlug => lenderSlug !== slug);
    } else {
      if (newSelectedSubCategories.length < 3) {
        newSelectedSubCategories.push(value);
        newSelectedLendersSlug.push(slug);
      } else {
        if (type == "sub_category") {
          setValidationMessage('You can only select up to 3 subcategories.');
          return;
        }
      }
    }
    setSelectedSubCategories(newSelectedSubCategories);
    props.setFormData(prevState => ({
      ...prevState,
      newSelectedSubCategories: newSelectedSubCategories
    }));
    setSelectedLendersSlug(newSelectedLendersSlug);
    if (type == "sub_category") {
      let newSelectedLenders = [...selectedLenders];
      const selectedLender = Finance_Prominent_List_Reworded.find(lender => lender.category === currentCategory);
      const exists = selectedLender.sub_categories.some(subcategory => newSelectedSubCategories.includes(subcategory.value));
      if (!newSelectedLenders.includes(currentCategory) && exists == true) {
        newSelectedLenders.push(currentCategory);
      } else if (exists == false) {
        newSelectedLenders = newSelectedLenders.filter(lender => lender !== currentCategory);
      }
      setSelectedLenders(newSelectedLenders);
    }
    setValidationMessage('');
    localStorage.setItem('multipleProviderSlug', JSON.stringify(newSelectedLendersSlug));
  };
  const handleSkip = () => {
    props.setFormData(prevState => ({
      ...prevState,
      newSelectedSubCategories: []
    }));
    setSelectedLenders([]);
    setSelectedLendersSlug([]);
    setSelectedSubCategories([]);
    localStorage.setItem('multipleProviderSlug', JSON.stringify([]));

    props.formSubmit('skip');
  };

  const handleSubmit = () => {
    if (selectedLenders.length === 0) {
      setFinalValidationMessage('Please select at least one lender.');
    } else {
      props.formSubmit('submit');
    }
  };

  const closePopup = () => {
    setIsPopupVisible(false);
  };
  const selectProvider = props.formData.provider;
  const filteredFinanceProminentList = Finance_Prominent_List_Reworded.map(categoryItem => ({
    ...categoryItem,
    sub_categories: categoryItem.sub_categories.filter(subCategory => subCategory.value !== selectProvider)
  }));
  return (
    <>
      {props.showMultipleLenders && (
        <section className="bg-gray p-2 sect03 vehiclenumber lendersection">
          <div className="bg-inner rounded-2 p-lg-5 p-md-5 p-sm-4 p-4">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 offset-lg-3">
                  <h2 className="mt-5 animate__animated animate__fadeInUp fw-bold">
                    Many of our clients have multiple cars financed through multiple lenders.
                  </h2>
                  <h5 className="animate__animated animate__fadeInUp animate__delay-1s">
                    <i>(You can choose up to 3 lenders)</i>
                  </h5>
                  {finalValidationMessage && <p class="lender-validation-error-msg animate__animated animate__fadeInUp animate__delay-.5s">{finalValidationMessage}</p>}
                  <div>
                    <ul className="lender-list ps-0 mx-3 mt-4 animate__animated animate__fadeInUp animate__delay-1s">
                      {filteredFinanceProminentList.map((lender) => (
                        <li key={lender.category}>
                          {/* <label className="form-check-label name" htmlFor={`lender-${lender.category}`}>
                            <input
                              className="form-check-input circle"
                              name="multipleProvider"
                              type="checkbox"
                              value={lender.category}
                              checked={selectedLenders.includes(lender.category)}
                              onChange={() => handleLenderSelection(lender.category)}
                              id={`lender-${lender.category}`}
                            />
                            {lender.category}
                          </label> */}
                          <CheckBoxV3
                            className="form-check-input circle"
                            name="multipleProvider"
                            value={lender.category}
                            checked={selectedLenders.includes(lender.category)}
                            onChange={() => handleLenderSelection(lender.category)}
                            dataId = {`lender-${lender.category}`}
                            labelClassName="form-check-label name"
                            labelText={lender.category}
                          />
                        </li>
                      ))}
                    </ul>
                    {isPopupVisible && (
                      <div className="modal fade show d-block" id="Add_preName" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1}
                        aria-labelledby="Add_preNameLabel" aria-modal="true" role="dialog">
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s ">
                            <div class="modal-header p-1 validation-message-header"><button type="button" class="btn-close" onClick={closePopup}></button></div>
                            <div className="d-block modal-body">
                              <div className="form_drop pt-2 drop_details col-lg-12 col-12">
                              </div>
                              <div className="popup">
                                {!validationMessagePopUp && (
                                  <div className="popup-content">
                                    <h5>Please confirm the specific lender you've taken out finance with:</h5>
                                    {validationMessage && <p class="lender-validation-error-msg animate__animated animate__fadeInUp animate__delay-.5s">{validationMessage}</p>}
                                    <ul className="lender-list-sub ps-0 mx-3 mt-4">
                                      {filteredFinanceProminentList.find(lender => lender.category === currentCategory).sub_categories.map(subCategory => (
                                        <li key={subCategory.value}>
                                          {/* <label className="form-check-label name" htmlFor={`subCategory-${subCategory.value}`}>
                                            <input
                                              className="form-check-input circle"
                                              name="subCategory"
                                              type="checkbox"
                                              value={subCategory.value}
                                              checked={selectedSubCategories.includes(subCategory.value)}
                                              onChange={() => handleSubCategorySelection(subCategory.value, subCategory.slug, "sub_category")}
                                              id={`subCategory-${subCategory.value}`}
                                            />
                                            {subCategory.label}
                                          </label> */}
                                          <CheckBoxV3
                                              className="form-check-input circle"
                                              name="subCategory"
                                              value={subCategory.value}
                                              checked={selectedSubCategories.includes(subCategory.value)}
                                              onChange={() => handleSubCategorySelection(subCategory.value, subCategory.slug, "sub_category")}
                                              dataId = {`subCategory-${subCategory.value}`}
                                              labelClassName="form-check-label name"
                                              labelText={subCategory.label}
                                            />
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                                {validationMessagePopUp && (
                                  <div className="popup-content">
                                    <h5 className="text-center">You can only select up to 3 lenders</h5>
                                  </div>
                                )}
                                <div class="multiple-lender-container">
                                  <div class="centered-div">
                                    <Button
                                    className="multiple-lender-button"
                                    onClick={closePopup}
                                    buttonText="Next"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="lenderbutton pt-4">
                    <div className="row">
                      <div className="col-4 pe-0">
                        <Button
                          type="button"
                          className="btn-comm2 p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-3 skipbtn"
                          buttonText={<span>Skip</span>}
                          id="next3"
                          onClick={handleSkip}
                        />
                      </div>
                      <div className="col-8">
                        <Button
                          type="button"
                          className="btn-comm2 p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-3"
                          buttonText={
                            <span className="fw-bold">
                              Next <i className="arrowicon"><img src={AngleArrow} alt="" /></i>
                            </span>
                          }
                          id="next4"
                          onClick={handleSubmit}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default MultipleLenders;

