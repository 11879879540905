import React, { useRef, useState, useReducer, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import Button from "../../../UI/Button";
import TextField from "../../../UI/TextField";
import PreviousNameModal from "../../../Includes/Layouts/PCP_HDSC_V3/PreviousNameModal";
import pre_contract from "../../../../docs/Hendersons - Pre-Contract Info.80eecbd0.pdf"
import Terms_and_Conditions from "../../../../docs/Hendersons - Terms & Conditions.4dcc0780.pdf"
import Letter_of_Authority from "../../../../docs/Letter of Authority - Hendersons.a47d22c0.pdf"
import { BrowserView, MobileView, deviceType, } from "react-device-detect";

const initialState = {
  isChecked: false,
  isAgreed: false,
  isCanvasEmpty: false,
  isValid: false,
  canvasValue: false,
};
const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }

    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};

const Signature = (props) => {
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const signPadRef = useRef();
  const [signatureData, setSignatureData] = useState("");
  const [isSigned, setIsSigned] = useState(false);
  const [isChecked, setIsChecked] = useState(true);

  const signValidate = async () => {
    try {
      const signPad = signPadRef.current;
      const rpdata = signPad.toData();
      var signLength = 0;
      for (var i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }
      if (signLength > 13) {
        var signData = signPad.getCanvas().toDataURL("image/png");
        setSignatureData(signData);
        props.clearErrors("signaturePad");
        return dispatch({ type: "validSignature", payload: { value: false } });
      } else {
        dispatch({ type: "validSignature", payload: { value: true } });
        dispatch({ type: "signPadChange", payload: { value: false } });
      }
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    if (state.isCanvasEmpty) {
      signValidate();
    }
  }, [state.isCanvasEmpty, isSigned]);

  const clearSign = () => {
    setSignatureData("");
    dispatch({ type: "signPadChange", payload: { value: false } });
    if (signPadRef.current) signPadRef.current.clear();
  };
  return (
    <>
      <TextField
        type="hidden"
        className="form-control"
        name="signature_data"
        id="signature_data"
        value={signatureData}
        validation={props.validation({})}
      />
      <div className="sign-sec" ref={props.section3Ref}>
        <p className="mb-3 mt-4 fw-bold">
          Please sign your signature in the box below to confirm you understand the above
        </p>
        <ul className="list-point ps-0">
          <li>Sign using your finger, mouse, or stylus</li>
          <li>Keep it fully contained within the box</li>
          <li>Ensure it is a true likeness of your signature</li>
        </ul>
        <div className="signbox">
          {(deviceType === "mobile" || deviceType === "tablet") && (
            <MobileView>
              <SignatureCanvas
                canvasProps={{
                  className: "writing-line",
                  id: "canvas_signature",
                  width: "400px", height: "200px",
                  name: "signaturePad",
                  id: "signaturePad"
                }}
                clearOnResize={false}
                ref={signPadRef}
                onEnd={() => {
                  setIsSigned(!isSigned);
                  dispatch({
                    type: "signPadChange",
                    payload: { value: true },
                  });
                }}
              />
            </MobileView>
          )}
          {deviceType === "browser" && (
            <BrowserView>
              <SignatureCanvas
                canvasProps={{
                  className: "writing-line",
                  id: "canvas_signature",
                  width: "400px", height: "200px",
                  name: "signaturePad",
                  id: "signaturePad"
                }}
                clearOnResize={false}
                ref={signPadRef}
                onEnd={() => {
                  setIsSigned(!isSigned);
                  dispatch({
                    type: "signPadChange",
                    payload: { value: true },
                  });
                }}
              />
            </BrowserView>
          )}
        </div>
        <div className="signature-error-div">
          {!props.validationMsg?.signaturePad?.message && state.isValid && (
            <span className="error_msg " id="email_err">
              Draw valid signature!
            </span>
          )}

          {props.validationMsg.signaturePad && (
            <span className="error_msg " id="email_err">
              {props.validationMsg.signaturePad.message}
            </span>
          )}
        </div>
        <Button
          type="button"
          className="clearbutton"
          id="clearbtn1"
          buttonText="Clear"
          onClick={clearSign}
        />
        <div className="d-flex align-items-top sign-check mb-3 mt-4">
          {/* <div>
            <input
              type="checkbox"
              name="isChecked"
              id="isChecked"
              className="checkinput me-2"
              checked={isChecked}
              onChange={() => {
                setIsChecked(!isChecked);
                props.setError("isChecked", {
                  type: "manual",
                  message: "",
                });
              }}
              ref={props.validation({
                required: "Please tick to show your authorisation for us to proceed",
              })}
            />
          </div> */}
          <div>
            <p className="non_check m-0 mt-3">
              In order to be able to submit a claim we need just need your signature. By signing below, you are giving Henderson Claims Limited T/A Hendersons authority to act on your behalf and to attach your signature to these <a href={Letter_of_Authority} target="_blank">documents</a> and agree to their <a href={Terms_and_Conditions} target="_blank">terms and conditions</a> and <a href={pre_contract} target="_blank">pre-contract summary document</a>.
            </p>
            {/* <label >
              In order to be able to submit a claim we need just  need your signature. By signing below,you are giving Henderson Claims Limited T/A Henderson authority to act on your behalf and to attach your signature to these  dcoument and agree to their terms and conditions and pre-contract-summary document.
            </label> */}
          </div>
        </div>
        {/* {props.validationMsg.isChecked && (
          <span className="error_msg " id="email_err">
            {props.validationMsg.isChecked.message}
          </span>
        )}
        <ul className="list-point ps-0">
          <li>The name I provided above matches the one provided to my lenders.</li>
          <li>
            I consent for my signature to be applied to a Letter of Authority to be sent with my Letter of Complaint to my Lender(s) and where applicable, a Financial Ombudsman Service application form.
          </li>
        </ul> */}
      </div>
    </>
  );
};

export default Signature;
