import React, {
  useContext,
  useReducer,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import info from "../../../assets/PCP_HDSC_V1/img/info.png";
import nextgreen from "../../../assets/PCP_HDSC_V1/img/next-green.png";
import secure from "../../../assets/PCP_HDSC_V1/img/secure.svg";
import { useEmailValidation } from "../../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../../Hooks/usePhoneValidation";
import { useForm } from "react-hook-form";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import QueryStringContext from "../../../Contexts/QueryString";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import PostCode_PCP_HDSC_V1_5T from "../../Includes/Layouts/PCP_HDSC_V1_5T/PostCode_PCP_HDSC_V1_5T";
import review_star from "../../../assets/PCP_HDSC_V1/img/review-star.png";

import bg_float from "../../../assets/PCP_HDSC_V1/img/bg-float3.png";

import PreviousPostcode_PCP_HDSC_V1_5T_page from "../../Includes/Layouts/PCP_HDSC_V1_5T/PreviousPostcode_PCP_HDSC_V1_5T_page";
import * as EnvConstants from "../../../Constants/EnvConstants";
import PrivacyPolicy from "../../../docs/Henderson Claims - Privacy Policy - June 2024.pdf";
import BasicOne from "../../Includes/Layouts/PCP_HDSC_V1_5T/BasicOne";
import BasicTwo from "../../Includes/Layouts/PCP_HDSC_V1_5T/BasicTwo";
import BasicThree from "../../Includes/Layouts/PCP_HDSC_V1_5T/BasicThree";
import BasicFour from "../../Includes/Layouts/PCP_HDSC_V1_5T/BasicFour";

import { Finance as UnsortedFinance } from "../../../Constants/ConstantsPCP";
import GTMDataLayer from "../../../Utility/GTMDataLayer";
import PreviousNameModal from "../../Includes/Layouts/PCP_HDSC_V1_5T/PreviousNameModal";

const highlightedBankValues = [6, 39, 61, 68];
// Filter the banks that match the highlighted values
const highlightedBank = UnsortedFinance.filter((bank) =>
  highlightedBankValues.includes(parseInt(bank.value))
);
// Order the highlighted banks according to highlightedBankValues
const highlightedBanksOrdered = highlightedBankValues.map((value) =>
  highlightedBank.find((bank) => parseInt(bank.value) === value)
);
// Get the remaining banks that are not highlighted and sort them alphabetically
const remainingBanks = UnsortedFinance.filter(
  (bank) => !highlightedBankValues.includes(parseInt(bank.value))
).sort((a, b) => a.label.localeCompare(b.label));
// Merge highlightedBanksOrdered and remainingBanks
const Finance = [...highlightedBanksOrdered, ...remainingBanks];

const PCP_HDSC_V1_5T_Form = ({
  nextStep,
  prevStep,
  step,
  showModal,
  handleYesClick,
  showSecondSection,
  error,
  formData,

  handleChangeButton,

  setEmailError,
  setPhoneError,
  dobError,
  monthError,
  yearError,
  postCodeError,
  setFormData,
  setError_alt,
  setFormModal,
  formModal,
  setCurrentModalCount,
  currentModalCount,
  bankError,
  commissionError,
  claimError,
  whyNotClaimError,
  setPostCodeError,
  showPreviousNameModal,
  setShowPreviousNameModal,
  consentError,
  setConsentError,
  setShowModal,
  setShowSecondSection,
  finerror
}) => {
  const {
    register,
    errors,
    trigger,
    clearErrors,
    getValues,
    setError,
    setValue,
    control,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const history = useHistory();
  const { queryString } = useContext(QueryStringContext);
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const [formModalTwo, setFormModalTwo] = useState(false);
  const field = "pid";

  ////handle submitt testttt
  const handleChange = (e) => {
    // Access current form values
    const currentValues = getValues();
    formData.lstSalutation = currentValues.lstSalutation;
    formData.txtFName = currentValues.txtFName;
    formData.txtLName = currentValues.txtLName;
    formData.txtEmail = currentValues.txtEmail;
    formData.txtPhone = currentValues.txtPhone;
    // formData.previousName = currentValues.previousName;
    if (currentValues.txtPostCode) {
      setPostCodeError("");
    }
  };

  const handleYesModal = (e) => {
    setFormModalTwo(true);
    const name = e.target.name;
    const value = "";
    setFormData({ ...formData, [name]: value });
  };
  const validateEmail = async (e) => {
    const email = await trigger("txtEmail");
    const phone = await trigger("txtPhone");
    const unsubscribeStatus = formData.unsubscribeStatus;
    if (email == "") {
      setEmailError("Please enter email.");
    }
    if (phone == "") {
      setPhoneError("Please enter phone number.");
    }
    if (unsubscribeStatus === null) {
      setConsentError("Please select any one of the above.");
    } else {
      setConsentError("");
    }
    if (email != "" && phone != "") {
      const getPhoneValidation = await phoneValidation(phone);
      const getEmailValidation = await emailValidation(
        email,
        visitorParameters.visitor_parameters.uuid
      );
      if (getEmailValidation.data.status_code === 1) {
        setEmailError("");

        if (getPhoneValidation.data.status_code === 1) {
          if (unsubscribeStatus != null) {
            nextStep();
          }
        }
        if (getPhoneValidation.data.status_code != 1) {
          setPhoneError("Invalid phone number.");
        }
      } else {
        setEmailError("Invalid email.");
      }
    }
  };
  console.log(12121,formData);
  // Handle form submission
  const handleSubmit = async (e) => {
    const split_name = "PCP_HDSC_V1_5T";
    const values = getValues();
    const storedFormData = localStorage.setItem(
      "formData",
      JSON.stringify(formData)
    );
    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");
    const formdatavalues = values;
    console.log("valuessssssss", formdatavalues);

    formdatavalues.txtFName = formData.txtFName.trim();
    formdatavalues.txtLName = formData.txtLName.trim();
    formdatavalues.lstSalutation = formData.lstSalutation;
    formdatavalues.txtEmail = formData.txtEmail;
    formdatavalues.txtPhone = formData.txtPhone;
    formdatavalues.dobDay = formData.dobDay;
    formdatavalues.dobMonth = formData.dobMonth;
    formdatavalues.dobYear = formData.dobYear;
    formdatavalues.financeProvider = formData.financeProvider;
    formdatavalues.commission = formData.commission;
    formdatavalues.bankruptcy = formData.bankruptcy;
    formdatavalues.claimed = formData.claimed;
    formdatavalues.whyNotClaimed = e.target.value;
    formdatavalues.previousName = formData.previousName;
    formdatavalues.unsubscribeStatus = formData.unsubscribeStatus;
    formdatavalues.address1 = formData.address1;
    formdatavalues.txtAddress2 = formData.txtAddress2;
    formdatavalues.txtAddress3 = formData.txtAddress3;
    formdatavalues.txtCountry = formData.txtCountry;
    formdatavalues.txtCounty = formData.txtCounty;
    formdatavalues.txtHouseNumber = formData.txtHouseNumber;
    formdatavalues.txtPostCode = formData.txtPostCode;
    formdatavalues.txtTown = formData.txtTown;
    formdatavalues.txtCountry1 = formData.txtCountry1;
    formdatavalues.txtCounty1 = formData.txtCounty1;
    formdatavalues.txtPrevAddress3 = formData.txtPrevAddress3;
    formdatavalues.txtPrevHouseNumber_1 = formData.txtPrevHouseNumber_1;
    formdatavalues.txtPrevPostCode_1 = formData.txtPrevPostCode_1;
    formdatavalues.txtPrevroute_1 = formData.txtPrevroute_1;
    formdatavalues.txtPrevaddress1_1 = formData.txtPrevaddress1_1;
    formdatavalues.txtTown1 = formData.txtTown1;

    const formSUbmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      formdatavalues,
      "split_form_submit",
      "PCP_HDSC_V1_5T",
      queryString,
      visitorParameters.data
    );

    if (formSUbmitResult.data.status === "Success") {
      if (queryString != null) {
        if (queryString.indexOf("&" + field + "=") !== -1) {
          if (EnvConstants.AppConversionPixel === "true") {
            console.log("if fb");
            history.push(
              "/fbpixel?split_name=" + split_name + "&next_page=signature"
            ); // fb fixel firing
          } else {
            console.log("else fb");
            window.scrollTo(0, 0);
            history.push(
              "/signature?uuid=" +
                visitorParameters.visitor_parameters.uuid +
                "&split_name=" +
                split_name
            );
          }
        } else {
          console.log("another else fb");
          window.scrollTo(0, 0);
          history.push(
            "/signature?uuid=" +
              visitorParameters.visitor_parameters.uuid +
              "&split_name=" +
              split_name
          );
        }
      } else {
        console.log("laset else  fb");
        window.scrollTo(0, 0);
        history.push(
          "/signature?uuid=" +
            visitorParameters.visitor_parameters.uuid +
            "&split_name=" +
            split_name
        );
      }
    }
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: "56px",
    }),
  };

  const handleKeyPress = (e) => {
    const charCode = e.charCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };
  const emailValidCheck = async (event) => {
    const email = event.target.value;
    if (email !== "") {
      const getEmailValidation = await emailValidation(
        email,
        visitorParameters.visitor_parameters.uuid
      );
      if (getEmailValidation.data.status_code === 1) {
        setEmailError("");
        return 1;
      } else {
        setEmailError("Invalid email.");
      }
    }
  };
  const cancelpopup = () => {
    setFormModal(false);
  };
  const cancelpopupTwo = () => {
    setFormModalTwo(false);
  };

  const phoneValidCheck = useCallback(async (event) => {
    const phone = event.target.value;

    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone);
      if (getPhoneValidation.data.status_code === 1) {
        clearErrors("txtPhone");
        return 1;
      } else if (getPhoneValidation.data.status_code === 0) {
        setPhoneError("Please Enter Valid Working Phone Number");
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        setPhoneError("Phone Number Already Exist");
        return 0;
      } else {
        setPhoneError("Please Enter Valid Phone Number");
        return 0;
      }
    } else {
      setPhoneError("Please Enter Phone Number");
      return 0;
    }
  });

  const handleFinanceProviderChange = (e) => {
    if (currentModalCount < 4) {
      setCurrentModalCount(currentModalCount + 1);
    }
    localStorage.setItem("currentModalCount", currentModalCount);
    if (currentModalCount > 3) {
      setFormModal(true);
    }
    const selectedValue = e.value;
    // Check if selectedValue exists in the Finance constant

    if (!formData.financeProvider.includes(e.value)) {
      // Add selectedValue to formData.financeProvider array
      if (formData.financeProvider.length < 4) {
        setFormData({
          ...formData,
          financeProvider: [...formData.financeProvider, e.value],
        });
      }
    }
    const financeItem = Finance.find((item) => item.value === selectedValue);
    if (financeItem) {
      // Retrieve slug name from the finance item
      const slug = financeItem.slug;

      // Get existing data from local storage or initialize an empty array
      const localStorageData =
        JSON.parse(localStorage.getItem("slugArray")) || [];

      // Check if the slug is already stored in local storage
      if (!localStorageData.includes(slug)) {
        // Add the slug to the array
        const updatedData = [...localStorageData, slug];
        // Update local storage
        if (updatedData.length <= 4) {
          localStorage.setItem("slugArray", JSON.stringify(updatedData));
        }
      }
    }

    setError_alt("");
  };

  useEffect(() => {
    const localStorageData =
      JSON.parse(localStorage.getItem("slugArray")) || [];
    if (localStorageData) {
      localStorage.removeItem("slugArray");
    }
  }, []);

  const handleRemoveFinanceProvider = (index) => {
    setCurrentModalCount(currentModalCount - 1);

    // Remove the value from the formData.financeProvider array
    const updatedFinanceProvider = [...formData.financeProvider];
    const removedValue = updatedFinanceProvider.splice(index, 1)[0];
    setFormData({ ...formData, financeProvider: updatedFinanceProvider });

    // Remove associated slug from local storage
    const financeItem = Finance.find((item) => item.value === removedValue);
    if (financeItem) {
      const slug = financeItem.slug;
      const localStorageData =
        JSON.parse(localStorage.getItem("slugArray")) || [];
      const updatedData = localStorageData.filter((item) => item !== slug);
      if (updatedData.length <= 4) {
        localStorage.setItem("slugArray", JSON.stringify(updatedData));
      }
    }
  };

  const autofocus = useRef();
  const toggleFocus = (event) => {
    if (autofocus.current) {
      const refElement = autofocus.current;
      const offset = -135;

      const scrollPosition =
        refElement.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    } else {
      window.scroll(0, 0);
    }
  };

  const postcodeCheck = () => {
    const postCode = getValues("txtPostCode");
    const address1 = getValues("address1");
    const txtAddress2 = getValues("txtAddress2");
    const txtAddress3 = getValues("txtAddress3");
    const txtCountry = getValues("txtCountry");
    const txtCounty = getValues("txtCounty");
    const txtHouseNumber = getValues("txtHouseNumber");
    const txtPostCode = getValues("txtPostCode");
    const txtTown = getValues("txtTown");
    const txtCountry1 = getValues("txtCountry1");
    const txtCounty1 = getValues("txtCounty1");
    const txtPrevAddress3 = getValues("txtPrevAddress3");
    const txtPrevHouseNumber_1 = getValues("txtPrevHouseNumber_1");
    const txtPrevPostCode_1 = getValues("txtPrevPostCode_1");
    const txtPrevroute_1 = getValues("txtPrevroute_1");
    const txtPrevaddress1_1 = getValues("txtPrevaddress1_1");
    const txtTown1 = getValues("txtTown1");
    formData.address1 = address1;
    formData.txtAddress2 = txtAddress2;
    formData.txtAddress3 = txtAddress3;
    formData.txtCountry = txtCountry;
    formData.txtCounty = txtCounty;
    formData.txtHouseNumber = txtHouseNumber;
    formData.txtPostCode = txtPostCode;
    formData.txtTown = txtTown;
    formData.txtCountry1 = txtCountry1;
    formData.txtCounty1 = txtCounty1;
    formData.txtPrevAddress3 = txtPrevAddress3;
    formData.txtPrevHouseNumber_1 = txtPrevHouseNumber_1;
    formData.txtPrevPostCode_1 = txtPrevPostCode_1;
    formData.txtPrevroute_1 = txtPrevroute_1;
    formData.txtPrevaddress1_1 = txtPrevaddress1_1;
    formData.txtTown1 = txtTown1;
    const storedFormData = localStorage.setItem(
      "formData",
      JSON.stringify(formData)
    );
    if (!postCode) {
      setPostCodeError("Postcode is required.");
      return;
    } else if (!address1) {
      setPostCodeError("Please select Address.");
      return;
    } else {
      setShowModal(true);
      // nextStep();
    }
  };

  const noClick = () => {
    let a = getValues();
    console.log(a)
    setShowModal(false);
    nextStep();
  };

  const submitAddress = () => {
    const currentValues = getValues();
    formData.txtCountry1 = currentValues.txtCountry1;
    formData.txtCounty1 = currentValues.txtCounty1;
    formData.txtPrevAddress3 = currentValues.txtPrevAddress3;
    formData.txtPrevHouseNumber_1 = currentValues.txtPrevHouseNumber_1;
    formData.txtPrevPostCode_1 = currentValues.txtPrevPostCode_1;
    formData.txtPrevroute_1 = currentValues.txtPrevroute_1;
    formData.txtPrevaddress1_1 = currentValues.txtPrevaddress1_1;
    formData.txtTown1 = currentValues.txtTown1;
    setShowSecondSection(false);
    setShowModal(false);
    GTMDataLayer({
      question: 'address',
      answer: null,
    });
    nextStep();
  };

  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()}>
        <section className="main_content">
          <div className="container">
            {/* row start */}
            <div className="row align-items-center">
              <div className="cs_hero_wrap cs_bg_filed">
                {/*Gradient*/}
                <div className="container-md">
                  <div className="home_plate_001">
                    <div className="row">
                      <div className="cs_hero_text col-lg-6 col-12 order-lg-1 order-md-1 order-2">
                        <BasicOne step={step} nextStep={nextStep} />

                        <div
                          className={`mt-5 form_dec col-12 col-md-11 animate__animated animate__fadeInUp animate__delay-.5s animated-element ${
                            step === 1 ? "d-none" : ""
                          }`}
                        >
                          <div
                            className=" animate__animated animate__fadeInUp animate__delay-.5s animated-element borderx"
                            id="dynamic"
                          >
                            {step === 1 && <div className="no_border"></div>}

                            <BasicTwo
                              step={step}
                              formData={formData}
                              handleChange={handleChange}
                              register={register}
                              prevStep={prevStep}
                              nextStep={nextStep}
                              getValues={getValues}
                              errors={errors}
                              trigger={trigger}
                              clearErrors={clearErrors}
                            />
                            {/* // slide_3dec */}
                            <BasicThree
                              step={step}
                              formData={formData}
                              handleChange={handleChange}
                              register={register}
                              prevStep={prevStep}
                              nextStep={nextStep}
                              emailValidCheck={emailValidCheck}
                              phoneValidCheck={phoneValidCheck}
                              setFormData={setFormData}
                              setConsentError={setConsentError}
                              getValues={getValues}
                              errors={errors}
                              trigger={trigger}
                              visitorParameters={visitorParameters}
                              handleKeyPress={handleKeyPress}
                              clearErrors={clearErrors}
                            />
                            <BasicFour
                              clearErrors={clearErrors}
                              step={step}
                              formData={formData}
                              handleChange={handleChange}
                              register={register}
                              prevStep={prevStep}
                              nextStep={nextStep}
                              emailValidCheck={emailValidCheck}
                              setFormData={setFormData}
                              setConsentError={setConsentError}
                              getValues={getValues}
                              errors={errors}
                              trigger={trigger}
                              setError={setError}
                            />

                            <div
                              className={`slide_6Dec ${step != 5 && "d-none"}`}
                            >
                              <h2 className="animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                                According to your responses, it seems you might
                                be eligible for compensation.
                              </h2>
                              <div className="px-3 py-3 widget_mobi borderx">
                                <p className="sub_quesHead animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                                  To proceed, please finalise your registration
                                  by providing your current address.
                                </p>
                                <div className="form_drop col-lg-12 col-12">
                                  <div
                                    className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s animated-element"
                                    style={{
                                      zIndex: 2,
                                      position: "relative",
                                    }}
                                  >
                                    <div className="enter_postcode_add animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                                      <label>Postcode</label>
                                      <PostCode_PCP_HDSC_V1_5T
                                        validation={register}
                                        validationMsg={errors}
                                        trigger={trigger}
                                        clearErrors={clearErrors}
                                        handleChange={handleChange}
                                        setError={setError}
                                        setValue={setValue}
                                        setPostCodeError={setPostCodeError}
                                        getValues={getValues}
                                      />
                                      {postCodeError &&
                                        !errors.txtPostCode &&
                                        !errors.txtHouseNumber &&
                                        !errors.txtAddress2 &&
                                        !errors.txtCounty &&
                                        !errors.txtTown &&
                                        !errors.txtCountry && (
                                          <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                                            {postCodeError}
                                          </p>
                                        )}
                                      {/*  */}
                                      <p className="tiny_note">
                                        <img
                                          src={info}
                                          className="pe-1"
                                          alt=""
                                        />
                                        Your address is required for a smooth
                                        claim process
                                      </p>
                                      <div className="col-lg-5 col-12"></div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="box_button animate__animated animate__fadeInUp animate__delay-.5s animated-element"
                                  id="subRegNo6"
                                >
                                  <div className="row align-items-center mt-4">
                                    <div className="col-4 text-center">
                                      <button
                                        type="button"
                                        data-type="back"
                                        id="backStep-5"
                                        className="backbutton"
                                        onClick={prevStep}
                                      >
                                        <img src={nextgreen} alt="" />
                                        Back
                                      </button>
                                    </div>
                                    <div className="col-8">
                                      <div className="bg-1 hme_btn">
                                        <button
                                          type="button"
                                          className="button w-100 next-btn"
                                          id="nextslideCl_006"
                                          value="Next"
                                          aria-label="submitid"
                                          name="subRegNo6"
                                          onClick={postcodeCheck}
                                          // data-bs-toggle="modal"
                                          // data-bs-target="#pre_add"
                                        >
                                          PROCEED
                                        </button>
                                      </div>
                                    </div>
                                    <div className="col-12 text-center tiny_note">
                                      By submitting your details and proceeding
                                      from this point you agree to our{" "}
                                      <a href={PrivacyPolicy} target="_blank">
                                        <u>Privacy Policy</u>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {step === 6 && (
                              <div className="slide_2Dec">
                                <h2 className="animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                                  We require information regarding the lender
                                  you used to buy your car.
                                </h2>

                                <div className="px-3 py-3 widget_mobi borderx">
                                  <h3 className="sub_quesHead animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                                    Please select lenders you've secured car finance between 2007 & 2020{" "}
                                    <br className="d-block d-sm-none" />
                                  </h3>
                                  <div style={{ color: "white" }}>
                                    <i>(You can select up to 4 lenders)</i>
                                  </div>
                                  <div className="form_drop col-lg-12 col-12">
                                    <div className="scale_blick_anim ">
                                      <div className="text-start">
                                        <div ref={autofocus}></div>
                                        <Select
                                          styles={customStyles}
                                          options={Finance.filter(
                                            (option) =>
                                              !formData.financeProvider.includes(
                                                option.value
                                              )
                                          )}
                                          className="w-100"
                                          value=""
                                          onChange={(e) =>
                                            handleFinanceProviderChange(e)
                                          }
                                          onFocus={toggleFocus}
                                          name="financeProvider"
                                          id="format"
                                          register={register}
                                          placeholder="Choose your finance providers"
                                          // isMulti={true}
                                        />

                                        {formData.financeProvider.map(
                                          (value, index) => {
                                            // Find the corresponding label for the current value
                                            const label =
                                              Finance.find(
                                                (finance) =>
                                                  finance.value === value
                                              )?.label || "Unknown";
                                            return (
                                              <div className="container ">
                                                <div
                                                  className="selected-options row "
                                                  key={index}
                                                >
                                                  <div className="col-10 col-sm-11">
                                                    <p>{label}</p>
                                                  </div>

                                                  <div
                                                    onClick={() =>
                                                      handleRemoveFinanceProvider(
                                                        index
                                                      )
                                                    }
                                                    className="col-2 col-sm-1 delete-option text-center d-flex align-items-center"
                                                  >
                                                    <p>x</p>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                      {finerror && (
                                        <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                                          {finerror}
                                        </p>
                                      )}
                                    </div>
                                  </div>

                                  <div
                                    className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s animated-element"
                                    id="subRegNo2"
                                  >
                                    <div className="row align-items-center">
                                      <div className="col-4 text-center">
                                        <button
                                          type="button"
                                          data-type="back"
                                          id="backStep-1"
                                          className="msf-nav-button backbutton"
                                          onClick={prevStep}
                                        >
                                          <img src={nextgreen} alt="" />
                                          Back
                                        </button>
                                      </div>
                                      <div className="col-8">
                                        <div className="bg-1 hme_btn">
                                          <button
                                            type="button"
                                            className="button w-100 next-btn"
                                            id="nextslideCl_002"
                                            value="Next"
                                            aria-label="submitid"
                                            name="subRegNo2"
                                            onClick={nextStep}
                                          >
                                            {" "}
                                            Next
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* // slide_3dec */}
                            {step === 7 && (
                              <div className="slide_2_1Dec">
                                <h2 className="animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                                  Did the dealer disclose their commission and
                                  how it may affect the interest rate on your
                                  loan?
                                </h2>
                                <div className="px-3 py-3 widget_mobi borderx">
                                  <div className="choose_Y_N col-lg-12 col-12 animate__animated animate__fadeInUp animate__delay-.5s">
                                    <div className="form-check px-0">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        aria-label="commission"
                                        name="commission"
                                        id="1"
                                        value={formData.commission}
                                        checked={formData.commission}
                                        onChange={handleChangeButton}
                                        onClick={nextStep}
                                        register={register}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="1"
                                      >
                                        No
                                      </label>
                                    </div>

                                    <div className="form-check px-0">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        aria-label="commission"
                                        name="commission"
                                        id="2"
                                        value={formData.commission}
                                        onClick={handleYesModal}
                                        register={register}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="2"
                                      >
                                        Yes
                                      </label>
                                    </div>
                                  </div>
                                  {commissionError && (
                                    <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                                      {commissionError}
                                    </p>
                                  )}

                                  <div
                                    className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s animated-element"
                                    id="subRegNo4"
                                  >
                                    <div className="row align-items-center">
                                      <div className="col-12 text-center ">
                                        <button
                                          type="button"
                                          data-type="back"
                                          id="backStep-3"
                                          className="msf-nav-button backbutton"
                                          onClick={prevStep}
                                        >
                                          <img src={nextgreen} alt="" />
                                          Back
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* // slide_4dec */}
                            {step === 8 && (
                              <div className="slide_2_2Dec">
                                <h2 className="animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                                  Are you currently in an IVA, bankruptcy, or
                                  other debt management arrangement?
                                </h2>
                                <div className="px-3 py-3 widget_mobi borderx">
                                  <div className="choose_Y_N col-lg-12 col-12 animate__animated animate__fadeInUp animate__delay-.5s">
                                    <div className="form-check px-0">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        aria-label="bankruptcy"
                                        name="bankruptcy"
                                        id="3"
                                        defaultValue="bankruptcy"
                                        checked={formData.bankruptcy}
                                        onChange={handleChangeButton}
                                        onClick={nextStep}
                                        register={register}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="3"
                                      >
                                        No
                                      </label>
                                    </div>

                                    <div className="form-check px-0">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        aria-label="bankruptcy"
                                        name="bankruptcy"
                                        id="4"
                                        defaultValue="bankruptcy"
                                        onClick={handleYesModal}
                                        register={register}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="4"
                                      >
                                        Yes
                                      </label>
                                    </div>
                                  </div>
                                  {bankError && (
                                    <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                                      {bankError}
                                    </p>
                                  )}

                                  <div
                                    className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s animated-element"
                                    id="subRegNo4"
                                  >
                                    <div className="row align-items-center">
                                      <div className="col-12 text-center ">
                                        <button
                                          type="button"
                                          data-type="back"
                                          id="backStep-3"
                                          className="msf-nav-button backbutton"
                                          onClick={prevStep}
                                        >
                                          <img src={nextgreen} alt="" />
                                          Back
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* // slide_5dec */}
                            {step === 9 && (
                              <div className="slide_2_3Dec">
                                <h2 className="animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                                  Have you already claimed?
                                </h2>
                                <div className="px-3 py-3 widget_mobi borderx">
                                  <div className="choose_Y_N col-lg-12 col-12 animate__animated animate__fadeInUp animate__delay-.5s">
                                    <div className="form-check px-0">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        aria-label="claimed"
                                        name="claimed"
                                        id="5"
                                        value={formData.claimed}
                                        checked={formData.claimed}
                                        onChange={handleChangeButton}
                                        onClick={nextStep}
                                        register={register}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="5"
                                      >
                                        No
                                      </label>
                                    </div>

                                    <div className="form-check px-0">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        aria-label="claimed"
                                        name="claimed"
                                        id="6"
                                        defaultValue="claimed"
                                        onClick={handleYesModal}
                                        register={register}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="6"
                                      >
                                        Yes
                                      </label>
                                    </div>
                                  </div>
                                  {claimError && (
                                    <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                                      {claimError}
                                    </p>
                                  )}

                                  <div
                                    className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s animated-element"
                                    id="subRegNo4"
                                  >
                                    <div className="row align-items-center">
                                      <div className="col-12 text-center ">
                                        <button
                                          type="button"
                                          data-type="back"
                                          id="backStep-3"
                                          className="msf-nav-button backbutton"
                                          onClick={prevStep}
                                        >
                                          <img src={nextgreen} alt="" />
                                          Back
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* // slide_6dec */}
                              <div className={`slide_2_3Dec ${step != 10 && "d-none"}`}>
                                <h2 className="animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                                  Why do you wish to seek profession help from us to make this claim?
                                </h2>
                                <div className="px-3 py-3 widget_mobi borderx">
                                  <div className="mb-2 row no100 choose_Y_N low_line_height animate__animated animate__fadeInUp animate__delay-.5s">
                                    <div className="form-check px-0 col-lg-12 col-12 w_full">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        aria-label="whyNotClaimed"
                                        name="whyNotClaimed"
                                        id="8"
                                        value="8"
                                        checked={
                                          formData.whyNotClaimed == "8"
                                            ? true
                                            : false
                                        }
                                        onChange={handleChangeButton}
                                        onClick={(e)=>{handleSubmit(e)}}
                                        ref={register}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="8"
                                      >
                                        I do not have the time
                                      </label>
                                    </div>
                                    <div className="form-check px-0 col-lg-12 col-12 w_full">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        aria-label="whyNotClaimed"
                                        name="whyNotClaimed"
                                        id="9"
                                        value="9"
                                        checked={
                                          formData.whyNotClaimed == "9"
                                            ? true
                                            : false
                                        }
                                        onChange={handleChangeButton}
                                        onClick={(e)=>{handleSubmit(e)}}
                                        ref={register}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="9"
                                      >
                                        I do not want to deal with the lender directly
                                      </label>
                                    </div>
                                    <div className="form-check px-0 col-lg-12 col-12 w_full">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        aria-label="whyNotClaimed"
                                        name="whyNotClaimed"
                                        id="10"
                                        value="10"
                                        checked={
                                          formData.whyNotClaimed == "10"
                                            ? true
                                            : false
                                        }
                                        onChange={handleChangeButton}
                                        onClick={(e)=>{handleSubmit(e)}}
                                        ref={register}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="10"
                                      >
                                        I would prefer professional help
                                      </label>
                                    </div>
                                    {/* <div className="form-check px-0 col-lg-12 col-12 w_full">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        aria-label="whyNotClaimed"
                                        name="whyNotClaimed"
                                        id="11"
                                        value="11"
                                        checked={
                                          formData.whyNotClaimed == "11"
                                            ? true
                                            : false
                                        }
                                        onChange={handleChangeButton}
                                        onClick={(e)=>{handleSubmit(e)}}
                                        ref={register}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="11"
                                      >
                                        Other
                                      </label>
                                    </div> */}
                                  </div>

                                  <div
                                    className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s animated-element"
                                    id="subRegNo4"
                                  >
                                    <div className="row align-items-center">
                                      <div className="col-12 text-center ">
                                        <button
                                          type="button"
                                          data-type="back"
                                          id="backStep-3"
                                          className="msf-nav-button backbutton"
                                          onClick={prevStep}
                                        >
                                          <img src={nextgreen} alt="" />
                                          Back
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`img_float animate__animated animate__fadeInLeft animate__delay-1s order-lg-2 order-md-2 order-1 col-lg-6 col-12 text-center ${
                          step !== 1 && " must_hide_at_mobile"
                        }`}
                      >
                        <img
                          src={bg_float}
                          alt="Hero"
                          className="cs_hero_img"
                        />
                      </div>
                      <div className="col-12 order-3">
                        {step === 1 && (
                          <div
                            className="box_button pt-3 animate__animated animate__fadeInLeft animate__delay-1s animated-element wht_iFNot no_border"
                            id="subRegNo1"
                          >
                            <div className="bg-1 hme_btn">
                              <input
                                type="button"
                                className="button button--moma button--text-thick button--text-upper button--size-s blick_notice"
                                id="nextPlate2_001"
                                defaultValue="EXPRESS REGISTRATION"
                                aria-label="submitid"
                                name="subRegNo1"
                                onClick={nextStep}
                              />
                            </div>
                          </div>
                        )}
                        <p
                          className={`mt-2 fw-semibold text-white text-center nimate__animated animate__fadeInLeft animate__delay-1s animated-element d-block d-md-none mb-2 ${
                            step !== 1 && " must_hide_at_mobile"
                          }`}
                        >
                          Quick, easy, fully online registration.
                        </p>
                        <div className="mob_view animate__animated animate__fadeInLeft animate__delay-1s">
                          <img src={review_star} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* form section */}
            </div>
            <div className="img_secure text-center py-4 d-none">
              <img src={secure} alt="" />
            </div>
            {/* row end */}
          </div>
        </section>
        {showModal && (
          <div
            className="modal fade show"
            id="pre_add"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="pre_addLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s ">
                <div className="modal-body d-block">
                  {!showSecondSection && (
                    <div className="form_drop yes_no_sec col-lg-12 col-12 p-3">
                      <div
                        className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s"
                        style={{ zIndex: 2, position: "relative" }}
                      >
                        <p className="png_t">
                          Did you register with your lender at a different/
                          previous address than your current address?
                        </p>
                        <div className="choose_Y_N col-lg-12 col-12 animate__animated animate__fadeInUp animate__delay-.5s">
                          <div className="form-check px-0">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="Radioshere2"
                              id="Radios2"
                              defaultValue="optionclick1"
                              onClick={noClick}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="Radios2"
                            >
                              No
                            </label>
                          </div>
                          <div className="form-check px-0">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="Radioshere2"
                              id="Radios1"
                              defaultValue="optionclick2"
                              onClick={handleYesClick}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="Radios1"
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    className={`p-lg-4 p-md-3 p-sm-2 p-2 enter_postcode_mod ${
                      showSecondSection == true ? "d-block" : "d-none"
                    }`}
                  >
                    <div className=" pt-0  animate__animated animate__fadeInUp animate__delay-.5s">
                      <p className="png_t">Enter your previous postcode</p>
                      <label>Postcode</label>
                      <div className="postcode_wrap d-block">
                        <PreviousPostcode_PCP_HDSC_V1_5T_page
                          validation={register}
                          validationMsg={errors}
                          trigger={trigger}
                          clearErrors={clearErrors}
                          setError={setError}
                        />
                      </div>
                      <p className="tiny_note mt-2">
                        Your address is required for a smooth claim process
                      </p>
                      <div className="col-lg-5 col-12"></div>
                    </div>
                    <div className="modal-footer">
                      <div className="box_button pt-3 final_sub pt-3 animate__animated animate__fadeInUp animate__delay-.5s">
                        <div className="bg-1">
                          <button
                            type="button"
                            className="button  w-100 button--moema button--text-thick button--text-upper button--size-s h60_click blick_notice"
                            id="nextPlate_007"
                            onClick={submitAddress}
                          >
                            Submit
                            <svg
                              width={27}
                              height={28}
                              viewBox="0 0 27 28"
                              fill="none"
                            >
                              <mask
                                id="mask0_695_171"
                                style={{ maskType: "alpha" }}
                                maskUnits="userSpaceOnUse"
                                x={0}
                                y={0}
                                width={27}
                                height={28}
                              >
                                <rect
                                  x="0.708008"
                                  y="0.813721"
                                  width="26.29"
                                  height="26.29"
                                  fill="#D9D9D9"
                                />
                              </mask>
                              <g mask="url(#mask0_695_171)">
                                <path
                                  d="M17.5751 15.4101H4.56348V12.5072H17.5751L11.7815 6.71327L13.8532 4.66895L23.1429 13.9586L13.8532 23.2483L11.7815 21.204L17.5751 15.4101Z"
                                  fill="#fff"
                                />
                              </g>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
      <div
        className={`modal fade ${formModalTwo && "show d-block"}`}
        id="CarDate"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="pre_addLabel"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s ">
            <div className="modal-header p-1">
              <button
                type="button"
                onClick={cancelpopupTwo}
                className="btn-close"
              ></button>
            </div>

            <div className="modal-body text-center">
              <div className="form_drop yes_no_sec col-lg-12 col-12 p-1">
                <div
                  className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s"
                  style={{ zIndex: 2, position: "relative" }}
                >
                  <p className="png_t">
                    Sorry, we will not be able to help with this claim.
                  </p>
                </div>
                <div className="button-group">
                  <button
                    type="button"
                    className="bt_cancel"
                    onClick={cancelpopupTwo}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${formModal && "show d-block"}`}
        id="CarDate"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="pre_addLabel"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s ">
            <div className="modal-header p-1">
              <button
                type="button"
                onClick={cancelpopup}
                className="btn-close"
              ></button>
            </div>

            <div className="modal-body text-center">
              <div className="form_drop yes_no_sec col-lg-12 col-12 p-1">
                <div
                  className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s"
                  style={{ zIndex: 2, position: "relative" }}
                >
                  <p className="png_t">You can only select up to 4 lenders</p>
                </div>
                <div className="button-group">
                  <button
                    type="button"
                    className="bt_cancel"
                    onClick={nextStep}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PreviousNameModal
        showPreviousNameModal={showPreviousNameModal}
        setShowPreviousNameModal={setShowPreviousNameModal}
        setFormData={setFormData}
        nextStep={nextStep}
      />
    </div>
  );
};

export default PCP_HDSC_V1_5T_Form;