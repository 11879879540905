import React, { useState } from 'react'
import FooterLogo from "../../../../assets/img/footer-logo.png";
import Accordion from './Accordion';
import PrivacyModal from '../../Layouts/PCP_HDSC_V1/PrivacyModal';
import TermsModal from '../../Layouts/PCP_HDSC_V1/TermsModal';
import ContractModal from '../../Layouts/PCP_HDSC_V1/ContractModal';

const Footer = () => {
  const [privacyModal, setPrivacyModal] = useState(false);
  const [termsModal, setTermsModal] = useState(false);
  const [contractModal, setContractModal] = useState(false);
  return (
    <>
      <div className="footer">
        <div className="bottom-section">
          <section className=" featured   py-lg-4 py-md-4 py-sm-4 py-5 ">
            <div className="container">
              <div className=" title-sec">
                <div className="col-lg-12  text-lg-center text-md-center  text-sm-center text-start">
                  <h2 className="pt-lg-5 pt-md-3 pt-sm-3 pt-0 text-center"> The mis-sold car finance scandal has featured in </h2>
                  <div className="row py-lg-5 py-md-5 py-sm-4 py-3">
                    <div className="col-lg-4 mb-3">
                      <div className="box-featured">
                        <img src="https://dev.hendersonclaims.com/static/media/bbc.199e481b.png" alt="" />
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <div className="box-featured">
                        <img src="https://dev.hendersonclaims.com/static/media/guardian.e04c695e.png" alt="" />
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <div className="box-featured">
                        <img src="https://dev.hendersonclaims.com/static/media/telegraph-white.fc3bbaf9.png" alt="" />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="bottom-section  ">
          <Accordion />
          <footer className="py-lg-5 py-md-5 py-sm-4 py-4">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 tex-lg-start mb-3 text-lg-start text-md-start text-sm-center text-center">
                  <div className="ft-logo"> <img src={FooterLogo} height="70" alt="" /></div>
                </div>
                <div className="col-lg-9">
                  <p className="text-lg-start text-lg-start text-sm-center text-center">Hendersons is a trading style of Henderson Claims Limited.
                    Henderson Claims Limited is authorised and regulated by
                    the Financial Conduct Authority with reference number
                    FRN:836041. Henderson Claims Limited is a Claims
                    Management Company (CMC). Registered with the Information
                    Commissioner's Office: registration number ZA147049.
                    Registered address: Grosvenor House Part Ground Floor (Rear Wing), St Thomas Place, Stockport, United Kingdom, SK1 3TZ. Registered in
                    England and Wales, Company number 07646017. VAT number:
                    240 8769 88.</p>
                  <p>
                    <a target='_blank' href="https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf" className='link-style'>*fca.org.uk</a>
                  </p>
                  <p>
                    <b>© 2023</b>
                  </p>
                  <ul className="m-0 p-0  text-lg-start text-lg-start text-sm-center text-center ">
                    <li><a onClick={() => setPrivacyModal(true)}>Privacy Policy</a></li>
                    <li><a onClick={() => setTermsModal(true)}>Terms &amp; Conditions</a></li>
                    <li><a onClick={() => setContractModal(true)}>Pre-contract Information</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
        </div>
        <PrivacyModal
          privacyModal={privacyModal}
          setPrivacyModal={setPrivacyModal}
        />
        <TermsModal
          termsModal={termsModal}
          setTermsModal={setTermsModal}
        />
        <ContractModal
          contractModal={contractModal}
          setContractModal={setContractModal}
        />
      </div>
    </>
  )
}

export default Footer