import React from "react";
import Button from "../../../UI/Button";

const LenderModal = (props) => {
  const okButton = (e) =>{
    props.setFormModal(false);
    props.slideChange(e)
  }
  return (
    <div>
      <div
        className={`modal fade ${props.formModal && "show d-block"}`}
        id="CarDate"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="pre_addLabel"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s ">
            <div className="modal-header p-1">
              <Button
                type="button"
                className="btn-close"
                onClick={props.cancelpopup}
              />
            </div>

            <div className="modal-body text-center">
              <div className="form_drop yes_no_sec col-lg-12 col-12 p-1">
                <div
                  className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s"
                  style={{ zIndex: 2, position: "relative" }}
                >
                  <p className="png_t">You can only select up to 4 lenders</p>
                </div>
                <div className="button-group">
                  <Button
                    type="button"
                    className="bt_cancel"
                    buttonText="Next"
                    name="LenderClick"
                    onClick={okButton}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LenderModal;
