import React, { useEffect, useState } from 'react'
import banner_img2 from "../../../../assets/PCP_HDSC_V5/img/banner-img2.png"
import ssl from "../../../../assets/PCP_HDSC_V5/img/ssl.png"
import { Finance as UnsortedFinance } from '../../../../Constants/ConstantsPCP'
import Select from 'react-select';
import CheckBox_span_inside_label_V5 from '../PCP_HDSC_V3/CheckBox_span_inside_label_V5';
import infotext from "../../../../assets/PCP_HDSC_V5/img/circle-info-text.png"
import Button from '../../../UI/Button';
import GTMDataLayer from "../../../../Utility/GTMDataLayer";

const Finance = UnsortedFinance.sort((a, b) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
});

const Lender = (props) => {
  const [banks, setBanks] = useState([]);
  const [remainingBanks, setRemainingBanks] = useState([]);
  const [additionalBanks, setAdditionalBanks] = useState([]);
  const [selectedBanks, setSelectedBanks] = useState([]);
  const [validationError, setValidationError] = useState(false);

  const highlightedBankValues = [6, 39, 61, 68];
  useEffect(() => {
    const highlightedBank = Finance.filter(bank => highlightedBankValues.includes(parseInt(bank.value)));

    const orderedBanks = highlightedBankValues.map(value => highlightedBank.find(bank => parseInt(bank.value) === value));
    setBanks(orderedBanks);

    setRemainingBanks(Finance.filter(bank => !highlightedBankValues.includes(parseInt(bank.value))));
  }, []);

  useEffect(() => {
    console.log(selectedBanks);
  }, [selectedBanks]);

  const handleSelectChange = async (selectedOption) => {
    let result = await props.trigger('lender');
    setValidationError(false);
    const selectedBankId = parseInt(selectedOption.value, 10);

    const selectedBank = remainingBanks.find(
      (bank) => bank.value == selectedBankId
    );
    if (selectedBank) {
      setAdditionalBanks((prev) => [...prev, selectedBank]);
      setRemainingBanks((prev) =>
        prev.filter((bank) => bank.value != selectedBankId)
      );
      setSelectedBanks((prev) => [...prev, selectedBank]);
    }
  };

  const handleCheckboxChange = (event) => {
    setValidationError(false);
    const { id, checked } = event.target;
    const bank =
      banks.find((bank) => bank.value == id) ||
      additionalBanks.find((bank) => bank.value == id);
    if (checked && bank) {
      setSelectedBanks((prev) => [...prev, bank]);
    } else {
      setSelectedBanks((prev) =>
        prev.filter((selectedBank) => selectedBank.value != bank.value)
      );
    }
  };

  const nextClick = (e) => {
    if (selectedBanks.length === 0) {
      setValidationError(true);

    } else {
      setValidationError(false);
      GTMDataLayer({
        question: 'select lender',
        answer: null,
      });
      localStorage.setItem(
        "selectedBanks",
        JSON.stringify(
          selectedBanks.map((bank) => ({
            label: bank.label,
            value: bank.value,
            slug: bank.slug,
            is_doc_required: bank.id_required,
            car_reg: bank.car_reg || false
          }))
        )
      );
      props.setPersonalDetailsModal(true);
    }
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: "56px",
      border: "1px solid #e7b222",
    }),
  };

  return (
    <>
      {props.currentPage === "Lender" && (
        <div className="home_plate_001">
          <div className="row">
            <div className="cs_hero_text col-lg-6 col-12 order-lg-1 order-md-1 order-md-2 order-sm-2 order-2">
              <div
                className="plateOne_anim mt-lg-0 mt-md-5 mt-sm-5 mt-0 "
                id="plate_1"
              >
                <h1 className="pt-0 cs_hero_title text-lg-start text-md-center text-sm-center text-center animate__animated animate__fadeInUp  animate__delay-1s   cs_fs_94  ">
                  Have You Bought A Car On Finance?
                </h1>
                <h2 className="banner-subtext animate__animated animate__fadeInUp animate__delay-1s text-lg-start  text-sm-center  text-center">
                  Find out if your lender owes you <strong>£1,000 </strong> 's in compensation.
                </h2>
                <div className="d-lg-none d-md-block d-sm-block  text-center  my-md-5 my-sm-5 my-4">
                  <img
                    src={banner_img2}
                    alt="Hero"
                    className="img-fluid px-lg-4 banner-img"
                    style={{ marginBottom: "auto" }}
                  />
                </div>
                <h5 className=" mt-lg-3 mt-md-3 mt-sm-4 mt-4 mb-lg-3 mb-md-3 mb-3 fs-lg-5 fs-md-5 fs-sm-6 fs-6 text-lg-start  text-sm-center  text-center animate__animated animate__fadeInUp animate__delay-1s ">
                  Which lender(s) have you taken our finance with between 2007 and 2021 that did not disclose their commission?
                </h5>
                {/* slide2 lenderslist*/}
                <div className="lenderpopslide2">
                  <div className="selectoptions animate__animated animate__fadeInUp animate__delay-.5s">
                    {banks.map((bank, index) => (
                      <div key={index} style={{ position: "relative", zIndex: "1" }}>
                        <CheckBox_span_inside_label_V5
                          className="custom-checkbox"
                          name="lender"
                          value={bank.label}
                          checked={selectedBanks.some(
                            (selectedBank) => selectedBank.value == bank.value
                          )}
                          onChange={handleCheckboxChange}
                          id={bank.value}
                          labelClassName=""
                          spanClassName="option_title title_1"
                          labelText={bank.label}
                          myRef={props.validation({
                            required: "Please Select Title",
                          })}
                        />
                      </div>
                    ))}
                    <div style={{ position: 'relative', zIndex: 0 }} className="mb-3  animate__animated animate__fadeInLeft animate__delay-.5s">
                      <Select
                        styles={customStyles}
                        className="h-3 z-10"
                        options={remainingBanks}
                        name="lender"
                        id="lender"
                        value=""
                        placeholder="Check for your lender(s)"
                        onChange={handleSelectChange}
                        register={props.validation}
                      />

                    </div>
                    {/* option select view lender */}
                    <div className="col-12 d-inline-block">
                      {/* <div className="l1_lender anotherlenderoptions"> */}
                      {additionalBanks.map((bank, index) => (
                        <div
                          key={index}
                          className="l2_lender anotherlenderoptions"
                        >
                          <CheckBox_span_inside_label_V5
                            className=""
                            name="lender"
                            value={bank.label}
                            checked={selectedBanks.some(
                              (selectedBank) => selectedBank.value == bank.value
                            )}
                            onChange={handleCheckboxChange}
                            id={bank.value}
                            labelClassName="selected-false"
                            spanClassName="option_title title_1"
                            labelText={bank.label}
                            myRef={props.validation({
                              required: "Please Select Title",
                            })}
                          />
                        </div>
                      ))}
                      {/* </div> */}
                    </div>
                    <div className="is_sticky col-lg-12 text-lg-end mt-2 col-12 box_button animate__animated animate__fadeInLeft animate__delay-.5s">
                      {validationError && (
                        <span className="error_msg">Select at least one lender</span>
                      )}

                    <Button
                        type="button"
                        className="pulse w-100 button button--moema button--text-thick button--text-upper button--size-s h60_click blick_notice"
                        buttonText={
                          <span>Next</span>
                        }
                        id="nextPlate2_001"
                        onClick={nextClick}
                      />
                    </div>
                  </div>
                </div>
                <ul className="secur-ul d-lg-flex text-center ps-0 ms-0 my-4 animate__animated animate__fadeInUp animate__delay-1s">
                  <li className="order-lg-2 mb-lg-0 mb-3 ps-lg-3">
                    {" "}
                    <i className="bi bi-clock-history" /> Takes only 60
                    Seconds
                  </li>
                  <li className="order-lg-1">
                    <div className="    ">
                      <img height={45} src={ssl} alt="" />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="img_float animate__animated animate__fadeInUp order-lg-2 order-md-1 order-sm-1 order-1 col-lg-6 col-12 text-center">
              <img
                src={banner_img2}
                alt="Hero"
                className="d-lg-block d-md-none d-none text-center   img-fluid px-lg-4 banner-img"
                style={{ marginBottom: "auto" }}
              />
              <div className="banner-wrap d-none">
                <div className="text-cicle d-none">
                  <img
                    className="rotate"
                    src={infotext}
                    alt=""
                  />
                  <div className="amount">£300m*</div>
                </div>
                <div className="shape1" />
                <div className="shape2" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Lender