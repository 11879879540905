import React from "react";
import nextgreen from "../../../../assets/PCP_HDSC_V1/img/next-green.png";
import RadioButtonV1 from "../../../UI/RadioButtonV1";
import Button from "../../../UI/Button";

const BankruptcyQuestion = ({
  formData,
  handleYesModal,
  className,
  slideChange,
  previousSlideChange,
  register,
  handleChangeButton
}) => {
  return (
    <div className={`slide_2_2Dec ${className}`}>
      <h2 className="animate__animated animate__fadeInUp animate__delay-.5s animated-element pt-5 pt-md-3">
        Are you currently in an IVA, bankruptcy, or other debt management arrangement?
      </h2>
      <div className="px-3 py-3 widget_mobi borderx">
        <div className="choose_Y_N col-lg-12 col-12 animate__animated animate__fadeInUp animate__delay-.5s">
          <div className="form-check px-0">

              <RadioButtonV1
              className="form-check-input"
              type="radio"
              aria-label="bankruptcy"
              name="bankruptcy"
              id="3"
              value="no"
              defaultChecked={formData.bankruptcy === '3'}
              onClick={handleChangeButton}
              labelName="No"
              register={register}
              
            />
          </div>

          <div className="form-check px-0">
          <RadioButtonV1
              className="form-check-input"
              type="radio"
              aria-label="bankruptcy"
              name="bankruptcy"
              id="4"
              value="yes"
              defaultChecked={formData.bankruptcy === '4'}
              onClick={handleYesModal}
              labelName="Yes"
              register={register}
            />
          </div>
        </div>
        {/* {bankError && (
          <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
            {bankError}
          </p>
        )} */}

        <div
          className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s animated-element"
          id="subRegNo4"
        >
          <div className="row align-items-center">
            <div className="col-12 text-center ">
              <Button
              type="button"
              name="back03"
              data-type="back"
              id="backStep-3"
              className="msf-nav-button backbutton"
              onClick={() =>previousSlideChange('back03')}
              buttonText={
                <span className="btntext"><img src={nextgreen} alt="" />
                  Back <i className="arrowicon"></i>
                </span>
              }

            />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankruptcyQuestion;
