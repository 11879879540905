import React, { useState } from "react";
import "../../../assets/PCP_HDSC_V3/css/custom.scss";
import Header from "../../Includes/Layouts/PCP_HDSC_V3/Header";
import Footer from "../../Includes/Layouts/PCP_HDSC_V3/Footer";
import BannerImage from "../../../assets/img/bannerimage.png";
import AngleArrow from "../../../assets/img/angle-arrow.svg";
import secoImage from "../../../assets/img/60seco.png";
import Button from "../../UI/Button";
import PCP_HDSC_V3Form from "../../Forms/PCP_HDSC_V3/PCP_HDSC_V3Form";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import FbViewPixel from "../../../Utility/FbViewPixel";
import Loader from "../../Includes/Layouts/PCP_HDSC_V3/Loader";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";
import PrivacyModal from '../../Includes/Layouts/PCP_HDSC_V1/PrivacyModal';
import TermsModal from '../../Includes/Layouts/PCP_HDSC_V1/TermsModal';
import Cookiespolicy from '../../Includes/Layouts/PCP_HDSC_V1/Cookiespolicy';
import ContractModal from '../../Includes/Layouts/PCP_HDSC_V1/ContractModal';
import ComplaintsProcedure from "../../../docs/Complaints Procedure - Hendersons - June 2024.pdf";
import PrivacyPolicy from "../../../docs/Henderson Claims - Privacy Policy - June 2024.6bf93601.pdf";

const PCP_HDSC_V3 = () => {

  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit(
    "PCP_HDSC_V3",
    "PCP_HDSC_V3"
  );
  const [personalDetailsSection, setPersonalDetailsSection] = useState('d-none');
  const [loader, setLoader] = useState('d-none');

  const showPersonalDetails = (e) => {
    window.scrollTo({ top: 720, behavior: 'smooth' });
    setPersonalDetailsSection(e);
  }
  const showLoader = (e) => {
    setLoader(e);
  }
  const [showCookie, setShowCookie] = useState('show');
  const [termsModal, setTermsModal] = useState(false);
  const [contractModal, setContractModal] = useState(false);
  const [showCookiesPolicy, setShowCookiesPolicy] = useState(false);
  const cookieName = "CookiesPolicyHide";

  const cookieClose = (e) => {
    e.preventDefault();
    setShowCookie('hide');
    if (cookieName != null) {
      const d = new Date();
      const cvalue = "Yes";
      d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
      const expires = "expires=" + d.toUTCString();
      document.cookie = cookieName + "=" + cvalue + "; " + expires;
    }
  };

  const handleCookieShow = () => {
    setShowCookiesPolicy(true);
  };

  const handleCookieHide = () => {
    setShowCookiesPolicy(false);
  };
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="PCP_HDSC_V3" />
      <FbViewPixel />
      <div className="pcpv3-home">
        <Header bannerShow = {true}/>
        <section className="bg-gray p-2 home-top">
          <div className="bg-inner bg-white rounded-2 p-lg-5 p-md-5 p-sm-4 p-3 pt-lg-0 pt-md-0 pt-sm-0 pt-3 ">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 offset-lg-3">
                  <div className="home-banner">
                    <div className="bnr-text">
                      <div className="text-end payout-wrap mt-lg-4 mt-md-4 mt-sm-4 mt-0">
                        <div className="payout-inner px-3 ">
                          <h2 className="payout text-end ">Consumers Overpaid Annually <span
                            className="fw-bold  amount animate__animated animate__fadeInUp ">
                            £300m* </span></h2>
                        </div>
                      </div>
                      <div className="row align-items-center">
                        <div className="col-lg-12">
                          <div className="main-text">
                            <div className="overflow-hidden">
                              <h1
                                className="my-lg-2 my-md-2 my-sm-2 my-2 drop-in text-lg-center text-md-center text-sm-center text-start">
                                Have You Bought A Car On Finance?
                              </h1>
                            </div>
                          </div>

                          <div className="overflow-hidden">
                            <p className=" drop-in-2 text-lg-center text-md-center text-sm-center text-start">Find out if your
                              lender owes you <span className="fw-bold"> £1,000 </span>'s in compensation.</p>
                          </div>
                          <h5 className="text-center mb-2">
                          </h5>
                          <div
                            className="mb-2 mt-lg-2 mt-md-2 mt-sm-3 mt-2 text-lg-start  text-md-start text-sm-start text-center animate__animated animate__fadeInUp ">
                            <Button
                              type="button"
                              className="btn-comm2 btn-start pulse w-100"
                              buttonText={
                                <span className="btntext">
                                  Express Registration <i className="arrowicon"><img src={AngleArrow} alt="" /></i>
                                </span>
                              }
                              id="btnForm"
                              onClick={(e) => showPersonalDetails('d-block')}
                            />
                          </div>
                          <div className="overflow-hidden mt-4">
                            <div className="d-flex align-items-center secondlabel my-2 drop-in-2">
                              <div className="pe-2"> <img height="40" src={secoImage} alt="" /> </div>
                              <div className="text-start">It’s easy to check if you may have a claim in just 60 seconds</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="bnr-img my-lg-0 my-md-0 my-sm-2 my-4 mb-5">
                            <img height="400" src={BannerImage} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <PCP_HDSC_V3Form
          personalDetailsSection={personalDetailsSection}
          showLoader={showLoader}
          showPersonalDetails={showPersonalDetails}
          splitName={DynamicRouteSplitName}
          nextPage={DynamicRouteNextPage}
        />
        <Loader
          loader={loader}
        />
         <div>
      <footer>
        <div className="container text-center">
          <div className="ftr_cntnt">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-12">
                <p>
                  Hendersons is a trading style of Henderson Claims Limited.
                  Henderson Claims Limited is authorised and regulated by
                  the Financial Conduct Authority with reference number
                  FRN:836041. Henderson Claims Limited is a Claims
                  Management Company (CMC). Registered with the Information
                  Commissioner's Office: registration number ZA147049.
                  Registered address: Grosvenor House Part Ground Floor (Rear Wing), St Thomas Place, Stockport, United Kingdom, SK1 3TZ. Registered in
                  England and Wales, Company number 07646017. VAT number:
                  240 8769 88.
                </p>
                <p>
                  <a target='_blank' href="https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf" className='link-style'>*fca.org.uk</a>
                </p>
                <p>
                  <b>© 2023</b>
                </p>
              </div>
            </div>
            <div className="text-center">
              <div className="copyright">
                <ul>
                  <li>
                    <a
                      href={PrivacyPolicy} target="_blank"
                    >Privacy Policy</a>
                  </li>
                  <li>
                    <a
                      onClick={() => setTermsModal(true)}
                    >Terms &amp; Conditions</a>
                  </li>
                  <li>
                    <a
                      onClick={() => setContractModal(true)}
                    >Pre-contract Information</a>
                  </li>
                  <li>
                    <a
                      href={ComplaintsProcedure} target="_blank"
                    >Complaints Procedure</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div id="cookieBar" className={`cooki cookie3 text-center ${showCookie}`}>
        <div className="col-lg-12 col-md-12 col-12 col-sm-12">
          <span>We use cookies to analyse traffic to this site and to give you the best experience. By clicking 'Accept' you agree to our use of cookies. To find out more about our use of cookies and how to refuse them, please read our Privacy Policy.
            <a href="#" className="rp_clk text-underline" onClick={handleCookieShow}><u>Learn More.</u></a>
          </span>
          <a className="rp_clk closecook text-danger" href="#" onClick={cookieClose}>Close <strong>X</strong></a>
        </div>
        <div className="clearfix"></div>
      </div>
      <TermsModal
        termsModal={termsModal}
        setTermsModal={setTermsModal}
      />
      <ContractModal
        contractModal={contractModal}
        setContractModal={setContractModal}
      />
      <Cookiespolicy
        showstatus={showCookiesPolicy}
        hidepopup={handleCookieHide}
      />
    </div>
      </div>
    </>
  );
};

export default PCP_HDSC_V3;
