import React, { useEffect, useState } from 'react'
import logo from "../../../../assets/PCP_HDSC_V5/img/logo.png"

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = (event) => {
    event.preventDefault();
  };
  return (
    <header id="header" className={`sticky-header ${isScrolled && "sticky"}`}>
      <div className="container">
        <div
          className="topnav justify-content-lg-start justify-content-center"
          id="myTopnav"
        >
          <a href="#" onClick={handleClick}>
            <img className="logo" src={logo} alt="" />
          </a>
        </div>
      </div>
    </header>
  )
}

export default Header