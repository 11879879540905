import React from 'react'
import HeaderLogo from "../../../../assets/PCP_HDSC_V3/img/hendersons_logo.png";

const Header = ({bannerShow}) => {
  return (
    <>
      <section className="header-wrap text-center">
      {bannerShow && (<div className="p-1 green-sec" style={{ position: "relative" }}>Did you know?<span className='mx-1'>🔊</span> Henderson Claims is a Claims Management Company (CMC). You can claim for free, without using a CMC, first to your lender or to the FOS/FSCS.</div>)}
        <div className="logo py-3">
          <img height="40" src={HeaderLogo} alt="" />
        </div>
      </section>
    </>
  )
}

export default Header