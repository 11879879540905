import React from 'react'
import loader03 from "../../../../assets/PCP_HDSC_V5/img/loader03.svg"

const PageLoader = ({ pageLoader, analysingText, style }) => {
  return (
    <>
      <div style={style} className={`loader-div loadercar loader-fix ${!pageLoader && "d-none"}`}>
        <div className="text-center">
          <img height={110} src={loader03} alt="" />
          {analysingText &&
            <h4>{analysingText}</h4>
          }
        </div>
      </div>
    </>
  )
}

export default PageLoader