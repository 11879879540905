import React, { useState, useContext, useEffect } from "react";
import info from "../../../../assets/PCP_HDSC_V1/img/info.png"
import { useDataIngestion } from "../../../../Hooks/useDataIngestion";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import QueryStringContext from "../../../../Contexts/QueryString";
import { useForm } from "react-hook-form";

const SignatureModal = ({
  showPrivacy,
  modalClick,
  closeModal,
  displayData
}) => {
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const storedFormData = localStorage.getItem('formData');
  const { queryString } = useContext(QueryStringContext);
  const userData = JSON.parse(storedFormData);
  const [titleError, settitleError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const { register, errors, trigger, clearErrors, getValues } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const [firstName, setFirstName] = useState(displayData.first_name);
  const [lastName, setLastName] = useState(displayData.last_name);
  const [salutation, setSalutation] = useState("");
  const [lstSalutation,setlstSalutation] = useState(displayData.title);

  useEffect(() => {
    setFirstName(displayData.first_name);
    setLastName(displayData.last_name);
    setlstSalutation(displayData.title);
  }, [displayData]);



  const handlefirstnameChange = (e) => {

    const value = e.target.value;
    if (/\d/.test(value)) {

      return;
    }
    setFirstName(value);

    if (!value.trim()) {
      setFirstNameError("Please enter the Firstname.")
      return;
    } else {
      setFirstNameError('');
    }
  }
  const handlelastnameChange = (e) => {

    const value = e.target.value;
    if (/\d/.test(value)) {

      return;
    }
    setLastName(value);

    if (!value.trim()) {
      setLastNameError("Please enter the lastname.")
      return;
    } else {
      setLastNameError('');
    }
  }
  const handletitleChange = (e) => {

    const value = e.target.value;
    if (/\d/.test(value)) {

      return;
    }
    setSalutation(value);

    if (!value.trim()) {
      settitleError("Please enter the Previous Surname.")
      return;
    } else {
      settitleError('');
    }
  }
  const updateUservalue = async () => {
    const updatedFormData = {
      ...displayData,
      txtFName: firstName,
      txtLName: lastName,
      previousName: salutation,
      lstSalutation:lstSalutation

    };

    if (!updatedFormData.lstSalutation) {
      settitleError("Please enter the salutation.")
      return;
    }
    if (!updatedFormData.txtFName) {
      setFirstNameError("Please enter the firstname.")
      return;
    }

    if (updatedFormData.txtFName.length < 3) {
      setFirstNameError("First name should be at least 3 characters long.")
      return;
    }
    if (!updatedFormData.txtLName) {
      setLastNameError("Please enter the lastname.")
      return;
    }
    if (updatedFormData.txtLName.length < 3) {
      setLastNameError("Last name should be at least 3 characters long.")
      return;
    }

    const storedFormData = localStorage.getItem('formData');

    //localStorage.setItem('formData', JSON.stringify(updatedFormData));


    const formSubmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      updatedFormData,
      "signature_form_update",
      "PCP_HDSC_V1",
      queryString,
      visitorParameters.data
    );
    if (formSubmitResult.data.status === "Success") {
      closeModal();
    }
  };

  return (
    <>
      <div className={`modal fade ${showPrivacy && "show d-block"}`} id="Add_preName" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1}
        aria-labelledby="Add_preNameLabel" aria-modal="true" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s ">
            {/* <div className="modal-header p-0">
              <h1 className="modal-title fs-5" id="add_dbLabel">
                <img src="dist/img/logo-blue.png" alt="" />
              </h1>
            </div> */}
            <div className="d-block modal-body">
              <div className="form_drop pt-2 drop_details col-lg-12 col-12">
                <p className="sty_pop">
                  Please update if your full name differs from
                  the one displayed
                </p>
                <p className="sty_pop">
                  {`${displayData.title} ${firstName} ${lastName}`}
                </p>
                <div className="row name_detail pt-0 animate__animated animate__fadeInUp animate__delay-.5s">
                  <div className="col-lg-6 col-md-6 col-12 f_name">
                    <label htmlFor="topmention1">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="topmention1"
                      placeholder="First name"
                      aria-label="First name"
                      value={firstName}
                      onChange={handlefirstnameChange}
                      defaultValue={displayData.first_name}
                    />
                    {firstNameError && (
                      <p className="animate__animated animate__fadeInUp animate__delay-.5s validate">
                        {firstNameError}
                      </p>
                    )}
                  </div>

                  <div className="col-lg-6 col-md-6 col-12 l_name">
                    <label htmlFor="topmention2">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="topmention2"
                      placeholder="Last name"
                      aria-label="Last name"
                      defaultValue={displayData.last_name}
                      value={lastName}
                      onChange={handlelastnameChange}
                    />
                    {lastNameError && (
                      <p className="animate__animated animate__fadeInUp animate__delay-.5s validate">
                        {lastNameError}
                      </p>
                    )}
                  </div>

                  <p className="tiny_note">
                    <img src={info} className="pe-1" alt="" />
                    What you are legally known as
                  </p>
                </div>
              </div>
              <div className="form_drop drop_details col-lg-12 col-12">
                <p className="sty_pop">
                  You have any Previous Name?(Leave blank if not applicable)
                </p>
                <div className="row name_detail pt-0 animate__animated animate__fadeInUp animate__delay-.5s">
                  <div className="col-lg-12 col-md-12 col-12 f_name">
                    <label htmlFor="topmention1">Previous Surname</label>
                    <input
                      type="text"
                      className="form-control"
                      id="topmention3"
                      placeholder="Previous Surname"
                      aria-label="First name"
                      onChange={handletitleChange}
                    />
                    {titleError && (
                      <p className="animate__animated animate__fadeInUp animate__delay-.5s validate">
                        {titleError}
                      </p>
                    )}
                  </div>

                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="box_button final_ent pt-3 animate__animated animate__fadeInUp animate__delay-.5s">
                <div className="bg-1 text-center">
                  <input
                    type="button"
                    className="button wb_5 button--moema button--text-thick button--text-upper button--size-s h60_click blick_notice"
                    id="nextUpdate_001"
                    value="Update"
                    onClick={updateUservalue} // Close modal on button click
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignatureModal;
