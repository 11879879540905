import React from 'react'
import SelectBox from '../../../UI/SelectBox';

import { Finance as UnsortedFinance } from "../../../../Constants/ConstantsPCP";
const highlightedBankValues = [6, 39, 61, 68];
// Filter the banks that match the highlighted values
const highlightedBank = UnsortedFinance.filter((bank) =>
  highlightedBankValues.includes(parseInt(bank.value))
);
// Order the highlighted banks according to highlightedBankValues
const highlightedBanksOrdered = highlightedBankValues.map((value) =>
  highlightedBank.find((bank) => parseInt(bank.value) === value)
);
// Get the remaining banks that are not highlighted and sort them alphabetically
const remainingBanks = UnsortedFinance.filter(
  (bank) => !highlightedBankValues.includes(parseInt(bank.value))
).sort((a, b) => a.label.localeCompare(b.label));
// Merge highlightedBanksOrdered and remainingBanks
const Finance = [...highlightedBanksOrdered, ...remainingBanks];

const Lender = (props) => {
  const handleLenderSelection = (e) => {
    const { name, value } = e.target;
    const selectedLender = Finance.find(lender => lender.value === value);
    const slug = selectedLender ? selectedLender.slug : null;
    props.setFormData(prevState => ({...prevState, [name]: value, providerSlug : slug}));
    const newSlug = [];
    localStorage.setItem('providerSlug', JSON.stringify([slug]));
    props.handleChange(e);
  }
  return (
    <>
      <div>
        <fieldset className="p-2 px-3 animate__animated animate__fadeInUp animate__delay-1s">
          <legend className="px-2 m-0 fw-bold">Which lender did you obtain your car loan from?</legend>
          <div className="mb-2 drop-down-multi">
            <SelectBox
              className="form-select"
              OptionValue={Finance}
              name="provider"
              id="provider"
              selectText="Select Bank"
              onChange={handleLenderSelection}
              myRef={props.validation({
                required: "Please Select Lender",
              })}
              validationMsg={props.validationMsg.provider?.message}
            />
          </div>
        </fieldset>
      </div>
    </>
  )
}

export default Lender