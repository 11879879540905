import React, { useReducer, useState, useContext } from "react";
import AddressTextField from "../../../UI/AddressTextField";
import AddressSelectBox from "../../../UI/AddressSelectBox";
import HiddenField from "../../../UI/HiddenField";
import Anchor from "../../../UI/Anchor";
import InputButton from "../../../UI/InputButton";
import { usePostCodeAddress } from "../../../../Hooks/usePostCodeAddress";
import ProgressBar from "../../Layouts/Split_1/ProgressBar";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import edit from "../../../../assets/PCP_HDSC_V1/img/edit.png"

const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "hide",
  getPostcode: "#",
  clickManualLink: 0,
};
const postcodeReducer = (state, action) => {
  switch (action.type) {
    case "validationSucess": {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "getExtraAddressDetails": {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "showManualDiv": {
      return {
        ...state,
        showManualAddress: "show",
        lookUp: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click,
      };
    }
    case "resetaddress": {
      return {
        ...state,
        getDetails: [],
        getAddress: [],
        showManualAddress: "hide",
        lookUp: true,
        nextClick: false,
        checkSelect: false,
      };
    }
    case "updateField": {
      return {
        ...state,
        getDetails: {
          ...state.getDetails,
          [action.payload.field]: action.payload.value
        }
      };
    }
    default:
      return state;
  }
};
const PostCode_PCP_HDSC_V1 = (props) => {
  const { getPostcodeValidation, getPostcodeAddress, getSplitPostcodeAddress } =
    usePostCodeAddress();
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const [postCodeAddress, setPostCodeAddress] = useState([]);
  const [postCodeEntry, setPostCodeEntry] = useState("0");
  const [manualEdit, setManualEdit] = useState(false);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const checkValidation = async (e) => {
    e.preventDefault();
    dispatch({
      type: "resetaddress",
      get_address: [],
    });
    const txtPostCode = await props.trigger("txtPostCode");
    if (txtPostCode) {
      await getPostcodeAddress(
        e.target.value,
        visitorParameters.visitor_parameters.uuid
      )
        .then((res) => {
          if (res.data.status === "0") {
            props.setError("txtPostCode", {
              type: "manual",
              message: "Please Enter Valid Postcode",
            });

            //setPostCodeError( "Please Enter Valid Postcode");
          }
          if (res.data.length > 0) {
            dispatch({
              type: "validationSucess",
              payload: {
                lookup: false,
                next_click: true,
                postcode: e.target.value,
                get_address: res.data,
                check_select: true,
                manual_address: "hide",
              },
            });
          }
        })
        .catch(function () {
          props.setError("txtPostCode", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
          // setPostCodeError("Please Enter Valid Postcode");
        });
    }
  };
  const getValue = async (e) => {
    props.handleChange(e);
    e.preventDefault();
    // document.getElementById("address1").classList.remove("anim_ylw");
    // document.getElementById("NextButton").classList.add("anim_ylw");
    props.clearErrors([
      "txtHouseNumber",
      "address1",
      "txtAddress2",
      "txtAddress3",
      "txtCounty",
      "txtTown",
      "txtCountry"
    ]);
    props.setPostCodeError("")
    if (e.target.value != "") {
      const getData = await getSplitPostcodeAddress(
        e.target.value,
        state.getPostcode,
        visitorParameters.visitor_parameters.uuid
      );
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: getData.data, manual_address: "show" },
      });
      setPostCodeAddress(getData.data);
      props.clearErrors([
        "txtHouseNumber",
        "txtAddress2",
        "txtCounty",
        "txtTown",
        "address1",
        "txtCountry",
      ]);
    } else {
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: [], manual_address: "hide" },
      });
      setPostCodeAddress([]);
    }
  };

  const lookupValidation = async (e) => {
    await props.trigger("txtPostCode");
    props.handleChange(e);
  };
  const handleChangeInsideOnChange = (e) => {
    props.handleChange(e);
  };

  const checkPostCodeEntry = () => {
    const addressLine1 = document.getElementById("txtHouseNumber").value;
    const addressLine2 = document.getElementById("txtAddress2").value;
    // const addressLine3_1 = document.getElementById("txtAddress3").value;
    const town = document.getElementById("txtTown").value;
    const county = document.getElementById("txtCounty").value;
    const country = document.getElementById("txtCountry").value;

    if (
      postCodeAddress.length === 0 ||
      postCodeAddress.Country !== country ||
      postCodeAddress.County !== county ||
      postCodeAddress.Line1 !== addressLine1 ||
      postCodeAddress.Line2 !== addressLine2 ||
      postCodeAddress.Town !== town
    ) {
      setPostCodeEntry("1");
    }
  };

  const addressValidation = async (e) => {
    let errorFlag = 0;
    const txtPostCode = await props.trigger("txtPostCode");
    var manual_txtHouseNumber;
    var manual_txtTown;
    var manual_txtCountry;
    var txtAddress;
    if (txtPostCode) {
      manual_txtHouseNumber = await props.trigger("txtHouseNumber");
      txtAddress = await props.trigger("address1");
    } else {
      document.getElementById("txtPostCode").focus();
      return false;
    }
    if (manual_txtHouseNumber) {
      manual_txtTown = await props.trigger("txtTown");
    } else {
      return false;
    }
    if (manual_txtTown) {
      manual_txtCountry = await props.trigger("txtCountry");
    } else {
      return false;
    }
    if (
      !txtPostCode ||
      !manual_txtHouseNumber ||
      !manual_txtTown ||
      !manual_txtCountry
    ) {
      errorFlag = 1;
    }

    if (state.clickManualLink === 0) {
      const address1 = await props.trigger("address1");
      if (!address1) {
        errorFlag = 1;
      }
    }
    if (errorFlag === 0) {
      checkPostCodeEntry();
      props.slideChange(e);
    } else {
      return false;
    }
  };
  const HiddenFields = () => (
    <>
      <HiddenField
        name="txtUdprn"
        inputId="txtUdprn"
        inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
      />
      <HiddenField
        name="txtDeliveryPointSuffix"
        inputId="txtDeliveryPointSuffix"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""
        }
      />
      <HiddenField
        name="txtPz_mailsort"
        inputId="txtPz_mailsort"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""
        }
      />
      <HiddenField
        name="txtStreet"
        inputId="txtStreet"
        inputValue={
          state.getDetails.line_3 !== "#" ? state.getDetails.line_3 : ""
        }
      />
    </>
  );

  const handlePostcodeChange = (e) => {
    const { name, value } = e.target;

    let fieldname = ""
    switch (name) {
      case "txtHouseNumber":
        fieldname = "line_1"
        break;
      case "txtAddress2":
        fieldname = "line_2"
        break;
      case "txtAddress3":
        fieldname = "line_3"
        break;
      case "txtCounty":
        fieldname = "county"
        break;
      case "txtTown":
        fieldname = "town"
        break;
      case "txtCountry":
        fieldname = "country"
        break;
      default:
        break;
    }
    dispatch({ type: "updateField", payload: { field: fieldname, value: value } });
  };

  return (
    <div>
      <HiddenField
        name="pCode_EntryType"
        inputId="pCode_EntryType"
        inputValue={postCodeEntry}
      />
      <div className="postcode_wrap">
        <AddressTextField
          type="text"
          placeholder="Your Postcode"
          name="txtPostCode"
          id="txtPostCode"
          className="form-control inputtttt"
          dataId="txtPostCode"
          onChange={handleChangeInsideOnChange}
          onBlur={checkValidation}
          autoComplete="off"
          validation={props.validation({
            required: "Please Enter Postcode",
            pattern: {
              value:
                /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
              message: "Please Enter a Valid Postcode",
            },
            minLength: {
              value: 5,
              message: "Please Enter a Valid Postcode",
            },
          })}
        />
        <button
          // style={{ float: "none" }}
          name="next"
          className="bg_add_click button"
          onClick={lookupValidation}
        >
          Find My Address
        </button>
      </div>
      {props.validationMsg.txtPostCode && (
        <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
          {props.validationMsg.txtPostCode.message}
        </p>
      )}
      <div
        className={`form-group col-lg-12 col-12 p-0 adj_width animate__animated animate__fadeInUp animate__delay-.5s animated-element`}
        id="currentAddressCollapse"
        style={state.checkSelect ? { display: "block" } : { display: "none" }}
      >
        <AddressSelectBox
          className="form-select mt-3"
          OptionValue={state.getAddress}
          name="address1"
          id="address1"
          onChange={getValue}
          myRef={props.validation({ required: "Please select address" })}
        // validationMsg={
        //   validationMsg.address1 && validationMsg.address1.message
        // }
        />
      </div>
      <div
        className="col-12 text-center"
        style={
          state.getDetails.length === 0
            ? { display: "block" }
            : { display: "none" }
        }
      >
      </div>
      <div id="selcetAdd" className={`slide8_add row mt-2 gy-2 ${state.showManualAddress}`}>
        {!manualEdit &&
          <div
            className="ADD_detail col-12 py-3 px-3 animate__animated animate__fadeInUp animate__delay-.5s animated-element"
            style={{ display: "block" }}
          >
            <p className="ADD_clr_typ">
              {state.getDetails.line_1 ? (<>{state.getDetails.line_1}<br /> </>) : null}
              {state.getDetails.line_2 ? (<>{state.getDetails.line_2}<br /> </>) : null}
              {state.getDetails.line_3 ? (<>{state.getDetails.line_3}<br /> </>) : null}
              {state.getDetails.county}<br />
              {state.getDetails.town}<br />
              {state.getDetails.country}<br />
            </p>
          </div>
        }
        <div className={`px-0 ${!manualEdit && "d-none"} col-lg-12 form-group required-field adj_width`}>
          <AddressTextField
            type="text"
            placeholder="Address Line 1 *"
            name="txtHouseNumber"
            id="txtHouseNumber"
            className="form-control address hero-input"
            dataId="txtHouseNumber"
            autoComplete="off"
            readonly={manualEdit ? false : true}
            validation={props.validation({
              required: "Please Enter Address Line 1",
            })}
            validationMsg={
              props.validationMsg.txtHouseNumber &&
              props.validationMsg.txtHouseNumber.message
            }
            value={state.getDetails.line_1 || ""}
            onChange={handlePostcodeChange}
          />
          <i
            className="validate validate_success"
            aria-hidden="true"
            style={{ display: "none" }}
          ></i>
        </div>

        <div className={`px-0 ${!manualEdit && "d-none"} col-lg-12 form-group adj_width`}>
          <AddressTextField
            type="text"
            placeholder="Address Line 2"
            name="txtAddress2"
            id="txtAddress2"
            className="form-control  address hero-input"
            dataId="txtAddress2"
            autoComplete="off"
            readonly={manualEdit ? false : true}
            validation={props.validation()}
            validationMsg=""
            value={state.getDetails.line_2 || ""}
            onChange={handlePostcodeChange}
          />
          <i
            className="validate validate_success"
            aria-hidden="true"
            style={{ display: "none" }}
          ></i>
        </div>
        <div className={`px-0 ${!manualEdit && "d-none"} col-lg-12 form-group adj_width`}>
          <AddressTextField
            type="hidden"
            placeholder="Address Line 3"
            name="txtAddress3"
            id="txtAddress3"
            className="form-control  address hero-input"
            dataId="txtAddress3"
            autoComplete="off"
            readonly={manualEdit ? false : true}
            validation={props.validation()}
            validationMsg=""
            value={state.getDetails.line_3 || ""}
            onChange={handlePostcodeChange}
          />
          <i
            className="validate validate_success"
            aria-hidden="true"
            style={{ display: "none" }}
          ></i>
        </div>

        <div className={`px-0 ${!manualEdit && "d-none"} col-lg-12 form-group adj_width`}>
          <AddressTextField
            type="text"
            placeholder="County"
            name="txtCounty"
            id="txtCounty"
            className="form-control  address hero-input"
            dataId="txtCounty"
            autoComplete="off"
            readonly={manualEdit ? false : true}
            validation={props.validation()}
            validationMsg=""
            value={state.getDetails.county || ""}
            onChange={handlePostcodeChange}
          />
          <i
            className="validate validate_success"
            aria-hidden="true"
            style={{ display: "none" }}
          ></i>
        </div>

        <div className={`px-0 ${!manualEdit && "d-none"} col-lg-12 form-group adj_width`}>
          <AddressTextField
            type="text"
            placeholder="Town *"
            name="txtTown"
            id="txtTown"
            className="form-control  address hero-input"
            dataId="txtTown"
            autoComplete="off"
            readonly={manualEdit ? false : true}
            validation={props.validation({ required: "Please Enter Town" })}
            validationMsg={
              props.validationMsg.txtTown && props.validationMsg.txtTown.message
            }
            value={state.getDetails.town || ""}
            onChange={handlePostcodeChange}
          />
          <i
            className="validate validate_success"
            aria-hidden="true"
            style={{ display: "none" }}
          ></i>
        </div>
        <div className={`px-0 ${!manualEdit && "d-none"} col-lg-12 form-group adj_width}`}>
          <AddressTextField
            type="text"
            placeholder="Country *"
            name="txtCountry"
            id="txtCountry"
            className="form-control address hero-input"
            dataId="txtCountry"
            autoComplete="off"
            readonly={manualEdit ? false : true}
            validation={props.validation({ required: "Please Enter Country" })}
            validationMsg={
              props.validationMsg.txtCountry && props.validationMsg.txtCountry.message
            }
            value={state.getDetails.country || ""}
            onChange={handlePostcodeChange}
          />
          <i
            className="validate validate_success"
            aria-hidden="true"
            style={{ display: "none" }}
          ></i>
        </div>

        {Object.keys(state.getDetails).length > 0 ? <HiddenFields /> : null}
      </div>
      <div id="selcetAdd" className={`animate__animated animate__fadeInUp animate__delay-.5s animated-element ${state.checkSelect ? "d-block" : "d-none"}`}>
        <div className="return_back ">
          <p className="mb-0 mt-3">
            <span onClick={() => (
              dispatch({
                type: "resetaddress",
                get_address: [],
              }),
              dispatch({
                type: "getExtraAddressDetails",
                payload: { get_details: [], manual_address: "hide" },
              }),
              setPostCodeAddress([]),
              props.setValue("txtPostCode", ""),
              document.getElementById("txtPostCode").focus(),
              setManualEdit(false)
            )}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-arrow-clockwise"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
                />
                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
              </svg>
              <span>Search Again</span>
            </span>
            <span className={`${manualEdit && "d-none"} ${state.showManualAddress}`} onClick={() => setManualEdit(true)}>
              <span>Edit</span>
              <img src={edit} alt="" />
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
export default PostCode_PCP_HDSC_V1;
