import React, { useReducer, useState, useContext } from "react";
import AddressTextField from "../../../UI/AddressTextField";
import AddressSelectBox from "../../../UI/AddressSelectBox";
import HiddenField from "../../../UI/HiddenField";
import { usePostCodeAddress } from "../../../../Hooks/usePostCodeAddress";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import Button from "../../../UI/Button";
import edit from "../../../../assets/PCP_HDSC_V5/img/edit.png"
import return_png from "../../../../assets/PCP_HDSC_V5/img/return.png"
import infopng from "../../../../assets/PCP_HDSC_V5/img/info.png"

const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "hide",
  getPostcode: "#",
  clickManualLink: 0,
};
const postcodeReducer = (state, action) => {
  switch (action.type) {
    case "validationSucess": {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "getExtraAddressDetails": {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "showManualDiv": {
      return {
        ...state,
        showManualAddress: "show",
        lookUp: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click,
      };
    }
    case "resetaddress": {
      return {
        ...state,
        getDetails: [],
        getAddress: [],
        showManualAddress: "hide",
        lookUp: true,
        nextClick: false,
        checkSelect: false,
      };
    }
    case "updateField": {
      return {
        ...state,
        getDetails: {
          ...state.getDetails,
          [action.payload.field]: action.payload.value
        }
      };
    }
    default:
      return state;
  }
};
const PreviousPostCode_PCP_HDSC_V5 = ({
  validation,
  validationMsg,
  clearErrors,
  setError,
  slideChange,
  trigger,
  className,
  backClick,
  splitForm,
  getValues,
  setValue
}) => {
  const { getPostcodeValidation, getPostcodeAddress, getSplitPostcodeAddress } =
    usePostCodeAddress();
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const [postCodeAddress, setPostCodeAddress] = useState([]);
  const [postCodeEntry, setPostCodeEntry] = useState("0");
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [manualEdit, setManualEdit] = useState(false);

  const checkValidation = async (e) => {
    e.preventDefault();
    dispatch({
      type: "resetaddress",
      get_address: [],
    });
    const txtPostCode = await trigger("txtPrevPostCode_1");
    if (txtPostCode) {
      await getPostcodeAddress(
        e.target.value,
        visitorParameters.visitor_parameters.uuid
      )
        .then((res) => {
          if (res.data.status === "0") {
            setError("txtPrevPostCode_1", {
              type: "manual",
              message: "Please Enter Valid Postcode",
            });
          }
          if (res.data.length > 0) {
            dispatch({
              type: "validationSucess",
              payload: {
                lookup: false,
                next_click: true,
                postcode: e.target.value,
                get_address: res.data,
                check_select: true,
                manual_address: "hide",
              },
            });
          }
        })
        .catch(function () {
          setError("txtPrevPostCode_1", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
        });
    }
  };
  const getValue = async (e) => {
    e.preventDefault();
    setManualEdit(false)
    clearErrors([
      "txtPrevHouseNumber_1",
      "txtPrevroute_1",
      "txtCounty1",
      "txtTown1",
      "txtCountry1",
    ]);
    if (e.target.value != "") {
      const getData = await getSplitPostcodeAddress(
        e.target.value,
        state.getPostcode,
        visitorParameters.visitor_parameters.uuid
      );
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: getData.data, manual_address: "show" },
      });
      setPostCodeAddress(getData.data);
      clearErrors([
        "txtPrevHouseNumber_1",
        "txtPrevroute_1",
        "txtCounty1",
        "txtTown1",
        "txtCountry1",
      ]);
      setTimeout(() => {
        document.getElementById("preAddressSubmit").focus();
      }, 1000);
    } else {
      dispatch({
        type: "getExtraAddressDetails",
        payload: { get_details: [], manual_address: "hide" },
      });
      setPostCodeAddress([]);
    }
  };

  const lookupValidation = async (e) => {
    await trigger("txtPrevPostCode_1");
  };

  const checkPostCodeEntry = () => {
    const addressLine1 = document.getElementById("txtPrevHouseNumber_1").value;
    const addressLine2 = document.getElementById("txtPrevroute_1").value;
    const town = document.getElementById("txtTown1").value;
    const county = document.getElementById("txtCounty1").value;
    const country = document.getElementById("txtCountry1").value;

    if (
      postCodeAddress.length === 0 ||
      postCodeAddress.Country !== country ||
      postCodeAddress.County !== county ||
      postCodeAddress.Line1 !== addressLine1 ||
      postCodeAddress.Line2 !== addressLine2 ||
      postCodeAddress.Town !== town
    ) {
      setPostCodeEntry("1");
    }
  };

  const addressValidation = async (e) => {
    let errorFlag = 0;
    const txtPostCode = await trigger("txtPrevPostCode_1");
    var manual_txtHouseNumber;
    var manual_txtTown;
    var manual_txtCountry;
    var txtAddress;
    if (txtPostCode) {
      manual_txtHouseNumber = await trigger("txtPrevHouseNumber_1");
      txtAddress = await trigger("txtPrevaddress1_1");
    } else {
      document.getElementById("txtPrevPostCode_1").focus();
      return false;
    }
    if (manual_txtHouseNumber) {
      manual_txtTown = await trigger("txtTown1");
    } else {
      return false;
    }
    if (manual_txtTown) {
      manual_txtCountry = await trigger("txtCountry1");
    } else {
      return false;
    }
    if (
      !txtPostCode ||
      !manual_txtHouseNumber ||
      !manual_txtTown ||
      !manual_txtCountry
    ) {
      errorFlag = 1;
    }

    if (state.clickManualLink === 0) {
      const address1 = await trigger("txtPrevaddress1_1");
      if (!address1) {
        errorFlag = 1;
      }
    }
    if (errorFlag === 0) {
      checkPostCodeEntry();
      slideChange(e);
    } else {
      return false;
    }
  };

  const handlePostcodeChange = (e) => {
    const { name, value } = e.target;

    let fieldname = ""
    switch (name) {
      case "txtPrevHouseNumber_1":
        fieldname = "line_1"
        break;
      case "txtPrevroute_1":
        fieldname = "line_2"
        break;
      case "txtPrevAddress3":
        fieldname = "line_3"
        break;
      case "txtCounty1":
        fieldname = "county"
        break;
      case "txtTown1":
        fieldname = "town"
        break;
      case "txtCountry1":
        fieldname = "country"
        break;
      default:
        break;
    }
    dispatch({ type: "updateField", payload: { field: fieldname, value: value } });
  };

  const HiddenFields = () => (
    <>
      <HiddenField
        name="txtPrevUdprn_1"
        inputId="txtPrevUdprn_1"
        inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
      />
      <HiddenField
        name="txtPrevDeliveryPointSuffix_1"
        inputId="txtPrevDeliveryPointSuffix_1"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""
        }
      />
      <HiddenField
        name="txtPrevPz_mailsort_1"
        inputId="txtPrevPz_mailsort_1"
        inputValue={
          state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""
        }
      />
      <HiddenField
        name="txtPrevStreet_1"
        inputId="txtPrevStreet_1"
        inputValue={
          state.getDetails.line_3 !== "#" ? state.getDetails.line_3 : ""
        }
      />
      <HiddenField
        name="txtPrevCountry_1"
        inputId="txtPrevCountry_1"
        inputValue={
          state.getDetails.country !== "#" ? state.getDetails.country : ""
        }
      />
    </>
  );
  return (
    <>
      <div className="d-grid gap-1">
        <HiddenField
          className="w-100"
          name="pCode_EntryType"
          inputId="pCode_EntryType"
          inputValue={postCodeEntry}
        />
        <div className="postcode_wrap">
          <AddressTextField
            type="text"
            placeholder="Your Postcode"
            name="txtPrevPostCode_1"
            id="txtPrevPostCode_1"
            className="form-control mb-3 input_section"
            dataId="txtPrevPostCode_1"
            onBlur={checkValidation}
            autoComplete="txtPrevPostCode_1"
            validation={validation({
              required: "Please Enter Postcode",
              pattern: {
                value:
                  /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                message: "Please Enter a Valid Postcode",
              },
              minLength: {
                value: 5,
                message: "Please Enter a Valid Postcode",
              },
            })}
          // validationMsg={
          //   validationMsg.txtPrevPostCode_1 &&
          //   validationMsg.txtPrevPostCode_1.message
          // }
          />
          <Button
            type="button"
            className="bg_mod_click button"
            buttonText={
              <span>Find My Address</span>
            }
            id="lookUp"
            onClick={lookupValidation}
          />
        </div>
        <p className="tiny_note">
          <img src={infopng} className="pe-1" alt="" />
          Your address is required for a smooth claim process
        </p>
        {validationMsg.txtPrevPostCode_1 && (
          <p className="error_msg animate__animated animate__fadeInUp animate__delay-.5s validate">
            {validationMsg.txtPrevPostCode_1.message}
          </p>
        )}
        <div
          className="col-12 pt-3 animate__animated animate__fadeInUp animate__delay-.5s"
          id="selcetMod"
          style={state.checkSelect ? { display: "block" } : { display: "none" }}
        >
          <AddressSelectBox
            className="form-select pe-5"
            OptionValue={state.getAddress}
            name="txtPrevaddress1_1"
            id="txtPrevaddress1_1"
            onChange={getValue}
            myRef={validation({ required: "Please select address" })}
            validationMsg={
              validationMsg.txtPrevaddress1_1 &&
              validationMsg.txtPrevaddress1_1.message
            }
          />
          {!manualEdit &&
            <div
              className={`Mod_detail py-3 px-3 animate__animated animate__fadeInUp animate__delay-.5s ${state.getDetails && Object.keys(state.getDetails).length > 0 ? "d-block" : "d-none"}`}
            >
              <p className="ADD_clr_typ">
                {state.getDetails.line_1 ? (<>{state.getDetails.line_1}<br /> </>) : null}
                {state.getDetails.line_2 ? (<>{state.getDetails.line_2}<br /> </>) : null}
                {state.getDetails.line_3 ? (<>{state.getDetails.line_3}<br /> </>) : null}
                {state.getDetails.county ? (<>{state.getDetails.county}<br /></>) : null}
                {state.getDetails.town ? (<>{state.getDetails.town}<br /></>) : null}
                {state.getDetails.country ? (<>{state.getDetails.country}<br /></>) : null}
              </p>
            </div>
          }
        </div>
        <div
          className="col-12 text-center"
          style={
            state.getDetails.length === 0
              ? { display: "block" }
              : { display: "none" }
          }
        ></div>
        <div className={`px-0 ${!manualEdit && "d-none"} col-lg-12 form-group adj_width`}>
          <AddressTextField
            type="text"
            placeholder="Address Line 1 *"
            name="txtPrevHouseNumber_1"
            id="txtPrevHouseNumber_1"
            className="form-control address hero-input"
            dataId="txtPrevHouseNumber_1"
            autoComplete="off"
            readonly={false}
            validation={validation({
              required: "Please Enter Address Line 1",
            })}
            validationMsg={
              validationMsg.txtPrevHouseNumber_1 &&
              validationMsg.txtPrevHouseNumber_1.message
            }
            value={state.getDetails.line_1}
            onChange={handlePostcodeChange}
          />
          <i
            className="validate validate_success"
            aria-hidden="true"
            style={{ display: "none" }}
          ></i>
        </div>
        <div className={`px-0 ${!manualEdit && "d-none"} col-lg-12 form-group adj_width`}>
          <AddressTextField
            type="text"
            placeholder="Address Line 2"
            name="txtPrevroute_1"
            id="txtPrevroute_1"
            className="form-control  address hero-input"
            dataId="txtPrevroute_1"
            autoComplete="off"
            readonly={false}
            validation={validation()}
            validationMsg=""
            value={state.getDetails.line_2}
            onChange={handlePostcodeChange}
          />
          <i
            className="validate validate_success"
            aria-hidden="true"
            style={{ display: "none" }}
          ></i>
        </div>
        <div className={`px-0 ${!manualEdit && "d-none"} col-lg-12 form-group adj_width`}>
          <AddressTextField
            type="text"
            placeholder="Address Line 3"
            name="txtPrevAddress3"
            id="txtPrevAddress3"
            className="form-control  address hero-input"
            dataId="txtPrevAddress3"
            autoComplete="off"
            readonly={false}
            validation={validation()}
            validationMsg=""
            value={state.getDetails.line_3}
            onChange={handlePostcodeChange}
          />
          <i
            className="validate validate_success"
            aria-hidden="true"
            style={{ display: "none" }}
          ></i>
        </div>
        <div className={`px-0 ${!manualEdit && "d-none"} col-lg-12 form-group adj_width`}>
          <AddressTextField
            type="text"
            placeholder="County"
            name="txtCounty1"
            id="txtCounty1"
            className="form-control  address hero-input"
            dataId="txtCounty1"
            autoComplete="off"
            readonly={false}
            validation={validation()}
            validationMsg=""
            value={state.getDetails.county}
            onChange={handlePostcodeChange}
          />
          <i
            className="validate validate_success"
            aria-hidden="true"
            style={{ display: "none" }}
          ></i>
        </div>
        <div className={`px-0 ${!manualEdit && "d-none"} col-lg-12 form-group adj_width`}>
          <AddressTextField
            type="text"
            placeholder="Town *"
            name="txtTown1"
            id="txtTown1"
            className="form-control  address hero-input"
            dataId="txtTown1"
            autoComplete="off"
            readonly={false}
            validation={validation({ required: "Please Enter Town" })}
            validationMsg={
              validationMsg.txtTown1 && validationMsg.txtTown1.message
            }
            value={state.getDetails.town}
            onChange={handlePostcodeChange}
          />
          <i
            className="validate validate_success"
            aria-hidden="true"
            style={{ display: "none" }}
          ></i>
        </div>
        <div className={`px-0 ${!manualEdit && "d-none"} col-lg-12 form-group adj_width`}>
          <AddressTextField
            type="text"
            placeholder="Country *"
            name="txtCountry1"
            id="txtCountry1"
            className="form-control address hero-input"
            dataId="txtCountry1"
            autoComplete="off"
            readonly={false}
            validation={validation({ required: "Please Enter Country" })}
            validationMsg={
              validationMsg.txtCountry1 && validationMsg.txtCountry1.message
            }
            value={state.getDetails.country}
            onChange={handlePostcodeChange}
          />
          <i
            className="validate validate_success"
            aria-hidden="true"
            style={{ display: "none" }}
          ></i>
        </div>
        {Object.keys(state.getDetails).length > 0 ? <HiddenFields /> : null}
        <div className={`return_back animate__animated animate__fadeInUp animate__delay-.5s animated-element ${state.checkSelect ? "d-block" : "d-none"}`}>
          <p className="mb-0 mt-3">
            <span className="cursor_pointer" onClick={() => (
              dispatch({
                type: "resetaddress",
                get_address: [],
              }),
              dispatch({
                type: "getExtraAddressDetails",
                payload: { get_details: [], manual_address: "hide" },
              }),
              setPostCodeAddress([]),
              setValue("txtPrevPostCode_1", ""),
              document.getElementById("txtPrevPostCode_1").focus(),
              setManualEdit(false)
            )}>
              <img src={return_png} alt="" />
              <span>Search Again</span>
            </span>{" "}
            <span className={`cursor_pointer ${manualEdit && "d-none"} ${state.showManualAddress}`} onClick={() => setManualEdit(true)}>
              <span>Edit</span>
              <img src={edit} alt="" />
            </span>
          </p>
        </div>
      </div>
    </>
  );
};
export default PreviousPostCode_PCP_HDSC_V5;
