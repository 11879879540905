import React from "react";

const LenderModal = ({ modalClass,handleFinanceProviderChange,cancelpopupLender }) => {
  const closModal = (e) => {
    document.body.classList.remove("no-scroll");
    document.getElementById("analayzeModal").classList.remove("show");
    document.getElementById("analayzeModal").classList.add("hide");
    document.body.classList.remove("modal-open");
  };
  return (
    <>
      <div
        className={`modal fade ${modalClass && "show d-block"}`}
        id="morelenders"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content cust_modal">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="col-lg-12 clearfix selectoptions">
                <div className=" option clearfix">
                  <input
                    value="62"
                    type="checkbox"
                    name="financeProvider"
                    id="62"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="62">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      ALD Automotive Limited - Lombard Vehicle Solutions
                    </span>{" "}
                  </label>
                </div>
                <div className=" option clearfix">
                  <input
                    value="10"
                    type="checkbox"
                    name="financeProvider"
                    id="10"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="10">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      ALD Automotive Limited - NatWest Car Finance
                    </span>{" "}
                  </label>
                </div>
                <div className=" option clearfix">
                  <input
                    value="77"
                    type="checkbox"
                    name="financeProvider"
                    id="77"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="77">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      Aldermore Bank PLC - Aldemore Bank PLC{" "}
                    </span>{" "}
                  </label>
                </div>
                <div className=" option clearfix">
                  <input
                    value="38"
                    type="checkbox"
                    name="financeProvider"
                    id="38"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="38">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      Black Horse Limited - Black Horse Limited
                    </span>{" "}
                  </label>
                </div>
                <div className=" option clearfix">
                  <input
                    value="36"
                    type="checkbox"
                    name="financeProvider"
                    id="36"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="36">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      Black Horse Limited - Black Horse Motor Finance{" "}
                    </span>{" "}
                  </label>
                </div>
                <div className=" option clearfix">
                  <input
                    value="89"
                    type="checkbox"
                    name="financeProvider"
                    id="89"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="89">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      Black Horse Limited - Black Horse Caravan and Motorhome
                      Finance
                    </span>{" "}
                  </label>
                </div>
                <div className=" option clearfix">
                  <input
                    value="65"
                    type="checkbox"
                    name="financeProvider"
                    id="65"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="65">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      Black Horse Limited - Land Rover Financial Services
                      2016-Present
                    </span>{" "}
                  </label>
                </div>
                <div className=" option clearfix">
                  <input
                    value="90"
                    type="checkbox"
                    name="financeProvider"
                    id="90"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="90">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      Black Horse Limited - Jaguar Financial Services
                      2016-Present
                    </span>{" "}
                  </label>
                </div>
                <div className=" option clearfix">
                  <input
                    value="9"
                    type="checkbox"
                    name="financeProvider"
                    id="9"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="9">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      Black Horse Limited - Triumph Motorcycle Finance
                      2016-Present{" "}
                    </span>{" "}
                  </label>
                </div>
                <div className=" option clearfix">
                  <input
                    value="76"
                    type="checkbox"
                    name="financeProvider"
                    id="76"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="76">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      Black Horse Limited - Subaru Finance 2016-Present{" "}
                    </span>{" "}
                  </label>
                </div>
                <div className=" option clearfix">
                  <input
                    value="25"
                    type="checkbox"
                    name="financeProvider"
                    id="25"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="25">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      Black Horse Limited - Harley Davidson Finance 2016-Present{" "}
                    </span>{" "}
                  </label>
                </div>
                <div className=" option clearfix">
                  <input
                    value="27"
                    type="checkbox"
                    name="financeProvider"
                    id="27"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="27">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      Black Horse Limited - Yamaha Finance 2016-2019{" "}
                    </span>{" "}
                  </label>
                </div>
                <div className=" option clearfix">
                  <input
                    value="61"
                    type="checkbox"
                    name="financeProvider"
                    id="61"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="61">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      Black Horse Limited - Ducati Finance 2016-Present{" "}
                    </span>{" "}
                  </label>
                </div>
                <div className=" option clearfix">
                  <input
                    value="22"
                    type="checkbox"
                    name="financeProvider"
                    id="22"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="22">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      Black Horse Limited - Lotus Finance 2016-Present
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="3"
                    type="checkbox"
                    name="financeProvider"
                    id="3"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="3">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      Black Horse Limited - Suzuki Finance 2016-Present{" "}
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="03"
                    type="checkbox"
                    name="financeProvider"
                    id="03"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="03">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      BMW Financial Services (GB) Limited - BMW Financial
                      Services (GB) Limited
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="04"
                    type="checkbox"
                    name="financeProvider"
                    id="04"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="04">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      BMW Financial Services (GB) Limited - BMW Financial
                      Services
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="05"
                    type="checkbox"
                    name="financeProvider"
                    id="05"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="05">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      BMW Financial Services (GB) Limited - MINI Finance
                      2005-Present{" "}
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="06"
                    type="checkbox"
                    name="financeProvider"
                    id="06"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="06">
                    {" "}
                    <span className="option_title title_1">
                      BMW Financial Services (GB) Limited - Alphera Financial
                      Services{" "}
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="07"
                    type="checkbox"
                    name="financeProvider"
                    id="07"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="07">
                    {" "}
                    <span className="option_title title_1">
                      BMW Financial Services (GB) Limited - Aston Martin
                      Financial Services 2016-Present{" "}
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="08"
                    type="checkbox"
                    name="financeProvider"
                    id="08"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="08">
                    {" "}
                    <span className="option_title title_1">
                      BMW Financial Services (GB) Limited - Rolls Royce
                      Financial Services{" "}
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="09"
                    type="checkbox"
                    name="financeProvider"
                    id="09"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="09">
                    {" "}
                    <span className="option_title title_1">
                      Borderway Finance Limited - Borderway Finance Limited{" "}
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="010"
                    type="checkbox"
                    name="financeProvider"
                    id="010"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="010">
                    {" "}
                    <span className="option_title title_1">
                      C.E.M. Day Ltd - C.E.M. Day Ltd{" "}
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="011"
                    type="checkbox"
                    name="financeProvider"
                    id="011"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="011">
                    {" "}
                    <span className="option_title title_1">
                      C.E.M. Day Ltd - Days Motor Group
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="012"
                    type="checkbox"
                    name="financeProvider"
                    id="012"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="012">
                    {" "}
                    <span className="option_title title_1">
                      CA Auto Finance UK Ltd - CA Auto Finance UK Ltd
                    </span>{" "}
                  </label>
                </div>
                <div className=" option clearfix">
                  <input
                    value="013"
                    type="checkbox"
                    name="financeProvider"
                    id="013"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="013">
                    {" "}
                    <span className="option_title title_1">
                      CA Auto Finance UK Ltd - FCA Automotive Services UK Ltd
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="014"
                    type="checkbox"
                    name="financeProvider"
                    id="014"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="014">
                    {" "}
                    <span className="option_title title_1">
                      CA Auto Finance UK Ltd - Alfa Romeo Financial Services
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="015"
                    type="checkbox"
                    name="financeProvider"
                    id="015"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="015">
                    {" "}
                    <span className="option_title title_1">
                      CA Auto Finance UK Ltd - Chrysler Financial Services
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="016"
                    type="checkbox"
                    name="financeProvider"
                    id="016"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="016">
                    {" "}
                    <span className="option_title title_1">
                      CA Auto Finance UK Ltd - FGA Capital UK Limited
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="017"
                    type="checkbox"
                    name="financeProvider"
                    id="017"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="017">
                    {" "}
                    <span className="option_title title_1">
                      CA Auto Finance UK Ltd - Fiat Financial Services
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="018"
                    type="checkbox"
                    name="financeProvider"
                    id="018"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="018">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      CA Auto Finance UK Ltd - Jaguar Cars Finance
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="019"
                    type="checkbox"
                    name="financeProvider"
                    id="019"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="019">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      CA Auto Finance UK Ltd - Jeep Financial Services
                      2016-Present
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="020"
                    type="checkbox"
                    name="financeProvider"
                    id="020"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="020">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      CA Auto Finance UK Ltd - Landrover Finance
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="021"
                    type="checkbox"
                    name="financeProvider"
                    id="021"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="021">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      CA Auto Finance UK Ltd - Maserati Financial Services
                      2016-Present
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="022"
                    type="checkbox"
                    name="financeProvider"
                    id="022"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="022">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      CA Auto Finance UK Ltd - Erwin Hymer Group Finance
                      2016-Present
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="023"
                    type="checkbox"
                    name="financeProvider"
                    id="023"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="023">
                    {" "}
                    <span className="option_title title_1">
                      Close Motor Finance Limited - Close Motor Finance Limited
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="024"
                    type="checkbox"
                    name="financeProvider"
                    id="024"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="024">
                    {" "}
                    <span className="option_title title_1">
                      Close Motor Finance Limited - Close Brothers Motor Finance
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="025"
                    type="checkbox"
                    name="financeProvider"
                    id="025"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="025">
                    {" "}
                    <span className="option_title title_1">
                      Barclays Partner Finance Limited - Clydesdale Financial
                      Services Limited
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="026"
                    type="checkbox"
                    name="financeProvider"
                    id="026"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="026">
                    {" "}
                    <span className="option_title title_1">
                      Barclays Partner Finance Limited - Barclays Partner
                      Finance
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="027"
                    type="checkbox"
                    name="financeProvider"
                    id="027"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="027">
                    {" "}
                    <span className="option_title title_1">
                      Barclays Partner Finance Limited - Barclaycard Motor loans
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="028"
                    type="checkbox"
                    name="financeProvider"
                    id="028"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="028">
                    {" "}
                    <span className="option_title title_1">
                      FCE Bank Plc - FCE Bank Plc
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="029"
                    type="checkbox"
                    name="financeProvider"
                    id="029"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="029">
                    {" "}
                    <span className="option_title title_1">
                      FCE Bank Plc - Ford Credit
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="030"
                    type="checkbox"
                    name="financeProvider"
                    id="030"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="030">
                    {" "}
                    <span className="option_title title_1">
                      FCE Bank Plc - Ford Financial
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="031"
                    type="checkbox"
                    name="financeProvider"
                    id="031"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="031">
                    {" "}
                    <span className="option_title title_1">
                      FCE Bank Plc - Jaguar Financial Services 2005-2015
                    </span>{" "}
                  </label>
                </div>
                <div className=" option clearfix">
                  <input
                    value="032"
                    type="checkbox"
                    name="financeProvider"
                    id="032"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="032">
                    {" "}
                    <span className="option_title title_1">
                      FCE Bank Plc - Land Rover Financial Services 2005-2015
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="033"
                    type="checkbox"
                    name="financeProvider"
                    id="033"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="033">
                    {" "}
                    <span className="option_title title_1">
                      FCE Bank Plc - Mazda Credit 2006-2018
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="034"
                    type="checkbox"
                    name="financeProvider"
                    id="034"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="034">
                    {" "}
                    <span className="option_title title_1">
                      FCE Bank Plc - Volvo Car Finance 2005-2015
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="035"
                    type="checkbox"
                    name="financeProvider"
                    id="035"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="035">
                    {" "}
                    <span className="option_title title_1">
                      Ferrari Financial Services GmbH - Ferrari Financial
                      Services GmbH
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="036"
                    type="checkbox"
                    name="financeProvider"
                    id="036"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="036">
                    {" "}
                    <span className="option_title title_1">
                      Honda Finance Europe Plc - Honda Finance Europe Plc
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="037"
                    type="checkbox"
                    name="financeProvider"
                    id="037"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="037">
                    {" "}
                    <span className="option_title title_1">
                      Honda Finance Europe Plc - Honda Financial Services
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="038"
                    type="checkbox"
                    name="financeProvider"
                    id="038"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="038">
                    {" "}
                    <span className="option_title title_1">
                      Hyundai Capital UK Limited - Hyundai Capital (UK) Limited
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="039"
                    type="checkbox"
                    name="financeProvider"
                    id="039"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="039">
                    {" "}
                    <span className="option_title title_1">
                      Hyundai Capital UK Limited - Hyundai Capital UK Limited
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="040"
                    type="checkbox"
                    name="financeProvider"
                    id="040"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="040">
                    {" "}
                    <span className="option_title title_1">
                      Hyundai Capital UK Limited - Hyundai Finance
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="041"
                    type="checkbox"
                    name="financeProvider"
                    id="041"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="041">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      Hyundai Capital UK Limited - Kia Finance 2015-Present
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="042"
                    type="checkbox"
                    name="financeProvider"
                    id="042"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="042">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      JBR Capital Limited - JBR Capital Limited
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="043"
                    type="checkbox"
                    name="financeProvider"
                    id="043"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="043">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      Mercedes-Benz Financial Services UK Limited -
                      Mercedes-Benz Financial Services UK Limited
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="044"
                    type="checkbox"
                    name="financeProvider"
                    id="044"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="044">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      MI Vehicle Finance Limited - MI Vehicle Finance Limited
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="045"
                    type="checkbox"
                    name="financeProvider"
                    id="045"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="045">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      MI Vehicle Finance Limited - Mann Island
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="046"
                    type="checkbox"
                    name="financeProvider"
                    id="046"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="046">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      MotoNovo Finance - MotoNovo Finance
                    </span>{" "}
                  </label>
                </div>
                <div className=" option clearfix">
                  <input
                    value="047"
                    type="checkbox"
                    name="financeProvider"
                    id="047"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="047">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      MotoNovo Finance - FirstRand Bank
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="048"
                    type="checkbox"
                    name="financeProvider"
                    id="048"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="048">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      RCI Financial Service Limited - RCI Financial Service
                      Limited
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="050"
                    type="checkbox"
                    name="financeProvider"
                    id="050"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="050">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      RCI Financial Service Limited - Nissan Finance / Financial
                      Services
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="051"
                    type="checkbox"
                    name="financeProvider"
                    id="051"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="051">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      RCI Financial Service Limited - Renault Finance /
                      Financial Services / RFS
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="052"
                    type="checkbox"
                    name="financeProvider"
                    id="052"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="052">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      RCI Financial Service Limited - Alpine Finance
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="053"
                    type="checkbox"
                    name="financeProvider"
                    id="053"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="053">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      RCI Financial Service Limited - Dacia Finance
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="054"
                    type="checkbox"
                    name="financeProvider"
                    id="054"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="054">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      Santander Consumer (UK) Plc - Santander Consumer (UK) Plc
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="055"
                    type="checkbox"
                    name="financeProvider"
                    id="055"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="055">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      Santander Consumer (UK) Plc - GE Capital Motor Finance
                      2015-2019
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="056"
                    type="checkbox"
                    name="financeProvider"
                    id="056"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="056">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      Santander Consumer (UK) Plc - GE Money Car Loans 2015-2019
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="057"
                    type="checkbox"
                    name="financeProvider"
                    id="057"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="057">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      Santander Consumer (UK) Plc - Kia Financial Services
                      2015-Present
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="058"
                    type="checkbox"
                    name="financeProvider"
                    id="058"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="058">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      Santander Consumer (UK) Plc - Lookers Finance 2015-2019
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="059"
                    type="checkbox"
                    name="financeProvider"
                    id="059"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="059">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      Santander Consumer (UK) Plc - Mazda Financial Services
                      2018-Present
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="060"
                    type="checkbox"
                    name="financeProvider"
                    id="060"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="060">
                    {" "}
                    <span className="option_title title_1">
                      {" "}
                      Santander Consumer (UK) Plc - Volvo Car Financial Services
                      2015-Present
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="061"
                    type="checkbox"
                    name="financeProvider"
                    id="061"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="061">
                    {" "}
                    <span className="option_title title_1">
                      Santander Consumer (UK) Plc - Woodchester Finance
                      2015-2019
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="062"
                    type="checkbox"
                    name="financeProvider"
                    id="062"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="062">
                    {" "}
                    <span className="option_title title_1">
                      Secure Trust Bank PLC - Secure Trust Bank PLC
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="063"
                    type="checkbox"
                    name="financeProvider"
                    id="063"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="063">
                    {" "}
                    <span className="option_title title_1">
                      Secure Trust Bank PLC - Moneyway
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="064"
                    type="checkbox"
                    name="financeProvider"
                    id="064"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="064">
                    {" "}
                    <span className="option_title title_1">
                      Secure Trust Bank PLC - V12 Vehicle Finance
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="065"
                    type="checkbox"
                    name="financeProvider"
                    id="065"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="065">
                    {" "}
                    <span className="option_title title_1">
                      Shogun Finance Limited - Shogun Finance Limited
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="066"
                    type="checkbox"
                    name="financeProvider"
                    id="066"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="066">
                    {" "}
                    <span className="option_title title_1">
                      Shogun Finance Limited - Finance Mitsubishi
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="067"
                    type="checkbox"
                    name="financeProvider"
                    id="067"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="067">
                    {" "}
                    <span className="option_title title_1">
                      Shogun Finance Limited - Shogun Finance / Financial
                      Services
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="068"
                    type="checkbox"
                    name="financeProvider"
                    id="068"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="068">
                    {" "}
                    <span className="option_title title_1">
                      Stellantis Financial Services UK Limited - Stellantis
                      Financial Services UK Limited
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="069"
                    type="checkbox"
                    name="financeProvider"
                    id="069"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="069">
                    {" "}
                    <span className="option_title title_1">
                      Stellantis Financial Services UK Limited - Citroen Finance
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="070"
                    type="checkbox"
                    name="financeProvider"
                    id="070"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="070">
                    {" "}
                    <span className="option_title title_1">
                      Stellantis Financial Services UK Limited - Peugeot Finance
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="071"
                    type="checkbox"
                    name="financeProvider"
                    id="071"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="071">
                    {" "}
                    <span className="option_title title_1">
                      Stellantis Financial Services UK Limited - PSA Finance
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="072"
                    type="checkbox"
                    name="financeProvider"
                    id="072"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="072">
                    {" "}
                    <span className="option_title title_1">
                      Stellantis Financial Services UK Limited - PSA Financial
                      Services Limited
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="073"
                    type="checkbox"
                    name="financeProvider"
                    id="073"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="073">
                    {" "}
                    <span className="option_title title_1">
                      Vauxhall Finance Plc - Vauxhall Finance Plc
                    </span>{" "}
                  </label>
                </div>
                <div className=" option clearfix">
                  <input
                    value="074"
                    type="checkbox"
                    name="financeProvider"
                    id="074"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="074">
                    {" "}
                    <span className="option_title title_1">
                      Vauxhall Finance Plc - Ally Finance / Financial Services
                      2010-2016
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="075"
                    type="checkbox"
                    name="financeProvider"
                    id="075"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="075">
                    {" "}
                    <span className="option_title title_1">
                      Vauxhall Finance Plc - Chevrolet Finance
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="076"
                    type="checkbox"
                    name="financeProvider"
                    id="076"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="076">
                    {" "}
                    <span className="option_title title_1">
                      Vauxhall Finance Plc - General Motors Financial Services
                      2016-2019
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="077"
                    type="checkbox"
                    name="financeProvider"
                    id="077"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="077">
                    {" "}
                    <span className="option_title title_1">
                      Vauxhall Finance Plc - GMAC 2005-2019
                    </span>{" "}
                  </label>
                </div>
                <div className=" option clearfix">
                  <input
                    value="078"
                    type="checkbox"
                    name="financeProvider"
                    id="078"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="078">
                    {" "}
                    <span className="option_title title_1">
                      Vauxhall Finance Plc - MG Financial Services 2016-2020
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="079"
                    type="checkbox"
                    name="financeProvider"
                    id="079"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="079">
                    {" "}
                    <span className="option_title title_1">
                      Vauxhall Finance Plc - Opel Vauxhall Finance 2018-Present
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="080"
                    type="checkbox"
                    name="financeProvider"
                    id="080"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="080">
                    {" "}
                    <span className="option_title title_1">
                      Vauxhall Finance Plc - Saab Finance 2010-2017
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="081"
                    type="checkbox"
                    name="financeProvider"
                    id="081"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="081">
                    {" "}
                    <span className="option_title title_1">
                      Vauxhall Finance Plc - Ssangyong Finance 2016-2020
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="082"
                    type="checkbox"
                    name="financeProvider"
                    id="082"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="082">
                    {" "}
                    <span className="option_title title_1">
                      Toyota Financial Services (UK) Plc - Toyota Financial
                      Services (UK) Plc
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="083"
                    type="checkbox"
                    name="financeProvider"
                    id="083"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="083">
                    {" "}
                    <span className="option_title title_1">
                      Toyota Financial Services (UK) Plc - Lexus Finance /
                      Financial Services
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="084"
                    type="checkbox"
                    name="financeProvider"
                    id="084"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="084">
                    {" "}
                    <span className="option_title title_1">
                      Toyota Financial Services (UK) Plc - Redline Finance /
                      Financial Services
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="085"
                    type="checkbox"
                    name="financeProvider"
                    id="085"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="085">
                    {" "}
                    <span className="option_title title_1">
                      Toyota Financial Services (UK) Plc - Toyota Finance /
                      Financial Services
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="086"
                    type="checkbox"
                    name="financeProvider"
                    id="086"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="086">
                    {" "}
                    <span className="option_title title_1">
                      Volkswagen Financial Services (UK) Limited - Volkswagen
                      Financial Services (UK) Limited
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="087"
                    type="checkbox"
                    name="financeProvider"
                    id="087"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="087">
                    {" "}
                    <span className="option_title title_1">
                      Volkswagen Financial Services (UK) Limited - Audi Finance
                      / Financial Services
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="088"
                    type="checkbox"
                    name="financeProvider"
                    id="088"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="088">
                    {" "}
                    <span className="option_title title_1">
                      Volkswagen Financial Services (UK) Limited - Bentley
                      Finance / Financial Services
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="089"
                    type="checkbox"
                    name="financeProvider"
                    id="089"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="089">
                    {" "}
                    <span className="option_title title_1">
                      Volkswagen Financial Services (UK) Limited - Porsche
                      Finance / Financial Services
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="090"
                    type="checkbox"
                    name="financeProvider"
                    id="090"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="090">
                    {" "}
                    <span className="option_title title_1">
                      Volkswagen Financial Services (UK) Limited - Seat Finance
                      / Financial Services
                    </span>{" "}
                  </label>
                </div>
                <div className=" option clearfix">
                  <input
                    value="091"
                    type="checkbox"
                    name="financeProvider"
                    id="091"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="091">
                    {" "}
                    <span className="option_title title_1">
                      Volkswagen Financial Services (UK) Limited - Skoda Finance
                      / Financial Services
                    </span>{" "}
                  </label>
                </div>

                <div className=" option clearfix">
                  <input
                    value="092"
                    type="checkbox"
                    name="financeProvider"
                    id="092"
                    className="test"
                    onChange={(e) => handleFinanceProviderChange(e)}
                  />
                  <label htmlFor="092">
                    {" "}
                    <span className="option_title title_1">
                      Volkswagen Financial Services (UK) Limited - Volkswagen
                      Finance / Financial Services
                    </span>{" "}
                  </label>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="bg-1 hme_btn">
                  <button
                    type="button"
                    className="button w-100 next-btn btn-pop"
                    id=""
                    value="Next"
                    // data-bs-dismiss="modal"
                    // aria-label="Close"
                    onClick={cancelpopupLender}
                  >
                    {" "}
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LenderModal;
