import React from 'react';
import nextgreen from "../../../../assets/PCP_HDSC_V1/img/next-green.png";
import RadioButtonV1 from "../../../UI/RadioButtonV1";
import Button from '../../../UI/Button';

const ClaimedQuestion = ({
  formData,
  handleYesModal,
  className,
  slideChange,
  previousSlideChange,
  handleChangeButton

}) => {
  return (
    <div className={`slide_2_3Dec ${className}`}>
      <h2 className="animate__animated animate__fadeInUp animate__delay-.5s animated-element pt-5 pt-md-3">
        Have you already claimed?
      </h2>
      <div className="px-3 py-3 widget_mobi borderx">
        <div className="choose_Y_N col-lg-12 col-12 animate__animated animate__fadeInUp animate__delay-.5s">
          <div className="form-check px-0">

               <RadioButtonV1
              className="form-check-input"
              type="radio"
              aria-label="claimed"
              name="claimed"
              id="5"
              value="no"
              defaultChecked={formData.claimed === '5'}
              onClick={handleChangeButton}
              labelName="No"
              
            />
          </div>

          <div className="form-check px-0">
               <RadioButtonV1
              className="form-check-input"
              type="radio"
              aria-label="claimed"
              name="claimed"
              id="6"
              value="yes"
              defaultChecked={formData.claimed === '6'}
              onClick={handleYesModal}
              labelName="Yes"
            />
          </div>
        </div>
        {/* {claimError && (
          <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
            {claimError}
          </p>
        )} */}

        <div
          className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s animated-element"
          id="subRegNo4"
        >
          <div className="row align-items-center">
            <div className="col-12 text-center ">
              <Button
              type="button"
              data-type="back"
              name="back04"
              id="backStep-3"
              className="msf-nav-button backbutton"
              onClick={() =>previousSlideChange('back04')}
              buttonText={
                <span className="btntext"><img src={nextgreen} alt="" />
                  Back <i className="arrowicon"></i>
                </span>
              }

            />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimedQuestion;
