import React from "react";

const TextFieldV3 = ({
  name,
  id,
  type,
  placeholder,
  className,
  onChange,
  onBlur,
  validation,
  validationMsg,
  onkeypress,
  value,
  defaultValue,
  maxlength,
  style,
  keypress,
  autoComplete
}) => {
  return (
    <>
      <input
        name={name}
        id={id}
        placeholder={placeholder}
        className={className}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={onkeypress}
        ref={validation}
        value={value}
        defaultValue={defaultValue}
        maxLength={maxlength}
        style={style}
        autoComplete={autoComplete}
      />
      {validationMsg && (
        <span className="error_msg">{validationMsg}</span>
      )}
    </>
  );
};

export default TextFieldV3;
