import React from "react";
import Button from "../../../UI/Button";

const BasicOne = ({ className, slideChange }) => {
  return (
    <div className={`plateOne_anim ${className}`} id="plate_1">
      <>
        <h1 className="cs_hero_title animate__animated animate__fadeInLeft animate__delay-1s cs_fs_94 ">
          Have You Bought A Car On Finance?
        </h1>
        <p className="cs_hero_subtitle highlight cs_fs_20 animate__animated animate__fadeInLeft animate__delay-1s cs_heading_color ">
          Find out if your lender owes you <strong>£1,000's</strong> in
          compensation.
        </p>
      </>
      <>
        <div
          className="box_button animate__animated animate__fadeInLeft animate__delay-1s animated-element wht_iF no_border"
          id="subRegNo1"
        >
          <div className="bg-1 hme_btn">
            <Button
              type="button"
              className="button button--moma button--text-thick button--text-upper button--size-s blick_notice"
              id="nextPlate_001"
              name="nextPlate_001"
              buttonText="EXPRESS REGISTRATION"
              onClick={slideChange}
            />
          </div>
        </div>

        <p
          className={`fw-semibold text-white pt-3 ps-5 nimate__animated animate__fadeInLeft animate__delay-1s animated-element d-none d-md-block
            `}
        >
          Quick, easy, fully online registration.
        </p>
      </>
    </div>
  );
};

export default BasicOne;
