import React from 'react';
import nextgreen from "../../../../assets/PCP_HDSC_V1/img/next-green.png";
import RadioButtonV1 from "../../../UI/RadioButtonV1";
import Button from '../../../UI/Button';

const CommissionDisclosureSection = ({
  formData,
  handleChangeButton,
  handleYesModal,
  className,
  slideChange,
  previousSlideChange,
  register
}) => {
  return (
    <div className={`slide_2_1Dec ${className}`}>
      <h2 className="animate__animated animate__fadeInUp animate__delay-.5s animated-element pt-5 pt-md-3">
        Did the dealer disclose their commission and how it may affect the interest rate on your loan?
      </h2>
      <div className="px-3 py-3 widget_mobi borderx">
        <div className="choose_Y_N col-lg-12 col-12 animate__animated animate__fadeInUp animate__delay-.5s">
          <div className="form-check px-0">
            <RadioButtonV1
              className="form-check-input"
              type="radio"
              aria-label="commission"
              name="commission"
              id="1"
              value="no"
              defaultChecked={formData.commission === '1'}
              onClick={handleChangeButton}
              labelName="No"
              register={register}

              
            />
          </div>

          <div className="form-check px-0">
            <RadioButtonV1
              className="form-check-input"
              type="radio"
              aria-label="commission"
              name="commission"
              id="2"
              value="yes"
              defaultChecked={formData.commission === '2'}
              onClick={handleYesModal}
              labelName="Yes"
              register={register}

            />
          </div>
        </div>
        {/* {commissionError && (
          <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
            {commissionError}
          </p>
        )} */}

        <div
          className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s animated-element"
          id="subRegNo4"
        >
          <div className="row align-items-center">
            <div className="col-12 text-center ">
              <Button
              type="button"
              data-type="back"
              name="back02"
              id="back02"
              className="msf-nav-button backbutton"
              onClick={() =>previousSlideChange('back02')}
              buttonText={
                <span className="btntext"><img src={nextgreen} alt="" />
                  Back <i className="arrowicon"></i>
                </span>
              }

            />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommissionDisclosureSection;
