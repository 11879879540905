import React, { useState } from "react";
import TextField from "../../../UI/TextField";
import RadioButton from "../../../UI/RadioButton";

const PreviousNameModal = (props) => {
  const [showSecondSection, setShowSecondSection] = useState(false);
  const handleYesClick = () => {
    setShowSecondSection(true);
  };
  const handleNoClick = () => {
    props.setShowPreviousNamePopUp(false);
    props.setShowPreviousAddressPopUp(true);
  };
  const handleSubmitButton = async () => {
      props.setShowPreviousNamePopUp(false);
      props.setShowPreviousAddressPopUp(true);
  };
  return (
    <>
      <div className={`modal fade ${props.showPreviousNamePopUp && "show d-block"}`} id="Add_preName" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1}
        aria-labelledby="Add_preNameLabel" aria-modal="true" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s ">
            <div className="d-block modal-body">
              <div className="form_drop pt-2 drop_details col-lg-12 col-12">
              </div>
              {!showSecondSection && (
                <div className="form_drop drop_details col-lg-12 col-12">
                  <p className="sty_pop">
                    You have any Previous Name?<br/>
                    (Leave blank if not applicable)
                  </p>
                  <div className="row name_detail pt-0 animate__animated animate__fadeInUp animate__delay-.5s">
                    <div className="col-lg-12 col-md-12 col-12 f_name">
                      <div className="choose_Y_N col-lg-12 col-12 animate__animated animate__fadeInUp animate__delay-.5s">
                        <div className="form-check px-0">
                          <RadioButton
                            name="Radioshere2"
                            className="form-check-input"
                            labelName="No"
                            labelClassName="form-check-label"
                            onClick={handleNoClick}
                          />
                        </div>
                        <div className="form-check px-0">
                        <RadioButton
                            name="Radioshere2"
                            className="form-check-input"
                            labelName="Yes"
                            labelClassName="form-check-label"
                            onClick={handleYesClick}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {showSecondSection && (
                <div className="form_drop drop_details col-lg-12 col-12">
                <div className="row name_detail pt-0 animate__animated animate__fadeInUp animate__delay-.5s">
                    <div className="col-lg-12 col-md-12 col-12 f_name">
                      <label className="topmention1a" htmlFor="topmention1">Enter Your Previous Surname<br/>
                      (Leave blank if not applicable)</label>
                      <TextField
                        type="text"
                        className="form-control"
                        placeholder="Previous Surname"
                        name="previousName"
                        id="previousName"
                        autoComplete="off"
                        validation={props.validation()}
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div className="box_button final_ent pt-3 animate__animated animate__fadeInUp animate__delay-.5s">
                      <div className="bg-1 text-center">
                        <input
                          type="button"
                          className="button wb_5 button--moema button--text-thick button--text-upper button--size-s h60_click blick_notice"
                          id="nextUpdate_001"
                          value="Update"
                          onClick={handleSubmitButton}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviousNameModal;
