import React, { useContext, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import QueryStringContext from "../../../Contexts/QueryString";
import { useHistory } from "react-router-dom";
import DateOfBirth from "../../Includes/Layouts/PCP_HDSC_V3/DateOfBirth";
import Signature from "../../Includes/Layouts/PCP_HDSC_V3/Signature";
import PostCode from "../../Includes/Layouts/PCP_HDSC_V3/PostCode";
import ContactDetails from "../../Includes/Layouts/PCP_HDSC_V3/ContactDetails";
import PersonalDetails from "../../Includes/Layouts/PCP_HDSC_V3/PersonalDetails";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { UUID } from "../../../Utility/UUID";
import AngleArrow from "../../../assets/img/angle-arrow.svg";
import Button from "../../UI/Button";
import Lender from "../../Includes/Layouts/PCP_HDSC_V3/Lender";
import PreviousAddressPopUp from "../../Includes/Layouts/PCP_HDSC_V3/PreviousAddressPopUp";
import MultipleLenders from "../../Includes/Layouts/PCP_HDSC_V3/MultipleLenders";
import * as EnvConstants from "../../../Constants/EnvConstants";
import PreviousNameModal from "../../Includes/Layouts/PCP_HDSC_V3/PreviousNameModal";

const PCP_HDSC_V3Form = (props) => {
  const history = useHistory();
  const field = "pid";
  const [show, setShow] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [firstName, setFirstName] = useState();
  const [showModal, setShowModal] = useState(false);
  const [dobDayError, setDobDayError] = useState(false);
  const [dobYearError, setDobYearError] = useState("");
  const [showPreviousAddressPopUp, setShowPreviousAddressPopUp] = useState(false);
  const [showPreviousNamePopUp, setShowPreviousNamePopUp] = useState(false);
  const [showMultipleLenders, setShowMultipleLenders] = useState(false);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const { queryString } = useContext(QueryStringContext);
  const { fetchUuid } = UUID();
  const uuid = fetchUuid();
  const split_name = props.splitName;
  const [formData, setFormData] = useState({});
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const { register, errors, clearErrors, setError, trigger, getValues } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const splitForm = useRef(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    //Start Dob Validation
    const address1 =getValues().address1;
    const txtPhone =getValues().txtPhone;
    const txtEmail =getValues().txtEmail;
    const provider =getValues().provider;
    const get_val =getValues();
    if(e.target.id =="address1" ){
      section1Ref.current.scrollIntoView({ 
        behavior: 'smooth', 
        block:"start",

       });
       setTimeout(() => {
        window.scrollBy(0, 150); 
      }, 500);
      // document.getElementById('dobDay').focus()
    }
    if(txtPhone && txtEmail && provider){
      if(e.target.id =="txtPhone" || e.target.id =="txtEmail" || e.target.id =="provider"){
 
        section3Ref.current.scrollIntoView({ 
          behavior: 'smooth', 
          block:"start" ,
         });
         setTimeout(() => {
          window.scrollBy(0, -150); 
        }, 500);
        // document.getElementById('clearbtn1').focus()
      }
    }
    if (name === "dobDay" || name === "dobMonth" || name === "dobYear") {
      const dobDay = getValues().dobDay;
      const dobMonth = getValues().dobMonth;
      const dobYear = getValues().dobYear;
      const isLeapYear = (year) => {
        return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
      };
      if (dobDay && dobMonth && dobYear) {
        if(e.target.id == "dobYear" || e.target.id == "dobMonth" || e.target.id == "dobDay" ){
          section2Ref.current.scrollIntoView({ 
            behavior: 'smooth',
            block:"start" ,
           });
           setTimeout(() => {
            window.scrollBy(0, -200); 
          }, 500);
          // document.getElementById('txtPhone').focus()
        }
        const daysInMonth = {
          January: 31,
          February: isLeapYear(dobYear) ? 29 : 28,
          March: 31,
          April: 30,
          May: 31,
          June: 30,
          July: 31,
          August: 31,
          September: 30,
          October: 31,
          November: 30,
          December: 31,
        };
        if (dobMonth === 'February' && (dobDay === '30' || dobDay === '31')) {
            setError("dobDay", {
              type: "manual",
              message: "Please enter valid day."
            });
            setDobDayError(true);
            //document.getElementById('dobDay').value = '';
            return false;
        }else{
            setDobDayError(false);
            setError("dobDay", {
              type: "manual",
              message: ""
            });
        }
        if (dobMonth === 'February' && dobDay === '29' && !isLeapYear(dobYear)) {
            setError("dobYear", {
              type: "manual",
              message: "This is not a valid leap year."
            });
            setDobYearError(true);
            //document.getElementById('dobYear').value = '';
           return false;
        }else{
          setDobYearError(false);
          setError("dobYear", {
            type: "manual",
            message: ""
          });
        }
        if (parseInt(dobDay) > daysInMonth[dobMonth]) {
          setError("dobDay", {
            type: "manual",
            message: "Please enter valid day."
          });
          setDobDayError(true);
          //document.getElementById('dobDay').value = '';
          return false;
        }else{
          setDobDayError(false);
          setError("dobDay", {
            type: "manual",
            message: ""
          });
        }
      }
    }
    //End Dob Validation
    switch (name) {
      case "lstSalutation":
        setError("lstSalutation", {
          type: "manual",
          message: "",
        });
        break;
      case "txtFName":
        setError("txtFName", {
          type: "manual",
          message: "",
        });
        break;
      case "txtLName":
        setError("txtLName", {
          type: "manual",
          message: "",
        });
        break;
      case "txtEmail":
        setError("txtEmail", {
          type: "manual",
          message: "",
        });
        break;
      case "txtPhone":
        setError("txtPhone", {
          type: "manual",
          message: "",
        });
        break;
      case "dobDay":
        setError("dobDay", {
          type: "manual",
          message: "",
        });
        break;
      case "dobMonth":
        setError("dobMonth", {
          type: "manual",
          message: "",
        });
        break;
      case "dobYear":
        setError("dobYear", {
          type: "manual",
          message: "",
        });
        break;
      case "provider":
        setError("provider", {
          type: "manual",
          message: "",
        });
        break;
      case "txtPostCode":
        setError("txtPostCode", {
          type: "manual",
          message: "",
        });
        break;
      case "address1":
        setError("address1", {
          type: "manual",
          message: "",
        });
        break;
      case "signaturePad":
        setError("txtPostCode", {
          type: "manual",
          message: "",
        });
        break;
      default:
        break;
    }
  };

  const basicDetailsValidation = async (e) => {
    e.preventDefault();
    const form = splitForm.current;
    const values = getValues();
    const formData = values;
    let errorFlag = 0;
    let txtlstSalutation = await trigger("lstSalutation");
    let txtFNameResult;
    let txtLNameResult;
    let DobDayResult;
    let DobMonthResult;
    let DobYearResult;
    let txtEmailResult;
    let txtPhoneResult;
    let postCodeResult;
    let address1 = false;
    let provider;
    // let isChecked;
    var signaturePadResult = formData.signature_data;

    if (txtlstSalutation) {
      txtFNameResult = await trigger("txtFName");
    } else {
      document.getElementById("lstSalutation").focus();
      return false;
    }
    if (txtFNameResult) {
      txtLNameResult = await trigger("txtLName");
    } else {
      document.getElementById("txtFName").focus();
      return false;
    }
    if (txtLNameResult) {
      postCodeResult = await trigger("txtPostCode");
    } else {
      document.getElementById("txtLName").focus();
      return false;
    }
    if (postCodeResult) {
      address1 = await trigger("address1");
    } else {
      document.getElementById("txtPostCode").focus();
      return false;
    }
    if (address1 === false) {
      document.getElementById("address1").focus();
      return false;
    } else {
      DobDayResult = await trigger("dobDay");
    }
    if (DobDayResult) {
      if(dobDayError){
        setError("dobDay", {
          type: "manual",
          message: "Please enter valid day."
        });
      }else{
        DobMonthResult = await trigger("dobMonth");
      }
    } else {
      document.getElementById("dobDay").focus();
      return false;
    }
    if (DobMonthResult) {
      if(dobYearError){
        setError("dobYear", {
          type: "manual",
          message: "This is not a valid leap year."
        });
      }else{
        DobYearResult = await trigger("dobYear");
      }
    } else {
      document.getElementById("dobMonth").focus();
      return false;
    }
    if (DobYearResult) {
      txtPhoneResult = await trigger("txtPhone");
    } else {
      document.getElementById("dobYear").focus();
      return false;
    }
    if (txtPhoneResult) {
      txtEmailResult = await trigger("txtEmail");
    } else {
      document.getElementById("txtPhone").focus();
      return false;
    }
    if (txtEmailResult) {
      provider = await trigger("provider");
    } else {
      document.getElementById("txtEmail").focus();
      return false;
    }
    if (provider) {
      if (signaturePadResult === "") {
        setError("signaturePad", {
          type: "manual",
          message: "Signature Required",
          shouldFocus: true,
        });
        document
          .getElementById("signaturePad")
          .scrollIntoView({ behavior: "smooth", block: "center" });
        return false;
      } else {
        signaturePadResult = true;
      }
    } else {
      document.getElementById("provider").focus();
      return false;
    }
    // if (signaturePadResult != "") {
    //   isChecked = await trigger("isChecked");
    // } else {
    //   document.getElementById("isChecked").focus();
    // }
    if (
      !txtlstSalutation ||
      !txtFNameResult ||
      !txtLNameResult ||
      !postCodeResult ||
      !address1 ||
      !DobDayResult ||
      !DobMonthResult ||
      !DobYearResult ||
      !txtPhoneResult ||
      !txtEmailResult ||
      !signaturePadResult
      //!isChecked
    ) {
      errorFlag = 1;
    }
    if (errorFlag == 0) {
      setShowPreviousNamePopUp(true);
      setShowPreviousAddressPopUp(true);
    } else {
      console.log("error");
    }
  }
  const formSubmit = async (type) => {
    props.showLoader('d-block');
    const providerSlug = JSON.parse(localStorage.getItem('providerSlug') || '[]');
    const multipleProviderSlug = JSON.parse(localStorage.getItem('multipleProviderSlug') || '[]');
    const updatedProviders = [...providerSlug, ...multipleProviderSlug];
    localStorage.setItem('finaceProviders', JSON.stringify(updatedProviders));
    const formDataValues = getValues();
    let financeProvidersArray = [];
    if (type == 'submit') {
      financeProvidersArray = [formDataValues.provider, ...formData.newSelectedSubCategories];
    } else {
      financeProvidersArray = [formDataValues.provider];
    }
    formDataValues.financeProvider = financeProvidersArray;
    if (formData.prevAddress) {
      formDataValues.txtCountry1 = formData.prevAddress.txtCountry1;
      formDataValues.txtCounty1 = formData.prevAddress.txtCounty1;
      formDataValues.txtPrevHouseNumber_1 = formData.prevAddress.txtPrevHouseNumber_1;
      formDataValues.txtPrevPostCode_1 = formData.prevAddress.txtPrevPostCode_1;
      formDataValues.txtPrevaddress1_1 = formData.prevAddress.txtPrevaddress1_1;
      formDataValues.txtPrevroute_1 = formData.prevAddress.txtPrevroute_1;
      formDataValues.txtPrevAddress3 = formData.prevAddress.txtPrevAddress3;
      formDataValues.txtTown1 = formData.prevAddress.txtTown1;
    }
    localStorage.setItem("formData", JSON.stringify(formData));
    const formSUbmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      formDataValues,
      "split_form_submit",
      split_name,
      queryString,
      visitorParameters.data
    );
    if (formSUbmitResult.data.status === "Success") {
      props.showLoader('d-none');
      if (queryString != null) {
        if (queryString.indexOf("&" + field + "=") !== -1) {
          if (EnvConstants.AppConversionPixel === "true") {
            setShow(true);
            history.push(
              `/fbpixel?split_name=${props.splitName}&next_page=${props.nextPage}`
            );
            window.scrollTo(0, 0);
          } else {
            window.scrollTo(0, 0);
            history.push(
              `/${props.nextPage}?uuid=${visitorParameters.visitor_parameters.uuid}&split_name=${props.splitName}`
            );
          }
        } else {
          window.scrollTo(0, 0);
          history.push(
            `/${props.nextPage}?uuid=${visitorParameters.visitor_parameters.uuid}&split_name=${props.splitName}`
          );
        }
      } else {
        window.scrollTo(0, 0);
        history.push(
          `/${props.nextPage}?uuid=${visitorParameters.visitor_parameters.uuid}&split_name=${props.splitName}`
        );
      }
    }
  }
  useEffect(() => {
    setFirstName(formData.txtFName);
  }, [formData])
  return (
    <div>
      <form
        onSubmit={(e) => e.preventDefault()}
        ref={splitForm}
        name="split_form"
        id="user_form"
        method="POST"
        autoComplete="off">
        <section className={`bg-gray p-2 sect02 ${props.personalDetailsSection}`}>
          <div className="bg-inner bg-form  rounded-2 p-lg-5 p-md-5 p-sm-4 p-4 ">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="form-style">

                    <div className="row">
                      <PersonalDetails
                        validation={register}
                        validationMsg={errors}
                        trigger={trigger}
                        clearErrors={clearErrors}
                        setError={setError}
                        handleChange={handleChange}
                        formData={formData}
                        firstName={firstName}
                      />
                      <PostCode
                        validation={register}
                        validationMsg={errors}
                        trigger={trigger}
                        clearErrors={clearErrors}
                        setError={setError}
                        handleChange={handleChange}
                      />
                    </div>
                    <DateOfBirth
                      textDob="Date Of Birth"
                      validation={register}
                      validationMsg={errors}
                      trigger={trigger}
                      handleChange={handleChange}
                      section1Ref = {section1Ref}
                    />
                    <ContactDetails
                      validation={register}
                      validationMsg={errors}
                      trigger={trigger}
                      clearErrors={clearErrors}
                      setError={setError}
                      handleChange={handleChange}
                      uuid={uuid}
                      setEmailError={setEmailError}
                      setPhoneError={setPhoneError}
                      section2Ref = {section2Ref}
                    />
                    <Lender
                      validation={register}
                      validationMsg={errors}
                      setFormData={setFormData}
                      handleChange={handleChange}
                    />
                    <Signature
                      validation={register}
                      validationMsg={errors}
                      trigger={trigger}
                      clearErrors={clearErrors}
                      handleChange={handleChange}
                      setError={setError}
                      getValues={getValues}
                      formData={formData}
                      setFormData={setFormData}
                      section3Ref={section3Ref}
                    />
                    <div className="mt-lg-5  mt-md-5 mt-sm-3 mt-4">
                      <Button
                        type="button"
                        className="btn-comm2 p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-3"
                        buttonText={
                          <span className="fw-bold">Next <i className="arrowicon"> <img src={AngleArrow} alt="" /></i>
                          </span>
                        }
                        id="next2"
                        onClick={basicDetailsValidation}
                      />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <PreviousAddressPopUp
          showPreviousAddressPopUp={showPreviousAddressPopUp}
          setShowPreviousAddressPopUp={setShowPreviousAddressPopUp}
          formData={formData}
          validation={register}
          validationMsg={errors}
          trigger={trigger}
          clearErrors={clearErrors}
          setError={setError}
          handleChange={handleChange}
          showLoader={props.showLoader}
          setShowMultipleLenders={setShowMultipleLenders}
          showPersonalDetails={props.showPersonalDetails}
          getValues={getValues}
          setFormData={setFormData}
        />
        <PreviousNameModal
          showPreviousNamePopUp={showPreviousNamePopUp}
          setShowPreviousAddressPopUp={setShowPreviousAddressPopUp}
          setShowPreviousNamePopUp={setShowPreviousNamePopUp}
          showPrivacy={showModal}
          validation={register}
          validationMsg={errors}
          setError={setError}
          trigger={trigger}
          formData={formData}
          setFormData={setFormData}
        />
        <MultipleLenders
          showMultipleLenders={showMultipleLenders}
          uuid={uuid}
          splitName={split_name}
          setFormData={setFormData}
          formData={formData}
          validation={register}
          formSubmit={formSubmit}
        />
      </form>
    </div>
  );
};

export default PCP_HDSC_V3Form;
