import React from "react";

const HiddenField = ({ name, inputValue, inputId, className }) => {
  return (
    <>
      <input className={className} type="hidden" name={name} id={inputId} value={inputValue} />
    </>
  );
};
export default HiddenField;
