import React, { useState } from "react";
import AdvFooter from "../../Includes/Layouts/ADV_1/AdvFooter";
import { useContact } from "../../../Hooks/useContact";
import logo from '../../../assets/img/logo.png';
const ADV1_Contact = () => {
  const { contactSubmit, constactLoading } = useContact();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [phoneValid, setPhoneValid] = useState(false);
  const [messageValid, setMessageValid] = useState(false);
  const [contactSuccess, setContactSuccess] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email == "") {
      setEmailValid(true);
    } else if (phone == "" || phone.replace(/\D/g, "").length == 0) {
      setPhoneValid(true);
    } else if (message == "") {
      setMessageValid(true);
    } else {
      const txtPhone = phone.replace(/\D/g, "");
      try {
        const response = await contactSubmit(phone, email, message);
        if (response.data.status === "Success") {
          setTimeout(() => setContactSuccess(true), 1000);
          setEmail("");
          setPhone("");
          setMessage("");
        }
      } catch (e) {
        console.warn(e);
      }
    }
  };
  const checkPhoneValid = (e) => {
    var regex = new RegExp("^[0-9]+$");
    var key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (!regex.test(key)) {
      e.preventDefault();
      return false;
    }
  };
  return (
    <>
      <div className="ADV1_Contact">
        <header className="border-bottom pb-3">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12 text-center d-none d-sm-none d-md-block d-lg-block">
                <p className="adv_txt m-0 text-black-50 ">Auisquam</p>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 logo">
                <a href="#" dataId="SITE_lOGO" className="text-center">
                  <img src={logo} alt="" />
                </a>
                <p className="adv_txt d-block d-sm-block d-md-none d-lg-none m-0">
                  Auisquam
                </p>
              </div>
            </div>
          </div>
        </header>
        <section className="contact_sec">
          <div className="container">
            <div className="row">
              <div className="offset-lg-3 col-lg-6 col-12">
                <div className="contact_area mb-3">
                  <div className="col-lg-12 text-center col-12">
                    <h1>Contact Us</h1>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label for="">Email Address</label>{email}
                      <input
                         type="email" 
                         name="emailid" 
                         id="emailid" 
                         className="form-control"
                         placeholder="Email Address" 
                         onChange={(e) => setEmail(e.target.value)}
                         value={email} 
                         onBlur={() => setEmailValid(false)}
                      />
                    <span id="email_err" className='error_msg' style={emailValid ? { display: "block" } : { display: "none" }}>Please Enter Email Address</span>
                    </div>
                    <div className="form-group">
                      <label for="">Phone Number</label>
                      <input
                        type="text"
                        name="phonenumber"
                        id="phonenumber"
                        className="form-control"
                        placeholder="Phone Number"
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                        onKeyPress={(e) => checkPhoneValid(e)}
                        onBlur={() => setPhoneValid(false)}
                        maxLength="11"
                      />
                    <span id="phone_err" className='error_msg' style={phoneValid ? { display: "block" } : { display: "none" }}>Please Enter Phone Number</span>
                    </div>
                    <div className="form-group ">
                      <label for="comment">Message</label>
                      <textarea
                        className="form-control"
                        rows="5"
                        name="message"
                        id="message"
                        placeholder="Message"
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                        onBlur={() => setMessageValid(false)}
                      ></textarea>
                      <span id="message_err" className='error_msg' style={messageValid ? { display: "block" } : { display: "none" }}>Please Enter Message</span>
                    </div>
                    <div className="text-center">
                      <button
                        type="submit"
                        id="contactbutton"
                        className="btn btn-success send_btn center-block"
                      >
                        Submit
                      </button>
                      <span id="contact_success" className="contact_success" style={contactSuccess ? { display: "block" } : { display: "none" }}>Your email has been sent successfully</span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <AdvFooter />
    </>
  );
};

export default ADV1_Contact;
