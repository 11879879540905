import React from 'react'
import Hendersonslogo from "../../../assets/PCP_HDSC_V1/img/hendersons_logo.png"
// import "../../../assets/PCP_HDSC_V1/css/custom.scss"

const Header_PCP_HDSC_V1 = ({ extraClass }) => {
  return (
    <>
      <div className='pcp_siteHome'>
        <header id="header" className={`pt-0 ${extraClass}`}>
          {/* {extraClass &&
            <div className="p-1 green-sec" style={{ position: "relative" }}>Did you know?<span className='mx-1'>🔊</span> Henderson Claims is a Claims Management Company (CMC). You can claim for free, without using a CMC, first to your lender or to the FOS/FSCS.</div>
          } */}
          <div className={`topnav px-0 px-md-2`} id="myTopnav" style={{ paddingTop: "0px" }}>

            <div className="container">
              <div className="row">
                <div className="col-12 px-0 px-md-2">
                  <img className="logo" src={Hendersonslogo} alt="" />
                  {/* <a href="#faq_sec">FAQ</a> */}
                  {/* <a href="#testimonial">Testimonial</a> */}
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  )
}

export default Header_PCP_HDSC_V1