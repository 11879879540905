import React from "react";
import info from "../../../../assets/PCP_HDSC_V1/img/info.png";
import nextgreen from "../../../../assets/PCP_HDSC_V1/img/next-green.png";
import PostCode_PCP_HDSC_V1 from "../../../Includes/Layouts/PCP_HDSC_V1/PostCode_PCP_HDSC_V1";
import PrivacyPolicy from "../../../../docs/Henderson Claims - Privacy Policy - June 2024.pdf";
import Button from "../../../UI/Button";

const PostcodeMainSection = (props) => {
  const ProceedClick = () => {
    const {
      txtPostCode,
      address1,
      txtHouseNumber,
      txtTown,
      txtCountry
    } = props.getValues();
    if (!txtPostCode) {
      props.setError("txtPostCode", {
        type: "manual",
        message: "Please Enter Valid Postcode",
      });
    } else if (!address1) {
      props.setError("address1", {
        type: "manual",
        message: "Please select address",
      });
    } else if (!txtHouseNumber) {
      props.setError("txtHouseNumber", {
        type: "manual",
        message: "Please Enter Address Line 1",
      });
    } else if (!txtTown) {
      props.setError("txtTown", {
        type: "manual",
        message: "Please Enter Town",
      });
    } else if (!txtCountry) {
      props.setError("txtCountry", {
        type: "manual",
        message: "Please Enter Country",
      });
    } else {
      props.setShowModal(true);
    }
  };
  
  return (
    <div className={`slide_6Dec ${props.className}`}>
      <h2 className="animate__animated animate__fadeInUp animate__delay-.5s animated-element pt-5 pt-md-3">
        According to your responses, it seems you might be eligible for
        compensation.
      </h2>
      <div className="px-3 py-3 widget_mobi borderx">
        <p className="sub_quesHead animate__animated animate__fadeInUp animate__delay-.5s animated-element">
          To proceed, please finalise your registration by providing your
          current address.
        </p>
        <div className="form_drop col-lg-12 col-12">
          <div
            className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s animated-element"
            style={{ zIndex: 2, position: "relative" }}
          >
            <div className="enter_postcode_add animate__animated animate__fadeInUp animate__delay-.5s animated-element">
              <label>Postcode</label>
              <PostCode_PCP_HDSC_V1
                validation={props.register}
                validationMsg={props.errors}
                trigger={props.trigger}
                clearErrors={props.clearErrors}
                handleChange={props.handleChange}
                setError={props.setError}
                setValue={props.setValue}
                setPostCodeError={props.setPostCodeError}
              />
              {props.postCodeError &&
                !props.errors.txtPostCode &&
                !props.errors.txtHouseNumber &&
                !props.errors.txtAddress2 &&
                !props.errors.txtCounty &&
                !props.errors.txtTown &&
                !props.errors.txtCountry && (
                  <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                    {props.postCodeError}
                  </p>
                )}

              {props.errors.address1 && (
                <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                  Please select address
                </p>
              )}

              <p className="tiny_note">
                <img src={info} className="pe-1" alt="" />
                Your address is required for a smooth claim process
              </p>
              <div className="col-lg-5 col-12"></div>
            </div>
          </div>
        </div>
        <div
          className="box_button animate__animated animate__fadeInUp animate__delay-.5s animated-element"
          id="subRegNo6"
        >
          <div className="row align-items-center mt-4">
            <div className="col-4 text-center">
              <Button
                type="button"
                data-type="back"
                id="backStep-5"
                name="back09"
                className="msf-nav-button backbutton"
                onClick={() => props.previousSlideChange("back09")}
                buttonText={
                  <span className="btntext">
                    <img src={nextgreen} alt="" />
                    Back <i className="arrowicon"></i>
                  </span>
                }
              />
            </div>
            <div className="col-8">
              <div className="bg-1 hme_btn">
                <Button
                  type="button"
                  id="nextslideCl_006"
                  value="Next"
                  aria-label="submitid"
                  name="nextslideCl_006"
                  className="button w-100 next-btn"
                  buttonText={
                    <span className="fw-bold" style={{ pointerEvents: "none" }}>
                      PROCEED{" "}
                    </span>
                  }
                  onClick={ProceedClick}
                />
              </div>
            </div>
            <div className="col-12 text-center tiny_note">
              By submitting your details and proceeding from this point you
              agree to our{" "}
              <a href={PrivacyPolicy} target="_blank">
                <u>Privacy Policy</u>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostcodeMainSection;
