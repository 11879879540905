import React from 'react'

const FaqAccordion = () => {
  return (
    <section id="faq_sec" className="faq_area">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1 col-12">
            <h2 className="text-white mb-5">Mis-sold Car Finance FAQs</h2>
            <div className="accordion accordion-flush" id="accordionFlushExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    What is PCP?
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                  style={{}}
                >
                  <div className="accordion-body">
                    <p className="mb-0">
                      {" "}
                      Personal contract purchases (PCP) are a very popular way of
                      financing a car, also known as personal contract hire. You
                      typically pay a deposit, and then a monthly fee for the car
                      for a set number of years. At the end of the deal, you can
                      usually hand the car back for no extra cost, pay a lump sum
                      to enable you to keep the car, or put the value towards a
                      different car with the same dealership.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    What is Hire Purchase?
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <p className="mb-0">
                      Hire Purchase is another way to finance a car. You’ll
                      typically pay a deposit, then pay a set payment each month
                      until you have paid off the value of the car. The loan is
                      secured against the car, so you don’t own it until you’ve
                      made all of the payments.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    How long will it take to claim for mis-sold car finance?
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <p className="mb-0">
                      There is no set time period to claim for mis sold car
                      finance loans. If the finance provider accepts
                      responsibility straight away and agrees to pay you back the
                      money you’re owed, it could take a matter of months.
                      However, if the finance provider disputes the claim, it
                      could take much longer. The final decision on your claim
                      will likely be linked to the FCA’s conclusion of their
                      investigation into the industry, but it is important that
                      you lodge your claim as soon as possible to join the queue.
                      One of our expert solicitors will be able to assist you with
                      this.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-headingFour"
                  >
                    How much do mis-sold car finance claims cost?
                  </button>
                </h2>
                <div
                  id="flush-collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingFour"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <p className="mb-0">
                      We work on a ‘no win no fee’ basis, meaning that if you do
                      not receive any compensation for car finance mis selling,
                      you will not have to pay us. Our expert solicitors will
                      manage the process of claiming back your lost funds from
                      start to finish for a small percentage fee of your
                      reimbursed costs. Contact us today and we can swiftly advise
                      you on the next steps and start the process of getting the
                      funds you are owed back to you.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingFive">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFive"
                    aria-expanded="false"
                    aria-controls="flush-headingFive"
                  >
                    What is the mis-sold car finance average payout?
                  </button>
                </h2>
                <div
                  id="flush-collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingFive"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <p className="mb-0">
                      The payout will depend on your specific circumstances and
                      how much you paid that you shouldn’t have done. However,
                      many people may be due back thousands of pounds. So, if you
                      believe you have been affected, it is worthwhile to
                      investigate, which our expert solicitors can assist you
                      with.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingSix">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseSix"
                    aria-expanded="false"
                    aria-controls="flush-headingSix"
                  >
                    What is a hidden commission?
                  </button>
                </h2>
                <div
                  id="flush-collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingSix"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <p className="mb-0">
                      Hidden commission is when you are sold a vehicle on a PCP or
                      Hire Purchase finance agreement, but you are either not told
                      that the dealer will be making commission, or they do not
                      tell you the extent of the commission. Many people
                      purchasing a car before January 2021 were not made aware
                      that the dealer would also earn more commission for selling
                      them the finance product (the PCP or Hire Purchase
                      agreement), on top of any commission they make for selling
                      the car itself. Dealers should always be transparent about
                      the amount of commission they are earning, to help ensure a
                      transparent sale and reduce the risk of the dealer offering
                      finance products based on commission and not what’s best for
                      their customer.If you were sold a vehicle on a PCP or Hire
                      Purchase agreement and the dealer did not make their
                      commission clear to you, this may be classed as hidden
                      commission and you may have a mis-sold car finance claim.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FaqAccordion