export const vehiclequestionaireV5 = {
    questionId: 3,
    question: "When did you purchase this vehicle?",
    answers: [
        {
            label: "12 September 2020",
            value: "6"
        },
        {
            label: "16 March 1999",
            value: "7"
        },
        {
            label: "6 March 1999",
            value: "7"
        },
        {
            label: "Other",
            value: "7"
        }
    ]
};