import React from 'react'
import bbc from "../../../../assets/PCP_HDSC_V5/img/bbc.png"
import guardian from "../../../../assets/PCP_HDSC_V5/img/guardian.png"
import telegraph_white from "../../../../assets/PCP_HDSC_V5/img/telegraph-white.png"

const FeaturedIn = () => {
  return (
    <section className="sec_design section_design2 featured    ">
      <div className="statsShadow pt-4">
        <h2 className="pt-lg-5 pt-md-3 pt-sm-3 pt-0 text-center">
          {" "}
          As featured in{" "}
        </h2>
        <div className="container">
          <div className="row py-lg-5 py-md-5 py-sm-4 py-3">
            <div className="col-lg-4 mb-3">
              <div className="box-featured">
                <img height={40} src={bbc} alt="" />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="box-featured">
                <img height={40} src={guardian} alt="" />
              </div>
            </div>
            <div className="col-lg-4 mb-3">
              <div className="box-featured">
                <img height={40} src={telegraph_white} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturedIn