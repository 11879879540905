import React, { useState } from 'react';

const ContractModal = ({ contractModal, setContractModal }) => {
  return (
    <>
      {/* Modal  contract*/}
      <div
        // className="modal fade"
        className={`modal fade show ${contractModal ? "d-block" : "d-none"}`}
        id="contractmodal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl ">
          <div className="modal-content footermodal">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Pre-contract Information
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setContractModal(false)}
              />
            </div>
            <div className="modal-body d-block">
              <h4>What will we do for you?</h4>
              <p>
                We will assist you in identification of claims, preparation of
                required paperwork and representation in making a claim. We will
                provide an independent surveyor to assess the damage at your home.
                We will liaise with financial institutions, the Financial Ombudsman
                Service (FOS), The Pensions Ombudsman (TPO) and/or Financial
                Services Compensation Scheme (FSCS) on your behalf. We will provide
                you with advice in regard to making the claim, any offer of
                compensation you receive and if the financial institution rejects
                your claim, advice on the next steps and whether your claim can be
                escalated to the FOS/TPO.{" "}
              </p>
              <h4>How will we keep you updated throughout the claim?</h4>
              <p>
                When there are any material developments throughout your claim, we
                will update you within 10 working days. Where there are no material
                developments, we will update you at least every 6 months in writing.
              </p>
              <h4>What will you need to do?</h4>
              <p>
                You may need to allow access to your home for a survey of the
                damage. You will need to sign paperwork which is required for us to
                submit your claim and you may also need to provide documentation
                such as account statements or proof of identity documents. You will
                need to provide us with information which will be used to formulate
                your claim such as details of the installer and the basis of your
                claim.{" "}
              </p>
              <h4>How much will you have to pay?</h4>
              <p>
                If your claim is successful, the Service Charge is a percentage of
                your gross redress or the maximum amount listed in column (2) below,
                whichever is lower.
              </p>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col" width="25%">
                      (1) Compensation amount
                    </th>
                    <th scope="col" width="25%">
                      (2) Your Service Charge (+VAT)
                    </th>
                    <th scope="col" width="25%">
                      (3) Example Compensation
                    </th>
                    <th scope="col" width="25%">
                      (4) Example Service Charge (inc VAT)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>£1 - £1,499 </td>
                    <td>30% up to a max of £420 +VAT</td>
                    <td>£100</td>
                    <td>£36</td>
                  </tr>
                  <tr>
                    <td>£1,500 - £9,999</td>
                    <td>28% up to a max of £2,500 +VAT</td>
                    <td>£6,000</td>
                    <td>£2,016</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>£10,000 - £24,999 </td>
                    <td>25% up to a max of £5,000 +VAT</td>
                    <td>£11,000</td>
                    <td>£3,300</td>
                  </tr>
                  <tr>
                    <td>£25,000 - £49,999 </td>
                    <td>20% up to a max of £7,500 +VAT</td>
                    <td>£40,000</td>
                    <td>£9,000</td>
                  </tr>
                  <tr>
                    <td>£50,000 +</td>
                    <td>15% up to a max of £10,000 +VAT</td>
                    <td>£80,000</td>
                    <td>£12,000</td>
                  </tr>
                </tbody>
              </table>
              <p>
                The examples shown in columns (3) and (4) above are for illustration
                purposes only and are not to be taken as an estimate of the likely
                amounts to be recovered. The amount You receive may be more or less
                than these examples.
              </p>
              <h4>Can you change your mind?</h4>
              <p>
                You can cancel at any time after the 14 day cooling period and
                before an offer of Compensation is made, however we reserve the
                right to apply a Fee to cover Our reasonable costs for any work
                undertaken in processing Your Claim up to the date of cancellation.
                Such charges will be calculated at £120 + VAT per hour as follows:
              </p>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Stage of Claim</th>
                    <th scope="col">Hours (maximum)</th>
                    <th scope="col">Fee (maximum)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      When We are carrying out/have completed a detailed
                      questionnaire as part of our investigation.{" "}
                    </td>
                    <td>2</td>
                    <td>£300</td>
                  </tr>
                  <tr>
                    <td>
                      When We are carrying out/have completed a detailed full review
                      of your case (this is not the same as any Free Check
                      assessment you may have already had).{" "}
                    </td>
                    <td>10</td>
                    <td>£1,500</td>
                  </tr>
                  <tr>
                    <td>When We have submitted Your Claim to the Third Party. </td>
                    <td>14</td>
                    <td>£2,100</td>
                  </tr>
                  <tr>
                    <td>
                      When We have liaised with the Third Party in respect of your
                      Claim (before any offer of compensation is made).
                    </td>
                    <td>18</td>
                    <td>£2,700</td>
                  </tr>
                </tbody>
              </table>
              <h4>Can you take alternative steps to pursue a claim?</h4>
              <p>
                You can of course pursue the claim yourself without using a CMC and
                without cost. You will first need to complain directly to the
                financial institution and if this is unsuccessful, you can complain
                through the FOS/TPO. Certain claims may fall under the scope of the
                FSCS which you can access directly. You may be entitled to legal
                advice, assistance and representation through your membership of a
                trade union or under a contract of insurance, therefore you may wish
                to investigate whether you are able to pursue this claim through
                these methods.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setContractModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Modal  contract end*/}
    </>
  );
};

export default ContractModal;
