import React from "react";

const Telephone = ({
  placeholder,
  name,
  className,
  validation,
  onBlur,
  onKeyPress,
  phoneStyle,
  value,
  id,
  onChange,
  maxlength,
  autoComplete
}) => {
  return (
    <>
      <input
        type="tel"
        name={name}
        className={className}
        placeholder={placeholder}
        ref={validation}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        style={phoneStyle}
        defaultValue={value}
        id={id}
        maxLength={maxlength}
        autoComplete={autoComplete}
      />
    </>
  );
};
export default Telephone;
