import React, { useState } from 'react'
import logo_white from "../../../../assets/PCP_HDSC_V5/img/logo-white.png"
import PrivacyModal from '../PCP_HDSC_V1/PrivacyModal'
import TermsModal from '../PCP_HDSC_V1/TermsModal'
import ContractModal from '../PCP_HDSC_V1/ContractModal'

const Footer = () => {
  const [privacyModal, setPrivacyModal] = useState(false);
  const [termsModal, setTermsModal] = useState(false);
  const [contractModal, setContractModal] = useState(false);
  const handleClick = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <div className="footer pt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 text-center">
              <div className="mb-2">
                <a href="#" onClick={handleClick}>
                  <img height={40} src={logo_white} alt="" />
                </a>
              </div>
            </div>
            <div className="col-lg-9 col-12 ps-lg-4 ps-md-4 ps-sm-1 ps-1">
              <p>
                Henderson Claims is a Claims Management Company (CMC). You can claim for free, without using a CMC, first to your lender or to the FOS/FSCS.
              </p>
              <p>
                Hendersons is a trading style of Henderson Claims Limited. Henderson
                Claims Limited is authorised and regulated by the Financial Conduct
                Authority with reference number FRN:836041. Henderson Claims Limited
                is a Claims Management Company (CMC). Registered with the
                Information Commissioner's Office: registration number ZA147049.
                Registered address: Office 206, Ashton Old Baths, Stamford Street
                West, Ashton-under-Lyne, OL6 7FW. Registered in England and Wales,
                Company number 07646017. VAT number: 240 8769 88.
              </p>
              <div className="copyright">
                <ul>
                  <li><a onClick={() => setPrivacyModal(true)}>Privacy Policy</a></li>
                  <li><a onClick={() => setTermsModal(true)}>Terms &amp; Conditions</a></li>
                  <li><a onClick={() => setContractModal(true)}>Pre-contract Information</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-modal">
        <PrivacyModal
          privacyModal={privacyModal}
          setPrivacyModal={setPrivacyModal}
        />
        <TermsModal
          termsModal={termsModal}
          setTermsModal={setTermsModal}
        />
        <ContractModal
          contractModal={contractModal}
          setContractModal={setContractModal}
        />
      </div>
    </>
  )
}

export default Footer