import React from "react";

const AddressTextField = ({
  name,
  type,
  placeholder,
  className,
  onChange,
  onBlur,
  validation,
  validationMsg,
  onkeypress,
  value,
  maxlength,
  style,
  readonly,
  id,
  autoComplete
}) => {
  return (
    <>
      <input
        name={name}
        id={id}
        placeholder={placeholder}
        className={className}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={onkeypress}
        ref={validation}
        value={value}
        maxLength={maxlength}
        style={style}
        readOnly={readonly}
        autoComplete={autoComplete}
      />
      {validationMsg && (
        <>
          <p className="validate address1_error error_msg tiny_note animate__animated animate__fadeInUp animate__delay-.5s">{validationMsg}</p>
        </>
      )}
    </>
  );
};
export default AddressTextField;
