import React, { useEffect, useState } from 'react'
import "../../../assets/PCP_HDSC_V5/css/custom.scss"
import Footer from '../../Includes/Layouts/PCP_HDSC_V5/Footer'
import FaqAccordion from '../../Includes/Layouts/PCP_HDSC_V5/FaqAccordion'
import FeaturedIn from '../../Includes/Layouts/PCP_HDSC_V5/FeaturedIn'
import Header from '../../Includes/Layouts/PCP_HDSC_V5/Header'
import PCP_HDSC_V5Form from '../../Forms/PCP_HDSC_V5/PCP_HDSC_V5Form'
import AdtopiaLoadLP from '../../../Utility/AdtopiaLoadLP'
import FbViewPixel from '../../../Utility/FbViewPixel'

const PCP_HDSC_V5 = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }, [])

  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="PCP_HDSC_V5" />
      <FbViewPixel />

      <div className="PCP_HDSC_V5">
        <div className="__PCPtcg">
          <div className="bg_lol">
            {/*TOP NAVIGATION*/}
            <Header />
            <section className="cs_hero cs_style_1 sec_design section_design1">
              <div className="cs_hero_wrap cs_bg_filed">
                {/*Gradient*/}
                <div className="container-md  ">
                  <PCP_HDSC_V5Form />
                </div>
              </div>
            </section>
          </div>
          <FeaturedIn />
          <FaqAccordion />
          <Footer />
        </div>
      </div>
    </>
  )
}

export default PCP_HDSC_V5