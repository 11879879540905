const GTMDataLayer = (props) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "slideclick",
      question: props.question,
      answer: props.answer,
    });

  return <></>;
};

export default GTMDataLayer;