import React, { useState } from 'react'
import bbc from "../../../../assets/PCP_HDSC_V1/img/bbc.png"
import guardian from "../../../../assets/PCP_HDSC_V1/img/guardian.png"
import telegraph from "../../../../assets/PCP_HDSC_V1/img/telegraph-white.png"
import ppi from "../../../../assets/PCP_HDSC_V1/img/ppi.png"
import Accordion from './Accordion'

const FeaturedinAndAccordion = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <section className="featured animate__animated animate__fadeInUp animate__delay-.1s animated-element">
        <div className="container-fluid badges_dec animate__animated animate__fadeInUp animate__delay-.1s animated-element">
          <h2>The mis-sold car finance scandal has featured in:</h2>
          <div className="row jb_cont animate__animated animate__fadeInUp animate__delay-.1s animated-element">
            <div className="col-4">
              <img src={bbc} alt="" />
            </div>
            <div className="col-4">
              <img src={guardian} alt="" />
            </div>
            <div className="col-4">
              <img src={telegraph} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="decs_view d-none d-md-block">
        <div className="container-fluid">
          <div className="row">
            <div className="col-4 p-0">
              <section className="claim-sec">
                <div className="container">
                  <div className="card">
                    <div className="box">
                      <div className="content text-center">
                        <img className="card-png-icon" src={ppi} alt="" />
                        <h3>
                          It’s easy to check if you may have a claim in just
                          60 seconds
                        </h3>
                        <a
                          href="#"
                          className="element-button-link cta click-find element-button"
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          <span className="sml-find">Check Now</span>
                          <svg
                            width="13px"
                            height="10px"
                            viewBox="0 0 13 10"
                          >
                            <path d="M1,5 L11,5" />
                            <polyline points="8 1 12 5 8 9" />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="col-8 p-0">
              <Accordion />
            </div>
          </div>
        </div>
      </section>
      <section className="claim-sec d-block d-md-none">
        <div className="container">
          <div className="card">
            <div className="box">
              <div className="content text-center">
                <img className="card-png-icon" src={ppi} alt="" />
                <h3>
                  It’s easy to check if you may have a claim in just 60
                  seconds
                </h3>
                <a
                  href="#"
                  className="element-button-link cta click-find element-button"
                >
                  <span className="sml-find">Check Now</span>
                  <svg width="13px" height="10px" viewBox="0 0 13 10">
                    <path d="M1,5 L11,5" />
                    <polyline points="8 1 12 5 8 9" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Accordion
        extraParentClasses=" d-block d-md-none"
      />
    </>
  )
}

export default FeaturedinAndAccordion