import React from 'react'
import car from "../../../../assets/PCP_HDSC_V5/img/car.gif"

const UnqualifiedModal = ({ unqualifiedModalBody }) => {
  return (
    <>
      <div
        className={`modal fade ${unqualifiedModalBody ? "show" : "d-none"}`}
        id="Sry_msgpops3"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="popupsLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s ">
            <div className="modal-body">
              <div className="cont_flex">
                <img src={car} className="gif_img" alt="" />
                <p>
                  {unqualifiedModalBody}
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <div className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s">
                <div className="bg-1">
                  <input
                    type="button"
                    className=" button wb_5 button--moema button--text-thick button--text-upper button--size-s h60_click blick_notice"
                    id="backPlate_005"
                    defaultValue="Back"
                    data-bs-dismiss="modal"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UnqualifiedModal