import { Api, CommonEmailValidateApi } from "../api/Api";
import * as EnvConstants from "../Constants/EnvConstants";
import { useErrorWebhook } from "./useErrorWebhook";

export const useEmailValidation = () => {

  const { ErrorWebhookTrigger } = useErrorWebhook();
  const emailValidation = async (email, uuid) => {
    let emailVal = 1;
    const getValidation = await CommonEmailValidateApi.post(
      "api/validate?ProjectCode=" + EnvConstants.AppAdtopiaUPID + '&Environment=' + EnvConstants.AppEnv + '&Email=' + email + '&uuid=' + uuid,
      {}
    ).catch(function (error) {
      const erroMessage = {
        serviceName: 'EMAIL VALIDATION',
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      emailVal = 0;
      ErrorWebhookTrigger(erroMessage);
    });
    if (emailVal == 0) {
      let mailformat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!email.match(mailformat)) {
        return {
          data: {
            status_code: 0
          }
        }
      } else {
        return {
          data: {
            status_code: 1
          }
        }
      }
    } else {
      return getValidation;
    }
  };
  return { emailValidation };
};
