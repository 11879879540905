import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const Cookiespolicy = ({ showstatus, hidepopup }) => {
  return (
    <>
      <Modal show={showstatus} onHide={hidepopup} size="lg" className='footclass' dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title">
        <Modal.Header className='model-hd-bg' closeButton>
          <Modal.Title className='cookie'>Cookies</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <h5>Cookie Policy</h5>
            <p>This website uses cookies to better the user's experience while visiting the website. Where applicable, this website uses a cookie control system allowing the user on their first visit to the website to allow or disallow the use of cookies on their computer/device. This complies with recent legislation requirements for websites to obtain explicit consent from users before leaving behind or reading files such as cookies on a user's computer/device.</p>
            <p>Cookies are small files saved to the user's computer's hard drive that track, save, and store information about the user's interactions and usage of the website. This allows the website, through its server, to provide the users with a tailored experience within this website.</p>
            <p>Users are advised that if they wish to deny the use and saving of cookies from this website onto their computer's hard drive, they should take necessary steps within their web browser's security settings to block all cookies from this website and its external serving vendors.</p>
            <p>This website uses tracking software to monitor its visitors to better understand how they use it. This software is provided by Google Analytics which uses cookies to track visitor usage. The software will save a cookie to your computer's hard drive in order to track and monitor your engagement and usage of the website but will not store, save, or collect personal information. You can read Google’s privacy policy here for further information [http://www.google.com/privacy.html].</p>
            <p>Other cookies may be stored on your computer's hard drive by external vendors when this website uses referral programs, sponsored links, or adverts. Such cookies are used for conversion and referral tracking and typically expire after 30 days, though some may take longer. No personal information is stored, saved, or collected.</p>
            <h5>How We Use Cookies</h5>
            <p>A cookie is a small file which asks permission to be placed on your computer’s hard drive. Once you agree, the file is added and the cookie helps analyze web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes, and dislikes by gathering and remembering information about your preferences.</p>
            <p>We use traffic log cookies to identify which pages are being used. This helps us analyze data about web page traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.</p>
            <p>Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.</p>
          </div>
        </Modal.Body>
        <Modal.Footer className='model-ft-bg'>
          <Button variant="secondary" onClick={hidepopup}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Cookiespolicy;
