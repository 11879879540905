import React, { useState } from "react";
import info from "../../../../assets/PCP_HDSC_V1/img/info.png";
import nextgreen from "../../../../assets/PCP_HDSC_V1/img/next-green.png";
import { Salutation } from "../../../../Constants/Constants";
import TextField from "../../../UI/TextField";
import Button from "../../../UI/Button";
import SelectBox from "../../../UI/SelectBox";
import { nameValidate } from "../../../../Utility/Validations";
import PreviousNameModal from "./PreviousNameModal";

const PersonalizeExperience = (props) => {
  const namevalidatefun = (e) => {
    const value = e.target.value.trim();
    const name = e.target.name === "txtFName" ? "First Name" : "Last Name";

    if (value.length < 3 || !/^[a-zA-Z\s]+$/.test(value)) {
      props.setError(e.target.name, {
        type: "manual",
        message: `Your ${name} is Invalid. Please Recheck`,
      });
    } else {
      props.clearErrors(e.target.name);
    }
  };

  const handleValidation = async () => {
    const isTitleValid = await props.trigger("lstSalutation");
    if (!isTitleValid) return false;

    const isFirstNameValid = await props.trigger("txtFName");
    if (!isFirstNameValid) return false;

    const isLastNameValid = await props.trigger("txtLName");
    return isLastNameValid;
  };

  const handleSubmit = async (e) => {
    const isValid = await handleValidation();

    if (isValid) {
      props.setShowPreviousNameModal(true);
      const currentValues = props.getValues();
      props.formData.lstSalutation = currentValues.lstSalutation;
      props.formData.txtFName = currentValues.txtFName;
      props.formData.txtLName = currentValues.txtLName;

      props.slideChange(e);
    } else {
    }
  };

  const handleBackClick = () => {
    props.setShowPreviousNameModal(false);
    props.previousSlideChange("back06");
    
  };

  return (
    <div
      className={`mt-5 form_dec col-12 col-md-11 animate__animated animate__fadeInUp animate__delay-.5s animated-element ${props.className}`}
    >
      <div
        className="animate__animated animate__fadeInUp animate__delay-.5s animated-element borderx"
        id="dynamic"
      >
        <div className="slide_3Dec">
          <h2 className="animate__animated animate__fadeInUp animate__delay-.5s animated-element">
            Personalise your experience. May we know your name?
          </h2>
          <div className="px-3 py-3 widget_mobi borderx">
            <div className="form_drop w-100">
              <div className="row">
                <div className="col-12">
                  <label
                    htmlFor="format2"
                    className="text-start w-100 animate__animated animate__fadeInUp animate__delay-.5s animated-element"
                  >
                    Title
                  </label>
                  <div className="select_title animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                    <SelectBox
                      className="form-select form-control"
                      OptionValue={Salutation}
                      name="lstSalutation"
                      id="lstSalutation"
                      onChange={() => props.clearErrors("lstSalutation")}
                      myRef={props.validation({
                        required: "Please select a title.",
                      })}
                    />
                  </div>
                  {props.validationMsg.lstSalutation && (
                    <span className="error_msg">
                      {props.validationMsg.lstSalutation.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="row name_detail animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                <div className="col-12 f_name mb-3">
                  <label htmlFor="topmention1">First Name</label>
                  <TextField
                    type="text"
                    className="form-control"
                    id="topmention1"
                    placeholder="First name"
                    aria-label="First name"
                    name="txtFName"
                    onChange={() => props.clearErrors("txtFName")}
                    onkeypress={(e) => nameValidate(e)}
                    validation={props.validation({
                      required: "Please enter your first name.",
                      minLength: {
                        value: 3,
                        message: "Please Enter Valid First Name",
                      },
                      pattern: {
                        value: /^[a-zA-Z\s]+$/,
                        message: "Your Name is Invalid. Please Recheck",
                      },
                    })}
                    onBlur={namevalidatefun}
                   
                    autoComplete="off"
                  />
                  {props.validationMsg.txtFName && (
                    <span className="error_msg" id="email_err">
                      {props.validationMsg.txtFName.message}
                    </span>
                  )}
                </div>

                <div className="col-12 l_name mb-3">
                  <label htmlFor="topmention2">Last Name</label>
                  <TextField
                    type="text"
                    className="form-control"
                    id="topmention2"
                    placeholder="Last name"
                    aria-label="Last name"
                    name="txtLName"
                    onChange={() => props.clearErrors("txtLName")}
                    onkeypress={(e) => nameValidate(e)}
                    autoComplete="off"
                    validation={props.validation({
                      required: "Please enter your last name.",
                      minLength: {
                        value: 3,
                        message: "Please Enter Valid Last Name",
                      },
                      pattern: {
                        value: /^[a-zA-Z\s]+$/,
                        message: "Your Name is Invalid",
                      },
                    })}
                  />
                  {props.validationMsg.txtLName && (
                    <span className="error_msg" id="email_err">
                      {props.validationMsg.txtLName.message}
                    </span>
                  )}
                </div>

                <p className="tiny_note m-0">
                  <img src={info} className="pe-1" alt="" />
                  What you are legally known as
                </p>
              </div>
            </div>
            <div
              className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s animated-element"
              id="subRegNo3"
            >
              <div className="row align-items-center">
                <div className="col-4 text-center">
                  <div className="bg-1 hme_btn">
                    <Button
                      type="button"
                      data-type="back"
                      name="back06"
                      id="backStep-2"
                      className="msf-nav-button backbutton"
                      onClick={handleBackClick}
                      buttonText={
                        <span className="btntext">
                          <img src={nextgreen} alt="" />
                          Back <i className="arrowicon"></i>
                        </span>
                      }
                    />
                  </div>
                </div>
                <div className="col-8">
                  <Button
                    type="button"
                    className="button w-100 next-btn"
                    id="nextslideCl_003"
                    value="Next"
                    aria-label="submitid"
                    name="nextslideCl_003"
                    buttonText={
                      <span
                        className="fw-bold"
                        style={{ pointerEvents: "none" }}
                      >
                        Next
                      </span>
                    }
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 
    </div>
  );
};

export default PersonalizeExperience;
