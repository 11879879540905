import React, { useState } from 'react';
import PrivacyModal from './PrivacyModal';
import TermsModal from './TermsModal';
import Cookiespolicy from '../../Layouts/PCP_HDSC_V1/Cookiespolicy';
import ContractModal from './ContractModal';
import ComplaintsProcedure from "../../../../docs/Complaints Procedure - Hendersons - June 2024.pdf";
import PrivacyPolicy from "../../../../docs/Henderson Claims - Privacy Policy - June 2024.6bf93601.pdf";

const Footer = () => {
  const [showCookie, setShowCookie] = useState('show');
  const [termsModal, setTermsModal] = useState(false);
  const [contractModal, setContractModal] = useState(false);
  const [showCookiesPolicy, setShowCookiesPolicy] = useState(false);
  const cookieName = "CookiesPolicyHide";

  const cookieClose = (e) => {
    e.preventDefault();
    setShowCookie('hide');
    if (cookieName != null) {
      const d = new Date();
      const cvalue = "Yes";
      d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
      const expires = "expires=" + d.toUTCString();
      document.cookie = cookieName + "=" + cvalue + "; " + expires;
    }
  };

  const handleCookieShow = () => {
    setShowCookiesPolicy(true);
  };

  const handleCookieHide = () => {
    setShowCookiesPolicy(false);
  };

  const query = new URLSearchParams(window.location.search);
  const splitName = query.get("split_name");

  return (
    <div>
      <footer>
        <div className="container text-center">
          <div className="ftr_cntnt">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-12">
                {splitName === "PCP_HDSC_V1_5T" &&
                  <p>
                    Henderson Claims is a Claims Management Company (CMC). You can claim for free, without using a CMC, first to your lender or to the FOS/FSCS.
                  </p>
                }
                <p>
                  Hendersons is a trading style of Henderson Claims Limited.
                  Henderson Claims Limited is authorised and regulated by
                  the Financial Conduct Authority with reference number
                  FRN:836041. Henderson Claims Limited is a Claims
                  Management Company (CMC). Registered with the Information
                  Commissioner's Office: registration number ZA147049.
                  Registered address: Grosvenor House Part Ground Floor (Rear Wing), St Thomas Place, Stockport, United Kingdom, SK1 3TZ. Registered in
                  England and Wales, Company number 07646017. VAT number:
                  240 8769 88.
                </p>
                <p>
                  <a target='_blank' href="https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf" className='link-style'>*fca.org.uk</a>
                </p>
                <p>
                  <b>© 2023</b>
                </p>
              </div>
            </div>
            <div className="text-center">
              <div className="copyright">
                <ul>
                  <li>
                    <a
                      href={PrivacyPolicy} target="_blank"
                    >Privacy Policy</a>
                  </li>
                  <li>
                    <a
                      onClick={() => setTermsModal(true)}
                    >Terms &amp; Conditions</a>
                  </li>
                  <li>
                    <a
                      onClick={() => setContractModal(true)}
                    >Pre-contract Information</a>
                  </li>
                  <li>
                    <a
                      href={ComplaintsProcedure} target="_blank"
                    >Complaints Procedure</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <div id="cookieBar" className={`cooki text-center ${showCookie}`}>
        <div className="col-lg-12 col-md-12 col-12 col-sm-12">
          <span>We use cookies to analyse traffic to this site and to give you the best experience. By clicking 'Accept' you agree to our use of cookies. To find out more about our use of cookies and how to refuse them, please read our Privacy Policy.
            <a href="#" className="rp_clk text-underline" onClick={handleCookieShow}><u>Learn More.</u></a>
          </span>
          <a className="rp_clk closecook text-danger" href="#" onClick={cookieClose}>Close <strong>X</strong></a>
        </div>
        <div className="clearfix"></div>
      </div> */}
      <TermsModal
        termsModal={termsModal}
        setTermsModal={setTermsModal}
      />
      <ContractModal
        contractModal={contractModal}
        setContractModal={setContractModal}
      />
      <Cookiespolicy
        showstatus={showCookiesPolicy}
        hidepopup={handleCookieHide}
      />
    </div>
  );
};

export default Footer;
