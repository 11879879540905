import { Profanity } from "../Constants/Profanity";
const slideValidation = async (fields, action) => {
    for (const item of fields) {
        if (!await action(item)) {
            var elementCheck = document.getElementById(item);
            if (elementCheck) { elementCheck.focus(); }
            return item;
        }
    }
    return 'Success';
};

const numberValidation = (evt) => {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode; console.log(charCode);
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
        return false;
    }
    else {
        return true;
    }
}

const nameValidate = (evt) => {
    const char = String.fromCharCode(evt.charCode);
    if (!/^[a-zA-Z\s]*$/.test(char)) {
        evt.preventDefault();
        return false;
    }
    evt.target.value = evt.target.value.trimStart();
}

const dobValidation = (DobDay, DobMonth, DobYear) => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const month = monthNames.indexOf(DobMonth) !== -1 ? monthNames.indexOf(DobMonth) + 1 : null;
    var date = new Date(parseInt(DobYear), parseInt(month) - 1, parseInt(DobDay));
    var dateStatus = parseInt(month) == date.getMonth() + 1;

    return dateStatus;
}

const profanityValidation = (validationFields, getValues) => {
    const values = getValues();
    for (const item of validationFields) {
        var inputWord = values[item];
        if (inputWord) {
            const words = inputWord.split(/\s+/);
            const containsProfanity = words.some(word => Profanity.includes(word.toLowerCase()));
            if (containsProfanity) {
                var elementCheck = document.getElementById(item);
                if (elementCheck) { elementCheck.focus(); }
                return item;
            }
        }
    }
    return 'success';
}
const preNameDuplicationCheck = async (getValues, setError, clearErrors, setLastNameError='') => {
    const storedData = JSON.parse(localStorage.getItem('sur_names')) || {};
    const values = getValues();
    const fieldNames = ['txtLName','txtPrevLName_1', 'txtPrevLName_2', 'txtPrevLName_3'];
    const enteredSurnames = fieldNames.map(field => values[field]?.trim().toLowerCase() || '');
  
    const isDuplicateInArray = (array, excludeIndex) => {
      const value = array[excludeIndex];
      return array.some((item, index) => index !== excludeIndex && item === value);
    };
    const isDuplicateInStoredData = (surname, excludeField) => {
      return fieldNames.some((field, index) => {
        if (field !== excludeField) {
          const storedValue = storedData[field] ? storedData[field].trim().toLowerCase() : '';
          return surname === storedValue;
        }
        return false;
      });
    };
  
    let lastDuplicateField = null;
    for (let i = enteredSurnames.length - 1; i >= 0; i--) {
      if (enteredSurnames[i] && isDuplicateInArray(enteredSurnames, i)) {
        lastDuplicateField = fieldNames[i];
        break;
      }else{
        clearErrors(fieldNames[i]);
      }
    }
    if (!lastDuplicateField) {
      for (let i = fieldNames.length -1; i >= 0; i--) {
        const fieldName = fieldNames[i];
        const surname = enteredSurnames[i];
        if (surname && isDuplicateInStoredData(surname, fieldName)) {
          lastDuplicateField = fieldName;
          break;
        }else{
          clearErrors(fieldName);
        }
      }
    }
    if (lastDuplicateField) {
      setLastNameError && setLastNameError(true);
      setError(lastDuplicateField, { message: "The surname cannot be the same as previously entered" });
      if (lastDuplicateField === 'txtLName') {
        const txtLName = values['txtLName'];
        const prevNames = ['txtPrevLName_1', 'txtPrevLName_2', 'txtPrevLName_3'];
        prevNames.forEach(prevName => {
            const value = values[prevName]?.toLowerCase().trim();
            const message = value === txtLName.toLowerCase().trim() ? "The surname cannot be the same as previously entered" : "";
            setError(prevName, { message });
        });
      }
      return false;
    }else{
        setError(lastDuplicateField, { message: "" });
    }
    if (lastDuplicateField === null) {
      setError('txtPrevLName_1', { message: "" });
      setError('txtPrevLName_2', { message: "" });
      setError('txtPrevLName_3', { message: "" });
    }
    return true;
  };
  // const checkDuplicateExtraPreviousName = (e, setError) => {
  //   const { name, value } = e.target;
  //   let storedData = JSON.parse(localStorage.getItem('sur_names')) || {};
  //   const trimmedValue = value.trim().toLowerCase();
  //   delete storedData[name];
  //   let isDuplicate = false;
    
  //   if (storedData) {
  //     for (let key in storedData) {
  //       if (storedData[key].trim().toLowerCase() === trimmedValue) {
  //         console.log(`A match is found for the value: ${value}, with the key: ${key}`);
  //         isDuplicate = true;
  //         break
  //         // setError(name, { message: "The surname cannot be the same as previously entered" });
  //         // delete storedData[name];
  //         // localStorage.setItem('sur_names', JSON.stringify(storedData));
  //         // return;
  //       }
  //       // else{
  //       //   setError(name, { message: "" });
  //       // }
  //     }
  //   }
  //   console.log(isDuplicate,55555);
  //   if (isDuplicate) {
  //     setError(name, { message: "The surname cannot be the same as previously entered" });
  //   } else {
  //     setError(name, { message: "" });
  //     storedData[name] = value;
  //     localStorage.setItem('sur_names', JSON.stringify(storedData));
  //   }
  //   // storedData[name] = value;
  //   // localStorage.setItem('sur_names', JSON.stringify(storedData));
  // }
  const checkDuplicateExtraPreviousName = (e, setError) => {
    const { name, value } = e.target;
    let storedData = JSON.parse(localStorage.getItem('sur_names')) || {};
    console.log("local storage data");
    console.log(storedData);
    let isDuplicate = false;
    for (let key in storedData) {
        if (storedData[key].trim().toLowerCase() === value.trim().toLowerCase() && key !== name) {
            console.log(`A match is found for the value: ${value}, with the key: ${key}`);
            isDuplicate = true;
            break;
        }
    }
    if (isDuplicate) {
        setError(name, { message: `The surname cannot be the same as previously entered` });
    } else {
        setError(name, { message: "" });
    }
    storedData[name] = value;
    localStorage.setItem('sur_names', JSON.stringify(storedData));
  };


export { slideValidation, numberValidation, nameValidate, dobValidation, profanityValidation, preNameDuplicationCheck, checkDuplicateExtraPreviousName }


