import React from 'react';
import GTMDataLayer from '../../Utility/GTMDataLayer';
const RadioButtonQuest = (props) => {
  const handleClick = (e) => {
    if (e.target.dataset.gtm_trigger) {
      GTMDataLayer({
        clickEvent: e.target.dataset.clickevent,
        question: e.target.dataset.gtm_question,
        answer: e.target.dataset.gtm_answer,
      });
    }
    props.onClick(e)
  }
    return (
      <>
        <input
          type="radio"
          className={props.className}
          name={props.name}
          id={props.id}
          data-ans-id={props.value}
          data-quest-id={props.name}
          data-ans-label={props.labelName}
          value={props.value}
          onClick={(e) => handleClick(e)}
          data-gtm_question={props.gtm_question}
          data-gtm_trigger={props.gtm_trigger}
          data-gtm_answer={props.gtm_answer}
          data-clickevent={props.clickevent}
        />
          <label htmlFor={props.id} data-ans-id={props.value} data-quest-id={props.name}  className={props.labelClassName} 
                onClick={props.onLabelClick}>
                <span  className="line-break-span" >{props.labelCountString}</span>{props.labelName}
          </label>
        </>
      );
}

export default RadioButtonQuest;