import React, { useState } from 'react'
import RadioButtonV2 from '../../../UI/RadioButtonV2';
import PreviousPostCode_PCP_HDSC_V5 from './PreviousPostCode_PCP_HDSC_V5';
import Button from '../../../UI/Button';
import GTMDataLayer from "../../../../Utility/GTMDataLayer";

export const PreviousPostcodeModal = (props) => {
  const [previousForm, setPreviousForm] = useState(false);

  const previousClick = (e) => {
    let Radios1 = e.target.id;
    if (Radios1 == "Radios1") {
      setPreviousForm(true);
    } else if (Radios1 == "Radios2") {
      props.finalSubmit();
    }
  }
  return (
    <>
      <div
        className={`modal fade ${props.prePostcodeModal ? "show" : "d-none"}`}
        id="pre_add"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="pre_addLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-4 modalContent_sml animate__animated animate__zoomIn animate__delay-.5s ">
            <div className="modal-body pb-0">
              {!previousForm && (
                <div className="form_drop yes_no_sec col-lg-12 col-12">
                  <div
                    className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s"
                    style={{ zIndex: 2, position: "relative" }}
                  >
                    <p className="png_t">
                      Did you register with Lender(s) at a different/previous address than your current address?
                    </p>
                    <div className=" mb-1 choose_Y_N col-lg-12 col-12 animate__animated animate__fadeInUp animate__delay-.5s">
                      <div className="form-check px-0 w-100">

                        <RadioButtonV2
                          className="form-check-input prevNext"
                          name="Radioshere2"
                          id="Radios2"
                          value="No"
                          labelName="No"
                          onClick={previousClick}
                        />
                      </div>
                      <div className="form-check px-0 w-100">

                        <RadioButtonV2
                          className="form-check-input"
                          name="Radioshere2"
                          id="Radios1"
                          value="Yes"
                          labelName="Yes"
                          onClick={previousClick}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {previousForm && (
                <div className="enter_postcode_mod animate__animated animate__fadeInUp animate__delay-.5s">
                  <p className="png_t">
                    Enter your previous address postcode
                  </p>
                  <label>Postcode</label>
                  <PreviousPostCode_PCP_HDSC_V5
                    validation={props.validation}
                    validationMsg={props.validationMsg}
                    trigger={props.trigger}
                    clearErrors={props.clearErrors}
                    setValue={props.setValue}
                  />
                </div>
              )}
            </div>
            <div className="modal-footer">
              {previousForm && (
                <div className="box_button final_sub pt-1 animate__animated animate__fadeInUp animate__delay-.5s">
                  <div className="bg-1">
                    <Button
                      type="button"
                      className="prevNext button w-100 button--moema button--text-thick button--text-upper button--size-s h60_click blick_notice "
                      buttonText={
                        <span>Next</span>
                      }
                      id='preAddressSubmit'

                      onClick={props.finalSubmit}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
