import React from 'react';
import { DobYear, DobMonth, DobDay } from '../../../../Constants/Constants';
import SelectBox from '../../../UI/SelectBox';

const DateOfBirth = (props) => {

  return (
    <div className="date-birth mt-4 mb-4 " ref={props.section1Ref}>

      <fieldset className="p-2 px-3 animate__animated animate__fadeInUp animate__delay-1s">
        <legend className="px-2 m-0 fw-bold">Date Of Birth</legend>
        <div className="row px-md-n5">

          <div className="mb-2 col-lg-4 col-md-4 col-sm-4">
          <SelectBox
              className="form-select form-control"
              OptionValue={DobDay}
              name="dobDay"
              id="dobDay"
              onChange={props.handleChange}
              myRef={props.validation({
                required: "Please Select Day",
              })}
              validationMsg={props.validationMsg.dobDay?.message}
            />
          </div>
          <div className="mb-2 col-lg-4 col-md-4 col-sm-4 ps-lg-0">
            <SelectBox
              className="form-select form-control"
              OptionValue={DobMonth}
              name="dobMonth"
              id="dobMonth"
              onChange={props.handleChange}
              myRef={props.validation({ required: "Please Select Month"})}
              validationMsg={props.validationMsg.dobMonth && props.validationMsg.dobMonth.message}
            />
          </div>
          <div className="mb-2 col-lg-4 col-md-4 col-sm-4 ps-lg-0">
            <SelectBox
              className="form-select form-control"
              OptionValue={DobYear}
              name="dobYear"
              id="dobYear"
              onChange={props.handleChange}
              myRef={props.validation({ required: "Please Select Year"})}
              validationMsg={props.validationMsg.dobYear && props.validationMsg.dobYear.message}
            />
          </div>

        </div>
      </fieldset>

    </div>

  )
}

export default DateOfBirth ;