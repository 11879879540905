import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import { QueryStringProvider } from "./Contexts/QueryString";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { queryString } from "./Utility/QueryString";
import HomePage from "./Components/Pages/HomePage";
import FbPixel from "./Components/Pages/FbPixel";
import "./App.scss";
import { RedirectProvider } from "./Contexts/RedirectContext";
import Signature from "./Components/Pages/Signature";
import SignatureV2 from "./Components/Pages/Signature";
import IdUpload from "./Components/Pages/IdUpload"
import PreviewLoa from "./Components/Pages/PreviewLoa";
import NotFound from "./Components/Pages/NotFound";
import ADV_1 from "./Components/Pages/Advertorials/ADV_1";
import ADV1_Contact from "./Components/Pages/Advertorials/ADV1_Contact";
import PdfView from"./Components/Pages/PdfView";
import AdtopiaLoadDynamic from "./Utility/AdtopiaLoadDynamic";
import PCP_HDSC_V1 from "./Components/Pages/PCP_HDSC_V1/PCP_HDSC_V1";
import FinanceAgreement from "./Components/Pages/FinanceAgreement/FinanceAgreement";
import CarRegistration from "./Components/Pages/PCP_HDSC_V1/CarRegistration";
import CarRegistrationV2 from "./Components/Pages/PCP_HDSC_V2/CarRegistrationV2";
import Thankyou from "./Components/Pages/PCP_HDSC_V1/Thankyou";
import ThankyouV2 from "./Components/Pages/PCP_HDSC_V2/ThankyouV2";
import PCP_HDSC_V2 from "./Components/Pages/PCP_HDSC_V2/PCP_HDSC_V2";
import PcpIdUpload from "./Components/Pages/PCP_HDSC_V1/PcpIdUpload";
import EnvDocView from "./Components/Pages/Docs/EnvDocView";
import PcpIdUploadV2 from "./Components/Pages/PCP_HDSC_V2/PcpIdUploadV2";
import PCP_HDSC_V3 from "./Components/Pages/PCP_HDSC_V3/PCP_HDSC_V3";
import CarRegistrationV3 from "./Components/Pages/PCP_HDSC_V3/CarRegistrationV3";
import PcpIdUploadV3 from "./Components/Pages/PCP_HDSC_V3/PcpIdUploadV3";
import QuestionnaireV3 from "./Components/Pages/PCP_HDSC_V3/QuestionnaireV3";
import EnvSignView from "./Components/Pages/Signature/EnvSignView";
import Preview from "./Components/Pages/PCP_HDSC_V1/Preview";
import PCP_HDSC_V5 from "./Components/Pages/PCP_HDSC_V5/PCP_HDSC_V5";
import SignatureV5 from "./Components/Pages/PCP_HDSC_V5/Signature/SignatureV5";
import ThankyouV5 from "./Components/Pages/PCP_HDSC_V5/ThankyouV5";
import PCP_HDSC_V1_5T from "./Components/Pages/PCP_HDSC_V1_5T/PCP_HDSC_V1_5T";

const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: '' });
  useEffect(() => {
    document.title = EnvConstants.AppTitle;
  }, []);
  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <BrowserRouter>
              <Route exact path="/" component={HomePage} />
              <Route exact path={"/signature"} component={Signature} />
              <Route exact path={"/signature-v2"} component={SignatureV2} />
              <Route exact path={["/fbpixel"]} component={FbPixel} />
              <Route exact path={"/thankyou"} component={Thankyou} />
              <Route exact path={"/thankyou-v2"} component={ThankyouV2} />
              <Route exact path={["/id-upload"]} component={IdUpload} />
              {/* <Route exact path={["/preview"]} component={PreviewLoa} /> */}
              <Route exact path={["/404"]} component={NotFound} />
              <Route exact path={["/ADV_1"]} component={ADV_1} />
              <Route exact path={["/ADV1/contact"]} component={ADV1_Contact}/>
              <Route exact path={["/:enviroment/:project_id/pdf/:doc_type/:filename"]} component={PdfView}/>
              <Route exact path={["/google","/testing"]} component={AdtopiaLoadDynamic}/>
              <Route exact path={["/PCP_HDSC_V1"]} component={PCP_HDSC_V1} />
              <Route exact path={["/PCP_HDSC_V2"]} component={PCP_HDSC_V2} />

              <Route exact path={["/finance-agreement"]} component={FinanceAgreement} />
              <Route exact path={["/CarRegistration"]} component={CarRegistration} />
              <Route exact path={['/pcp-id-upload']} component={PcpIdUpload} />
              <Route
                  exact
                  path={["/doc/:docType/:filename"]}
                  component={EnvDocView}
                />
              <Route exact path={["/pdf/:docType/:filename"]} component={PdfView}/>
              <Route exact path={["/CarRegistrationv2"]} component={CarRegistrationV2} />
              <Route exact path={['/pcp-id-uploadv2']} component={PcpIdUploadV2} />
              <Route exact path={["/PCP_HDSC_V3"]} component={PCP_HDSC_V3} />
              <Route exact path={["/car-registration-v3"]} component={CarRegistrationV3} />
              <Route exact path={['/id-upload-v3']} component={PcpIdUploadV3} />
              <Route exact path={['/questionnaire-v3']} component={QuestionnaireV3} />
                <Route
                  exact
                  path={["/signature/image/:filename"]}
                  component={EnvSignView}
                />
              <Route exact path={["/preview"]} component={Preview} />
              <Route exact path={["/PCP_HDSC_V5"]} component={PCP_HDSC_V5} />
              <Route exact path={["/signature-v5"]} component={SignatureV5} />
              <Route exact path={"/thankyou-v5"} component={ThankyouV5} />
              <Route exact path={["/PCP_HDSC_V1_5T"]} component={PCP_HDSC_V1_5T} />
            </BrowserRouter>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;
