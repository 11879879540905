import React, {
  useContext,
  useReducer,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import info from "../../../assets/PCP_HDSC_V1/img/info.png";
import nextgreen from "../../../assets/PCP_HDSC_V1/img/next-green.png";
import secure from "../../../assets/PCP_HDSC_V1/img/secure.svg";
import { Finance } from "../../../Constants/ConstantsPCP";
import { Finance_Prominent_List_Reworded } from "../../../Constants/ConstantsPCP";
import { useEmailValidation } from "../../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../../Hooks/usePhoneValidation";
import { useForm } from "react-hook-form";
import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import QueryStringContext from "../../../Contexts/QueryString";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Button } from "bootstrap";
import PrivacyPolicy from "../../../docs/Henderson Claims - Privacy Policy - June 2024.pdf"

import {
  DobDay,
  DobMonth,
  DobYear,
  Salutation,
} from "../../../Constants/Constants";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import PostCode_PCP_HDSC_V2 from "../../Includes/Layouts/PCP_HDSC_V2/PostCode_PCP_HDSC_V2";
import review_star from "../../../assets/PCP_HDSC_V1/img/review-star.png";
import bg_float from "../../../assets/PCP_HDSC_V1/img/bg-float3.png";
import PreviousPostcode_PCP_HDSC_V1 from "../../Includes/Layouts/PCP_HDSC_V1/PreviousPostcode_PCP_HDSC_V1";
import LenderModal from "../../Pages/PCP_HDSC_V1/LenderModal";
import { useSkipLender } from "../../../Hooks/useSkipLender";
import PreviousNameModal from "../../Includes/Layouts/PCP_HDSC_V2/PreviousNameModal";

const priorityCategories = [
  'Black Horse Limited',
  'Close Motor Finance',
  'MotoNovo',
  'Santander'
];

const sortedOptions = Finance_Prominent_List_Reworded.sort((a, b) => {
  const labelA = a.sub_categories.length === 1 ? a.sub_categories[0].label : a.category;
  const labelB = b.sub_categories.length === 1 ? b.sub_categories[0].label : b.category;

  const indexA = priorityCategories.indexOf(labelA);
  const indexB = priorityCategories.indexOf(labelB);

  if (indexA !== -1 && indexB !== -1) {
    return indexA - indexB; // Both are priority categories, sort by their predefined order
  } else if (indexA !== -1) {
    return -1; // Only labelA is a priority category, it should come first
  } else if (indexB !== -1) {
    return 1; // Only labelB is a priority category, it should come first
  } else {
    return labelA.localeCompare(labelB); // Neither is a priority category, sort alphabetically
  }
});

const PCP_HDSC_V2Form = ({
  nextStep,
  prevStep,
  step,
  showModal,
  handleYesClick,
  showSecondSection,
  error,
  formData,
  handleChange,
  handleChangeButton,
  firstNameError,
  titleError,
  lastNameError,
  phoneError,
  emailError,
  setEmailError,
  setPhoneError,
  getValues,
  register,
  errors,
  trigger,
  setError,
  clearErrors,
  dobError,
  monthError,
  yearError,
  postCodeError,
  setValue,
  setFormData,
  setError_alt,
  setFormModal,
  formModal,
  bankError,
  commissionError,
  claimError,
  setStep,
  lender_error,
  setPostCodeError,
  showPreviousNameModal,
  setShowPreviousNameModal
}) => {
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const history = useHistory();
  const { queryString } = useContext(QueryStringContext);
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const [formModalTwo, setFormModalTwo] = useState(false);
  const [modalClass, SetModalClass] = useState(false);
  const { skipLenderDetails } = useSkipLender();

  const handleYesModal = (e) => {
    setFormModalTwo(true);
    const name = e.target.name;
    const value = "";
    setFormData({ ...formData, [name]: value });
  };
  // Handle form submission
  const handleSubmit = async () => {
    const split_name = "PCP_HDSC_V2";
    const values = getValues();
    const storedFormData = localStorage.setItem(
      "formData",
      JSON.stringify(formData)
    );
    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");
    const formdatavalues = values;
    formdatavalues.txtFName = formData.txtFName.trim();;
    formdatavalues.txtLName = formData.txtLName.trim();;
    formdatavalues.lstSalutation = formData.lstSalutation;
    formdatavalues.txtEmail = formData.txtEmail;
    formdatavalues.txtPhone = formData.txtPhone;
    formdatavalues.dobDay = formData.dobDay;
    formdatavalues.dobMonth = formData.dobMonth;
    formdatavalues.dobYear = formData.dobYear;
    formdatavalues.financeProvider = formData.financeProvider;
    formdatavalues.commission = formData.commission;
    formdatavalues.bankruptcy = formData.bankruptcy;
    formdatavalues.claimed = formData.claimed;
    formdatavalues.whyNotClaimed = formData.whyNotClaimed;
    formdatavalues.previousName = formData.previousName;
    formdatavalues.unsubscribeStatus = formData.unsubscribeStatus;

    const formSUbmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      formdatavalues,
      "split_form_submit",
      "PCP_HDSC_V2",
      queryString,
      visitorParameters.data
    );

    if (formSUbmitResult.data.status === "Success") {
      history.push(
        `/signature-v2?uuid=${encodeURIComponent(
          query_uuid
        )}&split_name=${split_name}`
      );
    }
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: "56px",
    }),
  };
  const handleNoClick = async () => {
    const split_name = "PCP_HDSC_V2";
    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");
    const values = getValues();
    const storedFormData = localStorage.setItem(
      "formData",
      JSON.stringify(formData)
    );
    const formdatavalues = values;
    formdatavalues.txtFName = formData.txtFName.trim();;
    formdatavalues.txtLName = formData.txtLName.trim();;
    formdatavalues.lstSalutation = formData.lstSalutation;
    formdatavalues.txtEmail = formData.txtEmail;
    formdatavalues.txtPhone = formData.txtPhone;
    formdatavalues.dobDay = formData.dobDay;
    formdatavalues.dobMonth = formData.dobMonth;
    formdatavalues.dobYear = formData.dobYear;
    formdatavalues.financeProvider = formData.financeProvider;
    formdatavalues.commission = formData.commission;
    formdatavalues.bankruptcy = formData.bankruptcy;
    formdatavalues.claimed = formData.claimed;
    formdatavalues.whyNotClaimed  = formData.whyNotClaimed;
    formdatavalues.previousName  = formData.previousName;
    formdatavalues.unsubscribeStatus = formData.unsubscribeStatus;

    const formSUbmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      formdatavalues,
      "split_form_submit",
      "PCP_HDSC_V2",
      queryString,
      visitorParameters.data
    );
    if (formSUbmitResult.data.status === "Success") {
      // Get the slugs corresponding to the selected values
      const selectedSlugs = formData.financeProvider.map((value) => {
        const selectedOption = Finance.find((option) => option.value === value);
        return selectedOption ? selectedOption.slug : "";
      });

      // Join the slugs into a single string
      const lendersQueryParam = selectedSlugs.join(",");

      const url = `/signature-v2?uuid=${encodeURIComponent(
        query_uuid
      )}&split_name=${split_name}&lenders=${lendersQueryParam}`;

      history.push(url);
    }
  };
  const handleKeyPress = (e) => {
    const charCode = e.charCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };
  const emailValidCheck = async (event) => {
    const email = event.target.value;
    if (email !== "") {
      const getEmailValidation = await emailValidation(
        email,
        visitorParameters.visitor_parameters.uuid
      );
      if (getEmailValidation.data.status_code === 1) {
        setEmailError("");
        return 1;
      } else {
        setEmailError("Invalid email.");
      }
    }
  };
  const cancelpopup = () => {
    setFormModal(false);
  };
  const cancelpopupTwo = () => {
    setFormModalTwo(false);
  };
  const cancelpopupLender = () => {
    SetModalClass(false);
  };

  const phoneValidCheck = useCallback(async (event) => {
    const phone = event.target.value;

    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone);
      if (getPhoneValidation.data.status_code === 1) {
        clearErrors("txtPhone");
        return 1;
      } else if (getPhoneValidation.data.status_code === 0) {
        setPhoneError("Please Enter Valid Working Phone Number");
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        setPhoneError("Phone Number Already Exist");
        return 0;
      } else {
        setPhoneError("Please Enter Valid Phone Number");
        return 0;
      }
    } else {
      setPhoneError("Please Enter Phone Number");
      return 0;
    }
  });

  const errorRef = useRef(null);
  const [financeProviderViewerModal, setFinanceProviderViewerModal] = useState("null")
  const [financeProviderViewerError, setFinanceProviderViewerError] = useState("")
  const financeProviderViewer = (index) => {
    if (formData.financeProvider.length >= 4) {
      const selectedOption = Finance_Prominent_List_Reworded[index];
      const isAnySubCategorySelected = selectedOption.sub_categories.some(subCategory =>
        formData.financeProvider.includes(subCategory.value)
      );

      if (!isAnySubCategorySelected) {
        setFormModal(true);
        return;
      }
    }
    setFinanceProviderViewerModal(index);
  }

  const handleFinanceProviderChange = (e) => {
    setFinanceProviderViewerError("")
    const selectedValue = e.target?.value ?? e.value;

    // Check if selectedValue exists in the Finance constant
    const financeItem = Finance.find((item) => item.value === selectedValue);

    if (financeItem) {
      // Retrieve slug name from the finance item
      const slug = financeItem.slug;

      // Get existing data from local storage or initialize an empty array
      const localStorageData =
        JSON.parse(localStorage.getItem("slugArray")) || [];

      // Check if the slug is already stored in local storage
      if (!localStorageData.includes(slug)) {
        // Add the slug to the array
        const updatedData = [...localStorageData, slug];
        // Update local storage
        if (updatedData.length <= 4) {
          localStorage.setItem("slugArray", JSON.stringify(updatedData));
        }
      } else {
        const updatedData = localStorageData.filter(item => item !== slug);
        // Update local storage
        localStorage.setItem("slugArray", JSON.stringify(updatedData));
      }

      // Check if selectedValue exists in formData.financeProvider
      if (!formData.financeProvider.includes(selectedValue)) {
        // Add selectedValue to formData.financeProvider array
        if (formData.financeProvider.length < 4) {
          setFormData({
            ...formData,
            financeProvider: [
              ...formData.financeProvider.filter(provider => provider !== '0'),
              selectedValue
            ],
          });
        } else {
          if (financeProviderViewerModal !== "null") {
            setFinanceProviderViewerError("You can only select up to 4 lenders")
            errorRef.current.focus();
          } else {
            setFormModal(true);
          }
        }
      } else {
        // Remove selectedValue from formData.financeProvider array
        setFormData({
          ...formData,
          financeProvider: formData.financeProvider.filter(
            (value) => value !== selectedValue
          ),
        });
      }
    }

    setError_alt("");
  };

  useEffect(() => {
    const localStorageData =
      JSON.parse(localStorage.getItem("slugArray")) || [];
    if (localStorageData) {
      localStorage.removeItem("slugArray");
    }
  }, []);

  const handleRemoveFinanceProvider = (index) => {
    // Remove the value from the formData.financeProvider array
    const updatedFinanceProvider = [...formData.financeProvider];
    const removedValue = updatedFinanceProvider.splice(index, 1)[0];
    setFormData({ ...formData, financeProvider: updatedFinanceProvider });

    // Remove associated slug from local storage
    const financeItem = Finance.find((item) => item.value === removedValue);
    if (financeItem) {
      const slug = financeItem.slug;
      const localStorageData =
        JSON.parse(localStorage.getItem("slugArray")) || [];
      const updatedData = localStorageData.filter((item) => item !== slug);
      if (updatedData.length <= 4) {
        localStorage.setItem("slugArray", JSON.stringify(updatedData));
      }
    }
  };
  const skipThisPage = () => {

    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");
    const page_name = "PCP_HDSC_V2";
    const type="skipLender";
    if(type=="skipLender"){
      skipLenderDetailsAPi(query_uuid, page_name, type);
    }
  };
  const skipLenderDetailsAPi = async (uuid, page_name, type) => {
    try {
      await skipLenderDetails(uuid, page_name, type);
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()}>
        <section className="main_content">
          <div className="container">
            {/* row start */}
            <div className="row align-items-center">
              <div className="cs_hero_wrap cs_bg_filed">
                {/*Gradient*/}
                <div className="container-md">
                  <div className="home_plate_001">
                    <div className="row">
                      <div className="cs_hero_text col-lg-6 col-12 order-lg-1 order-md-1 order-2">
                        <div className="plateOne_anim" id="plate_1">
                          {step === 1 && (
                            <>
                              <h1 className="cs_hero_title animate__animated animate__fadeInLeft animate__delay-1s cs_fs_94 ">
                                Have You Bought A Car On Finance?
                              </h1>
                              <p className="cs_hero_subtitle highlight cs_fs_20 animate__animated animate__fadeInLeft animate__delay-1s cs_heading_color ">
                                Find out if your lender owes you{" "}
                                <strong>£1,000's</strong> in compensation.
                              </p>
                            </>
                          )}

                          {step === 1 && (
                            <>
                              <div
                                className="box_button animate__animated animate__fadeInLeft animate__delay-1s animated-element wht_iF no_border"
                                id="subRegNo1"
                              >
                                <div className="bg-1 hme_btn">
                                  <input
                                    type="button"
                                    className="button button--moma button--text-thick button--text-upper button--size-s blick_notice"
                                    id="nextPlate_001"
                                    defaultValue="EXPRESS REGISTRATION"
                                    aria-label="submitid"
                                    name="subRegNo1"
                                    onClick={nextStep}
                                  />
                                </div>
                              </div>

                              <p className="fw-semibold text-white pt-3 ps-5 nimate__animated animate__fadeInLeft animate__delay-1s animated-element d-none d-md-block">
                                Quick, easy, fully online registration.
                              </p>
                            </>
                          )}
                        </div>

                        <div
                          className={`mt-4 form_dec col-12 col-md-11 animate__animated  animated-element ${step === 1 ? "d-none" : ""
                            }`}
                        >
                          <div
                            className=" animate__animated animated-element borderx"
                            id="dynamic"
                          >
                            {step === 1 && <div className="no_border"></div>}
                            <div
                              className={`${step !== 2 && "d-none"} slide_2Dec`}
                            >
                              <div className="px-3 py-3 widget_mobi borderx">
                                <h3 className="sub_quesHead animate__animated animated-element">
                                  Please select lenders you've secured car
                                  finance with.{" "}
                                  <br className="d-block d-sm-none" />
                                </h3>
                                <div style={{ color: "white" }}>
                                  <i>(You can select up to 4 lenders)</i>
                                </div>
                                <div
                                  className="form_drop"
                                  id="wrapper"
                                >
                                  <div
                                    className="scale_blick_anim scrollbar col-lg-12 col-12"
                                    id="style-6"
                                  >
                                    <div className="col-lg-12 clearfix selectoptions force-overflow">
                                      <div className=" option clearfix">
                                        {sortedOptions.map((option, index) => {
                                            const isSelected = option.sub_categories.some(subCategory =>
                                              formData.financeProvider.includes(subCategory.value)
                                            );
                                            if (option.sub_categories.length === 1) {
                                              const subCategory = option.sub_categories[0];
                                              return (
                                                <div key={index}>
                                                  <input
                                                    value={subCategory.value}
                                                    type="checkbox"
                                                    name="financeProvider"
                                                    id={"solo_suboption_" + subCategory.value + "_" + index}
                                                    className=""
                                                    onChange={(e) => handleFinanceProviderChange(e)}
                                                  />
                                                  <label
                                                    htmlFor={"solo_suboption_" + subCategory.value + "_" + index}
                                                    className={`${isSelected ? 'selected-true' : 'selected-false'}`}
                                                  >
                                                    <span className="option_title title_1">{subCategory.label}</span>
                                                  </label>
                                                </div>
                                              );
                                            } else {
                                              return (
                                                <div key={index}>
                                                  <input
                                                    value={option.value}
                                                    type="checkbox"
                                                    name="financeProvider"
                                                    id={"option_" + index}
                                                    className="test"
                                                    onChange={(e) => financeProviderViewer(index)}
                                                  />
                                                  <label
                                                    htmlFor={"option_" + index}
                                                    className={isSelected ? 'selected-true' : 'selected-false'}
                                                  >
                                                    <span className="option_title title_1">{option.category}</span>
                                                  </label>
                                                </div>
                                              );
                                            }
                                          })}
                                      </div>
                                      <div>
                                        <p onClick={() => (setStep(prevCount => prevCount + 1), setFormData({ ...formData, ["financeProvider"]: ["0"] }),skipThisPage(), localStorage.removeItem("slugArray"))} style={{ color: "white", textAlign: "center", textDecoration: "underline", cursor: "pointer" }}>I don't know</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="box_button pt-3 animate__animated animated-element"
                                    id="subRegNo2"
                                  >
                                    <div className="mb-4">
                                      {/* {formData.financeProvider.map(
                                        (value, index) => {
                                          // Find the corresponding label for the current value
                                          const label =
                                            Finance.find(
                                              (finance) =>
                                                finance.value === value
                                            )?.label || "Unknown";
                                          return (
                                            <div className="container "
                                              key={index}>
                                              <div
                                                className="selected-options row "
                                              >
                                                <div className="col-10">
                                                  <p>{label}</p>
                                                </div>

                                                <div
                                                  onClick={() =>
                                                    handleRemoveFinanceProvider(
                                                      index
                                                    )
                                                  }
                                                  className="col-2 delete-option text-center d-flex align-items-center"
                                                >
                                                  <p>x</p>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )} */}
                                      {error && (
                                        <p style={{ backgroundColor: "transparent", color: "red", fontWeight: "bolder" }} className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                                          {error}
                                        </p>
                                      )}
                                    </div>
                                    <div ref={lender_error} tabIndex={-1}></div>
                                    <div className="row align-items-center lenders-page">
                                      <div className="col-4 text-center">
                                        <button
                                          type="button"
                                          data-type="back"
                                          id="backStep-1"
                                          className="msf-nav-button backbutton"
                                          onClick={prevStep}
                                        >
                                          <img src={nextgreen} alt="" />
                                          Back
                                        </button>
                                      </div>
                                      <div className="col-8">
                                        <div className="bg-1 hme_btn">
                                          <button
                                            type="button"
                                            className="button w-100 next-btn"
                                            id="nextslideCl_002"
                                            value="Next"
                                            aria-label="submitid"
                                            name="subRegNo2"
                                            onClick={nextStep}
                                          >
                                            {" "}
                                            Next
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* // slide_3dec */}
                            {step === 3 && (
                              <div className="slide_2_1Dec">
                                <h2 className="animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                                  Did the dealer disclose their commission and
                                  how it may affect the interest rate on your
                                  loan?
                                </h2>
                                <div className="px-3 py-3 widget_mobi borderx">
                                  <div className="choose_Y_N col-lg-12 col-12 animate__animated animate__fadeInUp animate__delay-.5s">
                                    <div className="form-check px-0">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        aria-label="commission"
                                        name="commission"
                                        id="1"
                                        value={formData.commission}
                                        checked={formData.commission}
                                        onChange={handleChangeButton}
                                        onClick={nextStep}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="1"
                                      >
                                        No
                                      </label>
                                    </div>

                                    <div className="form-check px-0">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        aria-label="commission"
                                        name="commission"
                                        id="2"
                                        value={formData.commission}
                                        onClick={handleYesModal}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="2"
                                      >
                                        Yes
                                      </label>
                                    </div>
                                  </div>
                                  {commissionError && (
                                    <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                                      {commissionError}
                                    </p>
                                  )}

                                  <div
                                    className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s animated-element"
                                    id="subRegNo4"
                                  >
                                    <div className="row align-items-center">
                                      <div className="col-12 text-center ">
                                        <button
                                          type="button"
                                          data-type="back"
                                          id="backStep-3"
                                          className="msf-nav-button backbutton"
                                          onClick={prevStep}
                                        >
                                          <img src={nextgreen} alt="" />
                                          Back
                                        </button>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {step === 4 && (
                              <div className="slide_2_2Dec">
                                <h2 className="animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                                  Are you currently in an IVA, bankruptcy, or
                                  other debt management arrangement?
                                </h2>
                                <div className="px-3 py-3 widget_mobi borderx">
                                  <div className="choose_Y_N col-lg-12 col-12 animate__animated animate__fadeInUp animate__delay-.5s">
                                    <div className="form-check px-0">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        aria-label="bankruptcy"
                                        name="bankruptcy"
                                        id="3"
                                        defaultValue="bankruptcy"
                                        checked={formData.bankruptcy}
                                        onChange={handleChangeButton}
                                        onClick={nextStep}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="3"
                                      >
                                        No
                                      </label>
                                    </div>

                                    <div className="form-check px-0">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        aria-label="bankruptcy"
                                        name="bankruptcy"
                                        id="4"
                                        defaultValue="bankruptcy"
                                        onClick={handleYesModal}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="4"
                                      >
                                        Yes
                                      </label>
                                    </div>
                                  </div>
                                  {bankError && (
                                    <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                                      {bankError}
                                    </p>
                                  )}

                                  <div
                                    className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s animated-element"
                                    id="subRegNo4"
                                  >
                                    <div className="row align-items-center">
                                      <div className="col-12 text-center ">
                                        <button
                                          type="button"
                                          data-type="back"
                                          id="backStep-3"
                                          className="msf-nav-button backbutton"
                                          onClick={prevStep}
                                        >
                                          <img src={nextgreen} alt="" />
                                          Back
                                        </button>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {step === 5 && (
                              <div className="slide_2_3Dec">
                                <h2 className="animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                                  Have you already claimed?
                                </h2>
                                <div className="px-3 py-3 widget_mobi borderx">
                                  <div className="choose_Y_N col-lg-12 col-12 animate__animated animate__fadeInUp animate__delay-.5s">
                                    <div className="form-check px-0">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        aria-label="claimed"
                                        name="claimed"
                                        id="5"
                                        value={formData.claimed}
                                        checked={formData.claimed}
                                        onChange={handleChangeButton}
                                        onClick={nextStep}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="5"
                                      >
                                        No
                                      </label>
                                    </div>

                                    <div className="form-check px-0">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        aria-label="claimed"
                                        name="claimed"
                                        id="6"
                                        defaultValue="claimed"
                                        onClick={handleYesModal}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="6"
                                      >
                                        Yes
                                      </label>
                                    </div>
                                  </div>
                                  {claimError && (
                                    <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                                      {claimError}
                                    </p>
                                  )}

                                  <div
                                    className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s animated-element"
                                    id="subRegNo4"
                                  >
                                    <div className="row align-items-center">
                                      <div className="col-12 text-center ">
                                        <button
                                          type="button"
                                          data-type="back"
                                          id="backStep-3"
                                          className="msf-nav-button backbutton"
                                          onClick={prevStep}
                                        >
                                          <img src={nextgreen} alt="" />
                                          Back
                                        </button>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {step === 6 && (
                              <div className="slide_2_3Dec">
                                <h2 className="animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                                  What reason best describes why you wish to seek professional assistance from a CMC to make this claim:
                                </h2>
                                <div className="px-3 py-3 widget_mobi borderx">
                                  <div className="mb-2 row no100 choose_Y_N animate__animated animate__fadeInUp animate__delay-.5s">

                                    <div className="form-check px-0 col-lg-12 col-12 w_full">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        aria-label="whyNotClaimed"
                                        name="whyNotClaimed"
                                        id="8"
                                        value="8"
                                        checked={formData.whyNotClaimed == "8" ? true : false}
                                        onChange={handleChangeButton}
                                        onClick={nextStep}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="8"
                                      >
                                        I do not have the time
                                      </label>
                                    </div>
                                    <div className="form-check px-0 col-lg-12 col-12 w_full">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        aria-label="whyNotClaimed"
                                        name="whyNotClaimed"
                                        id="9"
                                        value="9"
                                        checked={formData.whyNotClaimed == "9" ? true : false}
                                        onChange={handleChangeButton}
                                        onClick={nextStep}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="9"
                                      >
                                        I do not know how to make a claim
                                      </label>
                                    </div>
                                    <div className="form-check px-0 col-lg-12 col-12 w_full">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        aria-label="whyNotClaimed"
                                        name="whyNotClaimed"
                                        id="10"
                                        value="10"
                                        checked={formData.whyNotClaimed == "10" ? true : false}
                                        onChange={handleChangeButton}
                                        onClick={nextStep}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="10"
                                      >
                                        I would prefer professional help
                                      </label>
                                    </div>
                                    <div className="form-check px-0 col-lg-12 col-12 w_full">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        aria-label="whyNotClaimed"
                                        name="whyNotClaimed"
                                        id="11"
                                        value="11"
                                        checked={formData.whyNotClaimed == "11" ? true : false}
                                        onChange={handleChangeButton}
                                        onClick={nextStep}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="11"
                                      >
                                        Other
                                      </label>
                                    </div>

                                  </div>

                                  <div
                                    className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s animated-element"
                                    id="subRegNo4"
                                  >
                                    <div className="row align-items-center">
                                      <div className="col-12 text-center ">
                                        <button
                                          type="button"
                                          data-type="back"
                                          id="backStep-3"
                                          className="msf-nav-button backbutton"
                                          onClick={prevStep}
                                        >
                                          <img src={nextgreen} alt="" />
                                          Back
                                        </button>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* // slide_3dec */}
                            {step === 7 && (
                              <div className="slide_3Dec">
                                <h2 className="animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                                  Personalise your experience. May we know your name?
                                </h2>
                                <div className="px-3 py-3 widget_mobi borderx">
                                  <div className="form_drop w-100">
                                    <div className="row">
                                      <div className="col-12">
                                        <label
                                          htmlFor="format2"
                                          className="text-start w-100 animate__animated animate__fadeInUp animate__delay-.5s animated-element"
                                        >
                                          Title
                                        </label>
                                        <div className="select_title animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                                          <select
                                            className="form-select form-control"
                                            id="format2"
                                            name="lstSalutation"
                                            value={formData.lstSalutation}
                                            onChange={handleChange}
                                          >
                                            {Salutation.map((option) => (
                                              <option
                                                key={option.value}
                                                value={option.value}
                                              >
                                                {option.label}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                        {titleError && (
                                          <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                                            {titleError}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                    <div className="row   name_detail animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                                      <div className="col-12 f_name mb-3">
                                        <label htmlFor="topmention1">
                                          First Name
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="topmention1"
                                          placeholder="First name"
                                          aria-label="First name"
                                          name="txtFName"
                                          value={formData.txtFName}
                                          onChange={handleChange}
                                          register={register}
                                        />
                                        {firstNameError && (
                                          <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                                            {firstNameError}
                                          </p>
                                        )}
                                      </div>

                                      <div className="col-12 l_name mb-3">
                                        <label htmlFor="topmention2">
                                          Last Name
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="topmention2"
                                          placeholder="Last name"
                                          aria-label="Last name"
                                          name="txtLName"
                                          value={formData.txtLName}
                                          onChange={handleChange}
                                        />
                                        {lastNameError && (
                                          <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                                            {lastNameError}
                                          </p>
                                        )}
                                      </div>

                                      <p className="tiny_note m-0">
                                        <img
                                          src={info}
                                          className="pe-1"
                                          alt=""
                                        />
                                        What you are legally known as
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s animated-element"
                                    id="subRegNo3"
                                  >
                                    <div className="row align-items-center">
                                      <div className="col-4 text-center">
                                        <div className="bg-1 hme_btn">
                                          <button
                                            type="button"
                                            data-type="back"
                                            id="backStep-2"
                                            className="msf-nav-button backbutton"
                                            onClick={prevStep}
                                          >
                                            <img src={nextgreen} alt="" />
                                            Back
                                          </button>
                                        </div>
                                      </div>
                                      <div className="col-8">
                                        <button
                                          type="button"
                                          className="button w-100 next-btn"
                                          id="nextslideCl_003"
                                          value="Next"
                                          aria-label="submitid"
                                          name="subRegNo3"
                                          onClick={nextStep}
                                        >
                                          {" "}
                                          Next
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* // slide_4dec */}
                            {step === 8 && (
                              <div className="slide_4Dec">
                                <h2 className="animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                                  Please provide your email and phone number
                                  below so that we can keep you updated on your
                                  potential refund.
                                </h2>
                                <div className="px-3 py-3 widget_mobi borderx">
                                  <div
                                    className="form_drop col-lg-12 col-12"
                                    id="ENt_DTLS"
                                  >
                                    <div className="check_email_add pt-0 animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                                      <label>Email Address: </label>
                                      <div className="input-group flex-nowrap secure">
                                        <input
                                          type="email"
                                          className="form-control"
                                          placeholder="Email Address"
                                          aria-label="Username"
                                          aria-describedby="addon-wrapping"
                                          id="emailInput"
                                          name="txtEmail"
                                          value={formData.txtEmail}
                                          onChange={handleChange}
                                          onBlur={emailValidCheck}
                                        />
                                      </div>
                                      {emailError && (
                                        <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                                          {emailError}
                                        </p>
                                      )}

                                      <p className="tiny_note mb-2">
                                        <img
                                          src={info}
                                          className="pe-1"
                                          alt=""
                                        />
                                        We need this so we can keep you updated
                                        on your claim by email.
                                      </p>
                                    </div>
                                    <div className="row phone_deatail animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                                      <div className="col-12 user_no">
                                        <label>Mobile Number:</label>
                                        <div className="input-group flex-nowrap secure">
                                          <input
                                            type="tel"
                                            className="form-control"
                                            placeholder="Mobile Phone Number"
                                            aria-label="Phone Number"
                                            aria-describedby="Phone-Number"
                                            name="txtPhone"
                                            maxLength={11}
                                            value={formData.txtPhone}
                                            onChange={handleChange}
                                            onBlur={phoneValidCheck}
                                            onKeyPress={handleKeyPress}
                                          />
                                        </div>
                                        {phoneError && (
                                          <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                                            {phoneError}
                                          </p>
                                        )}
                                        <p className="tiny_note">
                                          <img
                                            src={info}
                                            className="pe-1"
                                            alt=""
                                          />
                                          We need this so we can keep you updated on your claim with text messages.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s animated-element"
                                    id="subRegNo4"
                                  >
                                    <div className="row align-items-center">
                                      <div className="col-4 text-center ">
                                        <button
                                          type="button"
                                          data-type="back"
                                          id="backStep-3"
                                          className="msf-nav-button backbutton"
                                          onClick={prevStep}
                                        >
                                          <img src={nextgreen} alt="" />
                                          Back
                                        </button>
                                      </div>
                                      <div className="col-8">
                                        <div className="bg-1 hme_btn w-100">
                                          <button
                                            type="button"
                                            id="nextslideCl_004"
                                            value="Next"
                                            aria-label="submitid"
                                            name="subRegNo4"
                                            className="button w-100 next-btn"
                                            onClick={nextStep}
                                          >
                                            Next
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* // slide_5dec */}
                            {step === 9 && (
                              <div className="slide_5Dec">
                                <h2 className="animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                                  Please provide your Date of Birth to ensure
                                  accuracy in our records.
                                </h2>
                                <div className="px-3 py-3 widget_mobi borderx">
                                  <div className="form_drop col-lg-12 col-12 text-start">
                                    <label htmlFor="">Date of Birth</label>
                                    <div className="row pt-0 Birth_detail animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                                      <div className="col-lg-4 col-md-4 col-12 date_here">
                                        <label htmlFor="DobDay">Day</label>
                                        <select
                                          className="form-select"
                                          name="dobDay"
                                          id="dobDay"
                                          value={formData.dobDay}
                                          onChange={handleChange}
                                        >
                                          {DobDay.map((option) => (
                                            <option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </option>
                                          ))}
                                        </select>
                                        {dobError && (
                                          <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate1">
                                            {dobError}
                                          </p>
                                        )}
                                      </div>

                                      <div className="col-lg-4 col-md-4 col-12 month_here">
                                        <label htmlFor="DobMonth">Month</label>
                                        <select
                                          className="form-select"
                                          name="dobMonth"
                                          id="dobMonth"
                                          value={formData.dobMonth}
                                          onChange={handleChange}
                                        >
                                          {DobMonth.map((option) => (
                                            <option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </option>
                                          ))}
                                        </select>
                                        {monthError && (
                                          <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate1">
                                            {monthError}
                                          </p>
                                        )}
                                      </div>

                                      <div className="col-lg-4 col-md-4 col-12 year_here">
                                        <label htmlFor="DobYear">Year</label>
                                        <select
                                          className="form-select"
                                          name="dobYear"
                                          id="dobYear"
                                          value={formData.dobYear}
                                          onChange={handleChange}
                                        >
                                          {DobYear.map((option) => (
                                            <option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </option>
                                          ))}
                                        </select>
                                        {yearError && (
                                          <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate1">
                                            {yearError}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  {/* <p className="tiny_note mt-0">
                                    <img src={info} className="pe-1" alt="" />
                                    To be eligible to claim, you need to be over
                                    25 years old.
                                  </p> */}
                                  <div
                                    className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s animated-element"
                                    id="subRegNo5"
                                  >
                                    <div className="row align-items-center">
                                      <div className="col-4 text-center">
                                        <button
                                          type="button"
                                          data-type="back"
                                          id="backStep-4"
                                          className="msf-nav-button backbutton"
                                          onClick={prevStep}
                                        >
                                          <img src={nextgreen} alt="" />
                                          Back
                                        </button>
                                      </div>
                                      <div className="col-8">
                                        <div className="bg-1 hme_btn">
                                          <button
                                            type="button"
                                            className="button w-100 next-btn"
                                            id="nextslideCl_005"
                                            value="Next"
                                            aria-label="submitid"
                                            name="subRegNo5"
                                            onClick={nextStep}
                                          >
                                            {" "}
                                            Next
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* // slide_6dec */}
                            {step === 10 && (
                              <div className="slide_6Dec">
                                <h2 className="animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                                  According to your responses, it seems you
                                  might be eligible for compensation.
                                </h2>
                                <div className="px-3 py-3 widget_mobi borderx">
                                  <p className="sub_quesHead animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                                    To proceed, please finalise your registration by providing your current address.
                                  </p>
                                  <div className="form_drop col-lg-12 col-12">
                                    <div
                                      className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s animated-element"
                                      style={{
                                        zIndex: 2,
                                        position: "relative",
                                      }}
                                    >
                                      <div className="enter_postcode_add animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                                        <label>Postcode</label>
                                        <PostCode_PCP_HDSC_V2
                                          validation={register}
                                          validationMsg={errors}
                                          trigger={trigger}
                                          clearErrors={clearErrors}
                                          handleChange={handleChange}
                                          setError={setError}
                                          setValue={setValue}
                                          setPostCodeError={setPostCodeError}
                                        />
                                        {(postCodeError && !errors.txtPostCode && !errors.txtHouseNumber && !errors.txtAddress2 && !errors.txtCounty && !errors.txtTown && !errors.txtCountry) && (
                                          <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                                            {postCodeError}
                                          </p>
                                        )}
                                        {/*  */}
                                        <p className="tiny_note">
                                          <img
                                            src={info}
                                            className="pe-1"
                                            alt=""
                                          />
                                          Your address is required for a smooth
                                          claim process
                                        </p>
                                        <div className="col-lg-5 col-12"></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="box_button animate__animated animate__fadeInUp animate__delay-.5s animated-element"
                                    id="subRegNo6"
                                  >
                                    <div className="row align-items-center mt-4">
                                      <div className="col-4 text-center">
                                        <button
                                          type="button"
                                          data-type="back"
                                          id="backStep-5"
                                          className="backbutton"
                                          onClick={prevStep}
                                        >
                                          <img src={nextgreen} alt="" />
                                          Back
                                        </button>
                                      </div>
                                      <div className="col-8">
                                        <div className="bg-1 hme_btn">
                                          <button
                                            type="button"
                                            className="button w-100 next-btn"
                                            id="nextslideCl_006"
                                            value="Next"
                                            aria-label="submitid"
                                            name="subRegNo6"
                                            onClick={nextStep}
                                          // data-bs-toggle="modal"
                                          // data-bs-target="#pre_add"
                                          >
                                            PROCEED
                                          </button>
                                        </div>
                                      </div>
                                      <div className="col-12 text-center tiny_note">
                                        By submitting your details and proceeding from this point you agree to our <a href={PrivacyPolicy} target="_blank"><u>Privacy Policy</u></a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div
                        className={`img_float animate__animated animate__fadeInLeft animate__delay-1s order-lg-2 order-md-2 order-2 col-lg-6 col-12 text-center ${step !== 1 && " must_hide_at_mobile"
                          }`}
                      >
                        <img
                          src={bg_float}
                          alt="Hero"
                          className="cs_hero_img"
                        />
                      </div>
                      <div className="col-12 order-3">
                        {step === 1 && (
                          <div
                            className="box_button pt-3 animate__animated animate__fadeInLeft animate__delay-1s animated-element wht_iFNot no_border"
                            id="subRegNo1"
                          >
                            <div className="bg-1 hme_btn">
                              <input
                                type="button"
                                className="button button--moma button--text-thick button--text-upper button--size-s blick_notice"
                                id="nextPlate2_001"
                                defaultValue="EXPRESS REGISTRATION"
                                aria-label="submitid"
                                name="subRegNo1"
                                onClick={nextStep}
                              />
                            </div>
                          </div>
                        )}
                        <p
                          className={`fw-semibold text-white text-center nimate__animated animate__fadeInLeft animate__delay-1s animated-element d-block d-md-none mb-2 ${step !== 1 && " must_hide_at_mobile"
                            }`}
                        >
                          Quick, easy, fully online registration.
                        </p>
                        <div className="mob_view animate__animated animate__fadeInLeft animate__delay-1s">
                          <img src={review_star} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* form section */}
            </div>
            <div className="img_secure text-center py-4 d-none">
              <img src={secure} alt="" />
            </div>
            {/* row end */}
          </div>
        </section>
        {showModal && (
          <div
            className="modal fade show"
            id="pre_add"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="pre_addLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s ">
                <div className="modal-body d-block">
                  {!showSecondSection && ( // Hide form_drop div when showSecondSection is true
                    <div className="form_drop yes_no_sec col-lg-12 col-12 p-3">
                      <div
                        className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s"
                        style={{ zIndex: 2, position: "relative" }}
                      >
                        <p className="png_t">
                          Did you register with your lender at a different/previous address
                          than your current address?
                        </p>
                        <div className="choose_Y_N col-lg-12 col-12 animate__animated animate__fadeInUp animate__delay-.5s">
                          <div className="form-check px-0">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="Radioshere2"
                              id="Radios2"
                              defaultValue="optionclick1"
                              onClick={handleNoClick}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="Radios2"
                            >
                              No
                            </label>
                          </div>
                          <div className="form-check px-0">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="Radioshere2"
                              id="Radios1"
                              defaultValue="optionclick2"
                              onClick={handleYesClick}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="Radios1"
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {showSecondSection && (
                    <div className="p-lg-4 p-md-3 p-sm-2 p-2 enter_postcode_mod">
                      <div className=" pt-0  animate__animated animate__fadeInUp animate__delay-.5s">
                        <p className="png_t">
                          Enter your previous postcode
                        </p>
                        <label>Postcode</label>
                        <div className="postcode_wrap d-block">
                          <PreviousPostcode_PCP_HDSC_V1
                            validation={register}
                            validationMsg={errors}
                            trigger={trigger}
                            clearErrors={clearErrors}
                            setError={setError}
                          />
                        </div>
                        <p className="tiny_note mt-2">
                          Your address is required for a smooth claim process
                        </p>
                        <div className="col-lg-5 col-12"></div>
                      </div>
                      <div className="modal-footer">
                        <div className="box_button pt-3 final_sub pt-3 animate__animated animate__fadeInUp animate__delay-.5s">
                          <div className="bg-1">
                            <button
                              type="button"
                              className="button  w-100 button--moema button--text-thick button--text-upper button--size-s h60_click blick_notice"
                              id="nextPlate_007"
                              onClick={handleSubmit}
                            >
                              Submit
                              <svg
                                width={27}
                                height={28}
                                viewBox="0 0 27 28"
                                fill="none"
                              >
                                <mask
                                  id="mask0_695_171"
                                  style={{ maskType: "alpha" }}
                                  maskUnits="userSpaceOnUse"
                                  x={0}
                                  y={0}
                                  width={27}
                                  height={28}
                                >
                                  <rect
                                    x="0.708008"
                                    y="0.813721"
                                    width="26.29"
                                    height="26.29"
                                    fill="#D9D9D9"
                                  />
                                </mask>
                                <g mask="url(#mask0_695_171)">
                                  <path
                                    d="M17.5751 15.4101H4.56348V12.5072H17.5751L11.7815 6.71327L13.8532 4.66895L23.1429 13.9586L13.8532 23.2483L11.7815 21.204L17.5751 15.4101Z"
                                    fill="#fff"
                                  />
                                </g>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </form>

      <LenderModal modalClass={modalClass} handleFinanceProviderChange={handleFinanceProviderChange} cancelpopupLender={cancelpopupLender} />
      <div
        className={`modal fade ${formModalTwo && "show d-block"}`}
        id="CarDate"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="pre_addLabel"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s ">
            <div className="modal-header p-1">
              <button
                type="button"
                onClick={cancelpopupTwo}
                className="btn-close"
              ></button>
            </div>

            <div className="modal-body text-center">
              <div className="form_drop yes_no_sec col-lg-12 col-12 p-1">
                <div
                  className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s"
                  style={{ zIndex: 2, position: "relative" }}
                >
                  <p className="png_t">
                    Sorry, we will not be able to help with this claim.
                  </p>
                </div>
                <div className="button-group">
                  <button
                    type="button"
                    className="bt_cancel"
                    onClick={cancelpopupTwo}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${formModal && "show d-block"}`}
        id="CarDate"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="pre_addLabel"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s ">
            <div className="modal-header p-1">
              <button
                type="button"
                onClick={cancelpopup}
                className="btn-close"
              ></button>
            </div>

            <div className="modal-body text-center">
              <div className="form_drop yes_no_sec col-lg-12 col-12 p-1">
                <div
                  className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s"
                  style={{ zIndex: 2, position: "relative" }}
                >
                  <p className="png_t">You can only select up to 4 lenders</p>
                </div>
                <div className="button-group">
                  <button
                    type="button"
                    className="bt_cancel"
                    onClick={nextStep}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${financeProviderViewerModal !== "null" && "show d-block"}`}
        id="CarDate"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="pre_addLabel"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modalContent_sml animate__animated animate__zoomIn animate__delay-.5s ">
            <div className="modal-header p-1">
              <button
                type="button"
                onClick={() => setFinanceProviderViewerModal("null")}
                className="btn-close"
              ></button>
            </div>
            <div className="modal-body text-center">
              <div className="form_drop yes_no_sec col-lg-12 col-12 p-1">
                <div
                  className="sect_bt animate__animated animate__fadeInUp animate__delay-.5s"
                  style={{ zIndex: 2, position: "relative" }}
                >
                  <p ref={errorRef} className="png_t">Please confirm the specific lender you've taken out finance with:</p>
                  {financeProviderViewerError &&
                    <p className="error-msg animate__animated animate__fadeInUp animate__delay-.5s">{financeProviderViewerError}</p>
                  }
                  {financeProviderViewerModal !== "null" &&
                    Finance_Prominent_List_Reworded[financeProviderViewerModal].sub_categories
                      .sort((a, b) => a.label.localeCompare(b.label))
                      .map((option, index) => {
                        const isSelected = formData.financeProvider.includes(option.value);
                        return (
                          <div className="selectoptions selectoptions-custom" key={index}>
                            <input
                              value={option.value}
                              type="checkbox"
                              name="financeProvider"
                              id={"suboption_" + financeProviderViewerModal + "_" + index}
                              className=""
                              onChange={(e) => handleFinanceProviderChange(e)}
                            />
                            <label
                              htmlFor={"suboption_" + financeProviderViewerModal + "_" + index}
                              className={`${isSelected ? 'selected-true' : 'selected-false'}`}
                            >
                              <span className="option_title title_1">{option.label}</span>
                            </label>
                          </div>
                        );
                      })
                  }
                </div>
                <div className="button-group">
                  <button
                    type="button"
                    className="bt_cancel mt-3"
                    onClick={() => setFinanceProviderViewerModal("null")}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PreviousNameModal
        showPreviousNameModal={showPreviousNameModal}
        setShowPreviousNameModal={setShowPreviousNameModal}
        setFormData={setFormData}
        nextStep={nextStep}
      />
    </div>
  );
};

export default PCP_HDSC_V2Form;
