import React, { useContext, useEffect, useState } from 'react'
import Email from '../../../UI/Email';
import Telephone from '../../../UI/Telephone';
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import { slideValidation } from '../../../../Utility/Validations';
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import FluidLoader from '../PCP_HDSC_V3/FluidLoader';
import info from "../../../../assets/PCP_HDSC_V5/img/info.png"
import Button from '../../../UI/Button';
import { numberValidation } from "../../../../Utility/Validations";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";

const ContactDetails = (props) => {
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();
  const [contactValidation, setContactValidation] = useState({ 'txtEmail': false, 'txtPhone': false });
  const { visitorParameters } = useContext(VisitorParamsContext);

  const emailValidCheck = async () => {
    const values = props.getValues();
    const email = values['txtEmail'];
    if (email !== "") {
      const getEmailValidation = await emailValidation(email, visitorParameters.visitor_parameters.uuid)
      if (getEmailValidation.data.status_code === 1) {
        setContactValidation(prevState => ({
          ...prevState,
          txtEmail: true,
        }));
        props.clearErrors("txtEmail");
        return 1;
      } else {
        setContactValidation(prevState => ({
          ...prevState,
          txtEmail: false,
        }));
        props.setError("txtEmail", {
          type: "manual",
          message: "Invalid Email",
        });
      }
    } else {
      props.setError("txtEmail", {
        type: "manual",
        message: "Please Enter Valid Email",
      });
    }
  };
  const phoneValidCheck = async () => {
    const values = props.getValues();
    const phone = values['txtPhone'];
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone, visitorParameters.visitor_parameters.uuid)
      if (getPhoneValidation.data.status_code === 0) {
        setContactValidation(prevState => ({
          ...prevState,
          txtPhone: false,
        }));
        props.setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        setContactValidation(prevState => ({
          ...prevState,
          txtPhone: false,
        }));
        props.setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 3) {
        setContactValidation(prevState => ({
          ...prevState,
          txtPhone: false,
        }));
        props.setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        return 0;
      }
      else {
        setContactValidation(prevState => ({
          ...prevState,
          txtPhone: true,
        }));
        props.clearErrors("txtPhone");
        return 1;
      }
    } else {
      props.setError("txtPhone", {
        type: "manual",
        message: "Please Enter Valid Phone Number",
      });
    }
  };
  const eventOnchange = (e) => {
    var field = e.target.id;
    setContactValidation(prevState => ({
      ...prevState,
      [field]: false,
    }));
    if (e.target.name == 'txtphone' && e.target.value.length >= 11) phoneValidCheck()
  }

  const submitContact = async () => {
    let validationStatus = 0;
    let contactPolicyStatus = 0;
    var validationArr = ['txtEmail', 'txtPhone'];
    const validationCheck = await slideValidation(validationArr, props.trigger);
    const contactStatus = Object.entries(contactValidation).find(([key, value]) => value === false);
    if (validationCheck == 'Success') {
      if (contactStatus) {
        let response;
        if (contactStatus[0] === "txtEmail") {
          response = await emailValidCheck();
        } else if (contactStatus[0] === "txtPhone") {
          response = await phoneValidCheck();
        }
        if (response == 1) {
          validationStatus = 1;
        } else {
          validationStatus = 0;
        }
      } else {
        validationStatus = 1;
      }

      if (validationStatus === 1) {
        props.setLoader(true);
        GTMDataLayer({
          question: 'contact details',
          answer: null,
        });
        props.setContactModal(false);
        props.setPlateFour(false);
        props.setCurrentPage("Postcode");
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      props.setLoader(false);
    }, 2000);
  }, [props.loader])
  return (
    <div>
      <div
        className={`modal ${props.contactModal ? 'show' : 'hide'}`}
        id="lenderinfopop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content animate__animated animate__zoomIn animate__delay-.5s ">
            <div className="modal-body pt-0">
              <div className="jst_adDf_lx">
                <div className="slide4 col-12">
                  <div className="cicle_loader pt-0">
                    <div className="text12">
                      <h5 className="txt">Checking Lender information</h5>
                    </div>
                    <div className="loader_gif_">
                      <FluidLoader />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form_drop col-lg-12 col-12" id="ENt_DTLS">
                <p className="sty_pop">
                  Please confirm your contact details to be used for critical communication about your PCP complaint.
                </p>
                <div className="check_email_add animate__animated animate__fadeInUp animate__delay-.5s">
                  <label>Email Address: </label>
                  <div className="input-group flex-nowrap secure">
                    <Email
                      name="txtEmail"
                      id="txtEmail"
                      className="form-control"
                      placeholder="Email Address"
                      onBlur={emailValidCheck}
                      autoComplete="new-email"
                      onKeyPress={e => { if (e.key === ' ') e.preventDefault() }}
                      onChange={(e) => { props.clearErrors("txtEmail"); eventOnchange(e) }}
                      validation={props.validation({
                        required: "Please Enter Valid Email Address",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Invalid Email Address",
                        },
                      })}
                    // validationMsg={
                    //     props.validationMsg.txtEmail && props.validationMsg.txtEmail.message
                    //   }
                    />
                  </div>
                  {props.validationMsg.txtEmail && <span className='error_msg'>{props.validationMsg.txtEmail.message}</span>}
                  <p className="tiny_note">
                    <img
                      src={info}
                      className="pe-1"
                      alt=""
                    />
                    We need this so we can keep you updated on your claim
                    by email.
                  </p>
                </div>
                <div className="row phone_deatail animate__animated animate__fadeInUp animate__delay-.5s">
                  <div className="col-12 user_no">
                    <label>Mobile Number:</label>
                    <div className="input-group flex-nowrap secure">

                      <Telephone
                        name="txtPhone"
                        id="txtPhone"
                        className="form-control"
                        placeholder="Phone Number"
                        onChange={(e) => { props.clearErrors("txtPhone"); eventOnchange(e); }}
                        nBlur={phoneValidCheck}
                        onKeyPress={e => { numberValidation(e) }}
                        // onKeyPress={validNumber}
                        maxlength="11"
                        autoComplete="new-phone"
                        validation={props.validation({
                          pattern: {
                            value: /^[0-9]*$/i,
                            message: "Please Enter Valid Phone Number",
                          },
                          required: "Please Enter Valid Phone Number",
                        })}
                        validationMsg={
                          props.validationMsg.txtPhone && props.validationMsg.txtPhone.message
                        }
                      />
                    </div>
                    {props.validationMsg.txtPhone && <span className='error_msg'>{props.validationMsg.txtPhone.message}</span>}

                  </div>
                  <p className="tiny_note mb-0">
                    <img
                      src={info}
                      className="pe-1"
                      alt=""
                    />
                    We need this so we can keep you updated on your claim
                    with txt message.
                  </p>
                </div>
              </div>
            </div>
            <div className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s">
              <div className="bg-1">

                    <Button
                        type="button"
                        className="confetti button w-100 button--moema button--text-thick button--text-upper button--size-s h60_click blick_notice"
                        buttonText={
                          <span>Next</span>
                        }
                        id="submitContact"
                        onClick={submitContact}
                      />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactDetails