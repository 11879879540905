const Footer = () => {
  return (
    <footer className="text-center">
      <div className="container">
        <div className="ftr_cntnt">
          <div className="row">
            <div className="col-12">
              <p>Hendersons is a trading style of Henderson Claims Limited. Henderson Claims Limited is authorised and regulated by the Financial Conduct Authority with reference number FRN:836041. Henderson Claims Limited is a Claims Management Company (CMC). Registered with the Information Commissioner's Office: registration number ZA147049. Registered address: Office 206, Ashton Old Baths, Stamford Street West, Ashton-under-Lyne, OL6 7FW. Registered in England and Wales, Company number 07646017. VAT number: 240 8769 88.</p>
              <p><b>© 2023</b></p>
            </div>
          </div>
          <div className="text-center">
            <div className="copyright">
              <ul>
                <li>
                  <a href="https://trusthendersons.co.uk/privacy.html" target="_blank">Privacy Policy</a>
                </li>
                <li>
                  <a href="https://trusthendersons.co.uk/terms.html" target="_blank">Terms & Conditions</a>
                </li>
                <li>
                  <a href="https://trusthendersons.co.uk/pre-contract.html" target="_blank"> Pre-contract Information</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </footer>
  )
}
export default Footer;