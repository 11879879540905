import React, { useContext, useState } from 'react';
import info from "../../../../assets/PCP_HDSC_V1/img/info.png";
import nextgreen from "../../../../assets/PCP_HDSC_V1/img/next-green.png";
import TextField from "../../../UI/TextField";
import Button from "../../../UI/Button";
import Email from '../../../UI/Email';
import Telephone from '../../../UI/Telephone';
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";

const EmailPhoneSection = (props) => {
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();
  const [contactValidation, setContactValidation] = useState({ 'txtEmail': false, 'txtPhone': false });
  const { visitorParameters } = useContext(VisitorParamsContext);

  const emailValidCheck = async () => {
    const values = props.getValues();
    const email = values['txtEmail'];
    if (email !== "") {
      const getEmailValidation = await emailValidation(email, visitorParameters.visitor_parameters.uuid);
      if (getEmailValidation.data.status_code === 1) {
        setContactValidation(prevState => ({
          ...prevState,
          txtEmail: true,
        }));
        props.clearErrors("txtEmail");
        return true;
      } else {
        setContactValidation(prevState => ({
          ...prevState,
          txtEmail: false,
        }));
        props.setError("txtEmail", {
          type: "manual",
          message: "Invalid Email",
        });
        return false;
      }
    } else {
      props.setError("txtEmail", {
        type: "manual",
        message: "Please Enter Valid Email",
      });
      return false;
    }
  };

  const phoneValidCheck = async () => {
    const values = props.getValues();
    const phone = values['txtPhone'];
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone, visitorParameters.visitor_parameters.uuid);
      if (getPhoneValidation.data.status_code === 0) {
        setContactValidation(prevState => ({
          ...prevState,
          txtPhone: false,
        }));
        props.setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        return false;
      } else
       if (getPhoneValidation.data.status_code === 2) {
        setContactValidation(prevState => ({
          ...prevState,
          txtPhone: false,
        }));
        props.setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exists",
        });
        return false;
      } else if (getPhoneValidation.data.status_code === 3) {
        setContactValidation(prevState => ({
          ...prevState,
          txtPhone: false,
        }));
        props.setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        return false;
      } else {
        setContactValidation(prevState => ({
          ...prevState,
          txtPhone: true,
        }));
        props.clearErrors("txtPhone");
        return true;
      }
    } else {
      props.setError("txtPhone", {
        type: "manual",
        message: "Please Enter Valid Phone Number",
      });
      return false;
    }
  };

  const handleFieldChange = async (event) => {
    const { name, value } = event.target;
    if (name === 'txtEmail') {
      await emailValidCheck();
    } else if (name == 'txtphone' && event.target.value.length >= 11) {
      
      await phoneValidCheck();
    }
  };

  const submitContact = async (e) => {
    let emailValid = true;
    let phoneValid = true;
    const emailValue = props.getValues()['txtEmail'];
    const phoneValue = props.getValues()['txtPhone'];
    props.formData.txtPhone  = phoneValue;
    props.formData.txtEmail = emailValue;
    if (emailValue === "") {
      props.setError("txtEmail", {
        type: "manual",
        message: "Please enter email.",
      });
      emailValid = false;
    } else if (phoneValue === "") {
      props.setError("txtPhone", {
        type: "manual",
        message: "Please enter phone number.",
      });
      phoneValid = false;
    } else {
      const emailCheck = await emailValidCheck();
      const phoneCheck = await phoneValidCheck();
      if (!phoneCheck) phoneValid = false;
      if (!emailCheck) emailValid = false;
    }
  
    if (emailValid && phoneValid) {
      props.slideChange(e);
    }
  };

  return (
    <div className={`slide_4Dec ${props.className}`}>
      <h2 className="animate__animated animate__fadeInUp animate__delay-.5s animated-element pt-5 pt-md-3">
        Please provide your email and phone number below so that we can keep you updated on your potential refund.
      </h2>
      <div className="px-3 py-3 widget_mobi borderx">
        <div className="form_drop col-lg-12 col-12" id="ENt_DTLS">
          <div className="check_email_add pt-0 animate__animated animate__fadeInUp animate__delay-.5s animated-element">
            <label>Email Address: </label>
            <div className="input-group flex-nowrap secure">
              <Email
                type="email"
                className="form-control"
                placeholder="Email Address"
                aria-label="Username"
                aria-describedby="addon-wrapping"
                id="emailInput"
                name="txtEmail"
                onBlur={emailValidCheck}
                onChange={handleFieldChange}
                value={props.formData.txtEmail}
                validation={props.validation({
                  required: "Please Enter Valid Email Address",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid Email Address",
                  },
                })}
                autoComplete="off"
              />
            </div>
            {props.validationMsg.txtEmail && <span className='error_msg'>{props.validationMsg.txtEmail.message}</span>}
            <p className="tiny_note mb-2">
              <img src={info} className="pe-1" alt="" />
              We need this so we can keep you updated on your claim by email.
            </p>
          </div>
          <div className="row phone_deatail animate__animated animate__fadeInUp animate__delay-.5s animated-element">
            <div className="col-12 user_no">
              <label>Mobile Number:</label>
              <div className="input-group flex-nowrap secure">
                <Telephone
                  type="tel"
                  className="form-control"
                  placeholder="Mobile Phone Number"
                  aria-label="Phone Number"
                  aria-describedby="Phone-Number"
                  name="txtPhone"
                  maxlength={11}
                  onChange={handleFieldChange}
                  onBlur={phoneValidCheck}
                  onKeyPress={props.handleKeyPress}
                  value={props.formData.txtPhone}
                  autoComplete="off"
                  validation={props.validation({
                    pattern: {
                      value: /^[0-9]*$/i,
                      message: "Please Enter Valid Phone Number",
                    },
                    required: "Please Enter Valid Phone Number",
                  })}
                  validationMsg={
                    props.validationMsg.txtPhone && props.validationMsg.txtPhone.message
                  }
                />
              </div>
              {props.validationMsg.txtPhone && <span className='error_msg'>{props.validationMsg.txtPhone.message}</span>}
              <p className="tiny_note">
                <img src={info} className="pe-1" alt="" />
                We need this so we can keep you updated on your claim with text messages.
              </p>
            </div>
          </div>
          <div className="sms-consent-div d-none" style={{ marginBottom: "20px" }}>
            <div style={{ textAlign: "left", marginBottom: "10px" }}>
              <input
                checked={props.formData.unsubscribeStatus === 0}
                type="checkbox"
                name=""
                id="smsConsent1"
                onChange={(e) =>
                  props.setFormData({
                    ...props.formData,
                    unsubscribeStatus: e.target.checked ? 0 : null,
                  })
                }
              />
              <label className="sms-consent-label" htmlFor="smsConsent1">
                Yes please keep me informed about my refund via SMS + Email
              </label>
            </div>
            <div style={{ textAlign: "left" }}>
              <input
                checked={props.formData.unsubscribeStatus === 1}
                type="checkbox"
                name=""
                id="smsConsent0"
                onChange={(e) =>
                  props.setFormData({
                    ...props.formData,
                    unsubscribeStatus: e.target.checked ? 1 : null,
                  })
                }
              />
              <label className="sms-consent-label" htmlFor="smsConsent0">
                No thanks, I don't want to stay informed about my refund via SMS + Email
              </label>
            </div>
            {props.consentError && (
              <div className="mt-4">
                <p className="ps-1 tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate1">
                  {props.consentError}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s animated-element" id="subRegNo4">
          <div className="row align-items-center">
            <div className="col-4 text-center ">
              <Button
                type="button"
                data-type="back"
                name="back07"
                id="backStep-3"
                className="msf-nav-button backbutton"
                onClick={() =>props.previousSlideChange('back07')}
                buttonText={
                  <span className="btntext"><img src={nextgreen} alt="" />
                    Back <i className="arrowicon"></i>
                  </span>
                }
              />
            </div>
            <div className="col-8">
              <div className="bg-1 hme_btn w-100">
                <Button
                  type="button"
                  id="nextslideCl_004"
                  value="Next"
                  aria-label="submitid"
                  name="nextslideCl_004"
                  className="button w-100 next-btn"
                  buttonText={
                    <span className="fw-bold" style={{ pointerEvents: 'none' }}>
                      Next{" "}
                    </span>
                  }
                  onClick={submitContact}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailPhoneSection;
