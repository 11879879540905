import React, { useState } from 'react';
import { DobYear, DobMonth, DobDay } from '../../../../Constants/Constants';
import SelectBox from '../../../UI/SelectBox';
import FluidLoader from '../PCP_HDSC_V3/FluidLoader';
import info from "../../../../assets/PCP_HDSC_V5/img/info.png"
import Button from '../../../UI/Button';
import GTMDataLayer from "../../../../Utility/GTMDataLayer";

const DOB = (props) => {
    const [dobDayy, setDay] = useState('');
    const [dobMonthh, setMonth] = useState('');
    const [dobYearr, setYear] = useState('');
    const continueClick = async () => {
        const isValid = await props.trigger(['dobDay', 'dobMonth', 'dobYear']);

        const isLeapYear = (year) => {
            return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        };
        if (dobDayy && dobMonthh && dobYearr) {
            const daysInMonth = {
                January: 31,
                February: isLeapYear(dobYearr) ? 29 : 28,
                March: 31,
                April: 30,
                May: 31,
                June: 30,
                July: 31,
                August: 31,
                September: 30,
                October: 31,
                November: 30,
                December: 31,
            };
            if (dobMonthh === 'February' && (dobDayy === '30' || dobDayy === '31')) {
                props.setError("dobDay", {
                    type: "manual",
                    message: "Please enter valid day."
                });
                return false;
            }
            if (dobMonthh === 'February' && dobDayy === '29' && !isLeapYear(dobYearr)) {
                props.setError("dobYear", {
                    type: "manual",
                    message: "This is not a valid leap year."
                });
                return false;
            }
            if (parseInt(dobDayy) > daysInMonth[dobMonthh]) {
                props.setError("dobDay", {
                    type: "manual",
                    message: "Please enter valid day."
                });
                return false;
            }
        }

        if (isValid) {
            props.setPlateFour(true);
            GTMDataLayer({
                question: 'dob',
                answer: null,
              });
            props.setDobModal(false);
        }
    }
    const dobdaychange = (e) => {
        setDay(e.target.value);
        props.clearErrors("dobDay")
    }
    const dobmonthchange = (e) => {
        setMonth(e.target.value)
        props.clearErrors("dobMonth")
    }
    const dobyearchange = (e) => {
        setYear(e.target.value)
        props.clearErrors("dobYear")
    }
    return (
        <div>
            <div
                className={`modal ${props.dobModal ? 'show' : 'hide'}`}
                id="add_db"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="add_dbLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content animate__animated animate__zoomIn animate__delay-.5s ">
                        <div className="modal-body">
                            <div className="jst_adDf_lx">
                                <div className="slide4 col-12">
                                    <div className="cicle_loader">
                                        <div className="text12">
                                            <h5 className="txt">
                                                Verifying your information with each lender.
                                            </h5>
                                        </div>
                                        <div className="loader_gif_">
                                            <FluidLoader />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form_drop col-lg-12 col-12">
                                <p className="sty_pop">
                                    Please confirm your Date of Birth to look up your details with lender(s).
                                </p>
                                <div className="row Birth_detail animate__animated animate__fadeInUp animate__delay-.5s">
                                    <label htmlFor="">Date of Birth</label>
                                    <div className="col-lg-4 col-md-4 col-12 date_here">
                                        <label htmlFor="dobDay">Day</label>
                                        <SelectBox
                                            className="form-select"
                                            OptionValue={DobDay}
                                            name="dobDay"
                                            id="dobDay"
                                            myRef={props.validation({
                                                required: "Please Select Date"
                                            })}
                                            validationMsg={props.validationMsg.dobDay && props.validationMsg.dobDay.message}
                                            onChange={(e) =>
                                                dobdaychange(e)
                                            }
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12 month_here">
                                        <label htmlFor="dobMonth">Month</label>
                                        <SelectBox
                                            className="form-select"
                                            OptionValue={DobMonth}
                                            name="dobMonth"
                                            id="dobMonth"
                                            myRef={props.validation({
                                                required: "Please Select Month"
                                            })}
                                            validationMsg={props.validationMsg.dobMonth && props.validationMsg.dobMonth.message}
                                            onChange={(e) =>
                                                dobmonthchange(e)
                                            }
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12 year_here">
                                        <label htmlFor="dobYear">Year</label>
                                        <SelectBox
                                            className="form-select"
                                            OptionValue={DobYear}
                                            name="dobYear"
                                            id="dobYear"
                                            myRef={props.validation({
                                                required: "Please Select Year"
                                            })}
                                            validationMsg={props.validationMsg.dobYear && props.validationMsg.dobYear.message}
                                            onChange={(e) =>
                                                dobyearchange(e)
                                            }
                                        />
                                    </div>
                                    <p className="tiny_note mb-0">
                                        <img
                                            src={info}
                                            className="pe-1"
                                            alt=""
                                        />
                                        To be eligible to claim you need to over 25 years old.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s">
                            <div className="bg-1">

                                <Button
                                    type="button"
                                    className="button w-100 button--moema button--text-thick button--text-upper button--size-s h60_click blick_notice"
                                    buttonText={
                                        <span>Submit To Claim</span>
                                    }
                                    id="nextPlate_007"

                                    onClick={continueClick}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DOB