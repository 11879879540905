
export const questionnaire = {
    question_1: {
        questionId: 1,
        question: " Did the dealer disclose their commission and how it may affect the interest rate on your loan?",
        gtmQuestion: "gtm question_1",
        gtm_trigger:true,
        clickevent:'clickslide',
        answers: [
           
            {
                label: "No",
                value: "1",
                gtmAnswer: 'no'
            },
            {
                label: "Yes",
                value: "2",
                gtmAnswer: 'yes',
            }
        ],

    },
    question_2: {
        questionId: "2",
        question: " Are you currently in an IVA, bankruptcy, or other debtmanagement arrangement?",
        gtmQuestion: "",
        gtm_trigger:true,
        clickevent:'clickslide',
        answers: [
            {
                label: "No",
                value: "3",
                gtmAnswer: 'no'
            },
            {
                label: "Yes",
                value: "4",
                gtmAnswer: 'yes',
            }
        ],
    },
    question_3: {
        questionId: '3',
        gtmQuestion: "gtm question_3",
        gtm_trigger:true,
        clickevent:'clickslide',
        question: " Have you already claimed?",
        answers: [
            {
                label: "No",
                value: "5",
                gtmAnswer: 'no',
            },
            {
                label: "Yes",
                value: "6",
                gtmAnswer: 'yes',
            },
        ],
    },
    question_4: {
        questionId: '4',
        gtmQuestion: "gtm question_4",
        question: "What reason best describes why you wish to seek professional assistance from a CMC to make this claim?",
        gtm_trigger:true,
        clickevent:'clickslide',
        answers: [
            {
                label: "I do not have the time",
                value: "7",
                gtmAnswer: 'Option-1',
            },
            {
                label: "I do not know how to make a claim",
                value: "8",
                gtmAnswer: 'Option-2',
            },
            {
                label: "I would prefer professional help",
                value: "9",
                gtmAnswer: 'Option-3',
            },
            {
                label: "Other",
                value: "10",
                gtmAnswer: 'Option-4',
            }
        ],
    },
}

