import React from "react";

const BasicOne = (props) => {
  return (
    <div>
      <div className="plateOne_anim" id="plate_1">
        {props.step === 1 && (
          <>
            <h1 className="cs_hero_title animate__animated animate__fadeInLeft animate__delay-1s cs_fs_94 ">
              Have You Bought A Car On Finance?
            </h1>
            <p className="cs_hero_subtitle highlight cs_fs_20 animate__animated animate__fadeInLeft animate__delay-1s cs_heading_color ">
              Find out if your lender owes you <strong>£1,000's</strong> in
              compensation.
            </p>
          </>
        )}

        {props.step === 1 && (
          <>
            <div
              className="box_button animate__animated animate__fadeInLeft animate__delay-1s animated-element wht_iF no_border"
              id="subRegNo1"
            >
              <div className="bg-1 hme_btn">
                <input
                  type="button"
                  className="button button--moma button--text-thick button--text-upper button--size-s blick_notice"
                  id="nextPlate_001"
                  defaultValue="EXPRESS REGISTRATION"
                  aria-label="submitid"
                  name="subRegNo1"
                  onClick={props.nextStep}
                />
              </div>
            </div>

            <p className="fw-semibold text-white pt-3 ps-5 nimate__animated animate__fadeInLeft animate__delay-1s animated-element d-none d-md-block">
              Quick, easy, fully online registration.
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default BasicOne;
