import React, { useRef, useState, useReducer, useContext, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import { useHistory } from "react-router-dom";
import Anchor from "../UI/Anchor";
import Button from "../UI/Button";
import CheckBox from "../UI/CheckBox";
import InputButton from "../UI/InputButton";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { CheckUUID } from "../../Utility/CheckUUID";
import Hendersonslogo from "../../assets/PCP_HDSC_V1/img/hendersons_logo.png";
import stars from "../../assets/PCP_HDSC_V1/img/stars-5.png";
import Sign from "../../assets/PCP_HDSC_V1/img/sign.png";
import Verify from "../../assets/PCP_HDSC_V1/img/verify.png";
import Footer from "../Includes/Layouts/PCP_HDSC_V1/Footer";
import FeaturedinAndAccordion from "../Includes/Layouts/PCP_HDSC_V1/FeaturedinAndAccordion";
import "../../assets/PCP_HDSC_V1/css/signature.scss";
import { queryString } from "../../Utility/QueryString";
import { useSignature } from "../../Hooks/useSignature";
import SignatureModal from "../Includes/Layouts/PCP_HDSC_V1/SignatureModal";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import Header_PCP_HDSC_V1 from "../Forms/PCP_HDSC_V1/Header_PCP_HDSC_V1";
import GTMDataLayer from "../../Utility/GTMDataLayer";
import { Api } from "../../api/Api";
import pre_contract from "../../docs/Hendersons - Pre-Contract Info.80eecbd0.pdf"
import Terms_and_Conditions from "../../docs/Hendersons - Terms & Conditions.4dcc0780.pdf"
import Letter_of_Authority from "../../docs/Letter of Authority - Hendersons.a47d22c0.pdf"
import { BrowserView, MobileView, deviceType } from "react-device-detect";
import { Finance } from "../../Constants/ConstantsPCP";
import { useCarRegistrationSkipAll } from "../../Hooks/useCarRegistrationSkipAll";

const initialState = {
  isChecked: false,
  isAgreed: false,
  isCanvasEmpty: false,
  isValid: false,
};

const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    case "privacyAgreed": {
      return {
        ...state,
        isAgreed: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};

const Signature = ({ title, firstName, middleName, lastName }) => {
  const { isCheck } = CheckUUID();
  isCheck();

  const focusRef = useRef(null);
  const { storeSkipallValues } = useCarRegistrationSkipAll();
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const signPadRef = useRef();
  const storedFormData = localStorage.getItem('formData');
  const userData = JSON.parse(storedFormData);
  const { saveSignature, isLoading } = useSignature();
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const message_type = "signature_store";
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
  });
  const [showLoader, setShowLoader] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const query = new URLSearchParams(window.location.search);
  const query_uuid = query.get("uuid");
  const split_name = query.get("split_name");
  const [displayData, setDisplayData] = useState({
    title: '',
    first_name: '',
    last_name: ''
  });

  useEffect(() => {
    const formatUserData = (data) => {
      if (data.title && data.first_name && data.last_name) {
        return {
          title: data.title,
          first_name: data.first_name.charAt(0).toUpperCase() + data.first_name.slice(1),
          last_name: data.last_name.charAt(0).toUpperCase() + data.last_name.slice(1)
        };
      } else if (data.lstSalutation && data.txtFName && data.txtLName) {
        return {
          title: data.lstSalutation,
          first_name: data.txtFName.charAt(0).toUpperCase() + data.txtFName.slice(1),
          last_name: data.txtLName.charAt(0).toUpperCase() + data.txtLName.slice(1)
        };
      }
      return {
        title: '',
        first_name: '',
        last_name: ''
      };
    };

    const makeApiCall = async () => {
      try {
        const response = await Api.post('v1/get_user_data', {
          uuid: query_uuid
        });
        return response.data;
      } catch (error) {
        console.error("Error fetching user data:", error);
        return null;
      }
    };

    const fetchData = async () => {
      try {
        const storedFormData = localStorage.getItem('formData');
        if (storedFormData) {
          const userData = JSON.parse(storedFormData);
          const formattedData = formatUserData(userData);
          setDisplayData(formattedData);
        } else {
          const apiData = await makeApiCall();
          if (apiData) {
            const formattedData = formatUserData(apiData);
            setDisplayData(formattedData);
            // Store data in local storage for future use
            localStorage.setItem('formData', JSON.stringify(apiData));
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array, fetch data only once

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const timeout = setTimeout(() => {
      setShowLoader(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  const handleSubmit = async () => {
    try {
      const signPad = signPadRef.current;
      if (signPad.isEmpty()) {
        focusRef.current.focus();
        return dispatch({ type: "signPadChange", payload: { value: true } });
      }
      if (!isChecked) {
        focusRef.current.focus();
        return dispatch({ type: "privacyAgreed", payload: { value: true } });
      }

      const rpdata = signPad.toData();
      let signLength = 0;
      for (let i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }

      if (signLength > 13) {
        setBtnLoader(true)
        const response = await saveSignature(
          signPad.getCanvas().toDataURL("image/png"),
          visitorParameters.visitor_parameters,
          storedFormData,
          visitorParameters.data,
          queryString,
          message_type
        );
        if (response.data.status === "Success") {
          setBtnLoader(false)

          const formdata = JSON.parse(localStorage.getItem("formData"));
          if (formdata === undefined || formdata === null || formdata == '') {
            localStorage.setItem("formData", JSON.stringify(formdata));
          } else {
            formdata.user_sign = signPad.getCanvas().toDataURL("image/png");
            localStorage.setItem("formData", JSON.stringify(formdata));
          }

          let redirectToPage = "thankyou"
          const storedData = localStorage.getItem("slugArray");
          if (storedData) {
            const slugs = JSON.parse(storedData);

            const matchedSlug = slugs.find((slug) =>
              Finance.some(
                (financeItem) =>
                  financeItem.slug === slug && financeItem.id_required === true
              )
            );
            const carRegRequired = slugs.find((slug) =>
              Finance.some(
                (financeItem) =>
                  financeItem.slug === slug && financeItem.car_reg === true
              )
            );
            const carRegNotRequiredValues = slugs.flatMap((slug) =>
              Finance
                .filter(
                  (financeItem) =>
                    financeItem.slug === slug && (financeItem.car_reg === false || !('car_reg' in financeItem))
                )
                .map((financeItem) => financeItem.value)
            );

            GTMDataLayer({
              question: 'signature',
              answer: null,
            });
            if (matchedSlug) {
              if (split_name === "PCP_HDSC_V1") {
                redirectToPage = "pcp-id-upload"
              } else if (split_name === "PCP_HDSC_V2"){
                redirectToPage = "pcp-id-uploadv2"
              }
              else if (split_name === "PCP_HDSC_V1_5T"){
                redirectToPage = "pcp-id-upload"
              }
            } else {
              if (carRegRequired) {
                if (split_name === "PCP_HDSC_V1") {
                  redirectToPage = "CarRegistration"
                } else if (split_name === "PCP_HDSC_V2"){
                  redirectToPage = "CarRegistrationv2"
                }
                else if (split_name === "PCP_HDSC_V1_5T"){
                  redirectToPage = "CarRegistration"
                }
              } else {
                if (carRegNotRequiredValues.length !== 0) {
                  storeSkipallValues(
                    visitorParameters.visitor_parameters,
                    "car_registration",
                    "store-skip-all-vehicle-details",
                    carRegNotRequiredValues
                  );
                }
              }
            }
          }

          if (split_name === "PCP_HDSC_V1") {
            const nextPageUrl = `/${redirectToPage}?${query.toString()}`;
            history.push(nextPageUrl);
          }
          else if (split_name === "PCP_HDSC_V2") {
            let nextPageUrl = ""

            if (!storedData || storedData.length === 0) {
              nextPageUrl = `/thankyou-v2?${query.toString()}`;
            } else {
              nextPageUrl = `/${redirectToPage}?${query.toString()}`;
            }
            history.push(nextPageUrl);
          }
          else if (split_name === "PCP_HDSC_V1_5T") {
            let nextPageUrl = ""

            if (!storedData || storedData.length === 0) {
              nextPageUrl = `/thankyou-v2?${query.toString()}`;
            } else {
              nextPageUrl = `/${redirectToPage}?${query.toString()}`;
            }
            history.push(nextPageUrl);
          }
        }

      } else {
        focusRef.current.focus();
        dispatch({ type: "validSignature", payload: { value: true } });
      }
    } catch (error) {
      setBtnLoader(false)
      console.error("Error submitting signature:", error);
    }
  };

  const handleClear = () => {
    const signPad = signPadRef.current;
    signPad.clear();
  };

  const openModal = () => {
    setIsModalOpen(true);
    setShowModal(true);
    setFormData({
      title: title,
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
    });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (isChecked) {
      // focusRef.current.focus();
      dispatch({ type: "privacyAgreed", payload: { value: false } });
    }
  }, [isChecked]);

  useEffect(() => {
    const fetchData = async () => {
      let localStorageValue = JSON.parse(localStorage.getItem("slugArray"));
      if (!localStorageValue) {
        const responseslug = await Api.get(
          "v1/get_finanace_slug_details?uuid=" + query_uuid,
          {}
        );
        localStorageValue = responseslug.data.map(item => item.slug);
        localStorage.setItem("slugArray", JSON.stringify(localStorageValue));
      }
    };

    fetchData();
  }, [query_uuid]);

  useEffect(() => {
    if (!showLoader) {
      window.scrollTo(0, 30);
    }
  }, [showLoader])

  return (
    <>
      <GetVisitorsParams />
      <div style={{ overflow: "hidden" }} className={`pcp_siteHome ${split_name == "PCP_HDSC_V1" && "pcp_siteHome_v1"}`}>
        {showLoader && (
          <div id="preloader">
            <div id="loader" />
          </div>
        )}
        <Header_PCP_HDSC_V1 />
        <section className="main_content sign_content">

          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <form className="form_action">
                  <div className="widget_mobi">
                    <div className="sign_dis_paGe">
                      <div className="slide_7Dec">
                        <div className="row justify-content-center">
                          <div className="cs_hero_text col-lg-8 col-12">
                            <div className="plateThree_anim" id="plate_3">
                              <h1 className="lts_add_sm">
                                Great news! You qualify to initiate a{" "}
                                <span className="clr-dif">claim online.</span>{" "}
                                Your signature is a crucial step in submitting
                                your claim.
                              </h1>

                              <div className="sign-here">
                                <div className="sign-note pb-0">
                                  <div className="clear-btn p-0">
                                    <p className="non_check m-0 mt-3">
                                      In order to be able to submit a claim we need just need your signature. By signing below, you are giving Henderson Claims Limited T/A Hendersons authority to act on your behalf and to attach your signature to these <a href={`/preview?uuid=${query_uuid}`} target="_blank">documents</a> and agree to their <a href={Terms_and_Conditions} target="_blank">terms and conditions</a> and <a href={pre_contract} target="_blank">pre-contract summary document</a>.
                                    </p>
                                    <div className="sign-below">Sign below</div>
                                  </div>

                                </div>


                                <div className="sign-pad">
                                  {(deviceType === "mobile" || deviceType === "tablet") && (
                                    <MobileView>
                                      <SignatureCanvas
                                        canvasProps={{
                                          className: "writing-line",
                                          id: "canvas_signature",
                                          // width: 720,
                                          // height: 420,
                                        }}
                                        clearOnResize={false}
                                        ref={signPadRef}
                                        onEnd={() => {
                                          if (!signPadRef.current.isEmpty()) {
                                            dispatch({
                                              type: "signPadChange",
                                              payload: { value: false },
                                            });
                                            dispatch({
                                              type: "validSignature",
                                              payload: { value: false },
                                            });

                                          }
                                        }}
                                      />
                                    </MobileView>
                                  )}

                                  {deviceType === "browser" && (
                                    <BrowserView>
                                      <SignatureCanvas
                                        canvasProps={{
                                          className: "writing-line",
                                          id: "canvas_signature",
                                          // width: 720,
                                          // height: 420,
                                        }}
                                        clearOnResize={false}
                                        ref={signPadRef}
                                        onEnd={() => {
                                          if (!signPadRef.current.isEmpty()) {
                                            dispatch({
                                              type: "signPadChange",
                                              payload: { value: false },
                                            });
                                            dispatch({
                                              type: "validSignature",
                                              payload: { value: false },
                                            });

                                          }
                                        }}
                                      />

                                    </BrowserView>
                                  )}
                                </div>

                                <div className="clear-btn">
                                  <p className="non_check m-0 mt-3">
                                    By clicking 'Submit To Claim' I agree to a soft credit check being carried out to verify information relating to my claim.I understand this will NOT affect my credit score.
                                  </p>
                                </div>
                                <span ref={focusRef} tabIndex={0}></span>
                                {state.isAgreed && (
                                  <div className="sign_msg col-12 text-center signature-validation" style={{ backgroundColor: 'red', borderRadius: "5px" }}>
                                    Please tick to provide your authorisation for us to proceed.
                                  </div>
                                )}
                                {state.isCanvasEmpty && (
                                  <div className="sign_msg col-12 text-center sign_msg signature-validation" style={{ backgroundColor: 'red', borderRadius: "5px" }} id="signatures_required">
                                    Signature is required!
                                  </div>
                                )}
                                {state.isValid && (
                                  <div className="sign_msg col-12 text-center sign_msg signature-validation" style={{ backgroundColor: 'red' }} id="signatures_valid">
                                    Draw valid signature!
                                  </div>
                                )}

                                <div className="box_button sign_Btn_paGe pt-4">
                                  <div className="bg-1 hme_btn">
                                    <InputButton
                                      name="clear"
                                      className="msf-nav-button clear button"
                                      id="clear"
                                      value="Clear"
                                      btnType="button"
                                      onClick={handleClear}
                                    />

                                    <span
                                      name="save"
                                      className={`button ${!btnLoader && "pulse"} text-center`}
                                      id="save"
                                      onClick={!btnLoader ? handleSubmit : null}
                                    >
                                      Submit To Claim
                                      {btnLoader &&
                                        <svg className="ms-2" width={30} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><linearGradient id="a10"><stop offset="0" stop-color="#FFFFFF" stop-opacity="0"></stop><stop offset="1" stop-color="#FFFFFF"></stop></linearGradient><circle fill="none" stroke="url(#a10)" stroke-width="25" stroke-linecap="round" stroke-dasharray="0 44 0 44 0 44 0 44 0 360" cx="100" cy="100" r="70" transform-origin="center"><animateTransform type="rotate" attributeName="transform" calcMode="discrete" dur="2" values="360;324;288;252;216;180;144;108;72;36" repeatCount="indefinite"></animateTransform></circle></svg>
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <SignatureModal
                        showPrivacy={showModal}
                        closeModal={closeModal}
                        displayData={displayData}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

        </section>

        {/* Featured and Accordion */}
        <FeaturedinAndAccordion />

        {/* Footer */}
        <Footer />
      </div>
    </>
  );
};

export default Signature;
