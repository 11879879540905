import { useCallback, useState } from "react";
import { Api } from "../api/Api";

export const useVehicleValidation = () => {
  const [isLoading, setIsLoading] = useState(false);

  const validateRegNum = useCallback(async (regNum, uuid, pageName) => {
    setIsLoading(true);
    const getValidation = await Api.post("/v1/ukvd-validation", {
      carRegNo: regNum,
      uuid: uuid,
      page_name: pageName,
    }).catch(function (error) {
      setIsLoading(false);
    });
    setIsLoading(false);
    return getValidation;
  }, []);

  return { isLoading, validateRegNum };
};
