import React from "react";
import { Link } from "react-router-dom";

const Anchor = ({
  className,
  children,
  href = "#",
  anchorStyle,
  onClick,
  idName,
  name,
  imageTag
}) => {
  return (
    <>
      <Link
        to={href}
        style={anchorStyle}
        className={className}
        id={idName}
        name={name}
        onClick={onClick}
      > {imageTag != 'no' && (<img src="/assets/QCL_V1/img/arrow.png" alt="" />)}{children}
      </Link>
    </>
  );
};
export default Anchor;
