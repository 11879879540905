import React from "react";
import GTMDataLayer from "../../Utility/GTMDataLayer";

const Button = (props) => {
  const handleButtonClick = (e) => {
    if (e.target.dataset.gtm_trigger) {
      GTMDataLayer({
        clickEvent: e.target.dataset.clickevent,
        question: e.target.dataset.gtm_question,
        answer: e.target.dataset.gtm_answer,
      });
    }
    props.onClick(e);
  };
  return (
    <button
      type={props.type}
      className={props.className}
      id={props.id}
      onClick={handleButtonClick}
      disabled={props.disabled}
      value={props.value}
      name={props.name}
      data-gtm_question={props.gtm_question}
      data-gtm_trigger={props.gtm_trigger}
      data-gtm_answer={props.gtm_answer}
      data-clickevent={props.clickevent}
      data-question_id = {props.question_id}
    >
      {props.buttonText}
    </button>
  );
};
export default Button;

