import React, { useContext, useEffect, useState } from "react";
import "../../../assets/PCP_HDSC_V1/css/custom.scss";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import FbViewPixel from "../../../Utility/FbViewPixel";
import PCP_HDSC_V1_5T_Form from "../../Forms/PCP_HDSC_V1_5T/PCP_HDSC_V1_5T_Form";
import Footer from "../../Includes/Layouts/PCP_HDSC_V1/Footer";
import FeaturedinAndAccordion from "../../Includes/Layouts/PCP_HDSC_V1/FeaturedinAndAccordion";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Header_PCP_HDSC_V1 from "../../Forms/PCP_HDSC_V1_5T/Header_PCP_HDSC_V1";
import { useEmailValidation } from "../../../Hooks/useEmailValidation";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import GTMDataLayer from "../../../Utility/GTMDataLayer";
import hendersons_logo_gold from "../../../assets/PCP_HDSC_V1/img/hendersons_logo.png";
import Spinner from "../../../assets/PCP_HDSC_V1/img/Spinner.gif";
import PrivacyModal from '../../Includes/Layouts/PCP_HDSC_V1/PrivacyModal';
import TermsModal from '../../Includes/Layouts/PCP_HDSC_V1/TermsModal';
import Cookiespolicy from '../../Includes/Layouts/PCP_HDSC_V1/Cookiespolicy';
import ContractModal from '../../Includes/Layouts/PCP_HDSC_V1/ContractModal';
import ComplaintsProcedure from "../../../docs/Complaints Procedure - Hendersons - June 2024.pdf";
import PrivacyPolicy from "../../../docs/Henderson Claims - Privacy Policy - June 2024.6bf93601.pdf";

const PCP_HDSC_V1_5T = () => {
  const history = useHistory();
  const [showLoader, setShowLoader] = useState(true);
  const [step, setStep] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showSecondSection, setShowSecondSection] = useState(false);
  const [finerror, setError_alt] = useState("");
  const [titleError, setTitleError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [bankError, setBankError] = useState("");
  const [commissionError, setcommissionError] = useState("");
  const [claimError, setClaimError] = useState("");
  const [whyNotClaimError, setWhyNotClaimError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [dobError, setDobDayError] = useState("");
  const [monthError, setDobMonthError] = useState("");
  const [postCodeError, setPostCodeError] = useState("");
  const [yearError, setDobYearError] = useState("");
  const { emailValidation } = useEmailValidation();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [formModal, setFormModal] = useState(false);
  const [currentModalCount, setCurrentModalCount] = useState(0);
  const [showPreviousNameModal, setShowPreviousNameModal] = useState(false);
  const [consentError, setConsentError] = useState("");

  const {
    register,
    errors,
    trigger,
    clearErrors,
    getValues,
    setError,
    setValue,
    control,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const question = '';
  const answer = '';
  const [formData, setFormData] = useState({
    lstSalutation: "",
    txtFName: "",
    txtLName: "",
    dobDay: "",
    dobMonth: "",
    txtPhone: "",
    txtEmail: "",
    dobYear: "",
    financeProvider: [],
    postcode: "",
    commission: "",
    bankruptcy: "",
    claimed: "",
    whyNotClaimed: "",
    previousName: "",
    unsubscribeStatus: 0,
    txtAddress2:"",
    txtAddress3:""
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoader(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);
  const emailValidCheck = async (email) => {
    if (email) {
      const getEmailValidation = await emailValidation(
        email,
        visitorParameters.visitor_parameters.uuid
      );
      if (getEmailValidation.data.status_code === 1) {
        setEmailError("");
        return 1;
      }
      if (getEmailValidation.data.status_code === 0) {
        setEmailError("Invalid email.");
        return;
      }
    }
  };
  const nextStep = async() => {
    setFormModal(false);
    const val = getValues();
    if (step === 6 && formData.financeProvider.length === 0) {
      setShowModal(false)
      setError_alt("Please select a finance provider.");
      return; // Prevent further execution if there's an error
    }
    if (step === 2) {
      if (showPreviousNameModal === false) {
        setShowPreviousNameModal(true);
        return;
      }
    }

    if (step === 4) {
      let isValid = true;
      if (!isValid) {
        return; // Exit if there are errors
      }
    }
    if (step < 10) {
      // for pre postcode loader
      // if (step === 9) {
      //   setShowLoader(true);
      //   setTimeout(() => {
      //     setShowLoader(false);
      //   }, 4000);
      // }

      setStep(step + 1);
    } if (step === 5) {

      // console.log(val,"values postcode")
      // if (!val.txtPostCode) {
      //   setPostCodeError("Please Enter postcode.");
      // } else if (!val.address1) {
      //   setPostCodeError("Please Enter Address.");
      // } else if (!val.txtHouseNumber) {
      //   // setPostCodeError("Please Enter house number.");
      // } else if (!val.txtCountry) {
      //   // setPostCodeError("Please Enter country.");
      // } else if (!val.txtTown) {
      //   // setPostCodeError("Please Enter town.");
      // } else {
      //   formData.address1 =val.address1
      //   formData.txtAddress2 =val.txtAddress2
      //   formData.txtAddress3 =val.txtAddress3
      //   formData.txtCountry =val.txtCountry
      //   formData.txtCounty =val.txtCounty
      //   formData.txtHouseNumber =val.txtHouseNumber
      //   formData.txtPostCode =val.txtPostCode
      //   formData.txtTown =val.txtTown
      //   formData.txtCountry1 =val.txtCountry1
      //   formData.txtCounty1 =val.txtCounty1
      //   formData.txtPrevAddress3 =val.txtPrevAddress3
      //   formData.txtPrevHouseNumber_1 =val.txtPrevHouseNumber_1
      //   formData.txtPrevPostCode_1 =val.txtPrevPostCode_1
      //   formData.txtPrevroute_1 =val.txtPrevroute_1
      //   formData.txtPrevaddress1_1 =val.txtPrevaddress1_1
      //   formData.txtTown1 =val.txtTown1
      // console.log(val,"values postcode")
      // if (!val.txtPostCode) {
      //   setPostCodeError("Please Enter postcode.");
      // } else if (!val.address1) {
      //   setPostCodeError("Please Enter Address.");
      // } else if (!val.txtHouseNumber) {
      //   // setPostCodeError("Please Enter house number.");
      // } else if (!val.txtCountry) {
      //   // setPostCodeError("Please Enter country.");
      // } else if (!val.txtTown) {
      //   // setPostCodeError("Please Enter town.");
      // } else {

      //   setPostCodeError("");
      // }
    }

    if (step == 2 || step == 3 || step == 4 || step == 6) {
      setStep(step + 1);
      setShowModal(false)
    }
    if (step === 1) {
      GTMDataLayer({
        question: 'express registration',
        answer: null,
      });
    }
    if (step === 2 && firstNameError === '' && lastNameError === '' && titleError === '') {
      GTMDataLayer({
        question: 'name',
        answer: null,
      });
    }
    if (step === 3 && emailError === '' && phoneError === '') {
      GTMDataLayer({
        question: 'email,phone',
        answer: null,
      });
    }
    if (step === 4 && dobError === '' && monthError === '' && yearError === '') {
      GTMDataLayer({
        question: 'dob ',
        answer: null,
      });
    }
    if (step === 5 &&  val.txtPostCode && val.address1) {
      GTMDataLayer({
        question: 'address',
        answer: null,
      });
    }
    if (step === 6) {
      GTMDataLayer({
        question: 'lender',
        answer: null,
      });
    }
    if (step === 7) {
      GTMDataLayer({
        question: 'commission',
        answer: null,
      });
    }
    if (step === 8) {
      GTMDataLayer({
        question: 'bankruptcy',
        answer: null,
      });
    }
    if (step === 9) {
      GTMDataLayer({
        question: 'already claimed',
        answer: null,
      });
    }
    if (step === 10) {
      GTMDataLayer({
        question: 'reason for claim',
        answer: null,
      });
    }
  };


  const handleYesClick = () => {
    setShowSecondSection(true);
  };


  const handleChangeButton = (e) => {

    const { name, value: targetValue } = e.target;
    let value;

    if (name === "whyNotClaimed") {
      value = targetValue;
      GTMDataLayer({
        question: 'reason for claim',
        answer: null,
      });
    } else {
      value = e.target.getAttribute("id");
    }


    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    switch (name) {
      case "commission":
        setcommissionError("");
        break;
      case "bankruptcy":
        setBankError("");
        break;
      case "claimed":
        setClaimError("");
        break;
      case "whyNotClaimed":
        setWhyNotClaimError("");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    localStorage.removeItem("dobYear");
    localStorage.removeItem("dobMonth");
    localStorage.removeItem("dobDay");
  }, [])

  // const handleChange = (e) => {
  //   let a= getValues();
  //   console.log("nameee",a)
  //   const { name, value } = e.target;
  //   if (name === "txtFName" || name === "txtLName") {
  //     if (/[^a-zA-Z\s]|[\s]{2,}/.test(value)) {
  //       return;
  //     }
  //   }

  //   if (name === "txtPostCode" && value) {
  //     setPostCodeError("");
  //   }
  //   const setFormDataAndLocalStorage = (name, value) => {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData, [name]: name === "txtFName" ?
  //         value.trim()
  //         : value.trimStart()
  //     }));
  //     localStorage.setItem(name, value.trim());
  //   };

  //   const clearFormDataAndLocalStorage = (fields) => {
  //     // setFormData((prevFormData) => {
  //     //     const newFormData = { ...prevFormData };
  //     //     fields.forEach(field => {
  //     //         newFormData[field] = "";
  //     //         localStorage.removeItem(field);
  //     //     });
  //     //     return newFormData;
  //     // });
  //   };

  //   if (name === "dobDay" || name === "dobMonth" || name === "dobYear") {
  //     setFormDataAndLocalStorage(name, value);

  //     const dobDay = localStorage.getItem('dobDay');
  //     const dobMonth = localStorage.getItem('dobMonth');
  //     const dobYear = localStorage.getItem('dobYear');

  //     const isLeapYear = (year) => {
  //       return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  //     };

  //     if (dobDay && dobMonth && dobYear) {
  //       const daysInMonth = {
  //         January: 31,
  //         February: isLeapYear(dobYear) ? 29 : 28,
  //         March: 31,
  //         April: 30,
  //         May: 31,
  //         June: 30,
  //         July: 31,
  //         August: 31,
  //         September: 30,
  //         October: 31,
  //         November: 30,
  //         December: 31,
  //       };

  //       if (dobMonth === 'February' && (dobDay === '30' || dobDay === '31')) {
  //         setDobDayError('Please enter valid date.');
  //         clearFormDataAndLocalStorage(['dobDay', 'dobMonth']);
  //         return;
  //       }

  //       if (dobMonth === 'February' && dobDay === '29' && !isLeapYear(dobYear)) {
  //         setDobYearError('This is not a valid leap year.');
  //         clearFormDataAndLocalStorage(['dobDay', 'dobMonth']);
  //         return;
  //       }

  //       if (parseInt(dobDay) > daysInMonth[dobMonth]) {
  //         setDobDayError('Please enter valid date.');
  //         clearFormDataAndLocalStorage(['dobDay', 'dobMonth']);
  //         return;
  //       }

  //       clearErrors('DobDay');
  //       setDobDayError("");
  //       setDobMonthError("");
  //       setDobYearError("");
  //     }
  //   } else {
  //     setFormDataAndLocalStorage(name, value);
  //   }

  //   switch (name) {
  //     case "lstSalutation":
  //       setTitleError("");
  //       break;
  //     case "txtFName":
  //       setFirstNameError("");
  //       break;
  //     case "txtLName":
  //       setLastNameError("");
  //       break;
  //     case "txtPhone":
  //       setPhoneError("");
  //       break;
  //     case "txtEmail":
  //       if (!value) {
  //         setEmailError("please enter email.");
  //       } else {
  //         setEmailError("");
  //       }
  //       break;
  //     case "dobDay":
  //       setDobDayError("");
  //       break;
  //     case "dobMonth":
  //       setDobMonthError("");
  //       break;
  //     case "dobYear":
  //       setDobYearError("");
  //       break;
  //     case "financeProvider":
  //       setError_alt("");
  //       break;
  //     case "postcode":
  //       setPostCodeError("");
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const prevStep = () => {
    // let savedCount = localStorage.getItem("currentModalCount");
    let savedCount = parseInt(localStorage.getItem("currentModalCount"));
    setCurrentModalCount(savedCount);
    setStep(step - 1);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [step]);
  const [showCookie, setShowCookie] = useState('show');
  const [termsModal, setTermsModal] = useState(false);
  const [contractModal, setContractModal] = useState(false);
  const [showCookiesPolicy, setShowCookiesPolicy] = useState(false);
  const cookieName = "CookiesPolicyHide";

  const cookieClose = (e) => {
    e.preventDefault();
    setShowCookie('hide');
    if (cookieName != null) {
      const d = new Date();
      const cvalue = "Yes";
      d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
      const expires = "expires=" + d.toUTCString();
      document.cookie = cookieName + "=" + cvalue + "; " + expires;
    }
  };

  const handleCookieShow = () => {
    setShowCookiesPolicy(true);
  };

  const handleCookieHide = () => {
    setShowCookiesPolicy(false);
  };
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="PCP_HDSC_V1_5T" />
      <FbViewPixel />
      <div className="pcp_siteHome pcp_siteHome_v1">
        {/* preloader */}
        {showLoader && (
          <div id="preloader">
            {step === 10 ?
              <div className="loader-wrap succLoader text-center text-light ">
                <nav className="navbar navbar-expand-lg">
                  <a className="navbar-brand text-center col-12 mt-5 pt-5">
                    <img src={hendersons_logo_gold} alt="Logo" height="45px" />
                  </a>
                </nav>
                <div className="mt-5 pt-5">
                  <img height="150" src={Spinner} alt="" />
                  <h2 className="mt-3 succtext px-5 ">
                    Analysing the information you have provided <br />{" "}
                    <br />
                    <small> Please wait...</small>
                  </h2>
                </div>
              </div>
              :
              <div id="loader" />
            }
          </div>
        )}
        <Header_PCP_HDSC_V1 extraClass="PCP_HDSC_V1_header" />

        <PCP_HDSC_V1_5T_Form
          nextStep={nextStep}
          step={step}
          prevStep={prevStep}
          showModal={showModal}
          handleYesClick={handleYesClick}
          showSecondSection={showSecondSection}
          // handleChange={handleChange}
          handleChangeButton={handleChangeButton}
          formData={formData}

          setEmailError={setEmailError}
          phoneError={phoneError}
          setPhoneError={setPhoneError}
          postCodeError={postCodeError}
          setFormData={setFormData}
          setError_alt={setError_alt}
          formModal={formModal}
          setFormModal={setFormModal}
          setCurrentModalCount={setCurrentModalCount}
          currentModalCount={currentModalCount}
          bankError={bankError}
          commissionError={commissionError}
          claimError={claimError}
          whyNotClaimError={whyNotClaimError}
          setPostCodeError={setPostCodeError}
          showPreviousNameModal={showPreviousNameModal}
          setShowPreviousNameModal={setShowPreviousNameModal}
          consentError={consentError}
          setConsentError={setConsentError}
          setShowModal={setShowModal}
          setShowSecondSection={setShowSecondSection}
          finerror={finerror}
        />

        <FeaturedinAndAccordion />
        <div>
      <footer>
        <div className="container text-center">
          <div className="ftr_cntnt">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-12">
                <p>
                  Henderson Claims is a Claims Management Company (CMC). You can claim for free, without using a CMC, first to your lender or to the FOS/FSCS.
                </p>
                <p>
                  Hendersons is a trading style of Henderson Claims Limited.
                  Henderson Claims Limited is authorised and regulated by
                  the Financial Conduct Authority with reference number
                  FRN:836041. Henderson Claims Limited is a Claims
                  Management Company (CMC). Registered with the Information
                  Commissioner's Office: registration number ZA147049.
                  Registered address: Grosvenor House Part Ground Floor (Rear Wing), St Thomas Place, Stockport, United Kingdom, SK1 3TZ. Registered in
                  England and Wales, Company number 07646017. VAT number:
                  240 8769 88.
                </p>
                <p>
                  <a target='_blank' href="https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf" className='link-style'>*fca.org.uk</a>
                </p>
                <p>
                  <b>© 2023</b>
                </p>
              </div>
            </div>
            <div className="text-center">
              <div className="copyright">
                <ul>
                  <li>
                    <a
                      href={PrivacyPolicy} target="_blank"
                    >Privacy Policy</a>
                  </li>
                  <li>
                    <a
                      onClick={() => setTermsModal(true)}
                    >Terms &amp; Conditions</a>
                  </li>
                  <li>
                    <a
                      onClick={() => setContractModal(true)}
                    >Pre-contract Information</a>
                  </li>
                  <li>
                    <a
                      href={ComplaintsProcedure} target="_blank"
                    >Complaints Procedure</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div id="cookieBar" className={`cooki cookie1 text-center ${showCookie}`}>
        <div className="col-lg-12 col-md-12 col-12 col-sm-12">
          <span>We use cookies to analyse traffic to this site and to give you the best experience. By clicking 'Accept' you agree to our use of cookies. To find out more about our use of cookies and how to refuse them, please read our Privacy Policy.
            <a href="#" className="rp_clk text-underline" onClick={handleCookieShow}><u>Learn More.</u></a>
          </span>
          <a className="rp_clk closecook text-danger" href="#" onClick={cookieClose}>Close <strong>X</strong></a>
        </div>
        <div className="clearfix"></div>
      </div>
      <TermsModal
        termsModal={termsModal}
        setTermsModal={setTermsModal}
      />
      <ContractModal
        contractModal={contractModal}
        setContractModal={setContractModal}
      />
      <Cookiespolicy
        showstatus={showCookiesPolicy}
        hidepopup={handleCookieHide}
      />
    </div>
      </div>
    </>
  );
};

export default PCP_HDSC_V1_5T;
