import React, { useRef } from 'react';
import Select from 'react-select';
import { Finance } from '../../../../Constants/ConstantsPCP';
import nextgreen from "../../../../assets/PCP_HDSC_V1/img/next-green.png";
import Button from "../../../UI/Button";

const FinanceProviderForm = (props) => {
  const autofocus = useRef();
  const toggleFocus = () => {
    if (autofocus.current) {
      const refElement = autofocus.current;
      const offset = -135;
      const scrollPosition = refElement.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth"
      });
    } else {
      window.scroll(0, 0);
    }
  };

  const handleNextClick = (e) => {
    if (props.formData.financeProvider.length === 0) {
      props.setError('financeProvider', {
        type: 'manual',
        message: 'Please select a finance provider.',
      });
    } else {
      props.clearErrors('financeProvider');
      props.slideChange(e);
    }
  };

  return (
    <div className={`${props.className} slide_2Dec`}>
      <h2 className="animate__animated animate__fadeInUp animate__delay-.5s animated-element pt-5 pt-md-3">
        We require information regarding the lender you used to buy your car.
      </h2>

      <div className="px-3 py-3 widget_mobi borderx">
        <h3 className="sub_quesHead animate__animated animate__fadeInUp animate__delay-.5s animated-element">
          Please select lenders you've secured car finance between 2007 & 2020 <br className="d-block d-sm-none" />
        </h3>
        <div style={{ color: "white" }}>
          <i>(You can select up to 4 lenders)</i>
        </div>
        <div className="form_drop col-lg-12 col-12">
          <div className="scale_blick_anim ">
            <div className="text-start">
              <div ref={autofocus}></div>
              <Select
                styles={props.customStyles}
                options={Finance.filter(
                  (option) => !props.formData.financeProvider.includes(option.value)
                ).sort((a, b) => a.label.localeCompare(b.label))}
                className="w-100"
                onChange={(e) => {
                  props.handleFinanceProviderChange(e);
                  props.clearErrors('financeProvider');
                }}
                onFocus={toggleFocus}
                name="financeProvider"
                id="format"
                placeholder="Choose your finance providers"
              />

              {props.formData.financeProvider.map((value, index) => {
                const label = Finance.find(finance => finance.value === value)?.label || "Unknown";
                return (
                  <div className="container " key={index}>
                    <div className="selected-options row ">
                      <div className="col-10 col-sm-11">
                        <p>{label}</p>
                      </div>
                      <div onClick={() => props.handleRemoveFinanceProvider(index)} className="col-2 col-sm-1 delete-option text-center d-flex align-items-center">
                        <p>x</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {props.errors.financeProvider && (
              <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                {props.errors.financeProvider.message}
              </p>
            )}
          </div>
        </div>

        <div className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s animated-element" id="subRegNo2">
          <div className="row align-items-center">
            <div className="col-4 text-center">
            <Button
            type="button"
            className="msf-nav-button backbutton"
            id="backStep-1"
            name="back01"
            buttonText={
              <span className="btntext">
                <img src={nextgreen} alt="" />
                Back <i className="arrowicon"></i>
              </span>
            }
            data-type="back"
            onClick={() => props.previousSlideChange('back01')}
            />
            </div>
            <div className="col-8">
              <div className="bg-1 hme_btn">
                <Button
                  type="button"
                  className="button w-100 next-btn"
                  id="nextslideCl_002"
                  value="Next"
                  aria-label="submitid"
                  name="nextslideCl_002"
                  buttonText={
                    <span className="fw-bold" style={{ pointerEvents: 'none' }}>
                    Next{" "}
                  </span>
                  }
                  onClick={handleNextClick}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinanceProviderForm;
