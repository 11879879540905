import React from "react";
import nextgreen from "../../../../assets/PCP_HDSC_V1/img/next-green.png";
import { useForm } from "react-hook-form";
import { DobDay, DobMonth, DobYear } from "../../../../Constants/Constants"; // Replace with actual constants

const BasicFour = ({
  step,
  formData,
  handleChange,
  prevStep,
  nextStep,
  dobError,
  monthError,
  yearError,
  register,
  trigger,
  getValues,
  errors,
  clearErrors,
  setError
}) => {


  const handleInputChange = (e) => {
    clearErrors(["dobDay", "dobMonth", "dobYear"]);
    handleChange(e); // Call parent handleChange function
  };

  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  const handleNextClick = async () => {
    const isdobDay = await trigger("dobDay");
    const isdobMonth = await trigger("dobMonth");
    const isdobYear = await trigger("dobYear");

    if (!isdobDay || !isdobMonth || !isdobYear) {
      return;
    }

    const currentValues = getValues();
    const selectedYear = parseInt(currentValues.dobYear, 10);


    const daysInMonth = {
      January: 31,
      // February: isLeapYear(dobYear) ? 29 : 28,
      March: 31,
      April: 30,
      May: 31,
      June: 30,
      July: 31,
      August: 31,
      September: 30,
      October: 31,
      November: 30,
      December: 31,
    };

    if (currentValues.dobMonth === 'February') {
      // if (!isLeapYear(selectedYear)) {
      //   setError('dobYear', {
      //     type: 'manual',
      //     message: 'Selected year is not a leap year.',
      //   });
      //   return;
      // }
      const selectedDay = parseInt(currentValues.dobDay, 10);

      if (selectedDay > 29 || (selectedDay === 29 && !isLeapYear(selectedYear))) {
        setError('dobDay', {
          type: 'manual',
          message: 'Please enter valid date.',
        });
        return;
      }
    } else {
      if (parseInt(currentValues.dobDay) > daysInMonth[currentValues.dobMonth]) {
        setError("dobDay", {
          type: "manual",
          message: "Please enter valid day."
        });
        return false;
      }
    }
    formData.dobDay = currentValues.dobDay;
    formData.dobMonth = currentValues.dobMonth;
    formData.dobYear = currentValues.dobYear;
    nextStep();
  };


  return (
    <div className={step != 4 && "d-none"}>
        <div className="slide_5Dec">
          <h2 className="animate__animated animate__fadeInUp animate__delay-.5s animated-element">
            Please provide your Date of Birth to ensure accuracy in our records.
          </h2>
          <div className="px-3 py-3 widget_mobi borderx">
            <div className="form_drop col-lg-12 col-12 text-start">
              <label htmlFor="">Date of Birth</label>
              <div className="row pt-0 Birth_detail animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                <div className="col-lg-4 col-md-4 col-12 date_here">
                  <label htmlFor="dobDay">Day</label>
                  <select
                    className={`form-select ${errors.dobDay && "is-invalid"}`}
                    name="dobDay"
                    id="dobDay"
                   // value={formData.dobDay}
                    onChange={handleInputChange}
                    ref={register({
                      required: "Please select a day.",
                    })}
                  >
                    {DobDay.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {errors.dobDay && (
                    <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate1">
                      {errors.dobDay.message}
                    </p>
                  )}
                </div>

                <div className="col-lg-4 col-md-4 col-12 month_here">
                  <label htmlFor="dobMonth">Month</label>
                  <select
                    className={`form-select ${errors.dobMonth && "is-invalid"}`}
                    name="dobMonth"
                    id="dobMonth"
                    //value={formData.dobMonth}
                    onChange={handleInputChange}
                    ref={register({
                      required: "Please select a month.",
                    })}
                  >
                    {DobMonth.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {errors.dobMonth && (
                    <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate1">
                      {errors.dobMonth.message}
                    </p>
                  )}
                </div>

                <div className="col-lg-4 col-md-4 col-12 year_here">
                  <label htmlFor="dobYear">Year</label>
                  <select
                    className={`form-select ${errors.dobYear && "is-invalid"}`}
                    name="dobYear"
                    id="dobYear"
                    //value={formData.dobYear}
                    onChange={handleInputChange}
                    ref={register({
                      required: "Please select a year.",
                    })}
                  >
                    {DobYear.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {errors.dobYear && (
                    <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate1">
                      {errors.dobYear.message}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div
              className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s animated-element"
              id="subRegNo5"
            >
              <div className="row align-items-center">
                <div className="col-4 text-center">
                  <button
                    type="button"
                    data-type="back"
                    id="backStep-4"
                    className="msf-nav-button backbutton"
                    onClick={prevStep}
                  >
                    <img src={nextgreen} alt="" />
                    Back
                  </button>
                </div>
                <div className="col-8">
                  <div className="bg-1 hme_btn">
                    <button
                      type="button"
                      className="button w-100 next-btn"
                      id="nextslideCl_005"
                      value="Next"
                      aria-label="submitid"
                      name="subRegNo5"
                      onClick={handleNextClick}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default BasicFour;
