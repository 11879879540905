import React from "react";

const RadioButton_span_inside_label_V5 = ({
  value,
  CheckBoxText,
  name,
  checked,
  onChange,
  id,
  className,
  labelClassName,
  validation,
  labelText,
  onClick,
  spanClassName,
}) => {
  return (
    <>
      <input
        type="radio"
        className={className}
        value={value}
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        onClick={onClick}
        ref={validation}
      />
      <label className={labelClassName} htmlFor={id}>
        <span className={spanClassName}>{labelText}</span>
      </label>
    </>
  );
};
export default RadioButton_span_inside_label_V5;
