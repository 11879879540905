import React, { useContext, useReducer, useState, useCallback } from "react";
import Email from "../../../UI/Email";
import Telephone from "../../../UI/Telephone";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";

const ContactDetails = (props) => {
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();
  const phoneValidCheck = useCallback(async (event) => {
    const phone = event.target.value;

    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone);
      if (getPhoneValidation.data.status_code === 1) {
        props.clearErrors("txtPhone");
        return 1;
      } else if (getPhoneValidation.data.status_code === 0) {
        props.setPhoneError("Please Enter Valid Working Phone Number");
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        props.setPhoneError("Phone Number Already Exist");
        return 0;
      } else {
        props.setPhoneError("Please Enter Valid Phone Number");
        return 0;
      }
    } else {
      props.setPhoneError("Please Enter Phone Number");
      return 0;
    }
  });
  const validNumber = (evt) => {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
      return false;
    }
    else {
      return true;
    }
  }
  const emailValidCheck = async (event) => {
    const email = event.target.value;
    if (email !== "") {
      const getEmailValidation = await emailValidation(
        email,
        props.uuid
      );
      if (getEmailValidation.data.status_code === 1) {
        props.setEmailError("");
        return 1;
      } else {
        props.setEmailError("Invalid email.");
      }
    }
  };
  return (
    <>
     <div className="mb-3" ref={props.section2Ref}>

<fieldset className="p-2 px-3 animate__animated animate__fadeInUp animate__delay-1s">
  <legend className="px-2 m-0 fw-bold">Contact Information</legend>

  <div className="row  contactinfo" >

    <div className="col-lg-6  ">
      <div className="mb-2">
      <Telephone
          name="txtPhone"
          id="txtPhone"
          className='form-control'
          placeholder="Mobile Number"
          onBlur={phoneValidCheck}
          onKeyPress={validNumber}
          onChange={props.handleChange}
          maxlength="11"
          validation={props.validation({
            pattern: {
              value: /^[0-9]*$/i,
              message: "Please Enter Valid Phone Number",
            },
            required: "Please Enter Valid Phone Number",
          })}
          validationMsg={
            props.validationMsg.txtPhone && props.validationMsg.txtPhone.message
          }
        />
        {props.validationMsg.txtPhone && (
            <span className="error_msg">{props.validationMsg.txtPhone.message}</span>
          )}
      </div>
    </div>
    <div className="col-lg-6 ps-lg-0 ">
      <div className="mb-2">
      <Email
          name="txtEmail"
          id="txtEmail"
          className="form-control"
          placeholder="Email Address"
          onBlur={emailValidCheck}
          onChange={props.handleChange}
          validation={props.validation({
            required: "Please Enter Valid Email Address",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Invalid Email Address",
            },
          })}
          validationMsg={
            props.validationMsg.txtEmail && props.validationMsg.txtEmail.message
          }
        />
      </div>
    </div>
  </div>
</fieldset>
</div>
    </>
  )
}

export default ContactDetails