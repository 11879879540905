import React, { useState, useContext, useEffect } from "react";
import Button from "../../../UI/Button";
import loader03 from '../../../../assets/PCP_HDSC_V5/img/loader03.svg';
import TextField from "../../../UI/TextField";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { useVehicleValidation } from "../../../../Hooks/useVehicleValidation";
import { vehiclequestionaireV5 } from "../../../../Constants/CarRegistrationV5Question";
import RadioButton from "../../../UI/RadioButton";
import { useQuestionnairev1 } from "../../../../Hooks/useQuestionnairev1";
import QueryStringContext from "../../../../Contexts/QueryString";
import { useCarRegistrationSkipAll } from "../../../../Hooks/useCarRegistrationSkipAll";
import { Api } from "../../../../api/Api";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";

const CarRegistrationV5 = ({ pageIndex, pageForward, selectedlenders, setSelectedLenders, setPageIndex, carRegNotRequiredFunction }) => {
  const { register, errors, trigger, setError, clearErrors, getValues, setValue } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  const page_name = "PCP_HDSC_V5";
  const { visitorParameters } = useContext(VisitorParamsContext);
  const uuid = visitorParameters.visitor_parameters.uuid ?? localStorage.getItem("uuid");
  const urlQuery = new URLSearchParams(window.location.search);
  const query_uuid = urlQuery.get("uuid");
  const { queryString } = useContext(QueryStringContext);
  const { storeVehicleDetails } = useQuestionnairev1();
  const [questionareModal, setquestionareModal] = useState(false)
  const { validateRegNum } = useVehicleValidation();
  const { storeSkipallValues } = useCarRegistrationSkipAll();
  const storedFormData = localStorage.getItem('formData');
  const message_type = "store-skip-all-vehicle-details";
  const [objectRelatedToEnteredVehicleNumber, setObjectRelatedToEnteredVehicleNumber] = useState({
    // sample data
    id_required: null,
    label: "",
    slug: "",
    value: "",
    vehicleNumber: ""
  });
  const history = useHistory();
  const [formattedDates, setFormattedDates] = useState([]);
  const [keeperLatest, setKeeperLatest] = useState(null);

  const arrayOfPendingLenderValues = selectedlenders.filter(financeprovider => !financeprovider.purchaseDate).map(financeprovider => financeprovider.value);

  const addButtonClick = async (e, item, index) => {
    const inputField = e.target.closest('div').querySelector('input');
    const value = inputField.value;
    const specialCharsRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (value == "") {
      setError(`vehicleNumber_${item.value}`, { message: "Please Enter Vehicle Number" });
      return;
    }

    if (specialCharsRegex.test(value)) {
      setError(`vehicleNumber_${item.value}`, { message: "Please Enter valid Vehicle Number" });
      return;
    }
    GTMDataLayer({
      question: 'car reg no',
      answer: null,
    });
    const validationResponse = await validateRegNum(
      value,
      uuid,
      page_name
    );
    if (validationResponse && validationResponse.data.keeper_date) {
      const keeperDatesObj = JSON.parse(validationResponse.data.keeper_date);
      if (keeperDatesObj && keeperDatesObj.keeper_dates) {
        const keeperDates = keeperDatesObj.keeper_dates;
        const latestDate = new Date(
          Math.max(...keeperDates.map((date) => new Date(date)))
        );
        const nextDayDate = new Date(latestDate);
        nextDayDate.setDate(latestDate.getDate() + 1);
        const nextDayDateString = nextDayDate.toISOString().split("T")[0];
        setKeeperLatest(nextDayDateString);
      }
    }
    if (
      validationResponse.status == 200 &&
      validationResponse.data.is_valid == 1
    ) {
      const keeperDateStr = validationResponse.data.keeper_date;
      const keeperDateObj = JSON.parse(keeperDateStr);
      const keeperDates = keeperDateObj.keeper_dates;
      const formattedDatesArray = keeperDates.map((dateStr) => {
        const parts = dateStr.split(" ");
        const month = parts[1];
        const year = parts[2];
        const formattedDate = `${month} ${year}`;
        return { formattedDate, originalDate: dateStr };
      });
      setFormattedDates(formattedDatesArray);
      formattedDatesArray.push({
        formattedDate: "Other",
        originalDate: "other",
      });

      setquestionareModal(true);

      setObjectRelatedToEnteredVehicleNumber({
        ...item,
        vehicleNumber: validationResponse.data.car_reg_no
      })
    } else {
      setError(`vehicleNumber_${item.value}`, { message: "Enter valid vehicle number" });
    }
  }

  const nextClick = async () => {
    // this "for loop" checks if the user has entered the value and also if they have selected a date from popup
    for (const value of arrayOfPendingLenderValues) {
      const fieldName = `vehicleNumber_${value}`;
      const isFieldValid = await trigger(fieldName);

      if (isFieldValid) {
        const financeprovider = selectedlenders.find(provider => provider.value === value);
        if (!financeprovider.purchaseDate) {
          setError(fieldName, {
            type: 'manual',
            message: "Click Add and select a date from the popup",
          });

          const element = document.getElementById(fieldName);
          if (element) {
            element.focus();
          }
        }
        return;
      } else {
        const element = document.getElementById(fieldName);
        if (element) {
          element.focus();
        }
        return;
      }
    }

    // const isIdRequired = selectedlenders.find(
    //   (lender) => lender.is_doc_required === true || lender.is_doc_required === 1
    // );

    if (arrayOfPendingLenderValues.length === 0) {
      carRegNotRequiredFunction()
      history.push(`/thankyou-v5?uuid=${query_uuid.toString()}`);
    }
  };


  const skipAll = async () => {
    const slugs = JSON.parse(localStorage.getItem("selectedBanks"));
    const carRegNotRequiredValues = slugs
      .filter((item) => item.car_reg === false || item.car_reg === null)
      .map((item) => item.value);

    // const query_uuid = query.get("uuid");
    const response = await storeSkipallValues(
      visitorParameters.visitor_parameters,
      "car_registration",
      message_type,
      arrayOfPendingLenderValues.concat(carRegNotRequiredValues)
    );

    history.push(`/thankyou-v5?uuid=${query_uuid.toString()}`);
  }
  const questionModalClick = async (e, date) => {
    const selectedValue = e.target.value;
    if (selectedValue !== '') {
      setquestionareModal(false);
      // const answer = e.target.value;
      const bank_id = objectRelatedToEnteredVehicleNumber.value;
      // const questionvalue = questionId;

      const formdata = {
        page_name: page_name,
      };
      const vehicle_data = {
        keeper_date: date,
        current_keeper_date: keeperLatest,
        yourVehicle: "",
        carRegNo: objectRelatedToEnteredVehicleNumber.vehicleNumber,
        pageName: page_name,
        bank_id: bank_id,
        // question_id: questionvalue,
        // question_option_id: answer,
        slug: objectRelatedToEnteredVehicleNumber.slug
      };
      const providers = {
        slug: objectRelatedToEnteredVehicleNumber.slug
      };
      GTMDataLayer({
        question: 'date of purchase',
        answer: null,
      });
      const result = await storeVehicleDetails(
        visitorParameters.visitor_parameters,
        visitorParameters.data,
        formdata,
        queryString,
        vehicle_data,
        providers,
        "store-vehicle-details"
      );
      setSelectedLenders(prevArray => (
        prevArray.map(financeProvider => {
          if (financeProvider.value == bank_id) {
            return { ...financeProvider, purchaseDate: date };
          }
          return financeProvider;
        })
      ));

    }
  };
  const checkCharacters = (e) => {
    const allowedCharacters = /^[a-zA-Z0-9-_.\s]+$/;
    if (!allowedCharacters.test(e.key)) {
      e.preventDefault();
      return false;
    }
  };
  const onHandleChange = (event, itemId) => {
    clearErrors(`vehicleNumber_${itemId}`)
  };

  useEffect(() => {
    const fetchData = async () => {
      let localStorageValue = localStorage.getItem("selectedBanks");
      const lendersArray = JSON.parse(localStorageValue);
    };

    fetchData();
  }, [query_uuid]);

  return (
    <>
      <div id="CardSect" className={pageIndex === 2 ? "" : "d-none"}>
        <div className="animate__animated animate__fadeInLeft ">
          <h1 className="title2 fw-bold">Car Registration</h1>
          <p className="sub-text2">
            Providing your car registration number will help us
            expedite your claim process.{" "}
          </p>
        </div>
        <h2 className="mt-4 mb-3 animate__animated animate__fadeInLeft ">
          Enter the vehicle registration number associated with
          your lender(s)
        </h2>
        {selectedlenders ?
          <>
            {selectedlenders.map((item, index) => (
              <div key={index}>
                <div className="car-box p-3 mb-3  animate__animated animate__fadeInLeft">
                  <div className="bank-name mb-2">
                    <i className="bi bi-bank2" /> {item.label}
                  </div>
                  <div className="  numb-plate-wrap justify-content-between ">
                    <div className="d-flex number-plate">
                      <div className="flag"> </div>
                      <div className="input-wrap w-100">
                        <TextField
                          type="text"
                          value={item.vehicleNumber ? item.vehicleNumber : ''}
                          onkeypress={checkCharacters}
                          className="form-control w-100"
                          placeholder="Enter Vehicle Number "
                          maxlength="13"
                          autoComplete="off"
                          name={`vehicleNumber_${item.value}`}
                          id={`vehicleNumber_${item.value}`}
                          validation={register({
                            required:
                              "Please Enter Vehicle Number",
                          })}
                          onChange={(e) =>
                            onHandleChange(e, item.value)
                          }
                        />
                      </div>
                    </div>
                    <Button
                      type="button"
                      className="add-more "
                      onClick={(e) =>
                        addButtonClick(e, item, index)
                      }
                      buttonText="Add"
                      dataBsToggle="modal"
                    />
                  </div>
                  <div
                    className="text-success text1 mt-1  "
                  >
                    {item.purchaseDate && item.purchaseDate !== 'other' && (
                      <p>Purchased On {item.purchaseDate}</p>
                    )}
                    {/* {item.purchaseDate && item.purchaseDate === 'other' && (
                      <p>{item.purchaseDate}</p>
                    )} */}
                  </div>
                </div>
                {errors[`vehicleNumber_${item.value}`] &&
                  errors[`vehicleNumber_${item.value}`]
                    .message && (
                    <span
                      className="error_msg animate__animated animate__fadeInUp animate__delay-.5s"
                    >
                      {
                        errors[`vehicleNumber_${item.value}`]
                          .message
                      }
                    </span>
                  )}
              </div>
            ))}
            <div className="row my-lg-4 my-md-4 my-sm-4 my-5 animate__animated animate__fadeInLeft">
              <div className="col-4 pe-0">
                <Button type="button" id="next3" className="com-button p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-2 skipbtn" onClick={() =>
                  skipAll()
                } name="back01" buttonText="Skip" />
              </div>
              <div className="col-8">
                <Button
                  type="button"
                  className="com-button p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-2 fw-bold"
                  id="carRegistNext"
                  value="Next"
                  name=""
                  onClick={() =>
                    nextClick()
                  }
                  buttonText="Next"
                  dataBsDismiss="modal"
                  ariaLabel="Close"
                />
              </div>
            </div>
          </>
          : <></>}
      </div>
      {/* Modal */}
      {questionareModal &&
        <div
          className={`modal fade ${questionareModal && "show d-block"}`}
          id="carLender"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body ">
                <h2 className="  title pt-2">
                  {vehiclequestionaireV5.question}
                  {/* <div data-bs-dismiss="modal" class="close-btn"> <i class="bi bi-x-circle"></i> </div> */}
                </h2>
                <div className="wraper-option banklist mb-4 mt-4 ">
                  {formattedDates.map((dateObj, index) => (
                    <div
                      className="option-btn optionBtn  mb-2  selLender"
                      key={index}
                    >
                      <span className="check"> </span>
                      <RadioButton
                        className="form-check-input"
                        type="radio"
                        name="Radioshere2"
                        id={`radio${index}`}
                        value={dateObj.originalDate}
                        onClick={(e) =>
                          questionModalClick(e, dateObj.originalDate)
                        }
                        labelClassName="carreglabel"
                        labelName={dateObj.originalDate}
                      />
                    </div>
                  ))}
                  <div
                    className="text-center loader-div loaderLender"
                    style={{ display: "none" }}
                  >
                    {" "}
                    <img height={50} src={loader03} alt="" />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}

export default CarRegistrationV5