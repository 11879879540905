import { Api } from "../api/Api";

export const useSkipLender = () => {
  const skipLenderDetails = async (uuid, page_name, type) => {
    return await Api.post("v1/skip_lender_details", {
      uuid,
      page_name,
      type
    });
  };

  return { skipLenderDetails };
};
