import { useState } from "react";
import { AdtopiaApi } from "../api/AdtopiaApi";
import {useErrorWebhook} from "./useErrorWebhook";

export const useAdtopiaLoadApi = () => {
  const [isAdtopiaLoading, setIisAdtopiaLoading] = useState(false);
  const [adtopiaLoadingErr, setAdtopiaLoadingErr] = useState(false);
  const { ErrorWebhookTrigger } = useErrorWebhook();
  const adtopiaLoadResponse = async (
    url,
    domain_name,
    user_agent,
    ip_address,
    uuid,
    upid,
    referral_url
  ) => {
    setIisAdtopiaLoading(true);
    const response = await AdtopiaApi.post("loader", {
      url,
      domain_name,
      user_agent,
      ip_address,
      uuid,
      upid,
      referral_url,
    }).catch((error) => {
      setAdtopiaLoadingErr(true);
      if (error.response) {
        console.log("client received an error response (5xx, 4xx)");
      } else if (error.request) {
        console.log("client never received a response, or request never left");
      } else {
        console.log("anything else");
      }
      const erroMessage = {
        serviceName : 'ADTOPIA LOADER',
        errorReason:error.message,
        errorCode:error.code,
        uuid: uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    setIisAdtopiaLoading(false);
    return response;
  };
  return { adtopiaLoadResponse, isAdtopiaLoading, adtopiaLoadingErr };
};
