import React, { useState } from "react";

const AccordionItem = ({ title, content, index, openIndex, setOpenIndex }) => {
  const isOpen = index === openIndex;

  const toggleAccordion = () => {
    setOpenIndex(isOpen ? null : index);
  };

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={`accordFAQ${index}`}>
        <button
          className={`accordion-button ${isOpen ? "" : "collapsed"}`}
          type="button"
          onClick={toggleAccordion}
          aria-expanded={isOpen}
          aria-controls={`collapse  ${index}`}
        >
          {title}
        </button>
      </h2>
      <div
        id={`collapse   ${index}`}
        className={`accordion-collapse collapse ${isOpen ? "show" : ""}`}
        aria-labelledby={`accordFAQ${index}`}
      >
        <div className="accordion-body">{content}</div>
      </div>
    </div>
  );
};

const Accordion = ({ extraParentClasses }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const accordionData = [
    {
      title: "What is PCP?",
      content:
        "Personal contract purchases (PCP) are a very popular way of financing a car. You typically pay a deposit, and then a monthly fee for the car for a set number of years. At the end of the deal, you can usually hand the car back for no extra cost, pay a lump sum to enable you to keep the car, or put the value towards a different car with the same dealership.",
    },
    {
      title: "What is Hire Purchase?",
      content:
        "Hire Purchase is another way to finance a car. You’ll typically pay a deposit, then pay a set payment each month until you have paid off the value of the car. The loan is secured against the car, so you don’t own it until you’ve made all of the payments.",
    },
    {
      title: "How long will it take to claim for mis-sold car finance?",
      content:
        " There is no set time period to claim for mis sold car finance loans. If the finance provider accepts responsibility straight away and agrees to pay you back the money you’re owed, it could take a matter of months. However, if the finance provider disputes the claim, it could take much longer. The final decision on your claim will likely be linked to the FCA’s conclusion of their investigation into the industry, but it is important that you lodge your claim as soon as possible to join the queue.",
    },
    {
      title: "How much do mis-sold car finance claims cost?",
      content:
        "As we are regulated Claims Management Company we are subject to the FCA’s fee capping rules which cap our fees between 15% - 30% (plus VAT) of any award you may receive.  Full details of what fees we charge can be found in our Pre-Contract Information and Terms and Conditions.  Contact us today and we can swiftly advise you on the next steps and start the process of getting the redress you may be owed back to you",
    },
    {
      title: "What is the mis-sold car finance average payout?",
      content:
        "The payout will depend on your specific circumstances and how much you paid that you shouldn’t have done. However, many people may be due back thousands of pounds. So, if you believe you have been affected, it is worthwhile to investigate, which our expert team can assist you with.",
    },
    {
      title: "What is a hidden commission?",
      content:
        "Hidden commission is when you are sold a vehicle on a PCP or Hire Purchase finance agreement, but you are either not told that the dealer will be making commission, or they do not tell you the extent of the commission. Many people purchasing a car before January 2021 were not made aware that the dealer would also earn more commission for selling them the finance product (the PCP or Hire Purchase agreement), on top of any commission they make for selling the car itself.  Dealers should always be transparent about the amount of commission they are earning, to help ensure a transparent sale and reduce the risk of the dealer offering finance  products based on commission and not what’s best for their customer.If you were sold a vehicle on a PCP or Hire Purchase agreement and the dealer did not make their commission clear to you, this may be classed as hidden commission and you may have a mis-sold car finance claim.",
    },
    // Add more accordion items as needed
  ];

  return (
    <section className={`faqsection py-lg-4 py-md-4 py-sm-4 py-5 ${extraParentClasses}`} id="faq_sec">
      <div className="container">
        <div className=" title-sec">
          <div className="col-lg-12  text-lg-center text-md-center  text-sm-center text-start">
            <h2 className="pt-lg-5 pt-md-3 pt-sm-3 pt-0 text-center">Mis-sold Car Finance FAQs</h2>
          </div>
        </div>
        <div className="col-lg-12 mb-lg-5 mb-md-5 mb-sm-5 mb-2">
          <div className="accordion mt-lg-4 mt-md-4 mt-sm-4 mt-3" id="accordFAQ">
            <div className="row">
              <section className="accordion">
                {accordionData.map((item, index) => (
                  <AccordionItem
                    key={index}
                    index={index}
                    title={item.title}
                    content={item.content}
                    openIndex={openIndex}
                    setOpenIndex={setOpenIndex}
                  />
                ))}
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Accordion;
