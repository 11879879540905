import React, { useContext, useEffect, useState } from "react";
import "../../../assets/PCP_HDSC_V1/css/custom.scss";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import FbViewPixel from "../../../Utility/FbViewPixel";
import PCP_HDSC_V1Form from "../../Forms/PCP_HDSC_V1/PCP_HDSC_V1Form";
import FeaturedinAndAccordion from "../../Includes/Layouts/PCP_HDSC_V1/FeaturedinAndAccordion";
import { useForm } from "react-hook-form";
import Header_PCP_HDSC_V1 from "../../Forms/PCP_HDSC_V1/Header_PCP_HDSC_V1";
import hendersons_logo_gold from "../../../assets/PCP_HDSC_V1/img/hendersons_logo.png";
import Spinner from "../../../assets/PCP_HDSC_V1/img/Spinner.gif";
import PrivacyModal from "../../Includes/Layouts/PCP_HDSC_V1/PrivacyModal";
import TermsModal from "../../Includes/Layouts/PCP_HDSC_V1/TermsModal";
import Cookiespolicy from "../../Includes/Layouts/PCP_HDSC_V1/Cookiespolicy";
import ContractModal from "../../Includes/Layouts/PCP_HDSC_V1/ContractModal";
import ComplaintsProcedure from "../../../docs/Complaints Procedure - Hendersons - June 2024.pdf";
import PrivacyPolicy from "../../../docs/Henderson Claims - Privacy Policy - June 2024.6bf93601.pdf";

const PCP_HDSC_V1 = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSecondSection, setShowSecondSection] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [postCodeError, setPostCodeError] = useState("");
  const [currentModalCount, setCurrentModalCount] = useState(0);
  const [showPreviousNameModal, setShowPreviousNameModal] = useState(false);
  const [consentError, setConsentError] = useState("");
  const [initialLoader, setInitialLoader] = useState(true);
  const step = 1;

  const {
    register,
    errors,
    trigger,
    clearErrors,
    getValues,
    setError,
    setValue,
    control,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const [formData, setFormData] = useState({
    lstSalutation: "",
    txtFName: "",
    txtLName: "",
    dobDay: "",
    dobMonth: "",
    txtPhone: "",
    txtEmail: "",
    dobYear: "",
    financeProvider: [],
    postcode: "",
    commission: "",
    bankruptcy: "",
    claimed: "",
    whyNotClaimed: "",
    previousName: "",
    unsubscribeStatus: 0,
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setInitialLoader(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  const handleYesClick = () => {
    setShowSecondSection(true);
  };

  useEffect(() => {
    localStorage.removeItem("dobYear");
    localStorage.removeItem("dobMonth");
    localStorage.removeItem("dobDay");
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "txtFName" || name === "txtLName") {
      if (/[^a-zA-Z\s]|[\s]{2,}/.test(value)) {
        return;
      }
    }

    if (name === "txtPostCode" && value) {
      setPostCodeError("");
    }
    const setFormDataAndLocalStorage = (name, value) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: name === "txtFName" ? value.trim() : value.trimStart(),
      }));
      localStorage.setItem(name, value.trim());
    };

    setFormDataAndLocalStorage(name, value);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [step]);
  const [showCookie, setShowCookie] = useState("show");
  const [termsModal, setTermsModal] = useState(false);
  const [contractModal, setContractModal] = useState(false);
  const [showCookiesPolicy, setShowCookiesPolicy] = useState(false);
  const cookieName = "CookiesPolicyHide";

  const cookieClose = (e) => {
    e.preventDefault();
    setShowCookie("hide");
    if (cookieName != null) {
      const d = new Date();
      const cvalue = "Yes";
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
      const expires = "expires=" + d.toUTCString();
      document.cookie = cookieName + "=" + cvalue + "; " + expires;
    }
  };

  const handleCookieShow = () => {
    setShowCookiesPolicy(true);
  };

  const handleCookieHide = () => {
    setShowCookiesPolicy(false);
  };
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="PCP_HDSC_V1" />
      <FbViewPixel />
      <div className="pcp_siteHome pcp_siteHome_v1">
        {initialLoader ? (
          <div id="preloader">
            <div id="loader" />
          </div>
        ) : (
          showLoader && (
            <div id="preloader">
              <div className="loader-wrap succLoader text-center text-light">
                <nav className="navbar navbar-expand-lg">
                  <a className="navbar-brand text-center col-12 mt-5 pt-5">
                    <img src={hendersons_logo_gold} alt="Logo" height="45px" />
                  </a>
                </nav>
                <div className="mt-5 pt-5">
                  <img height="150" src={Spinner} alt="" />
                  <h2 className="mt-3 succtext px-5">
                    Analysing the information you have provided <br /> <br />
                    <small> Please wait...</small>
                  </h2>
                </div>
              </div>
            </div>
          )
        )}
        <Header_PCP_HDSC_V1 extraClass="" />

        <PCP_HDSC_V1Form
          step={1}
          showModal={showModal}
          handleYesClick={handleYesClick}
          showSecondSection={showSecondSection}
          handleChange={handleChange}
          formData={formData}
          emailError={emailError}
          setEmailError={setEmailError}
          phoneError={phoneError}
          setPhoneError={setPhoneError}
          register={register}
          errors={errors}
          setError={setError}
          trigger={trigger}
          clearErrors={clearErrors}
          getValues={getValues}
          setValue={setValue}
          postCodeError={postCodeError}
          setFormData={setFormData}
          setCurrentModalCount={setCurrentModalCount}
          currentModalCount={currentModalCount}
          setPostCodeError={setPostCodeError}
          showPreviousNameModal={showPreviousNameModal}
          setShowPreviousNameModal={setShowPreviousNameModal}
          consentError={consentError}
          setConsentError={setConsentError}
          setShowLoader={setShowLoader}
          setShowModal={setShowModal}
        />

        <FeaturedinAndAccordion />
        <div>
          <footer>
            <div className="container text-center">
              <div className="ftr_cntnt">
                <div className="row justify-content-center">
                  <div className="col-lg-12 col-12">
                    <p>
                      Henderson Claims is a Claims Management Company (CMC). You
                      can claim for free, without using a CMC, first to your
                      lender or to the FOS/FSCS.
                    </p>
                    <p>
                      Hendersons is a trading style of Henderson Claims Limited.
                      Henderson Claims Limited is authorised and regulated by
                      the Financial Conduct Authority with reference number
                      FRN:836041. Henderson Claims Limited is a Claims
                      Management Company (CMC). Registered with the Information
                      Commissioner's Office: registration number ZA147049.
                      Registered address: Grosvenor House Part Ground Floor
                      (Rear Wing), St Thomas Place, Stockport, United Kingdom,
                      SK1 3TZ. Registered in England and Wales, Company number
                      07646017. VAT number: 240 8769 88.
                    </p>
                    <p>
                      <a
                        target="_blank"
                        href="https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf"
                        className="link-style"
                      >
                        *fca.org.uk
                      </a>
                    </p>
                    <p>
                      <b>© 2023</b>
                    </p>
                  </div>
                </div>
                <div className="text-center">
                  <div className="copyright">
                    <ul>
                      <li>
                        <a href={PrivacyPolicy} target="_blank">
                          Privacy Policy
                        </a>
                      </li>
                      <li>
                        <a onClick={() => setTermsModal(true)}>
                          Terms &amp; Conditions
                        </a>
                      </li>
                      <li>
                        <a onClick={() => setContractModal(true)}>
                          Pre-contract Information
                        </a>
                      </li>
                      <li>
                        <a href={ComplaintsProcedure} target="_blank">
                          Complaints Procedure
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </footer>
          <div
            id="cookieBar"
            className={`cooki cookie1 text-center ${showCookie}`}
          >
            <div className="col-lg-12 col-md-12 col-12 col-sm-12">
              <span>
                We use cookies to analyse traffic to this site and to give you
                the best experience. By clicking 'Accept' you agree to our use
                of cookies. To find out more about our use of cookies and how to
                refuse them, please read our Privacy Policy.
                <a
                  href="#"
                  className="rp_clk text-underline"
                  onClick={handleCookieShow}
                >
                  <u>Learn More.</u>
                </a>
              </span>
              <a
                className="rp_clk closecook text-danger"
                href="#"
                onClick={cookieClose}
              >
                Close <strong>X</strong>
              </a>
            </div>
            <div className="clearfix"></div>
          </div>
          <TermsModal termsModal={termsModal} setTermsModal={setTermsModal} />
          <ContractModal
            contractModal={contractModal}
            setContractModal={setContractModal}
          />
          <Cookiespolicy
            showstatus={showCookiesPolicy}
            hidepopup={handleCookieHide}
          />
        </div>
      </div>
    </>
  );
};

export default PCP_HDSC_V1;
