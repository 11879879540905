import React, { useState } from 'react';

const TermsModal = ({ termsModal, setTermsModal }) => {
  return (
    <>
      {/* Modal  terms*/}
      <div
        // className="modal fade"
        className={`modal fade show ${termsModal ? "d-block" : "d-none"}`}

        id="termsmodal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl ">
          <div className="modal-content footermodal">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Terms &amp; Conditions
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setTermsModal(false)}
              />
            </div>
            <div className="modal-body d-block">
              <p>
                Henderson Claims Limited is authorised and regulated by the
                Financial Conduct Authority with reference number 836041{" "}
              </p>
              <p>
                Please be aware that there may be time limits attached to your claim
                such as when you may run out of time to bring a claim (known as the
                'limitation period').{" "}
              </p>
              <p>
                These Terms and Conditions form the legal agreement between us.{" "}
              </p>
              <ol>
                {/* 1 */}
                <li>
                  <h4>Definitions</h4>
                  <ul>
                    <li>
                      Claim/Claims -Your claim or claims against the Third Party
                      relating to the mis-selling of a Financial Product or Service,
                      Section 75 claims, the application of unlawful charges or a
                      breach of legislation relating to a Financial Product or
                      Service.
                    </li>
                    <li>
                      Compensation - means any sums paid, offered or awarded in
                      respect of any Claim as a result of Our efforts. This includes
                      benefits, redress, gestures of goodwill, ex gratia payments,
                      refunds, discounts, any reduction in the loan outstanding or
                      outstanding liabilities, any amount added to a pension and/or
                      any interest or capital recovered. Where such an offer is
                      increased on appeal during the term of this agreement, then
                      the higher amount shall be used in order to calculate the
                      amount of the Compensation.
                    </li>
                    <li>
                      Data Protection Legislation - the General Data Protection
                      Regulation 2016 and the Data Protection Act 2018.
                    </li>
                    <li>FCA - the Financial Conduct Authority.</li>
                    <li>
                      Financial Products - credit cards, loans, pensions and
                      investments, car finance/PCP, high cost short term lending
                      (including but not limited to; payday loans, guarantor loans,
                      doorstop loans, revolving credit) and breaches of the Consumer
                      Credit Act 1974 (as amended) and/or a combination of these.
                    </li>
                    <li>
                      Gross Amount - the total amount of Compensation offered before
                      tax is deducted, before our Service Charge is deducted or
                      before any Compensation is offset against debt/arrears or
                      before it is placed into your pension.
                    </li>
                    <li>
                      Inspection Report - any report completed following an
                      inspection of Your property, which is used to support Your
                      Claim.
                    </li>
                    <li>
                      Letter of Authority - the signed letter of authority which you
                      provide to Us in respect of a Claim.
                    </li>
                    <li>
                      Service/s - the work which We will undertake on your behalf
                      set out in these terms.
                    </li>
                    <li>
                      Service Charge - the charges payable by You as set out in
                      these terms.
                    </li>
                    <li>
                      Survey - A survey on Your property completed by a surveyor,
                      which is used to support Your Claim.
                    </li>
                    <li>
                      Third Party - means the financial institution and/or persons
                      to whom the Letter of Authority in relation to a Claim is
                      addressed such as a bank, building society, broker, pension
                      provider, car dealership or financial adviser or any other
                      entity which sold the Financial Product or Service or gave the
                      advice to You. 'Third Party' also includes the Financial
                      Ombudsman Service (FOS), The Pension Ombudsman (TPO) and the
                      Financial Services Compensation Scheme (FSCS).
                    </li>
                    <li>'Us', 'We' and 'Our' means Henderson Claims Limited.</li>
                    <li>
                      'You' and 'Your' means the client/s whose details are recorded
                      on the Letter of Authority.
                    </li>
                  </ul>
                </li>
                {/* 2 */}
                <li>
                  <h4>Length of this Contract</h4>
                  <ol>
                    <li>
                      This contract will start on the date You sign and return these
                      terms to Us and, unless cancelled earlier in accordance with
                      Clauses 6 or 7 below, will continue until:
                      <ol>
                        <li>
                          Compensation is recovered for You and You have paid the
                          Service Charge; or{" "}
                        </li>
                        <li>
                          Your Claim is rejected, and We notify You that there is no
                          avenue of appeal or any such avenue is, in Our reasonable
                          opinion, unlikely to succeed.
                        </li>
                      </ol>
                    </li>
                    <li>
                      If the Claim to which a Letter of Authority relates is dealt
                      with over more than one account then We will, for the
                      avoidance of doubt, be entitled to charge you a Fee in
                      accordance with Clause 5 in respect of any and all additional
                      accounts identified. We will notify You of any additional
                      accounts that are identified.
                    </li>
                  </ol>
                </li>
                {/* 3 */}
                <li>
                  <h4>What Services We will provide to You</h4>
                  <ol>
                    <li>
                      We will:
                      <ol>
                        <li>
                          Ask you a series of questions to enable Us to ascertain
                          the basis and merits of your claim.{" "}
                        </li>
                        <li>
                          Assess the likelihood of Your Claim being successful and
                          provide Our advice on this.
                        </li>
                        <li>
                          If We reasonably believe that it is unlikely to be
                          successful or it is not in Your best interests to pursue a
                          Claim, we may decline to act for You. We will notify You
                          of this and tell You why We have reached Our decision.{" "}
                        </li>
                        <li>
                          If We accept Your Claim, we will confirm this to You and
                          prepare and submit Your Claim to the relevant Third Party,
                          where appropriate.{" "}
                        </li>
                        <li>
                          Liaise with the Third Party on your behalf and use our
                          reasonable endeavours to pursue Your Claim.
                        </li>{" "}
                        <li>
                          Promptly advise you of any requests for additional
                          information or documentation that the Third Party needs.
                        </li>
                        <li>
                          Update You within 10 working days when there are any
                          material developments on Your case or when We receive any
                          information which is for Your attention. Where there are
                          no material developments, we will update You at least
                          every 6 months in writing.
                        </li>
                        <li>
                          Notify You in writing of any offers of Compensation made
                          by the Third Party and provide Our advice regarding
                          whether the offer You have received is in line with the
                          rules and/or methodology stipulated by the FCA or the
                          principles used by the FOS, TPO or the FSCS.{" "}
                        </li>
                        <li>
                          Where necessary and appropriate, seek Your agreement to
                          refer Your Claim to the FOS, TPO or the FSCS.{" "}
                        </li>
                        <li>
                          Notify You of any circumstances beyond Our control which
                          prevent Us from performing the Services under this
                          contract.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                {/* 4 */}
                <li>
                  <h4>What you will need to do</h4>
                  <ol>
                    <li>
                      You agree to:
                      <ol>
                        <li>
                          Provide full authority to Us to deal with the Third Party
                          on your behalf.
                        </li>
                        <li>
                          Appoint Us as Your exclusive representative to handle this
                          Claim. You cannot appoint another firm, person or
                          Yourself, to act on Your behalf in respect of this Claim,
                          unless You terminate this agreement with Us in accordance
                          with Clause 6 below.
                        </li>
                        <li>
                          Provide Us with information about Your circumstances which
                          is truthful, to the best of Your knowledge and belief.
                          Such information includes, but not limited to, Your
                          financial circumstances, recollections of the sale of the
                          financial product/service, employments details, health
                          information (where necessary), installer details and
                          details of any damage.
                        </li>
                        <li>
                          Allow access to Your home for a survey of the damage (only
                          if relevant to the type of Claim you have instructed us to
                          pursue).
                        </li>
                        <li>Co-operate with Us at all times.</li>
                        <li>
                          Respond promptly to requests by Us for further
                          information, or documents that may be required.{" "}
                        </li>
                        <li>
                          Provide copies of all documentation that you hold which
                          relate to the Claim. Documentation which may be required
                          are; finance agreements or statements, installation
                          certificates, proof of identity documentation, proof of
                          beneficiary documentation, suitability reports and pension
                          provider details. This is not an exhaustive list.{" "}
                        </li>
                        <li>
                          Respond promptly to requests by Us for further
                          information, or documents and/or questionnaires that may
                          be needed to progress Your Claim.{" "}
                        </li>
                        <li>
                          Notify Us promptly of any communication from the Third
                          party (including telephone calls), this includes any offer
                          of compensation, rejection of a Claim or other information
                          relating to the Claim.{" "}
                        </li>
                        <li>
                          Notify Us promptly upon becoming aware of any changes in
                          Your circumstances or of any documents not provided to Us
                          which could affect the Claim. This includes, but is not
                          limited to, any arrears, the issue of a default notice, a
                          petition for a County Court Judgment or bankruptcy claim.
                          In this case, we reserve the right to terminate the
                          agreement in accordance with clause 7.1.1 below.{" "}
                        </li>
                        <li>
                          Pay our Service Charge due as a result of a successful
                          Claim.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                {/* 5 */}
                <li>
                  <h4>Service Charges</h4>
                  <ol>
                    <li>
                      If your claim is successful, the Service Charge is a
                      percentage of your gross redress or the maximum amount listed
                      in column (2) below, whichever is lower. <br />
                      Examples table:
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col" width="25%">
                              (1) Compensation amount
                            </th>
                            <th scope="col" width="25%">
                              (2) Your Service Charge (+VAT)
                            </th>
                            <th scope="col" width="25%">
                              (3) Example Compensation
                            </th>
                            <th scope="col" width="25%">
                              (4) Example Service Charge (inc VAT)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>£1 - £1,499 </td>
                            <td>30% up to a max of £420 +VAT</td>
                            <td>£100</td>
                            <td>£36</td>
                          </tr>
                          <tr>
                            <td>£1,500 - £9,999</td>
                            <td>28% up to a max of £2,500 +VAT</td>
                            <td>£6,000</td>
                            <td>£2,016</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>£10,000 - £24,999 </td>
                            <td>25% up to a max of £5,000 +VAT</td>
                            <td>£11,000</td>
                            <td>£3,300</td>
                          </tr>
                          <tr>
                            <td>£25,000 - £49,999 </td>
                            <td>20% up to a max of £7,500 +VAT</td>
                            <td>£40,000</td>
                            <td>£9,000</td>
                          </tr>
                          <tr>
                            <td>£50,000 +</td>
                            <td>15% up to a max of £10,000 +VAT</td>
                            <td>£80,000</td>
                            <td>£12,000</td>
                          </tr>
                        </tbody>
                      </table>
                      The examples shown in columns (3) and (4) above are for
                      illustration purposes only and are not to be taken as an
                      estimate of the likely amounts to be recovered. The amount You
                      receive may be more or less than these examples.
                    </li>
                    <li>
                      In addition to our Service Charge, we charge an additional
                      £600 + VAT (at the standard rate) for any Inspection Report /
                      Survey that may be required (where appropriate for your
                      Claim), which is only payable where Compensation has been
                      offered or refunded to you by the Third Party. We will seek to
                      recover the cost of any Inspection Report / Survey from the
                      Third Party should Compensation be offered or refunded. If we
                      are unable to recover the cost of the Inspection Report /
                      Survey in full from the Third Party, we will not seek to
                      recover any shortfall from You.{" "}
                    </li>
                    <li>
                      If you have arrears or outstanding liabilities on your
                      Financial Product, then the Third Party may use the
                      Compensation to pay those before making any payment to You. If
                      you do not receive any cash refund and all compensation is
                      used against debt, you must still pay Our Service Charge.
                    </li>
                    <li>
                      Your Compensation may also be paid into Your pension (where
                      relevant depending on your Claim), before you are able to
                      access it. In this case You will still be required to pay Our
                      Fees.{" "}
                    </li>
                    <li>
                      Where the Third Party makes payment of Compensation directly
                      to You, we will send You an invoice equal to the amount of Our
                      Service Charge.{" "}
                    </li>
                    <li>
                      Our Service Charge must be paid within 14 business days of
                      receipt of our invoice.{" "}
                    </li>
                    <li>
                      We reserve the right to charge You for any reasonable costs
                      incurred in seeking to recover Our Service Charge from You,
                      including interest at the rate of 2% per annum over the base
                      lending rate of Barclays Bank if Our Service Charge is unpaid
                      at the end of the 14 business days.
                    </li>
                    <li>
                      We reserve the right to charge the full Service Charge on the
                      basis of the offer of Compensation being made if:
                      <ol>
                        <li>
                          You reject an offer of Compensation that is calculated in
                          accordance with rules of the FCA or the principles used by
                          the FOS, TPO or the FSCS; or{" "}
                        </li>
                        <li>
                          You reject any other reasonable offer of Compensation
                          which We recommend (acting reasonably) should be accepted
                          by You.{" "}
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                {/* 6 */}
                <li>
                  <h4>How You Can Cancel This Agreement</h4>
                  <ol>
                    <li>
                      You have the right to cancel this contract within 14 days
                      without giving any reason. This cooling off period will expire
                      after 14 days from the day You sign and return the agreement.
                    </li>
                    <li>
                      You must inform us of your decision to cancel this contract
                      using one of the contact methods detailed in Clause 8.1.
                    </li>
                    <li>
                      We will communicate to You an acknowledgement of the
                      cancellation in writing.
                    </li>
                    <li>
                      You can cancel at any time after the 14 day cooling period and
                      before an offer of Compensation is made, however we reserve
                      the right to apply a Fee to cover Our reasonable costs for any
                      work undertaken in processing Your Claim up to the date of
                      cancellation. Such charges will be calculated at £120 + VAT as
                      follows:
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Stage of Claim</th>
                            <th scope="col">Hours (maximum)</th>
                            <th scope="col">Fee (maximum)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              When We are carrying out/have completed a detailed
                              questionnaire as part of our investigation.{" "}
                            </td>
                            <td>2</td>
                            <td>£300</td>
                          </tr>
                          <tr>
                            <td>
                              When We are carrying out/have completed a detailed
                              full review of your case (this is not the same as any
                              Free Check assessment you may have already had).{" "}
                            </td>
                            <td>10</td>
                            <td>£1,500</td>
                          </tr>
                          <tr>
                            <td>
                              When We have submitted Your Claim to the Third Party.
                            </td>
                            <td>14</td>
                            <td>£2,100</td>
                          </tr>
                          <tr>
                            <td>
                              When We have liaised with the Third Party in respect
                              of your Claim (before any offer of compensation is
                              made).
                            </td>
                            <td>18</td>
                            <td>£2,700</td>
                          </tr>
                        </tbody>
                      </table>
                    </li>
                    <li>
                      If We are in breach of Our obligations under this agreement
                      You can cancel this contract at any time without having to pay
                      a Service Charge or any fee to us.
                    </li>
                  </ol>
                </li>
                {/* 7 */}
                <li>
                  <h4>How We Can Cancel or Change This Agreement</h4>
                  <ol>
                    <li>
                      We can cancel this agreement by giving You 14 days' notice in
                      writing if any of the following events occur:
                      <ol>
                        <li>
                          We become aware that Your Claim is unlikely to succeed;
                        </li>
                        <li>
                          You are declared bankrupt, enter into an Individual
                          Voluntary Arrangement or a Trust Deed, have a Bankruptcy
                          petition presented against You, enter into Sequestration,
                          or a reduced repayment arrangement with Your creditors;
                        </li>
                        <li>
                          You provide information which You knew to be false or
                          misleading in support of Your Claim and this information
                          is material to the success of Your Claim or as to whether
                          We would have agreed to act for You;{" "}
                        </li>
                        <li>
                          You fail to respond to reasonable requests for information
                          in a timely manner and this prevents Us from progressing
                          Your Claim;{" "}
                        </li>
                        <li>
                          We become aware or suspect that the claim is fraudulent or
                          vexatious;
                        </li>
                        <li>
                          You breach a term of the agreement and You do not correct
                          this breach within 30 days of receiving written
                          notification from Us detailing the breach and the action
                          required to resolve the breach.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Subject to the notice period below, we may change the terms of
                      this agreement from time to time to reflect the increased cost
                      of providing the Services to You, to reflect changes or
                      predicted changes in taxation, or changes in legislation.
                    </li>
                    <li>
                      We will always give You at least 30 days' written notice of
                      any changes before they take place. If You are not willing to
                      accept the proposed change, you will be free to end the
                      agreement and You will have no amount to pay.
                    </li>
                  </ol>
                </li>
                {/* 8 */}
                <li>
                  <h4>Complaints</h4>
                  <ol>
                    <li>
                      You can submit a complaint to us in one the following ways:{" "}
                      <br />
                      Write to us: Grosvenor House Part Ground Floor (Rear Wing), St Thomas Place, Stockport, United Kingdom, SK1 3TZ. <br />
                      Telephone us: 0161 359 7140 <br />
                      Email us:{" "}
                      <a href="mailto:contact@trusthendersons.co.uk">
                        contact@trusthendersons.co.uk
                      </a>{" "}
                      <br />
                    </li>
                    <li>
                      We will send you a written acknowledgement with a copy of our
                      complaint's procedure promptly and in any event within 3
                      business days. If we haven't resolved Your complaint within 8
                      weeks of receipt or You are not satisfied with our response
                      You can refer it to the Financial Ombudsman Service, whose
                      contact details are: Financial Ombudsman Service, Exchange
                      Tower, Harbour Exchange, London, E14 9SR,{" "}
                      <a
                        href="http://www.financial-ombudsman.org.uk"
                        target="_blank"
                      >
                        www.financial-ombudsman.org.uk
                      </a>
                      , Tel: 0800 023 4567.{" "}
                    </li>
                    <li>
                      If you believe we have breached our Data Protection
                      Legislation, you have the right to make a complaint at any
                      time to the Information Commissioner's Office (ICO), the UK
                      supervisory authority for data protection issues (
                      <a href="http://www.www.ico.org.uk" target="_blank">
                        www.ico.org.uk
                      </a>
                      ). We would, however, appreciate the chance to deal with your
                      concerns before you approach the ICO so please contact us
                      using the details above.
                    </li>
                  </ol>
                </li>
                {/* 9 */}
                <li>
                  <h4>Data Protection</h4>
                  <ol>
                    <li>
                      We will process Your information in accordance with Data
                      Protection Legislation.
                    </li>
                    <li>
                      You can read more about how We use Your data in our Privacy
                      Policy on our website at{" "}
                      <a
                        href="https://www.trusthendersons.co.uk/privacy.html"
                        target="_blank"
                      >
                        www.trusthendersons.co.uk/privacy.html
                      </a>
                      .{" "}
                    </li>
                  </ol>
                </li>
                {/* 10 */}
                <li>
                  <h4>Waiver</h4>
                  <ol>
                    <li>
                      We may decide not to apply certain conditions of these terms
                      to some/all of Your claims.
                    </li>
                    <li>
                      If We decide not to apply a condition of these terms to one of
                      Your Claims, this does not mean that We cannot apply it to any
                      of Your other Claims.
                    </li>
                  </ol>
                </li>
                {/* 11 */}
                <li>
                  <h4>Other Important Terms</h4>
                  <ol>
                    <li>
                      We may transfer these Terms and Conditions and Our rights and
                      obligations under it. We will always provide You with at least
                      30 days' written notice of such a transfer. You will be free
                      to cancel the agreement if You object to such a transfer.{" "}
                    </li>
                    <li>
                      This agreement is governed by English Law and is subject to
                      the jurisdiction of the English courts.
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setTermsModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Modal  terms end*/}
    </>
  );
};

export default TermsModal;
