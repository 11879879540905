import React, { useState, useContext } from 'react';
import Button from '../../../UI/Button';
import 'bootstrap-icons/font/bootstrap-icons.css';
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { useIdUpload } from "../../../../Hooks/useIdUpload";
import GetVisitorsParams from "../../../../Utility/GetVisitorsParams";
import { useHistory } from "react-router-dom";
import { useCarRegistrationSkipAll } from "../../../../Hooks/useCarRegistrationSkipAll";
import { useSkipLender } from "../../../../Hooks/useSkipLender";
import Arrowforward from "../../../../assets/PCP_HDSC_V5/img/arrow_forward_ios.svg";
import Security from "../../../../assets/PCP_HDSC_V5/img/security.png";
import pdf_logo from '../../../../assets/PCP_HDSC_V5/img/pdf_logo.png';
import xx from "../../../../assets/PCP_HDSC_V5/img/xx.svg";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";

const IdUploadV5 = ({ pageIndex, pageForward, setPageIndex, isCarRegRequired, carRegNotRequiredFunction }) => {
  const [isModalVisible, setIsModalVisible] = useState({show : false , type: ''});
  const [progressbar, setProgressBar] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [idType, setIdType] = useState('');
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [userfile, setUserfile] = useState({ preview: '', title: '' ,type:''});
  const [selectedFileType, setSelectedFileType] = useState('');
  const [selectedFile, setSelectedFile] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const [errorMessageUpload, setErrorMessageUpload] = useState();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const uuid = visitorParameters.visitor_parameters.uuid ?? localStorage.getItem("uuid");
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const [isInValidFile, setIsInValidFile] = useState(false);
  const [base64Image, setBase64Image] = useState('');
  const { saveIdUpload, isLoading } = useIdUpload();
  const urlQuery = new URLSearchParams(window.location.search);
  const query_uuid = urlQuery.get("uuid");
  const history = useHistory();
  const [viewReuploadBtn, setViewReuploadBtn] = useState(false);
  const [viewTakePhotoBtn, setViewTakePhotoBtn] = useState(true);
  const { skipLenderDetails } = useSkipLender();


  const handleUploadClick = (fileType) => {
   setErrorMessage('')
    setIsModalVisible({show :true , type: fileType});
  };

  const handleCloseModal = () => {
    setIsModalVisible({show: false , type: ''});
    setSelectedFileType(''); //remove selectedFileType value if doc not submitted
    setIsFileSelected(false);
    setUserfile({ preview: '', title: '', type: '' });
    setBase64Image('');
    setErrorMessage('');
    setErrorMessageUpload('');
    setProgressBar(false);
    setBtnLoader(false);
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setBase64Image(binaryString);
  };

  const skipIdPage = async () => {
    const type = 'Skip_idupload';
    const page_name = "PCP_HDSC_V5";
    const response = await skipLenderDetails(uuid, page_name, "Skip_idupload");
    history.push(`/thankyou-v5?uuid=${query_uuid.toString()}`);
  };

  const handleFileChange = (event) => {
    setProgressBar(true);
   const timer = setTimeout(()=>{
      setProgressBar(false);


    const imageFile = event.target.files[0];
    if (imageFile) {
      setErrorMessageUpload()
      setErrorMessage(''); // for clear error msg when user upload doc
      const extension = imageFile.type;
      const imgsize = imageFile.size;
      const allowedExtensions = ["image/jpeg", "image/png", "application/pdf"];
      if (!allowedExtensions.includes(extension)) {
        setIsInValidFile(true);
        setErrorMessage("The uploaded ID type should be an image in jpeg, png, or jpg" );
        return;
      } else if (imgsize > 1024 * 1024 * 10) {
        setIsInValidFile(true);
        setErrorMessage("Please choose a file less than 10MB");
        return;
      } else {
        setIsInValidFile(false);
        setIsFileSelected(true);
        setUserfile({ preview: URL.createObjectURL(imageFile), title: imageFile.name, type:imageFile.type });

        const reader = new FileReader();
        reader.onload = _handleReaderLoaded;
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsInValidFile(true);
      setUserfile({ preview: '', title: '',type:'' });
    }
    setProgressBar(false);
  },2000)
  };

  const handleSubmit = async (event) => {
    setBtnLoader(true);
    event.preventDefault();

    if (isFileSelected) {
    setErrorMessageUpload()
      const uploadData = {
        imageFile: base64Image,
        captureType: isModalVisible.type.split('_')[0],
        documentType: isModalVisible.type.split('_')[0],
      };

      const response = await saveIdUpload(
        uploadData,
        visitorParameters.visitor_parameters,
        formParameters,
        visitorParameters.data,
        '',
        'queryString',
        "user_docs_store"
      );
      // if (response.status == 200) {
        setSelectedFile('');
        setBase64Image('');
        // setSelectedFileType('');
        setIsModalVisible({show : false, type : ""});
        setErrorMessage('');
        setSelectedFileType(isModalVisible.type);
      // } else {
      //   setErrorMessage("Upload failed, please try again.");
      // }
    } else {
      setErrorMessageUpload("Please upload a file");
    }
    setBtnLoader(false);
  };

  const handleButtonClick = (e) => {
    if (e.target.name == 'photo') {
      document.getElementById(`${idType}_cam`).click();
    }
    if (e.target.name == 'image') {
      document.getElementById(`${idType}_doc`).click();
    }
  };

  const handleNextClick = () => {
    if (!isFileSelected) {
      setErrorMessage("Please upload a file");
      return false;
    } else {
      GTMDataLayer({
        question: 'verify identity',
        answer: null,
      });
      if (isCarRegRequired) {
        setPageIndex(2);
      } else {
        carRegNotRequiredFunction();
        history.push(`/thankyou-v5?${urlQuery.toString()}`);
      }
    }
  };

  //for handling retake option
  const retakeClick = (e) => {
    setUserfile(({ preview: '', title: '' ,type: ''}));
    setIsFileSelected(false);
    setSelectedFile('');
  };

  const handleDisable =(txt) =>{
    setSelectedFileType('');
    setIsFileSelected(false);
  }

  return (
    <>
      <GetVisitorsParams />
      <div className={`id-uploads ${pageIndex === 1 ? "" : "d-none"}`} id="IdUploads">
        <div className="animate__animated animate__fadeInLeft">
          <h1 className="title2">Now, let's verify your identity</h1>
          <div>
            <h6 className="upload-sigo mt-4 mb-lg-4 mb-md-4 mb-sm-4 mb-3">
              To expedite your claim and ensure its authenticity, please upload a valid ID as part of your PCP claim submission.
            </h6>

            {/* Upload Box */}
            <div className="upload-box p-3 mb-3 py-lg-4 py-md-3 py-sm-3 py-3 nation-card">
              <div className="d-flex align-items-center">
                <div>
                  <div className="icon-circle me-3">
                    <i className="bi bi-person-badge" />
                  </div>
                </div>
                <div id="upload">
                  <h3>National ID Card</h3>
                  {selectedFileType == 'NationalId_doc' ? (
                    <div className="click-upload p-1 px-2 pe-3 fileupload " >
                      <i className="bi bi-check-circle-fill me-1"></i>File Uploaded</div>) : (
                    <Button
                      className="click-upload p-1 px-3 clickupload-label"
                      id="NationalId"
                      name="idType"
                      onClick={() => handleUploadClick('NationalId_doc')}
                      buttonText="Click to upload"
                      disabled={selectedFileType && selectedFileType !== 'NationalId_doc'}
                    />
                  )}


                </div>
                <div className="forward-angle">
                  <span>
                    <button className='svg-x' disabled={selectedFileType && selectedFileType !== 'NationalId_doc'}  onClick={(e)=>handleDisable('NationalId_doc')}><img src={ selectedFileType =="NationalId_doc" ? xx : Arrowforward } alt="" /></button>
                  </span>
                </div>
              </div>
            </div>

            {/* Upload Box */}
            <div className="upload-box p-3 mb-3 py-lg-4 py-md-3 py-sm-3 py-3 license">
              <div className="d-flex align-items-center">
                <div>
                  <div className="icon-circle me-3">
                    <i className="bi bi-car-front-fill" />
                  </div>
                </div>
                <div id="upload">
                  <h3>Driver's License</h3>
                  {selectedFileType == 'DrivingLicense_doc' ? (
                    <div className="click-upload p-1 px-2 pe-3 fileupload " >
                      <i className="bi bi-check-circle-fill me-1"></i>File Uploaded</div>) : (
                    <Button
                      className="click-upload p-1 px-3 clickupload-label"
                      id="DrivingLicense"
                      name="idType"
                      onClick={() => handleUploadClick('DrivingLicense_doc')}
                      buttonText="Click to upload"
                      disabled={selectedFileType && selectedFileType !== 'DrivingLicense_doc'}
                    />
                  )}
                </div>
                <div className="forward-angle">
                <span>
                    <button className='svg-x' disabled={selectedFileType && selectedFileType !== 'DrivingLicense_doc'}  onClick={(e)=>handleDisable(e)}><img src={ selectedFileType =="DrivingLicense_doc" ? xx : Arrowforward } alt="" /></button>
                  </span>
                </div>
              </div>
            </div>

            {/* Upload Box */}
            <div className="upload-box p-3 mb-3 py-lg-4 py-md-3 py-sm-3 py-3 passport">
              <div className="d-flex align-items-center">
                <div>
                  <div className="icon-circle me-3">
                    <i className="bi bi-airplane" />
                  </div>
                </div>
                <div id="upload">
                  <h3>Passport</h3>
                  {selectedFileType == 'Passport_doc' ? (
                    <div className="click-upload p-1 px-2 pe-3 fileupload " >
                      <i className="bi bi-check-circle-fill me-1"></i>File Uploaded</div>) : (
                    <Button
                      className="click-upload p-1 px-3 clickupload-label"
                      id="Passport"
                      name="idType"
                      onClick={() => handleUploadClick('Passport_doc')}
                      buttonText="Click to upload"
                      disabled={selectedFileType && selectedFileType !== 'Passport_doc'}
                    />
                  )}
                </div>
                <div className="forward-angle">
                <span>
                    <button className='svg-x' disabled={selectedFileType && selectedFileType !== 'Passport_doc'}  onClick={(e)=>handleDisable(e)}><img src={ selectedFileType =="Passport_doc" ? xx : Arrowforward } alt="" /></button>
                  </span>
                </div>
              </div>
            </div>
            <div className="row mt-lg-4 mt-md-4 mt-sm-4 mt-5">
              {(errorMessage && isModalVisible.show == false) && (
                <div
                  className="validation-error mt-3 text-center"
                  style={{
                    background: "red",
                    borderRadius: "4px",
                    width: "100%",
                    marginLeft: "0px",
                    marginTop: "5px",
                    padding: "5px",
                    color: "white",
                  }}
                >
                  {errorMessage}
                </div>
              )}
              <div className="col-8">

                <Button
                  className="com-button p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-2 fw-bold"
                  id=""
                  type="button"
                  onClick={handleNextClick}
                  buttonText="Next"
                />

              </div>

            </div>
          </div>
        </div>
        <h2 className="mt-4 mb-lg-4 mb-md-4 mb-sm-4 mb-3 d-flex mb-2 upload-sigo">
          <div className="me-2">
            <img height={45} src={Security} alt="" />
          </div>
          <div>
            <h6>
              Your ID will be stored securely and used solely for the purpose of identity verification.
            </h6>
          </div>
        </h2>
      </div>

      {/* Modal */}

        <div
          className={`modal fade${isModalVisible.show ? ' show d-block' : ''}`}
          id="uploadModal"
          tabIndex={-1}
          aria-labelledby="uploadModalLabel"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}

        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body file-upload-body">
                <h2 className="d-flex title pt-2">
                  Upload your files
                  <div onClick={handleCloseModal} className="close-btn" data-bs-dismiss="modal">
                    <i className="bi bi-x-circle" />
                  </div>
                </h2>
                {/* Uploads Box */}
                <div className="file-uploads-box text-center p-3 my-3">
                  <div className="choosed-filebody" style={{ display: "none" }} >
                    {/* <img src={Upload_demo} alt="Upload Demo" /> */}
                  </div>
                  {isFileSelected ? (
                    <div className="uploaded" >
                      <img
                        className="img-fluid"
                        style={{ maxWidth: '100%', height: '190px' }}
                        // src={userfile.preview}
                        src={userfile.type === 'application/pdf' ? pdf_logo : userfile.preview }

                        alt={userfile.title}
                      />
                      {/* for dispaly pdf file name  */}
                      {userfile.type === 'application/pdf' ? (<p>{userfile.title}</p>):('')}
                    </div>) : (
                    <div className="choose-filebody">
                      <div>
                        <i className="bi bi-cloud-arrow-up" />
                      </div>
                      <div className="file-text">JPG, PNG, or PDF, smaller than 10MB</div>
                      <div className="my-3 choose-items">
                        <div className='choose-filebody'>
                          <input type="file"
                            accept=".jpg,.jpeg,.png,.pdf"  // Accept only jpg, png, and pdf files
                            className="iput-file"
                            id={`${idType}_doc`}
                            name={`${idType}_doc`}
                            onChange={handleFileChange} />
                          <span className='choose-file p-2 px-3'>Choose File</span>
                        </div>
                      </div>
                      {progressbar && <div
                        className="text-center imgloader"
                      >
                        <div
                          className="progress"
                          role="progressbar"
                          aria-label="Animated striped example"
                          aria-valuenow={75}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        >
                          <div
                            className="progress-bar progress-bar-striped progress-bar-animated bg-warning"
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>}
                    </div>)}
                </div>
                <div className="row">
                  {!isFileSelected ? (
                    <div className="col-6 pe-0">
                      <Button
                        className="com-button px-1 takephoto w-100 skipbtn"
                        id=""
                        style={{ display: "none" }}
                        type="button"
                        onClick={(e) => handleButtonClick(e)}
                        name="photo"
                        buttonText={
                          <>
                            <span style={{ pointerEvents: 'none' }}>
                              <i className="bi bi-camera-fill me-2 ms-0" /> Take Photo{" "}
                              <input
                                type="file"
                                className="iput-file d-none"
                                accept=".jpg,.jpeg,.png,.pdf"  // Accept only jpg, png, and pdf files
                                id={`${idType}_cam`}
                                name={`${idType}_cam`}
                                onChange={handleFileChange}
                              />

                            </span>
                          </>
                        }
                      />
                    </div>
                  ) : (
                    <div className="col-6 pe-0">
                      <Button
                        className="com-button px-1 takephoto reupload w-100 skipbtn"
                        onClick={(e) => retakeClick(e)}
                        name="image"
                        buttonText={
                          <>
                            <i className="bi bi-arrow-repeat" />
                            &nbsp; Re Upload
                          </>
                        }
                      />
                    </div>
                  )}
                  <div className="col-6">
                    <Button
                      type="button"
                      id="fileuplSubmit"
                      className="com-button w-100"
                      onClick={(e) => (!btnLoader ? handleSubmit(e) : null)}
                      buttonText={
                        <>
                          <span className="">
                            {btnLoader ? (
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                              "Submit"
                            )}
                          </span>
                        </>
                      }
                    />
                  </div>
                  {errorMessageUpload && (
                    <div
                      className="validation-error mt-3 text-center"
                      style={{
                        background: "red",
                        borderRadius: "4px",
                        width: "100%",
                        marginLeft: "0px",
                        marginTop: "5px",
                        padding: "5px",
                        color: "white",
                      }}
                    >
                      {errorMessageUpload}
                    </div>
                  )}
                  {isInValidFile && (
                    <div
                      className="validation-error mt-3 text-center"
                      style={{
                        background: "red",
                        borderRadius: "4px",
                        width: "100%",
                        marginLeft: "0px",
                        marginTop: "5px",
                        padding: "5px",
                        color: "white",
                      }}
                    >
                      {errorMessage}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default IdUploadV5;
