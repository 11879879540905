import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import "../../../assets/PCP_HDSC_V3/css/questionnaire.scss"
import Header from "../../Includes/Layouts/PCP_HDSC_V3/Header";
import Footer from "../../Includes/Layouts/PCP_HDSC_V3/Footer";
import { questionnaire } from "../../../Constants/QuestionV3"
import BasicQuestionnaireOne from "../../Includes/Layouts/PCP_HDSC_V3/BasicQuestionnaireOne";
import { useForm } from "react-hook-form";
import Button from "../../UI/Button";
import sademoji from "../../../assets/PCP_HDSC_V3/img/sademoji.png"
import { useHistory } from "react-router-dom";
import GetVisitorsParams from "../../../Utility/GetVisitorsParams";
import { CheckUUID } from "../../../Utility/CheckUUID";
import QueryStringContext from "../../../Contexts/QueryString";
import VisitorParamsContext from "../../../../src/Contexts/VisitorParams";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";
import { useQuestionnairev1 } from "../../../Hooks/useQuestionnairev1";
import "../../../assets/PCP_HDSC_V3/css/carrrgistrationV3.scss";
import { Finance } from "../../../Constants/ConstantsPCP";
import { useCarRegistrationSkipAll } from "../../../Hooks/useCarRegistrationSkipAll";
const QuestionnaireV3 = () => {
    const initialState = {
        showSlide1: "show",
        showSlide2: "hide",
        showSlide3: "hide",
        showSlide4: "hide",
    };
    const FormReducer = (state, action) => {
        switch (action.type) {
            case "showSlide": {
                if (action.payload.clickedSlide.slide === "question_1") {
                    return {
                        ...state,
                        showSlide1: "hide",
                        showSlide2: "show",
                    };
                } else if (action.payload.clickedSlide.slide === "question_2") {
                    return {
                        ...state,
                        showSlide2: "hide",
                        showSlide3: "show",
                    };
                } else if (action.payload.clickedSlide.slide === "question_3") {
                    return {
                        ...state,
                        showSlide3: "hide",
                        showSlide4: "show",
                    };
                } else if (action.payload.clickedSlide.slide === "question_4") {
                    return {
                        ...state,
                        showSlide4: "hide",
                    };
                } else {
                    return {
                        ...state,
                    };
                }
            }
            case 'previousSlides': {
                if (action.payload.previousSlide.slide == 'back2') {
                    return {
                        ...state,
                        showSlide1: "show",
                        showSlide2: "hide"
                    }
                } else if (action.payload.previousSlide.slide == 'back3') {
                    return {
                        ...state,
                        showSlide2: "show",
                        showSlide3: "hide"
                    }
                } else if (action.payload.previousSlide.slide == 'back4') {
                    return {
                        ...state,
                        showSlide3: "show",
                        showSlide4: "hide"
                    }
                } else {
                    return {
                        ...state
                    }
                }
            }
            default: {
            }
        }
    };
    const scrollfrmv2div = useRef([]);
    const [state, dispatch] = useReducer(FormReducer, initialState);
    const [clickedSlide, setClickedSlide] = useState([]);
    const [previousSlide, setPreviousSlide] = useState([]);
    const [modelContent, setModalContent] = useState(false);
    const { queryString } = useContext(QueryStringContext);
    const { visitorParameters } = useContext(VisitorParamsContext);
    const [errorMessage, setErrorMessage] = useState("");
    const query = new URLSearchParams(window.location.search);
    const { storeSkipallValues } = useCarRegistrationSkipAll();
    const uuid = visitorParameters.visitor_parameters.uuid ?? localStorage.getItem("uuid");
    const history = useHistory();
    const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit(
        "PCP_HDSC_V3",
        "questionnaire-v3"
    );
    const { SaveQuestionnaire } = useQuestionnairev1()
    const { isCheck } = CheckUUID();
    isCheck();
    const { register, errors, trigger, setError, clearErrors, getValues } =
        useForm({
            mode: "onBlur",
            reValidateMode: "onChange",
            defaultValues: {},
            resolver: undefined,
            context: undefined,
            criteriaMode: "firstError",
            shouldFocusError: true,
            shouldUnregister: true,
        });

    const slugs = JSON.parse(localStorage.getItem('finaceProviders'));
    const idRequired = slugs.find((slug) =>
        Finance.some((financeItem) => financeItem.slug === slug && financeItem.id_required === true)
    );
    const carRegRequired = slugs.find((slug) =>
        Finance.some((financeItem) => financeItem.slug === slug && financeItem.car_reg === true)
    );

    const carRegNotRequiredFunction = async () => {
        const slugs = JSON.parse(localStorage.getItem("finaceProviders"));
        const carRegNotRequiredValues = slugs.flatMap((slug) =>
            Finance
                .filter(
                    (financeItem) =>
                        financeItem.slug === slug && (financeItem.car_reg === false || !('car_reg' in financeItem))
                )
                .map((financeItem) => financeItem.value)
        );

        if (carRegNotRequiredValues.length !== 0) {
            storeSkipallValues(
                visitorParameters.visitor_parameters,
                "car_registration",
                "store-skip-all-vehicle-details",
                carRegNotRequiredValues
            );
        }
    }

    const handleClick = (e) => {
        // setTimeout(() => {
        //     scrollfrmv2div.current.scrollIntoView({
        //         behavior: "smooth",
        //         top: 0
        //     });
        // }, 50);
        window.scrollTo(0, 0);
        const values = getValues();
        const formData = values;
        if (e.target.value == 2 || e.target.value == 4 || e.target.value == 6) {
            return;
        }
        var question_id = e.target.dataset.question_id;
        var optionId = e.target.value;
        let question_data = {
            question_id: question_id,
            option_id: optionId,
            answer_text: '',
            input_answer: '',
            is_qualified: '',
        };
        if (!optionId) {
            setErrorMessage("Please select an option.");
        }
        if (optionId && question_id) {
            storeQuestionnire(question_data);

            setClickedSlide({ slide: e.target.name });
            if (e.target.name == 'question_4') {
                if (carRegRequired) {
                    history.push("/" + DynamicRouteNextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&split_name=" + DynamicRouteSplitName);
                } else if (idRequired) {
                    carRegNotRequiredFunction();
                    history.push(`/id-upload-v3?${query.toString()}`);
                } else {
                    carRegNotRequiredFunction();
                    history.push(`/thankyou?${query.toString()}`);
                }
            }
        }

    };
    useEffect(() => {
        if (clickedSlide) {
            dispatch({ type: "showSlide", payload: { clickedSlide } });
        }
    }, [clickedSlide]);

    useEffect(() => {
        if (previousSlide) {
            dispatch({ type: 'previousSlides', payload: { previousSlide } });
        }
    }, [previousSlide]);

    const previousSlideChange = (e) => {
        var previous_slide = e.target.name;
        console.log(e.target);
        setPreviousSlide({ slide: previous_slide });
    };

    const formdata = {
        page_name: "PCP_HDSC_V3",
    };
    const storeQuestionnire = async (question_data) => {
        const response = await SaveQuestionnaire(
            visitorParameters.visitor_parameters,
            question_data,
            "question_store",
            visitorParameters.data,
            queryString,
            formdata
        );
    };



    return (
        <>
            <GetVisitorsParams />
            <div className="carregistrationV3">
                <div className="questionnaire-v3">
                    <Header />
                    <section className="bg-gray  p-2   vehiclenumber  ">
                        <div className="bg-inner vehicle-srtop  rounded-2 p-lg-5 p-md-5 p-sm-4 p-4 ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 offset-lg-3">
                                        <form>
                                            <div className="slider hender-quest-wraper" ref={scrollfrmv2div}>
                                                {/* Slide */}
                                                <BasicQuestionnaireOne
                                                    className={`qsslide1 ${state.showSlide1}`}
                                                    validation={register}
                                                    // slideChange={slideChange}
                                                    getValues={getValues}
                                                    gtm_question={questionnaire.question_1}
                                                    buttonName='question_1'
                                                    handleClick={handleClick}
                                                    setModalContent={setModalContent}
                                                    previousSlide={previousSlideChange}
                                                    backBtn={false}
                                                    errorMessage={errorMessage}
                                                    setErrorMessage={setErrorMessage}
                                                />
                                                <BasicQuestionnaireOne
                                                    className={`qsslide2 ${state.showSlide2}`}
                                                    validation={register}
                                                    // slideChange={slideChange}
                                                    getValues={getValues}
                                                    gtm_question={questionnaire.question_2}
                                                    buttonName='question_2'
                                                    handleClick={handleClick}
                                                    previousSlide={previousSlideChange}
                                                    backBtn={true}
                                                    errorMessage={errorMessage}
                                                    setErrorMessage={setErrorMessage}
                                                    setModalContent={setModalContent}
                                                />
                                                <BasicQuestionnaireOne
                                                    className={`qsslide3 ${state.showSlide3}`}
                                                    validation={register}
                                                    // slideChange={slideChange}
                                                    getValues={getValues}
                                                    gtm_question={questionnaire.question_3}
                                                    buttonName='question_3'
                                                    handleClick={handleClick}
                                                    previousSlide={previousSlideChange}
                                                    backBtn={true}
                                                    errorMessage={errorMessage}
                                                    setErrorMessage={setErrorMessage}
                                                    setModalContent={setModalContent}
                                                />
                                                <BasicQuestionnaireOne
                                                    className={`qsslide4  ${state.showSlide4}`}
                                                    validation={register}
                                                    // slideChange={slideChange}
                                                    getValues={getValues}
                                                    gtm_question={questionnaire.question_4}
                                                    buttonName='question_4'
                                                    handleClick={handleClick}
                                                    previousSlide={previousSlideChange}
                                                    backBtn={true}
                                                    errorMessage={errorMessage}
                                                    setErrorMessage={setErrorMessage}
                                                />
                                                {/* Slide */}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />

                    {modelContent && <div
                        className="modal fade show background-clr "
                        id="modalErro"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabIndex={-1}
                        aria-labelledby="staticBackdropLabel"
                        style={{ display: "block" }}
                        aria-modal="true"
                        role="dialog"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-sm ">
                            <div className="modal-content modal-quest">
                                <div className="modal-body ">
                                    <div className="text-center">
                                        <img src={sademoji} height={60} alt="" />
                                    </div>
                                    <h2 className="text-center textmsg pt-3">
                                        Sorry, we will not be able to help with this claim.
                                    </h2>
                                </div>
                                <div className="text-center pb-4 ">
                                    <Button
                                        type="button"
                                        className="btn btn-secondary pop-close py-2 fs-4 btn-quest"
                                        data-bs-dismiss="modal"
                                        onClick={() => { setModalContent(false) }}
                                        buttonText="OK"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}

                </div>
            </div>
        </>
    )
}
export default QuestionnaireV3;