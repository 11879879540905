import React, { useRef, useState, useReducer, useContext, useEffect } from "react";
import "../../../../assets/PCP_HDSC_V5/css/signature.scss"
import "../../../../assets/PCP_HDSC_V5/css/car-registration.scss"
import banner_img2 from "../../../../assets/PCP_HDSC_V5/img/banner-img2.png"
import Header from '../../../Includes/Layouts/PCP_HDSC_V5/Header'
import Footer from '../../../Includes/Layouts/PCP_HDSC_V5/Footer'
import CarRegistrationV5 from './CarRegistrationV5'
import IdUploadV5 from './IdUploadV5'
import PageLoader from '../../../Includes/Layouts/PCP_HDSC_V5/PageLoader'
import InputButton from '../../../UI/InputButton'
import SignatureCanvas from "react-signature-canvas";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { CheckUUID } from "../../../../Utility/CheckUUID";
import { queryString } from "../../../../Utility/QueryString";
import { useSignature } from "../../../../Hooks/useSignature";
import GetVisitorsParams from "../../../../Utility/GetVisitorsParams";
import { BrowserView, MobileView, deviceType } from "react-device-detect";
import { Api } from "../../../../api/Api";
import pre_contract from "../../../../../src/docs/Hendersons - Pre-Contract Info.80eecbd0.pdf"
import Terms_and_Conditions from "../../../../../src/docs/Hendersons - Terms & Conditions.4dcc0780.pdf"
import { Finance } from "../../../../Constants/ConstantsPCP";
import GTMDataLayer from "../../../../Utility/GTMDataLayer";
import { useHistory } from "react-router-dom";
import { useCarRegistrationSkipAll } from "../../../../Hooks/useCarRegistrationSkipAll";

const initialState = {
  isChecked: false,
  isAgreed: false,
  isCanvasEmpty: false,
  isValid: false,
};

const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    case "privacyAgreed": {
      return {
        ...state,
        isAgreed: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};
const SignatureV5 = () => {
  const [pageIndex, setPageIndex] = useState(0)
  const [pageLoader, setPageLoader] = useState(false);
  const { isCheck } = CheckUUID();
  isCheck();
  const focusRef = useRef(null);
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const signPadRef = useRef();
  const storedFormData = localStorage.getItem('formData');
  const { saveSignature } = useSignature();
  const [isChecked, setIsChecked] = useState(true);
  const message_type = "signature_store";
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [btnLoader, setBtnLoader] = useState(false);
  const query = new URLSearchParams(window.location.search);
  const query_uuid = query.get("uuid");
  const queryString = "";
  const [selectedlenders, setSelectedLenders] = useState([]);
  const history = useHistory();
  const { storeSkipallValues } = useCarRegistrationSkipAll();

  const handleClear = () => {
    const signPad = signPadRef.current;
    signPad.clear();
  };

  useEffect(() => {
    const fetchData = async () => {
      let localStorageValue = localStorage.getItem("selectedBanks");
      let lendersArray = JSON.parse(localStorageValue);

      if (lendersArray) {
        const filteredLocalStorageData = lendersArray.filter(item => item.car_reg === true);
        setSelectedLenders(filteredLocalStorageData);
      } else {
        const responseSlug = await Api.get(
          "v1/get_finanace_slug_details?uuid=" + query_uuid
        );

        // converting the data to required format
        const processedData = responseSlug.data.map(item => {
          const matchedFinance = Finance.find(finance => finance.value === item.id.toString());

          // Convert purchaseDate to "D MMMM YYYY" format
          let purchaseDate = null;
          if (item.purchaseDate === '0000-00-00') {
            purchaseDate = 'other';
          } else if (item.purchaseDate === null) {
            purchaseDate = null;
          } else if (item.purchaseDate !== '0000-00-00') {
            const date = new Date(item.purchaseDate);
            purchaseDate = date.toLocaleDateString('en-GB', {
              day: 'numeric',
              month: 'long',
              year: 'numeric'
            });
          }

          return {
            ...item,
            value: item.id.toString(),
            label: matchedFinance ? matchedFinance.label : item.label,
            is_doc_required: item.is_doc_required === 1,
            purchaseDate: purchaseDate,
            car_reg: matchedFinance.car_reg ? matchedFinance.car_reg : null,
          };
        });

        // Filter the processed data based on car_reg
        const filteredProcessedData = processedData.filter(item => {
          const matchedFinance = Finance.find(finance => finance.value === item.value);
          return matchedFinance && matchedFinance.car_reg;
        });

        localStorage.setItem("selectedBanks", JSON.stringify(processedData));
        setSelectedLenders(filteredProcessedData);
      }
    };

    fetchData();
  }, [query_uuid]);

  const isCarRegRequired = selectedlenders.some(
    (lender) => lender.car_reg === true
  );

  const carRegNotRequiredFunction = async () => {
    const slugs = JSON.parse(localStorage.getItem("selectedBanks"));
    const carRegNotRequiredValues = slugs
      .filter((item) => item.car_reg === false || item.car_reg === null)
      .map((item) => item.value);

      console.log(carRegNotRequiredValues);
    if (carRegNotRequiredValues.length !== 0) {
      storeSkipallValues(
        visitorParameters.visitor_parameters,
        "car_registration",
        "store-skip-all-vehicle-details",
        carRegNotRequiredValues
      );
    }
  }

  const pageForward = async (buttonClicked) => {
    try {
      const signPad = signPadRef.current;
      if (signPad.isEmpty()) {
        focusRef.current.focus();
        return dispatch({ type: "signPadChange", payload: { value: true } });
      }
      if (!isChecked) {
        focusRef.current.focus();
        return dispatch({ type: "privacyAgreed", payload: { value: true } });
      }

      const rpdata = signPad.toData();
      let signLength = 0;
      for (let i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }

      if (signLength > 13) {
        setBtnLoader(true)
        GTMDataLayer({
          question: 'signature',
          answer: null,
        });
        const response = await saveSignature(
          signPad.getCanvas().toDataURL("image/png"),
          visitorParameters.visitor_parameters,
          storedFormData,
          visitorParameters.data,
          queryString,
          message_type
        );
        if (buttonClicked === "nextSign") {
          const isIdRequired = selectedlenders.find(
            (lender) => lender.is_doc_required === true || lender.is_doc_required === 1
          );

          setPageLoader(true);
          if (isIdRequired) {
            setTimeout(() => {
              setPageIndex(1);
              window.scrollTo(0, 0);
              setPageLoader(false);
            }, 2000);
          } else {
            if (isCarRegRequired) {
              setTimeout(() => {
                setPageIndex(2);
                window.scrollTo(0, 0);
                setPageLoader(false);
              }, 2000);
            } else {
              carRegNotRequiredFunction();
              history.push(`/thankyou-v5?${query.toString()}`);
            }
          }
        }
        if (response.data.status === "Success") {
          setBtnLoader(false)
        }
      } else {
        focusRef.current.focus();
        dispatch({ type: "validSignature", payload: { value: true } });
      }
    } catch (error) {
      setBtnLoader(false)
      console.error("Error submitting signature:", error);
    }
  };

  return (
    <>

      <div className="PCP_HDSC_V5">
        <div className="__PCPtcg">
          <div className="bg_lol">
            {/*TOP NAVIGATION*/}
            <Header />
            <section className="cs_hero cs_style_1 sec_design section_design1">
              <div className="cs_hero_wrap cs_bg_filed">
                {/*Gradient*/}
                <div className="container-md">
                  <form id="form" onSubmit={(e) => e.preventDefault()}>
                    <div className={`home_plate_002 signature-section animate__animated animate__fadeInLeft ${pageIndex === 0 ? "" : "d-none"}`}>
                      <div className="row ">
                        <div className="col-lg-8 offset-lg-2 ">
                          <div className="text-center">
                            <h1 className="fw-bold ">Your signature</h1>
                            <p>Keep it fully contained within the box below</p>
                          </div>
                          <div className="sign-sec p-3">
                            <div className="sign-note pb-0">
                              <div className="text-start signature-footer p-2">
                                <p className="non_check m-0 mt-3">
                                  In order to be able to submit a claim we need just need your signature. By signing below, you are giving Henderson Claims Limited T/A Hendersons authority to act on your behalf and to attach your signature to these <a href={`/preview?uuid=${query_uuid}`} target="_blank">documents</a> and agree to their <a href={Terms_and_Conditions} target="_blank" className="underline-text">terms and conditions</a> and <a href={pre_contract} target="_blank" className="underline-text">pre-contract summary document</a>.
                                </p>
                                {/* <div className="sign-below">Sign below</div> */}
                              </div>

                            </div>
                            <div className="signbox" id="signBox">
                              {(deviceType === "mobile" || deviceType === "tablet") && (
                                <MobileView>
                                  <SignatureCanvas
                                    canvasProps={{
                                      className: "writing-line",
                                      id: "canvas_signature",
                                    }}
                                    clearOnResize={false}
                                    ref={signPadRef}
                                    onEnd={() => {
                                      if (!signPadRef.current.isEmpty()) {
                                        dispatch({
                                          type: "signPadChange",
                                          payload: { value: false },
                                        });
                                        dispatch({
                                          type: "validSignature",
                                          payload: { value: false },
                                        });

                                      }
                                    }}
                                  />
                                </MobileView>
                              )}

                              {deviceType === "browser" && (
                                <BrowserView>
                                  <SignatureCanvas
                                    canvasProps={{
                                      className: "writing-line",
                                      id: "canvas_signature",
                                    }}
                                    clearOnResize={false}
                                    ref={signPadRef}
                                    onEnd={() => {
                                      if (!signPadRef.current.isEmpty()) {
                                        dispatch({
                                          type: "signPadChange",
                                          payload: { value: false },
                                        });
                                        dispatch({
                                          type: "validSignature",
                                          payload: { value: false },
                                        });

                                      }
                                    }}
                                  />

                                </BrowserView>
                              )}
                            </div>
                            {state.isCanvasEmpty && (
                              <div className="error_msg col-12 text-center sign_msg signature-validation" id="signatures_required">
                                Signature is required!
                              </div>
                            )}
                            {state.isValid && (
                              <div className="error_msg col-12 text-center sign_msg signature-validation" id="signatures_valid">
                                Draw valid signature!
                              </div>
                            )}
                            <InputButton
                              name="clear"
                              className="clearbutton"
                              id="clear"
                              value="Clear"
                              btnType="button"
                              onClick={handleClear}
                            />
                            <div className="text-start signature-footer p-2">
                              <p className="non_check m-0 mt-3">
                                By clicking <b>'Submit To Claim'</b> I agree to a soft credit check being carried out to verify information relating to my claim. I understand this will NOT affect my credit score.
                              </p>
                            </div>
                            <span ref={focusRef} tabIndex={0}></span>
                            {/* <div className="text-start signature-footer p-2">
                              <p>
                                {" "}
                                Click here to view the{" "}
                                <a href="">terms &amp; conditions</a>
                              </p>
                              <p className="mb-2">
                                I agree that I have read, understand and accept the
                                Courmacs Legal Limited terms of business and that the
                                check is free. I also agree to instruct Courmacs Legal
                                Limited to send a data subject access request (DSAR)
                                to find out if my PCP or HP agreements were mis-sold
                              </p>
                              <p>
                                By clicking <b>'Submit &amp; complete'</b> you agree
                                that Fountain Finances will carry out a soft credit
                                check to validate your case and verify your identity.
                                I understand this check will NOT affect my credit
                                score
                              </p>
                            </div> */}
                          </div>
                          <div
                            className=" is_sticky box_button mb-5 sign_Btn_paGe pt-4 animate__animated animate__fadeInUp animate__delay-1s animated-element wht_iFNot"
                            id="subRegNo1"
                          >
                            <div className="bg-1 hme_btn text-center">
                              <InputButton
                                className="button button--moema button--text-thick button--text-upper button--size-s blick_notice"
                                id="nextSign"
                                value="Submit To Claim"
                                btnType="button"
                                onClick={() => pageForward("nextSign")}
                                name="subRegNo1"
                                aria-label="submitid"
                              />

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*  Car Registration */}
                    <div className={`car-section ${pageIndex === 2 ? "" : "d-none"}`}>
                      <div className="row">
                        <div className="col-xl-6 col-lg-8 col-md-12  8 car-regi-middle mb-5">
                          {/* Selcted Lenders rection removed as told */}

                          <CarRegistrationV5
                            pageIndex={pageIndex}
                            pageForward={pageForward}
                            selectedlenders={selectedlenders}
                            setSelectedLenders={setSelectedLenders}
                            setPageIndex={setPageIndex}
                            carRegNotRequiredFunction={carRegNotRequiredFunction}
                          />
                        </div>
                        <div className="col-xl-6 col-lg-4 col-md-8 d-lg-block d-md-none d-sm-none d-none">
                          <div className="mt-lg-4 ">
                            <img
                              src={banner_img2}
                              alt="Hero"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`car-section ${pageIndex === 1 ? "" : "d-none"}`}>
                      <div className="row">
                        <div className="col-xl-6 col-lg-8 col-md-12  8 car-regi-middle mb-5">
                          {/* Selcted Lenders rection removed as told */}
                          <IdUploadV5
                            pageIndex={pageIndex}
                            pageForward={pageForward}
                            setPageIndex={setPageIndex}
                            isCarRegRequired={isCarRegRequired}
                            carRegNotRequiredFunction={carRegNotRequiredFunction}
                          />
                        </div>
                        <div className="col-xl-6 col-lg-4 col-md-8 d-lg-block d-md-none d-sm-none d-none">
                          <div className="mt-lg-4 ">
                            <img
                              src={banner_img2}
                              alt="Hero"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
          <div className={`confetti-pop ${`d-none`}`}>
            <canvas id="congratulations_confetti" />
          </div>
          <Footer />
        </div>
        <PageLoader
          pageLoader={pageLoader}
          analysingText={pageIndex === 1 ? "Analysing your vehicle details...." : ""}
        />
      </div>
    </>
  )
}

export default SignatureV5