import React, { lazy } from 'react';

const Split_1 = lazy(() => import('./Components/Pages/Splits/Split_1'));
const PCP_HDSC_V1 = lazy(() => import('./Components/Pages/PCP_HDSC_V1/PCP_HDSC_V1'));
const PCP_HDSC_V5 = lazy(() => import('./Components/Pages/PCP_HDSC_V5/PCP_HDSC_V5'));

const AppSplitList = (props) => {

    const SplitListMap = {
        'Split_1': Split_1,
        'PCP_HDSC_V1': PCP_HDSC_V1,
        'PCP_HDSC_V5': PCP_HDSC_V5,
    }
    const SplitComponent = SplitListMap[props.splitName];
    return (
        <>
            {SplitComponent && <SplitComponent />}
        </>
    )

}

export default AppSplitList;