import React, { useState } from 'react';
import { Salutation } from "../../../../Constants/Constants";
import SelectBox from '../../../UI/SelectBox';
import logo from "../../../../assets/PCP_HDSC_V5/img/logo.png";
import TextField from '../../../UI/TextField';
import info from "../../../../assets/PCP_HDSC_V5/img/info.png";
import { nameValidate } from "../../../../Utility/Validations";
import Button from '../../../UI/Button';
import GTMDataLayer from "../../../../Utility/GTMDataLayer";

const PersonalDetails = (props) => {
    const [checkboxAgrement, setCheckboxAgrement] = useState(true);
    const [checkboxAgrementValidation, setCheckboxAgrementValidation] = useState(false);

    const handleValidation = async () => {
        const isTitleValid = await props.trigger('lstSalutation');
        if (!isTitleValid) return false;

        const isFirstNameValid = await props.trigger('txtFName');
        if (!isFirstNameValid) return false;

        const isLastNameValid = await props.trigger('txtLName');
        return isLastNameValid;
    };

    const handleSubmit = async () => {
        const isValid = await handleValidation();
        if (!checkboxAgrement) {
            setCheckboxAgrementValidation(true);
            return;
        }
        if (isValid) {
            GTMDataLayer({
                question: 'name',
                answer: null,
              });
            props.setDobModal(true);
            props.setPersonalDetailsModal(false);
            props.setCurrentPage("QuestionnaireSlide");
        } else {
        }
    };

    const handleKeyPress = (event) => {
        const invalidChars = /[0-9@]/;
        if (invalidChars.test(event.key)) {
            event.preventDefault();
        }
    };

    return (
        <div>
            <div
                className={`modal ${props.personalDetailsModal ? 'show' : 'hide'}`}
                id="persDetails"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content animate__animated animate__zoomIn animate__delay-.5s ">
                        <div className="modal-body">
                            <div className="text-center mb-3">
                                <img height={40} src={logo} alt="" />
                            </div>
                            <div className="form_drop col-lg-12 col-12">
                                <p className="sty_pop">
                                    Please confirm your legal name as it appears is the lender(s) system.
                                </p>
                                <label htmlFor="format2">Title</label>
                                <div className="select_title animate__animated animate__fadeInUp animate__delay-.5s">
                                    <SelectBox
                                        className="form-select form-control"
                                        OptionValue={Salutation}
                                        onChange={() => props.clearErrors("lstSalutation")}
                                        name="lstSalutation"
                                        id="lstSalutation"
                                        myRef={props.validation({
                                            required: "Please Select Title",
                                        })}
                                    />
                                </div>
                                <span className="error_msg">
                                    {props.validationMsg.lstSalutation?.message}
                                </span>
                                <div className="row name_detail animate__animated animate__fadeInUp animate__delay-.5s">
                                    <div className="col-lg-6 col-md-6 col-12 f_name">
                                        <label htmlFor="topmention1">First Name</label>
                                        <TextField
                                            type="text"
                                            className="form-control"
                                            placeholder="First Name"
                                            name="txtFName"
                                            onChange={() => props.clearErrors("txtFName")}
                                            id="txtFName"
                                            autoComplete="off"
                                            onkeypress={(e) => nameValidate(e)}
                                            validation={props.validation({
                                                required: "Please Enter First Name",
                                                minLength: {
                                                    value: 3,
                                                    message: "Please Enter Valid First Name",
                                                },
                                                pattern: {
                                                    value: /^[a-zA-Z\s]+$/,
                                                    message: "Your Name is Invalid",
                                                },
                                            })}
                                            validationMsg={props.validationMsg.txtFName?.message}
                                        />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12 l_name">
                                        <label htmlFor="topmention2">Last Name</label>
                                        <TextField
                                            type="text"
                                            className="form-control"
                                            placeholder="Last Name"
                                            name="txtLName"
                                            id="txtLName"
                                            onChange={() => props.clearErrors("txtLName")}
                                            autoComplete="off"
                                            onkeypress={(e) => nameValidate(e)}
                                            validation={props.validation({
                                                required: "Please Enter Last Name",
                                                minLength: {
                                                    value: 3,
                                                    message: "Please Enter Valid Last Name",
                                                },
                                                pattern: {
                                                    value: /^[a-zA-Z\s]+$/,
                                                    message: "Your Name is Invalid",
                                                },
                                            })}
                                            validationMsg={props.validationMsg.txtLName?.message}
                                        />
                                    </div>
                                    <p className="tiny_note">
                                        <img
                                            src={info}
                                            className="pe-1"
                                            alt=""
                                        />
                                        What you are legally known as
                                    </p>
                                    <div style={{ display: "inline-block", fontSize: "12px", cursor: "pointer" }} className="checkbox-label">
                                        <label htmlFor="checkboxAgrement">
                                            <input
                                                type="checkbox"
                                                id="checkboxAgrement"
                                                checked={checkboxAgrement}
                                                onChange={() => {
                                                    setCheckboxAgrement(!checkboxAgrement)
                                                    setCheckboxAgrementValidation(false);
                                                }}
                                            />{` `}
                                            I confirm that I am not currently involved in an IVA, bankruptcy, or any other debt management arrangement.
                                        </label>
                                    </div>
                                    {checkboxAgrementValidation &&
                                        <span className="error_msg">
                                            Agree to this to continue
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s">
                            <div className="bg-1">

                        <Button
                        type="button"
                        className="button w-100 button--moema button--text-thick button--text-upper button--size-s h60_click blick_notice"
                        buttonText={
                          <span>Next</span>
                        }
                        id="nextPlate_005"
                        onClick={handleSubmit}
                      />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PersonalDetails;
