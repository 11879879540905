import React from "react";
import info from "../../../../assets/PCP_HDSC_V1/img/info.png";
import nextgreen from "../../../../assets/PCP_HDSC_V1/img/next-green.png";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";

const BasicThree = ({
  step,
  formData,
  handleChange,
  prevStep,
  emailValidCheck,
  handleKeyPress,
  setFormData,
  setConsentError,
  register,
  getValues,
  errors,
  trigger,
  nextStep,
  visitorParameters,
  phoneValidCheck,
  clearErrors
}) => {

  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();


  const handleInputChange = (e) => {
    clearErrors([
      "txtEmail",
      "txtPhone"
      
    ]);
    handleChange(e); // Call parent handleChange function

  
  };

  const handleNextClick = async (e) => {
  
    const istxtEmail = await trigger("txtEmail");

    if (!istxtEmail) {
      return;
    }
    const istxtPhone = await trigger("txtPhone");

    if (!istxtPhone) {
      return; 
    }
    validateEmail(e); 
  };

  const validateEmail = async (e) => {
    console.log(e.target);
    const email = getValues("txtEmail");
    const phone = getValues("txtPhone");
    console.log("email",phone,email)
    if (email != "" && phone != "") {
      const getPhoneValidation = await phoneValidation(phone);
      const getEmailValidation = await emailValidation(
        email,
        visitorParameters.visitor_parameters.uuid
      );
      console.log("e",getEmailValidation.data.status_code)
      console.log("p",getPhoneValidation.data.status_code)

     if (getEmailValidation.data.status_code === 1) {
       

        if (getPhoneValidation.data.status_code === 1) {
         
            nextStep();
        
       }
       
      } else {
       // setEmailError("Invalid email.");
      }
    }
  };
  return (
    <div className={step === 3 ? 'show' : 'hide'}>
      
        <div className="slide_4Dec">
          <h2 className="animate__animated animate__fadeInUp animate__delay-.5s animated-element">
            Please provide your email and phone number below so that we can keep
            you updated on your potential refund.
          </h2>
          <div className="px-3 py-3 widget_mobi borderx">
            <div className="form_drop col-lg-12 col-12" id="ENt_DTLS">
              <div className="check_email_add pt-0 animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                <label>Email Address: </label>
                <div className="input-group flex-nowrap secure">
                  <input
                    type="email"
                    className={`form-control ${
                      errors.txtEmail ? "is-invalid" : ""
                    }`}
                    placeholder="Email Address"
                    aria-label="Username"
                    aria-describedby="addon-wrapping"
                    id="txtEmail"
                    name="txtEmail"
                   // value={formData.txtEmail}
                    onChange={handleInputChange}
                    onBlur={emailValidCheck}
                    ref={register({
                      required: "Email Address is required",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Please enter a valid email address",
                      },
                    })}
                  />
                </div>
                {errors.txtEmail && (
                    <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                      {errors.txtEmail.message}
                    </p>
                  )}
                <p className="tiny_note mb-2">
                  <img src={info} className="pe-1" alt="" />
                  We need this so we can keep you updated on your claim by
                  email.
                </p>
              </div>
              <div className="row phone_deatail animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                <div className="col-12 user_no">
                  <label>Mobile Number:</label>
                  <div className="input-group flex-nowrap secure">
                    <input
                      type="tel"
                      className={`form-control ${
                        errors.txtPhone ? "is-invalid" : ""
                      }`}
                      placeholder="Mobile Phone Number"
                      aria-label="Phone Number"
                      aria-describedby="Phone-Number"
                      name="txtPhone"
                      id="txtPhone"
                      maxLength={11}
                      //value={formData.txtPhone}
                      onChange={handleInputChange}
                      onBlur={phoneValidCheck}
                      onKeyPress={handleKeyPress}
                      ref={register({
                        required: "Mobile Number is required",
                        pattern: {
                          value: /^[0-9]{10,11}$/,
                          message: "Please enter a valid mobile number",
                        },
                      })}
                    />
                  </div>
                    {errors.txtPhone && (
                      <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                        {errors.txtPhone.message}
                      </p>
                    )}
                  <p className="tiny_note">
                    <img src={info} className="pe-1" alt="" />
                    We need this so we can keep you updated on your claim with
                    text messages.
                  </p>
                </div>
              </div>
              <div
                className="sms-consent-div d-none"
                style={{ marginBottom: "20px" }}
              >
                <div style={{ textAlign: "left", marginBottom: "10px" }}>
                  <input
                    checked={formData.unsubscribeStatus === 0 ? true : false}
                    type="checkbox"
                    name=""
                    id="smsConsent1"
                    onChange={(e) => (
                      setFormData({
                        ...formData,
                        unsubscribeStatus: e.target.checked ? 0 : null,
                      }),
                      setConsentError("")
                    )}
                  />
                  <label className="sms-consent-label" htmlFor="smsConsent1">
                    Yes please keep me informed about my refund via SMS + Email
                  </label>
                </div>
                <div style={{ textAlign: "left" }}>
                  <input
                    checked={formData.unsubscribeStatus === 1 ? true : false}
                    type="checkbox"
                    name=""
                    id="smsConsent0"
                    onChange={(e) => (
                      setFormData({
                        ...formData,
                        unsubscribeStatus: e.target.checked ? 1 : null,
                      }),
                      setConsentError("")
                    )}
                  />
                  <label className="sms-consent-label" htmlFor="smsConsent0">
                    No thanks, I don't want to stay informed about my refund via
                    SMS + Email
                  </label>
                </div>
              </div>
            </div>
            <div
              className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s animated-element"
              id="subRegNo4"
            >
              <div className="row align-items-center">
                <div className="col-4 text-center ">
                  <button
                    type="button"
                    data-type="back"
                    id="backStep-3"
                    className="msf-nav-button backbutton"
                    onClick={prevStep}
                  >
                    <img src={nextgreen} alt="" />
                    Back
                  </button>
                </div>
                <div className="col-8">
                  <div className="bg-1 hme_btn w-100">
                    <button
                      type="button"
                      id="nextslideCl_004"
                      value="Next"
                      aria-label="submitid"
                      name="subRegNo4"
                      className="button w-100 next-btn"
                      onClick={handleNextClick}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
    </div>
  );
};

export default BasicThree;
