import { useRedirection } from '../Hooks/useRedirection';
import { AppLpRedirectDefaultUrl, AppAdtopiaUPID } from '../Constants/EnvConstants';
import { urlParams } from "../Utility/QueryString";

export const CheckRedirectUrl = () => {
  const { getRedirectionUrl } = useRedirection();
  const lp_id = urlParams.get('lp_id');
  var redirectURL = "";
  const isLpUrlExist = async () => {
    if(localStorage.getItem('redirectURL') != '' && localStorage.getItem('redirectURL') != null){
      redirectURL = localStorage.getItem('redirectURL');
      console.log("redirectURL from local storage: " + redirectURL);
  } else {
    if (lp_id) {
      const getdtopiaLPApi = await getRedirectionUrl(
        lp_id,
        AppAdtopiaUPID
      );
      if (typeof getdtopiaLPApi !== "undefined") {
        if (getdtopiaLPApi.data.response === 200) {
          redirectURL = getdtopiaLPApi.data.page_url;
        } else if (getdtopiaLPApi.data.response === 404) {
          redirectURL = AppLpRedirectDefaultUrl;
        }
      }
    } else {
      redirectURL = AppLpRedirectDefaultUrl;
    }
  }
    return redirectURL;
  }
  return { isLpUrlExist }
}