import React, { useEffect, useRef, useReducer, useState, useContext } from 'react'
import banner_img2 from "../../../../assets/PCP_HDSC_V5/img/banner-img2.png"
import confetti from 'canvas-confetti';
import { usePostCodeAddress } from "../../../../Hooks/usePostCodeAddress";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import PostCode_PCP_HDSC_V5 from './PostCode_PCP_HDSC_V5';
import Button from '../../../UI/Button';

const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "d-none",
  getPostcode: "#",
  clickManualLink: 0,
};

const postcodeReducer = (state, action) => {
  switch (action.type) {
    case "validationSucess": {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "getExtraAddressDetails": {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address,
      };
    }
    case "showManualDiv": {
      return {
        ...state,
        showManualAddress: "d-block",
        lookUp: false,
        nextClick: true,
        checkSelect: false,
        clickManualLink: action.payload.manual_click,
      };
    }
    case "resetaddress": {
      return {
        ...state,
        getDetails: [],
        getAddress: [],
        showManualAddress: "d-none",
        lookUp: true,
        nextClick: false,
        checkSelect: false,
      };
    }
    default:
      return state;
  }
};

const PostCodeDetails = (props) => {
  const canvasRef = useRef(null);
  const inputRef = useRef(null);
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const { getPostcodeAddress, getSplitPostcodeAddress } = usePostCodeAddress();
  const { visitorParameters } = useContext(VisitorParamsContext);


  useEffect(() => {
    if (props.currentPage !== "Postcode") return;

    const canvas = canvasRef.current;
    if (!canvas) return;

    const myConfetti = confetti.create(canvas, {
      resize: true, // will fit all screen sizes
      useWorker: true // will use a web worker to improve performance
    });

    // Trigger the confetti animation
    myConfetti({
      particleCount: 180,
      spread: 70,
      origin: { y: 1 }
    });
  }, [props.currentPage]);


  return (
    <div>
      <div className={`home_plate_005 ${props.currentPage !== "Postcode" && "d-none"}`}>
        <div className="confettiCanvas">
          <canvas ref={canvasRef} id="confettiCanvas" />
        </div>
        <div className="row justify-content-center ">
          <div className="cs_hero_text col-lg-7 col-12 order-lg-1 order-md-1 order-2">
            <div className="plateTwo_anim">
              <h3 className="top_pad_Lil animate__animated animate__fadeInLeft animate__delay-.5s">
                Congratulations!
              </h3>
              <h3 className="top_pad_Lil sub_bl2Xw pb-3 animate__animated animate__fadeInLeft animate__delay-.5s">
                According to your responses, it seems you might be eligible for compensation.
              </h3>
              <div className="form_drop col-lg-12 col-12">
                <div
                  className="sect_bt animate__animated animate__fadeInLeft animate__delay-.5s"
                  style={{ zIndex: 2, position: "relative" }}
                >
                  <p className="png_t">
                    Where should we send your refund cheque?
                  </p>
                  <div className="enter_postcode_add animate__animated animate__fadeInLeft animate__delay-.5s">
                    <label>Postcode</label>
                    <PostCode_PCP_HDSC_V5
                      validation={props.validation}
                      validationMsg={props.validationMsg}
                      trigger={props.trigger}
                      clearErrors={props.clearErrors}
                      setValue={props.setValue}
                    />

                  </div>
                </div>
                <div className="box_button pt-3 animate__animated animate__fadeInLeft animate__delay-.5s">
                  <div className="bg-1">
                    <Button
                      type="button"
                      className="button button--moema button--text-thick button--text-upper button--size-s h60_click blick_notice"
                      buttonText={
                        <span>Next</span>
                      }
                      id="nextPostCode"

                      onClick={props.postCodeNextClick}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="img_float animate__animated animate__fadeInLeft animate__delay-.5s order-lg-2 order-md-2 order-1 col-lg-5 col-12 text-center d-lg-block d-md-block d-none">
            <img
              src={banner_img2}
              alt="Hero"
              className="cs_hero_img"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PostCodeDetails