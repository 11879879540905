import React, { useEffect, useState, useContext } from 'react';
import "../../../assets/PCP_HDSC_V1/css/idupload.scss";
import logo from "../../../assets/PCP_HDSC_V1/img/hendersons_logo.png";
import uploaddemo from "../../../assets/PCP_HDSC_V1/img/upload-demo.jpg"
import browse from "../../../assets/PCP_HDSC_V1/img/browse.png"
import Header_PCP_HDSC_V1 from '../../Forms/PCP_HDSC_V1/Header_PCP_HDSC_V1';
import Button from '../../UI/Button';
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { queryString } from "../../../Utility/QueryString";
import { useIdUpload } from "../../../Hooks/useIdUpload";
import GetVisitorsParams from '../../../Utility/GetVisitorsParams';
import Footer from '../../Includes/Layouts/PCP_HDSC_V1/Footer';
import { Finance } from '../../../Constants/ConstantsPCP';
import { useCarRegistrationSkipAll } from '../../../Hooks/useCarRegistrationSkipAll';

const PcpIdUpload = () => {
  const [showSlide2, setShowSlide2] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [idType, setIdType] = useState('');
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  const [isInValidfFile, setIsInValidfFile] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState(false);
  const [userfile, setUserfile] = useState({ preview: '', title: '' })
  const [btnLoader, setBtnLoader] = useState(false);
  const handleNextUploadClick = (e) => {
    setIdType(e.target.id)
    setShowSlide2(true);
  };
  const [base64Image, setBase64Image] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const { saveIdUpload, isLoading } = useIdUpload();
  const { storeSkipallValues } = useCarRegistrationSkipAll();
  const getUuid = () => {
    const visitorData = visitorParameters.visitor_parameters.uuid;
    if (visitorData) {
      var uuid = visitorData;
    } else {
      const query = new URLSearchParams(window.location.search);
      const query_uuid = query.get("uuid");
      const local_storage_uuid = localStorage.getItem("uuid");
      var uuid = query_uuid ? query_uuid : local_storage_uuid;
    }
    return uuid;
  };
  const uuid = getUuid();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const timeout = setTimeout(() => {
      setShowLoader(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    let imageFile = event.target.files[0];
    if (imageFile) {
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;
      let allowedExtensions = ["image/jpeg", "image/png"];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage({
          txt: "The uploaded ID type should be an image in jpeg,png or jpg",
        });
        return false;
      } else if (imgsize > 1024 * 1024 * 20) {
        setIsInValidfFile(true);
        setErrorMessage({ txt: "Please choose a file less than 20mb" });
        return false;
      } else {
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
        setIsFileSelected(true);
      }

      setUserfile({ preview: URL.createObjectURL(imageFile), title: imageFile.name })
      if (imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsInValidfFile(true);
      setUserfile({ preview: '', title: '' })
    }
  };
  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setBase64Image({
      base64TextString: binaryString,
    });
  };
  const retakeClick = (e) => {
    setUserfile(({ preview: '', title: '' }));
    setIsFileSelected(false);
    setSelectedFile('');
  };
  const handleSubmit = async (event) => {
    setBtnLoader(true);
    event.preventDefault();
    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");

    let uploadData = "";
    if (isFileSelected) {
      uploadData = {
        imageFile: base64Image.base64TextString,
        captureType: selectedFileType.split('_')[0],
        documentType: selectedFileType.split('_')[0],
      };
      let providers = '';
      const response = await saveIdUpload(
        uploadData,
        visitorParameters.visitor_parameters,
        formParameters,
        visitorParameters.data,
        providers,
        queryString,
        "user_docs_store"
      );
      setSelectedFile('');
      setBase64Image('');
      setSelectedFileType('');
      // history.push(`/thankyou?uuid=${uuid}`);

      const slugs = JSON.parse(localStorage.getItem("slugArray"));
      const carRegRequired = slugs.find((slug) =>
        Finance.some(
          (financeItem) =>
            financeItem.slug === slug && financeItem.car_reg === true
        )
      );
      const carRegNotRequiredValues = slugs.flatMap((slug) =>
        Finance
          .filter(
            (financeItem) =>
              financeItem.slug === slug && (financeItem.car_reg === false || !('car_reg' in financeItem))
          )
          .map((financeItem) => financeItem.value)
      );

      if (carRegRequired) {
        history.push(`/CarRegistration?${query.toString()}`);
      } else {
        if (carRegNotRequiredValues.length !== 0) {
          storeSkipallValues(
            visitorParameters.visitor_parameters,
            "car_registration",
            "store-skip-all-vehicle-details",
            carRegNotRequiredValues
          );
        }

        history.push(`/thankyou?${query.toString()}`);
      }
    } else {
      setBtnLoader(false)
      setIsInValidfFile(true);
      setErrorMessage({ txt: "Please upload a file" });
    }

  };
  return (
    <div className='hendersons_idupload'>
      <GetVisitorsParams />
      <>

        {showLoader && (
          <div id="preloader">
            <div id="loader" />
          </div>
        )}
        <Header_PCP_HDSC_V1 />
        <section className="upload_sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <form className="form_action" action="">
                  <h2>Now, let's verify your identity</h2>
                  <h4>You must provide 1 proof(s) of address to proceed.</h4>
                  <h5>
                    Your ID will be stored securely and used solely for the purpose of
                    identity verification.
                  </h5>
                  <div className="upload_but_sec col-lg-4 col-12 mt-3 mb-5 offset-lg-4">
                    {!showSlide2 ? (
                      <div className="slide1">
                        <h3>Please provide a photo of one of the following</h3>
                        {/* <Button type="button" className="uploadbut nextupload" name="idType" id="nationalIdCard" onClick={handleNextUploadClick}
                          buttonText="National ID Card" /> */}
                        <Button type="button" className="uploadbut nextupload" name="idType" id="drivingLicense" onClick={handleNextUploadClick}
                          buttonText="Driver's License" />
                        <Button type="button" className="uploadbut nextupload" name="idType" id="passport" onClick={handleNextUploadClick} buttonText="Passport" />

                        <div className="needidproof">
                        <h4>Why We Need Your ID Proof</h4>
                        <p>To ensure a smooth and secure process for your claim application, we require you to provide valid identification proof. This is a mandatory step for several reasons:</p>
                        <ul>
                          <li><strong>Identity Verification:</strong> To verify your identity and ensure you are the rightful applicant.</li>
                          <li><strong>Fraud Prevention:</strong>  To protect against fraudulent activities and safeguard your claim.</li>
                          <li><strong>Regulatory Compliance:</strong> To comply with legal and regulatory requirements.</li>
                          <li><strong>Efficient Processing:</strong>  To process your claim accurately and efficiently.</li>
                        </ul>
                        <p>Rest assured, your personal information will be handled with the utmost care and confidentiality, safeguarding your privacy throughout the entire process.</p>
                        </div>

                      </div>
                    ) : (
                      <div className="slide2 id_view row">
                        <h3 className="head3">Take or upload a picture of your ID</h3>
                        <div className="col-12 col-lg-12 text-center pb-3">

                          {isFileSelected ? (
                            <img src={userfile.preview} alt={userfile.title} className="img-thumbnail" />
                          ) : (
                            <img
                              src={uploaddemo}
                              className="img-thumbnail"
                              alt=""
                            />
                          )}
                        </div>
                        {!isFileSelected && <><div className="col-lg-12 col-12 button-uploadsec text-center b1">
                          <span className="browsebut next-sub">
                            <img src={browse} alt="" /> Take Photo{" "}
                            <input
                              type="file"
                              accept="image/*"
                              capture="camera"
                              id={`${idType}_cam`}
                              name={`${idType}_cam`}
                              onChange={handleFileChange}
                            />
                          </span>
                        </div>
                          <div className="col-lg-12 col-12 button-uploadsec text-center b1">
                            <span className="browsebut next-sub">
                              <img src={browse} alt="" /> Upload Image{" "}
                              <input
                                type="file"
                                accept="image/*"
                                id={`${idType}_doc`}
                                name={`${idType}_doc`}
                                onChange={handleFileChange}
                              />
                            </span>
                          </div></>}
                        {isFileSelected && (<div className="col-lg-12 col-12 button-uploadsec text-center btsub">
                          <Button type="button" className="browsebut" buttonText="Retake" onClick={(e) => retakeClick(e)} />
                            <span
                              className={`uploadbut btn_submit ${!btnLoader && "pulse"} text-center`}
                              onClick={(e) => (!btnLoader ? handleSubmit(e) : null)}
                            >
                              SUBMIT
                              {btnLoader &&
                                <svg className="ms-2" width={30} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><linearGradient id="a10"><stop offset="0" stop-color="#FFFFFF" stop-opacity="0"></stop><stop offset="1" stop-color="#FFFFFF"></stop></linearGradient><circle fill="none" stroke="url(#a10)" stroke-width="25" stroke-linecap="round" stroke-dasharray="0 44 0 44 0 44 0 44 0 360" cx="100" cy="100" r="70" transform-origin="center"><animateTransform type="rotate" attributeName="transform" calcMode="discrete" dur="2" values="360;324;288;252;216;180;144;108;72;36" repeatCount="indefinite"></animateTransform></circle></svg>
                              }
                            </span>
                        </div>)}
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    </div>
  );
};

export default PcpIdUpload;
