import React from "react";
import info from "../../../../assets/PCP_HDSC_V1/img/info.png";
import nextgreen from "../../../../assets/PCP_HDSC_V1/img/next-green.png";
import {nameValidate} from "../../../../Utility/Validations";
import { Salutation } from "../../../../Constants/Constants";

const BasicTwo = ({
  step,
  formData,
  handleChange,
  prevStep,
  nextStep,
 
  register,
  getValues,
  errors,
  trigger,
  clearErrors
}) => {
  const handleInputChange = (e) => {
    clearErrors([
      "lstSalutation",
      "txtFName",
      "txtLName" 
    ]);
    handleChange(e); 
   
  };

  const handleNextClick = async () => {

    const isSalutationValid = await trigger("lstSalutation");

    if (!isSalutationValid) {
      return; // Do not set an error state here
    }
    const isFirstNameValid = await trigger("txtFName");

    if (!isFirstNameValid) {
      return; // Do not set an error state here
    }
    const isLastNameValid = await trigger("txtLName");

    if (!isLastNameValid) {
      return; 
    }
    nextStep(); 
  };
  return (
    <div>
      <div className={`${step !== 2 && "d-none"} slide_2Dec`}>
        <h2 className="animate__animated animate__fadeInUp animate__delay-.5s animated-element">
          Personalise your experience. May we know your name?
        </h2>
        <div className="px-3 py-3 widget_mobi borderx">
          <div className="form_drop w-100">
            <div className="row">
              <div className="col-12">
                <label
                  htmlFor="format2"
                  className="text-start w-100 animate__animated animate__fadeInUp animate__delay-.5s animated-element"
                >
                  Title
                </label>
                <div className="select_title animate__animated animate__fadeInUp animate__delay-.5s animated-element">
                  <select
                    //className="form-select form-control"
                    className={`form-select form-control ${
                      errors.lstSalutation ? "is-invalid" : ""
                    }`}
                    id="lstSalutation"
                    name="lstSalutation"
                    //value={formData.lstSalutation}
                    onChange={handleInputChange}
                    ref={register({
                      required: "Please select a title",
                    })}
                  >
                    {Salutation.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                {/* {titleError && (
                  <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                    {titleError}
                  </p>
                )} */}

{errors.lstSalutation && (
                  <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                    {errors.lstSalutation.message}
                  </p>
                )}
              </div>
            </div>
            <div className="row   name_detail animate__animated animate__fadeInUp animate__delay-.5s animated-element">
              <div className="col-12 f_name mb-3">
                <label htmlFor="topmention1">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="txtFName"
                  placeholder="First name"
                  aria-label="First name"
                  name="txtFName"
                 // value={formData.txtFName}
                  onChange={handleInputChange}
                  onKeyPress={(e) => nameValidate(e)}
                  ref={register({
                    required: "Please Enter First Name",
                    minLength: {
                      value: 3,
                      message: "Please Enter Valid First Name",
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message: "Your Name is Invalid. Please Recheck",
                    },
                  })}
                />
                {/* {firstNameError && (
                  <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                    {firstNameError}
                  </p>
                )} */}
                  {errors.txtFName && (
                  <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                    {errors.txtFName.message}
                  </p>
                )}
              </div>

              <div className="col-12 l_name mb-3">
                <label htmlFor="topmention2">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="txtLName"
                  placeholder="Last name"
                  aria-label="Last name"
                  name="txtLName"
                  onKeyPress={(e) => nameValidate(e)}
                  onChange={handleInputChange}
                  ref={register({
                    required: "Please Enter Last Name",
                    minLength: {
                      value: 3,
                      message: "Please Enter Valid Last Name",
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message: "Your Name is Invalid. Please Recheck",
                    },
                  })}
                />
                {/* {lastNameError && (
                  <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                    {lastNameError}
                  </p>
                )} */}
                 {errors.txtLName && (
                  <p className="tiny_note animate__animated animate__fadeInUp animate__delay-.5s validate">
                    {errors.txtLName.message}
                  </p>
                )}
              </div>

              <p className="tiny_note m-0">
                <img src={info} className="pe-1" alt="" />
                What you are legally known as
              </p>
            </div>
          </div>
          <div
            className="box_button pt-3 animate__animated animate__fadeInUp animate__delay-.5s animated-element"
            id="subRegNo3"
          >
            <div className="row align-items-center">
              <div className="col-4 text-center">
                <div className="bg-1 hme_btn">
                  <button
                    type="button"
                    data-type="back"
                    id="backStep-2"
                    className="msf-nav-button backbutton"
                    onClick={prevStep}
                  >
                    <img src={nextgreen} alt="" />
                    Back
                  </button>
                </div>
              </div>
              <div className="col-8">
                <button
                  type="button"
                  className="button w-100 next-btn"
                  id="nextslideCl_003"
                  value="Next"
                  aria-label="submitid"
                  name="subRegNo3"
                  onClick={handleNextClick}
                >
                  {" "}
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicTwo;
