import React from "react";
import GTMDataLayer from "../../Utility/GTMDataLayer";

const InputButton = ({ value, className, name, id, onClick, btnType, disabled, style,gtm_question,gtm_trigger,gtm_answer,clickevent }) => {
  const handleButtonClick = (e) => {
    if (e.target.dataset.gtm_trigger) {
      GTMDataLayer({
        clickEvent: e.target.dataset.clickevent,
        question: e.target.dataset.gtm_question,
        answer: e.target.dataset.gtm_answer,
      });
    }
    onClick(e);
  };
  return (
    <input
      className={className}
      name={name}
      id={id}
      style={style}
      onClick={handleButtonClick}
      type={btnType}
      disabled={disabled}
      value={value}
      data-gtm_question={gtm_question}
      data-gtm_trigger={gtm_trigger}
      data-gtm_answer={gtm_answer}
      data-clickevent={clickevent}
    />
  );
};
export default InputButton;
