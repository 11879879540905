import { Api } from "../api/Api";
import {useErrorWebhook} from "./useErrorWebhook";

export const useUserAgent = () => {
  const { ErrorWebhookTrigger } = useErrorWebhook();
  const getUserAgent = async (
    user_agent,
    page_type,
    query_string,
    domain_name,
    existingdomain,
    referer_site,
    uuid
  ) => {
    const response = await Api.post("v1/user-agent", {
      user_agent,
      page_type,
      query_string,
      domain_name,
      existingdomain,
      referer_site,
      uuid,
    }).catch(function (error) {
      const erroMessage = {
        serviceName : 'USER AGENT',
        errorReason:error.message,
        errorCode:error.code
      }
      ErrorWebhookTrigger(erroMessage);
    })
    return response;
  };
  return { getUserAgent };
};
