import React, { useEffect } from 'react'
import Footer from '../../Includes/Layouts/PCP_HDSC_V5/Footer'
import Header from '../../Includes/Layouts/PCP_HDSC_V5/Header'
import "../../../assets/PCP_HDSC_V5/css/custom.scss"
import success_tick from "../../../assets/PCP_HDSC_V5/img/success-tick.gif"

const ThankyouV5 = () => {
  localStorage.clear();
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }, [])
  return (
    <>
      <div className="PCP_HDSC_V5">
        <div className="__PCPtcg">
          <div className="bg_lol">
            {/*TOP NAVIGATION*/}
            <Header />
            <section className="cs_hero cs_style_1 sec_design section_design1">
              <div className="cs_hero_wrap cs_bg_filed">
                {/*Gradient*/}
                <div className="container-md">
                  <div className=" success-box col-lg-6 col-md-8 offset-lg-3 offset-md-2 rounded-3 shadow-md  text-center">
                    <div>
                      <div className="mb-lg-5 mb-md-5 mb-sm-3 mb-3">
                        <img height={130} src={success_tick} alt="" />
                      </div>
                      <div>
                        <h2> Thank You</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default ThankyouV5